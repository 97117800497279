import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { fadeIn, fadeInDown } from '@shared/animations';
import { PageComponent } from '@app/shared/models/page-component';

@Component({
  selector: 'app-forgot-user-page',
  templateUrl: './forgot-user-page.component.html',
  styleUrls: ['./forgot-user-page.component.scss'],
  animations: [fadeInDown, fadeIn],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotUserPageComponent extends PageComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
