import {
  SearchHistoryActions,
  SearchHistoryActionTypes
} from '../actions/search-history.action';

export interface SearchHistoryState {
  results: any;
  loaded: boolean;
  loading: boolean;
}

const initialState: SearchHistoryState = {
  results: [],
  loaded: false,
  loading: false
};

export function reducer(
  state = initialState,
  action: SearchHistoryActions
): SearchHistoryState {
  switch (action.type) {
    case SearchHistoryActionTypes.SearchHistoryLoad: {
      return {
        ...state,
        loading: true
      };
    }
    case SearchHistoryActionTypes.SearchHistoryLoadSuccess: {
      const searchHistoryConst = action.payload.results;

      return {
        ...state,
        loading: false,
        loaded: true,
        results: searchHistoryConst
      };
    }
    case SearchHistoryActionTypes.SearchHistoryLoadFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case SearchHistoryActionTypes.ResetSearchHistoryState: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export const getSearchHistory = (state: SearchHistoryState) => state.results;
export const getSearchHistoryLoading = (state: SearchHistoryState) =>
  state.loading;
export const getSearchHistoryLoaded = (state: SearchHistoryState) =>
  state.loaded;
