import { Injectable } from '@angular/core';
import { ApiService } from '@core/api.service';
import { Observable, of } from 'rxjs';
import {
  IShowcase,
  IShowcaseGroup
} from '@pages/dashboard/models/showcase.model';
import { map } from 'rxjs/internal/operators';
import { CachedRequestService } from '@app/core/service/cached-req.service';
import { IBanner } from '../models/banner.model';

@Injectable()
export class ShowcaseService {
  cachedListTimeoutHandler: any;

  constructor(
    private apiService: ApiService,
    private cachedRequestService: CachedRequestService,
  ) {}

  /**
   * Get showcase list data from API.
   * @returns {Observable<any>}
   */
  getShowcases(): Observable<IShowcase[]> {
    return this.apiService.get(`/products/dashboard-items/items/`).pipe(
      map(data => {
        return data.map(res => {
          res.showRealPicture = false;
          return res;
        });
      })
    );
  }

  getBanners(): Observable<IBanner[]> {
    return this.apiService.get('/products/banner-items/items/', { _ems_silenced: 'true' }).pipe(
      map(data => data.map(res => {
        res.showRealPicture = false;
        res.isLocalUrl = false;
        return res;
      }))
    );
  }
  updateBannerField(id: number, field: string, value: string): Observable<IBanner>{
    return this.apiService.patch(`/products/banner-items/${id}/`, { [field]: value }).pipe(
      map(res => {
        res.showRealPicture = false;
        res.isLocalUrl = false;
        return res;
      })
    );
  }

  getGroups(): Observable<IShowcaseGroup[]> {
    if (this.cachedRequestService.hasKey('cached-groups')) {
      return of(this.cachedRequestService.getItem('cached-groups'));
    } else {
      return this.apiService.get(`/products/dashboard-groups/`).pipe(
        map(data => {
          if (this.cachedListTimeoutHandler) {
            clearTimeout(this.cachedListTimeoutHandler);
          }
          this.cachedRequestService.setItem('cached-groups', data.results);
          this.cachedListTimeoutHandler = setTimeout(
            () => this.cachedRequestService.clearItem('cached-groups'),
            3600000
          );
          return data.results;
        })
      );
    }
  }
}
