import { Action } from '@ngrx/store';
import { ILanguageItem } from '@core/models/language-switch';

export enum LanguageActionTypes {
  LanguagesLoad = '[Languages] Load Languages',
  LanguagesLoadFail = '[Languages] Load Languages Fail',
  LanguagesLoadSuccess = '[Languages] Load Languages Success',
  SetActiveLanguage = '[Languages] Set Active Language',
  ResetLanguagesState = '[Languages] Reset State'
}

export class LanguagesLoad implements Action {
  readonly type = LanguageActionTypes.LanguagesLoad;

  constructor() {}
}

export class LanguagesLoadFail implements Action {
  readonly type = LanguageActionTypes.LanguagesLoadFail;

  constructor(public payload: any) {}
}

export class LanguagesLoadSuccess implements Action {
  readonly type = LanguageActionTypes.LanguagesLoadSuccess;

  constructor(public payload: ILanguageItem[]) {}
}

export class ResetLanguagesState implements Action {
  readonly type = LanguageActionTypes.ResetLanguagesState;
}

export class SetActiveLanguage implements Action {
  readonly type = LanguageActionTypes.SetActiveLanguage;

  constructor(public payload: string) {}
}

export type LanguageActions =
  | LanguagesLoad
  | LanguagesLoadFail
  | LanguagesLoadSuccess
  | SetActiveLanguage
  | ResetLanguagesState;
