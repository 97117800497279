import { Injectable } from '@angular/core';
import { ApiService } from '@core/api.service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/internal/operators';

@Injectable()
export class SearchByCarService {
  private plateNumberStatus = new BehaviorSubject<any>({found: false, reload: false, state: 0, value: ''});
  plateNumberStatus$ = this.plateNumberStatus.asObservable();

  constructor(
    private apiService: ApiService
  ) {}

  emitChange(data: any) {
    this.plateNumberStatus.next(data);
  }

  getPlateNumberSuggestion(plateNumber = null) {
    return this.apiService
      .get(`/users/plate_number/${plateNumber}/`)
      .pipe(map(data => data));
  }

  getPlateNumberSearches() {
    return this.apiService
      .get(`/statistics/plate_number_searches/`)
      .pipe(map(data => data));
  }
}
