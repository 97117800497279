import { Action } from '@ngrx/store';

export enum ShowcaseActionTypes {
  ShowcaseLoad = '[Dashboard] Load Showcase',
  ShowcaseLoadFail = '[Dashboard] Load Showcase Fail',
  ShowcaseLoadSuccess = '[Dashboard] Load Showcase Success',
  LoadGroups = '[Dashboard] Load Groups',
  LoadGroupsSuccess = '[Dashboard] Load Groups Success',
  LoadGroupsFail = '[Dashboard] Load Groups Fail',
  ResetShowcaseState = '[Dashboard] Reset Showcase State'
}

/**
 * Load Showcase Actions
 */
export class LoadShowcase implements Action {
  readonly type = ShowcaseActionTypes.ShowcaseLoad;
}

export class LoadShowcaseSuccess implements Action {
  readonly type = ShowcaseActionTypes.ShowcaseLoadSuccess;

  constructor(public payload: any) {}
}

export class LoadShowcaseFail implements Action {
  readonly type = ShowcaseActionTypes.ShowcaseLoadFail;

  constructor(public payload: any) {}
}

export class LoadGroups implements Action {
  readonly type = ShowcaseActionTypes.LoadGroups;
}

export class LoadGroupsSuccess implements Action {
  readonly type = ShowcaseActionTypes.LoadGroupsSuccess;

  constructor(public payload: any) {}
}

export class LoadGroupsFail implements Action {
  readonly type = ShowcaseActionTypes.LoadGroupsFail;

  constructor(public payload: any) {}
}

export class ResetShowcaseState implements Action {
  readonly type = ShowcaseActionTypes.ResetShowcaseState;
}

export type ShowcaseAction =
  | LoadShowcase
  | LoadShowcaseSuccess
  | LoadShowcaseFail
  | LoadGroups
  | LoadGroupsSuccess
  | LoadGroupsFail
  | ResetShowcaseState;
