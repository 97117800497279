<div class="footer-contacts" [@fadeIn] *ngIf="contacts">
  <div class="col">
    <div class="contacts-container row">
      <ng-container *ngFor="let contact of contacts">
        <div class="contact-box col" *ngIf="(contact.country | uppercase) === (currentLanguage | uppercase)">
          <p>{{'SK_ONLY_FOOTER_HEADER' | translate}}</p>
          <h4 class="contacts-name">
            <a [href]="contact.home_url" target="_blank">{{contact.city}}</a>
          </h4>
          <p class="contacts-address" *ngIf="contact.latitude && contact.longitude">
            <a href="https://www.google.com/maps/?q={{contact.latitude}},{{contact.longitude}}" target="_blank">
              <i class="fas fa-map-marked-alt"></i>
              {{contact.address}}
            </a>
          </p>
          <div class="contacts-phones">
            <p *ngFor="let phone of phoneSplit(contact.phone)">
              <a href="tel:{{phone}}">{{phone}}</a>
            </p>
          </div>
          <p class="contacts-email" *ngIf="contact.email">
            <a href="mailto:{{contact.email}}">{{contact.email}}</a>
          </p>
          <div class="contacts-work-hours">
            <p *ngFor="let work_hours of fieldSplit(contact.work_hours)">
              {{work_hours}}
            </p>
          </div>
          <p *ngIf="contact.service">{{'TRUCK_SERVICE' | translate}}</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>
