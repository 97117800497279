<div *ngIf="itemsExpired && itemsExpired.length > 0" class="table-container">
  <app-table [isConfigTable]="false">
    <table class="table table-condensed">
      <thead tableThead
             class="ant-table-thead"
             [filter]="false"
             [actions]="true"
             [columnMaps]="extraSettings">
      </thead>
      <tbody>
        <ng-container *ngFor="let product of itemsExpired">
          <tr>
            <td [title]="'ADD_TO_CART' | translate" class="actions-column action" (click)="addProduct(product)">
              <div class="flex-start">
                <div class="add-to-cart">
                    <i class="material-icons cart-icon">add_shopping_cart</i>
                </div>
              </div>
            </td>
            <td class="product-photo">
              <img *ngIf="product.picture_url" src="{{base_url}}{{product.picture_url}}?width=100" [alt]="product.name">
            </td>
            <td class="product-details">
              <div *ngIf="product.name" class="detail full">
                <p><strong>{{product.name}}</strong></p>
              </div>
              <div *ngIf="product.brand" class="detail">
                <p>{{'BRAND' | translate}}: <strong>{{product.brand}}</strong></p>
              </div>
              <div *ngIf="product.skn" class="detail">
                <p>{{'SKN' | translate}}: <strong>{{product.skn}}</strong></p>
              </div>
              <div *ngIf="product.sub_group_name" class="detail">
                <p>{{'SUBGROUP' | translate}}: <strong>{{product.sub_group_name}}</strong></p>
              </div>
              <div *ngIf="product.manufacturer_name" class="detail">
                <p>{{'MANUFACTURER' | translate}}: <strong>{{product.manufacturer_name}}</strong></p>
              </div>
              <div *ngIf="product.warehouse_title" class="detail">
                <p>{{'WAREHOUSE' | translate}}: <strong>{{product.warehouse_title}}</strong></p>
              </div>
              <div *ngIf="product.weight" class="detail">
                <p>{{'WEIGHT' | translate}}: <strong>{{product.weight | customNumber : 3 | async}}</strong></p>
              </div>
              <div *ngIf="product.dimensions" class="detail">
                <p>{{'DIMENSION' | translate}}: <strong>{{product.dimensions}}</strong></p>
              </div>
            </td>
            <td>{{ product.quantity }}</td>
            <td>{{ product.note }}</td>
            <td *ngIf="product.ndo">{{ (product.ndo.toUpperCase() | translate) }}</td>
            <td *ngIf="showUserClientRef">{{ getUserClientSelectorValue(product.client_ref_id) }}</td>
            <td [title]="'VIEW' | translate" class="actions-column action" (click)="viewProduct(product.skn)">
              <div class="flex-start">
                <div class="add-to-cart">
                    <i class="material-icons">search</i>
                </div>
              </div>
            </td>
            <td 
              [title]="'REMOVE' | translate"
              class="actions-column action"
              nz-popconfirm
              [nzTitle]="'DELETE_CONFIRMATION_TEXT' | translate"
              (nzOnConfirm)="removeProduct(product)"
              [nzCancelText]="'DELETE_CANCEL_TEXT' | translate"
              [nzOkText]="'DELETE_OK_TEXT' | translate"
              nzPopconfirmPlacement="bottom"
              >
              <div
                class="flex-start"
              >
                <div class="add-to-cart">
                    <i class="material-icons cart-icon">remove_shopping_cart</i>
                </div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </app-table>
  <div class="more-btn" *ngIf="showLoadMoreBtn">
    <app-button text="MORE" (click)="loadItemsExpired()"></app-button>
  </div>
</div>