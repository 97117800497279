/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/ng-zorro-antd/modal/ng-zorro-antd-modal.ngfactory";
import * as i2 from "ng-zorro-antd/modal";
import * as i3 from "ng-zorro-antd/core";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "ng-zorro-antd/i18n";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/common";
import * as i8 from "../../shared/pipes/translate/translate.pipe";
import * as i9 from "../service/translation.service";
import * as i10 from "@ngrx/store";
import * as i11 from "./version-error.component";
var styles_VersionErrorComponent = [".vertical-center-modal {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n    }\n\n      .vertical-center-modal .ant-modal {\n      top: 0;\n    }"];
var RenderType_VersionErrorComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_VersionErrorComponent, data: { "animation": [{ type: 7, name: "fadeIn", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "400ms ease" }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "400ms ease" }], options: null }], options: {} }, { type: 7, name: "fadeInDown", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0, transform: "translateY(-2rem)" }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateY(0rem)" }, offset: null }, timings: "300ms ease-in" }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0, transform: "translateY(-2rem)" }, offset: null }, timings: "300ms ease-out" }], options: null }], options: {} }] } });
export { RenderType_VersionErrorComponent as RenderType_VersionErrorComponent };
export function View_VersionErrorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 9, "nz-modal", [], null, [[null, "nzVisibleChange"], [null, "nzOnOk"], [null, "nzOnCancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("nzVisibleChange" === en)) {
        var pd_0 = ((_co.isVisibleVersionErrorModal = $event) !== false);
        ad = (pd_0 && ad);
    } if (("nzOnOk" === en)) {
        var pd_1 = (_co.handleVROk() !== false);
        ad = (pd_1 && ad);
    } if (("nzOnCancel" === en)) {
        var pd_2 = (_co.handleVRCancel() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_NzModalComponent_0, i1.RenderType_NzModalComponent)), i0.ɵdid(1, 4964352, null, 1, i2.NzModalComponent, [i3.NzConfigService, i4.Overlay, i4.OverlayKeyboardDispatcher, i5.NzI18nService, i0.ComponentFactoryResolver, i0.ElementRef, i0.ViewContainerRef, i2.NzModalControlService, i6.FocusTrapFactory, i0.ChangeDetectorRef, [2, i2.NZ_MODAL_CONFIG], i7.DOCUMENT], { nzVisible: [0, "nzVisible"], nzClosable: [1, "nzClosable"], nzMaskClosable: [2, "nzMaskClosable"], nzStyle: [3, "nzStyle"], nzTitle: [4, "nzTitle"], nzOkText: [5, "nzOkText"], nzCancelText: [6, "nzCancelText"] }, { nzOnOk: "nzOnOk", nzOnCancel: "nzOnCancel", nzVisibleChange: "nzVisibleChange" }), i0.ɵqud(603979776, 1, { modalFooter: 0 }), i0.ɵpod(3, { top: 0 }), i0.ɵpid(131072, i8.TranslatePipe, [i9.TranslationService, i10.Store, i0.ChangeDetectorRef]), i0.ɵpid(131072, i8.TranslatePipe, [i9.TranslationService, i10.Store, i0.ChangeDetectorRef]), i0.ɵpid(131072, i8.TranslatePipe, [i9.TranslationService, i10.Store, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, null, 0, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpid(131072, i8.TranslatePipe, [i9.TranslationService, i10.Store, i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVisibleVersionErrorModal; var currVal_1 = false; var currVal_2 = false; var currVal_3 = _ck(_v, 3, 0, "20px"); var currVal_4 = i0.ɵunv(_v, 1, 4, i0.ɵnov(_v, 4).transform("INTERNET_VERSION_ERROR")); var currVal_5 = i0.ɵunv(_v, 1, 5, i0.ɵnov(_v, 5).transform("REFRESH")); var currVal_6 = i0.ɵunv(_v, 1, 6, i0.ɵnov(_v, 6).transform("DELETE_CANCEL_TEXT")); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_7 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform("INTERNET_VERSION_ERROR_DETAIL")); _ck(_v, 8, 0, currVal_7); }); }
export function View_VersionErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-version-error", [], null, null, null, View_VersionErrorComponent_0, RenderType_VersionErrorComponent)), i0.ɵdid(1, 4440064, null, 0, i11.VersionErrorComponent, [i10.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VersionErrorComponentNgFactory = i0.ɵccf("app-version-error", i11.VersionErrorComponent, View_VersionErrorComponent_Host_0, {}, {}, []);
export { VersionErrorComponentNgFactory as VersionErrorComponentNgFactory };
