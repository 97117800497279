import { Injectable } from '@angular/core';
import { ApiService } from '@core/api.service';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@environments/environment';

@Injectable()
export class VersionService {
  versionCheckTime: NodeJS.Timeout;
  versionCheckCounter = 0;
  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  public init(frequency = environment.version_check_time * 1000) {
    this.versionCheckTime = this.initVersionCheckFn(frequency);
  }

  initVersionCheckFn(frequency) {
    let calculatedInterval = frequency;

    switch (this.versionCheckCounter) {
      case 0:
        calculatedInterval = 3 * frequency;
        break;
      case 1:
        calculatedInterval = 6 * frequency;
        break;
      case 2:
        calculatedInterval = 10 * frequency;
        break;
      case 3:
        calculatedInterval = 15 * frequency;
        break;
      case 4:
        calculatedInterval = 30 * frequency;
        break;
      default:
        calculatedInterval = 60 * frequency;
        break;
    }

    return setInterval(() => {
      if (this.versionCheckCounter < 6) {
        this.versionCheckCounter += 1;
      }
      clearInterval(this.versionCheckTime);
      const subscription = this.apiService
        .getJSON(
          'frontend.json',
          '/version/frontend.json?' + new Date().getTime()
        )
        .subscribe(data => {
          subscription.unsubscribe();
          this.versionCheckTime = this.initVersionCheckFn(frequency);
          this.check(data);
        }, () => {
          this.versionCheckTime = this.initVersionCheckFn(frequency);
        });
    }, calculatedInterval);
  }

  check(data) {
    const hash = this.localStorageService.getItem('version');
    if (!hash) {
      this.localStorageService.setItem('version', data.commit.hash);
    } else if (data && data.commit && hash !== data.commit.hash) {
      this.localStorageService.setItem('version', data.commit.hash);

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          // eslint-disable-next-line deprecation/deprecation
          window.location.reload(true);
        }
      });
    } else if (data && data.commit && data.commit.hash) {
      this.localStorageService.setItem('version', data.commit.hash);
    }
  }
}
