<form *ngIf="products && cartForm && cartForm.controls['products'].value.length > 0"
      [formGroup]="cartForm"
      #formDir="ngForm"
      nz-form
      [ngClass]="{'not-submitted': !formSubmitAttempt}">
  <div class="app-cart-list-mini" formGroupName="products">
    <div class="product-row"
         *ngFor="let product of products; let i = index; trackBy: trackByFn"
         [formArrayName]="i">
      <div class="product-info product-col">
        <div class="product-row-top">
          <div class="product-photo" (click)="viewProduct(product.skn)">
            <img *ngIf="product.picture_url" src="{{base_url}}{{product.picture_url}}?width=100" [alt]="product.name">
          </div>
          <div class="product-description">
            <div class="title" (click)="viewProduct(product.skn)">
              <h4>{{product.name}}</h4>
            </div>
            <div class="product-details">
              <div *ngIf="product.brand" class="detail">
                <p>{{product.brand}}</p>
              </div>
              <div *ngIf="product.warehouse_title" class="detail">
                <p>{{product.warehouse_title}}</p>
              </div>
              <div *ngIf="product.card" class="detail">
                <p>
                  {{product.card}}
                  <sat-popover #p horizontalAlign="after">
                    <div class="tooltip-wrapper">
                      <p *ngIf="!product.is_carried && !product.on_the_way_date">{{'ON_THE_WAY' | translate}}</p>
                      <p *ngIf="!product.is_carried && product.on_the_way_date">{{'ON_THE_WAY_W_DATE' | translate:{ date: product.on_the_way_date } }}</p>
                      <p *ngIf="product.is_carried">{{'STOCK_CARRY' | translate}}</p>
                    </div>
                  </sat-popover>
                  <sat-popover #pp horizontalAlign="after">
                    <div class="tooltip-wrapper">
                      <p>
                        {{'ITEM_WITH_DEPOSIT' | translate}}
                        {{'DEPOSIT_VALUE' | translate}}
                        <ng-container *ngIf="(product | productState: 'deposit')">
                          {{((showLocalPrice && product.deposit_wo_vat) || product.deposit_wo_vat_eur) | customCurrency | async}}
                        </ng-container>
                      </p>
                    </div>
                  </sat-popover>
                  <i class="fas fa-fw" [ngClass]="{'fa-truck': !product.is_carried, 'fa-people-carry': product.is_carried }"
                    *ngIf="product.on_the_way"
                    [satPopoverAnchor]="p"
                    (mouseenter)="p.open()"
                    (mouseleave)="p.close()">
                  </i>
                  <i class="fas fa-hand-holding-usd fa-fw"
                    *ngIf="product.deposit > 0"
                    [satPopoverAnchor]="pp"
                    (mouseenter)="pp.open()"
                    (mouseleave)="pp.close()">
                  </i>
                </p>
              </div>
            </div>
            <div class="amount-price">
              <div class="price">
                <p *ngIf="!showPricesWithVat">{{ (showLocalPrice && (+product.total_price_wo_vat + (+product.deposit_wo_vat * +product.quantity)) || (product.total_price_wo_vat_eur + (+product.deposit_wo_vat_eur * +product.quantity))) || 0.00 | customCurrency | async}} {{"" | currencyName | async}}</p>
                <p *ngIf="showPricesWithVat">{{ (showLocalPrice && (+product.total_price + (+product.deposit * +product.quantity)) || (+product.total_price_eur + (+product.deposit_eur * +product.quantity))) || 0.00 | customCurrency | async}} {{"" | currencyName | async}}</p>
              </div>
            </div>
          </div>
        </div>
          <div class="product-options">
            <div nz-row>
              <nz-form-item class="note" nz-col>
                <nz-form-control>
                    <app-input
                      [formControl]="cartFormProductsFA.controls[i].controls.note"
                      placeholder="NOTE">
                      <app-input-validation
                        className="error"
                        message="{{'MAX_SYMBOLS' | translate:{ value: 26 } }}"
                        [condition]="cartFormProductsFA.controls[i].controls.note.errors && cartFormProductsFA.controls[i].controls.note.errors.maxlength">
                      </app-input-validation>
                    </app-input>
                  </nz-form-control>
              </nz-form-item>
              <nz-form-item class="counter-input" nz-col>
                <nz-form-control>
                  <nz-input-number
                                  [formControl]="cartFormProductsFA.controls[i].controls.quantity"
                                  name="inputnumber-success"
                                  [nzMin]="product.increment"
                                  [nzStep]="product.increment"
                                  (nzBlur)="validateQuantity(i, product)"
                                  [nzSize]="'small'"
                                  [nzDisabled]="isCartSaving">
                  </nz-input-number>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-row class="product-ndo" *ngIf="product.ndo">
              <nz-form-item>
                <nz-form-control>
                  <nz-select
                    (nzOpenChange)="selecting($event)"
                    nzSize="small"
                    [formControl]="cartFormProductsFA.controls[i].controls.ndo"
                  >
                    <ng-container *ngIf="product | productState: 'centralStock': user">
                      <nz-option *ngFor="let option of noteDeliveryValuesCentral" [nzLabel]="option.label" [nzValue]="option.value" nzShowSearch></nz-option>
                    </ng-container>
                    <ng-container *ngIf="!(product | productState: 'centralStock': user)">
                      <nz-option *ngFor="let option of noteDeliveryValues" [nzLabel]="option.label" [nzValue]="option.value" nzShowSearch></nz-option>
                    </ng-container>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-row class="product-client-ref" *ngIf="showUserClientRef">
              <nz-form-item>
                  <nz-form-control>
                    <nz-select
                      style="width: 100%;"
                      (nzOpenChange)="selecting($event)"
                      nzSize="small"
                      [formControl]="cartFormProductsFA.controls[i].controls.client_ref_id"
                      [nzCustomTemplate]="custom"
                    >
                      <nz-option nzCustomContent
                        *ngFor="let option of userClientRefValues"
                        [nzLabel]="option.client.name"
                        [nzValue]="option.client.id"
                        nzShowSearch>
                        <span [title]="option.client.name">
                          {{option.client.skuba_client_id}} - {{option.client.name}}
                        </span>
                      </nz-option>
                    </nz-select>
                    <ng-template #custom let-selected>
                      {{getUserClientSelectorValue(selected.nzValue)}}
                    </ng-template>
                  </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div class="remove_product" (click)="removeProduct(product)">
            <i class="material-icons">remove_shopping_cart</i>
          </div>
        </div>
    </div>
  </div>
</form>
