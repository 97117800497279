import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserRegister } from '@app/auth/models/user';
import { ApiService } from '@core/api.service';
import { map } from 'rxjs/internal/operators';

@Injectable()
export class RegistrationService {
  constructor(private apiService: ApiService) {}

  register(body: IUserRegister): Observable<any> {
    return this.apiService
      .post(`/users/register/`, body, true)
      .pipe(map(data => data));
  }
}
