import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { ColumnActions, ColumnMap } from '@shared/ui/table/models/table.model';
import { FormGroup } from '@angular/forms';
import { fadeInDown } from '@shared/animations';

@Component({
  // Augument build-in element
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tableThead]',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  animations: [fadeInDown],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHeaderComponent implements OnInit {
  @Input() checkbox: boolean;
  @Input() actions: ColumnActions[];
  @Input() allowRequest = false;
  @Input() filter = true;
  @Input() viewButton = true;
  @Input() group: FormGroup = new FormGroup({});
  @Input() languageSet: boolean;
  @Input() columnMaps: ColumnMap[];
  @Output() readonly clickedFilter = new EventEmitter();
  @Output() readonly clickedReset = new EventEmitter();
  @Output() readonly clickedSort = new EventEmitter();
  @Output() readonly changedInput = new EventEmitter();
  @Output() readonly searchOnEnter = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  setDate(date: any, inputName: string) {
    if (date.data && date.data.formatted) {
      this.group.controls[inputName].setValue(date.data.formatted);
    }
  }

  resetFilter() {
    this.group.reset();
    this.clickedReset.emit();
  }

  clickFilter() {
    this.clickedFilter.emit();
  }

  clickSort(header) {
    if (header.sort) {
      this.clickedSort.emit(header);
    }
  }

  inputChanged(event, header) {
    this.changedInput.emit({ event, header });
  }

  searchOnEnterKeyPress(event, doSearch) {
    if (doSearch) {
      this.searchOnEnter.emit(event);
    }
  }
}
