import { Injectable } from '@angular/core';

import { ApiService } from '@core/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { ClientInfo, IClients } from '@shared/models/client-info.model';
import { IUserShop, IResultsModel } from '@app/auth/models/user';

@Injectable()
export class ShopService {
  constructor(private apiService: ApiService) {}

  /**
   * Get clients list from API by skuba_client_id.
   * @param {string} id
   * @returns {Observable<any>}
   */
  getClients(id: number): Observable<any> {
    return this.apiService.get(`/shops/clients/${id}/`).pipe(map(data => data));
  }

  /**
   * Search clients by skuba_client_id and client name
   * @param {any} body
   * @returns {Observable<any>}
   */
  searchClients(body: any): Observable<IClients> {
    return this.apiService.get('/shops/clients/', body).pipe(map(data => data));
  }

  /**
   * Get shop list from API.
   * @returns {Observable<IResultsModel<IUserShop>>}
   */
  getShops(): Observable<IResultsModel<IUserShop>> {
    return this.apiService.get('/shops/shops/').pipe(map(data => data));
  }

  /**
   * Get shop emails list from API.
   * @returns {Observable<any>}
   */
  getShopEmails(id): Observable<any> {
    return this.apiService
      .get(`/shops/shops/${id}/emails/`)
      .pipe(map(data => data));
  }

  /**
   * Get companies list from API.
   * @returns {Observable<any>}
   */
  getCompanies(): Observable<any> {
    return this.apiService.get('/shops/companies/').pipe(map(data => data));
  }

  /**
   * Add new client from Skuba API database.
   * @param {number} skubaClientId
   * @returns {Observable<ClientInfo>}
   */
  addClient(skubaClientId: number): Observable<ClientInfo> {
    return this.apiService
      .put(`/shops/clients/${skubaClientId}/`)
      .pipe(map(data => data));
  }
}
