import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';

import { Observable, Subscription } from 'rxjs';
import { ILanguageItem } from '@core/models/language-switch';
import * as LanguageActions from '@core/store/actions/language.action';
import * as fromRoot from '@app/reducers';
import * as fromUser from '@app/auth/store';
import { IUser } from '@app/auth/models/user';
import * as TranslationActions from '@core/store/actions/translation.action';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent implements OnInit, OnDestroy {
  activeLanguage$: Observable<string>;
  languageEntities$: Observable<any>;
  languages$: Observable<ILanguageItem[]>;

  activeLanguageSub: Subscription;
  languageEntitiesSub: Subscription;
  languagesSub: Subscription;

  activeLanguage = '';
  languageEntities: any = {};
  languages: ILanguageItem[] = [];

  user$: Observable<IUser>;
  userSub: Subscription;

  constructor(private store: Store<fromRoot.AppState>) {
    this.user$ = this.store.pipe(select(fromUser.getUser));
    this.activeLanguage$ = this.store.pipe(select(fromRoot.getActiveLanguage));
    this.languages$ = this.store.pipe(select(fromRoot.getAllLanguages));
    this.languageEntities$ = this.store.pipe(select(fromRoot.getLanguageEntities));
  }

  ngOnInit(): void {
    this.userSub = this.user$.subscribe(user => {
      if (user && user.profile) {
        if (user.profile.language_code) {
          this.store.dispatch(
            new LanguageActions.SetActiveLanguage(user.profile.language_code)
          );
          this.store.dispatch(
            new TranslationActions.SetTranslationLanguage(
              user.profile.language_code
            )
          );
        } else {
          this.store.dispatch(
            new LanguageActions.SetActiveLanguage(
              user.profile.company.country.code
            )
          );
          this.store.dispatch(
            new TranslationActions.SetTranslationLanguage(
              user.profile.company.country.code
            )
          );
        }
      }
      if(this.userSub) { this.userSub.unsubscribe(); }
    });
    this.activeLanguageSub = this.activeLanguage$.subscribe(activeLanguage => {
      this.activeLanguage = activeLanguage;
    });
    this.languageEntitiesSub = this.languageEntities$.subscribe(languageEntities => {
      this.languageEntities = languageEntities;
    });
    this.languagesSub = this.languages$.subscribe(languages => {
      this.languages = languages;
    });
  }

  selectLanguage(language: string): void {
    if (this.userSub) { this.userSub.unsubscribe(); }
    this.userSub = this.user$.subscribe(user => {
      if (user) {
        this.store.dispatch(
          new fromUser.UpdateUserLanguage({ language_code: language })
        );
        this.store.dispatch(
          new TranslationActions.SetTranslationLanguage(language)
        );
        location.reload();
      } else {
        this.store.dispatch(new LanguageActions.SetActiveLanguage(language));
        this.store.dispatch(
          new TranslationActions.SetTranslationLanguage(language)
        );
      }

      if (this.userSub) {
        this.userSub.unsubscribe();
      }
    });
  }

  ngOnDestroy() {
    if (this.userSub) { this.userSub.unsubscribe(); }
    if (this.activeLanguageSub) { this.activeLanguageSub.unsubscribe(); }
    if (this.languageEntitiesSub) { this.languageEntitiesSub.unsubscribe(); }
    if (this.languagesSub) { this.languagesSub.unsubscribe(); }
  }
}
