<div class="base-container inner-floating-container">
  <div class="back-button">
    <ng-container *ngIf="!shouldNavigateOther && shouldNavigateHome">
      <a [routerLink]="['/']">
        <i class="material-icons">arrow_back</i>
      </a>
    </ng-container>
    <ng-container *ngIf="!shouldNavigateOther && !shouldNavigateHome">
      <i class="material-icons" (click)="backClick()">arrow_back</i>
    </ng-container>
    
    <ng-container *ngIf="shouldNavigateOther">
      <a [routerLink]="[otherPath]">
        <i class="material-icons">arrow_back</i>
      </a>
    </ng-container>
    
  </div>
</div>
