import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { IAuthenticate } from '../../models/user';
import { fadeInDown } from '@shared/animations';
import { HelperService } from '@shared/services/helper.service';
import { ApiService } from '@core/api.service';
import { filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { TranslationService } from '@app/core/service/translation.service';
import { Subscription, Observable, Subject } from 'rxjs';
import { throttle } from '@swimlane/ngx-charts/release/utils';

@Component({
  selector: 'app-skuba-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
  animations: [fadeInDown]
})
export class LoginFormComponent implements OnInit {
  usernameSub: Subscription;
  usernameValueChangeSub: Subscription;
  checkUsernameSubject = new Subject<string>();
  showGeneralTermsSKCheckbox = false;
  showAdditionalTermsSKCheckbox = false;

  isPending: boolean;
  @Input() set pending(isPending: boolean) {
    this.isPending = isPending;
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input() errorMessage: string | null;
  @Input() infoMessage: string | null;
  @Output() readonly submitted = new EventEmitter<IAuthenticate>();

  canRegister = true;

  logoSettings = {
    normal: true,
    christmas: false,
  };

  constructor(
    private fb: FormBuilder,
    private element: ElementRef,
    private helperService: HelperService,
    private apiService: ApiService,
    private translationService: TranslationService,
  ) {
    this.usernameSub = this.checkUsernameSubject
      .pipe(
        debounceTime(500),
        filter(username => username !== ''),
        distinctUntilChanged(),
      )
      .subscribe(username => {
        this.apiService.get(`/auth/check/${username}/`).subscribe(response => {
          if (response) {
            if (response.accepted_tos) {
              this.form.controls['agreement'].patchValue(response.accepted_tos);
            } else {
              this.form.controls['agreement'].patchValue(false);
            }
            if (response.hasOwnProperty('accepted_general_terms')) {
              this.showGeneralTermsSKCheckbox = true;
              setTimeout(() => {
                this.form.controls['general_terms'].setValidators(Validators.required);
                this.form.controls['general_terms'].updateValueAndValidity();
                this.form.controls['general_terms'].patchValue(response.accepted_general_terms);
              });
            } else {
              this.showGeneralTermsSKCheckbox = false;
              this.form.controls['general_terms'].setValidators(null);
              this.form.controls['general_terms'].updateValueAndValidity();
              this.form.controls['general_terms'].patchValue(false);
            }
            if (response.hasOwnProperty('accepted_additional_terms') && !response.accepted_additional_terms) {
              this.showAdditionalTermsSKCheckbox = true;
              setTimeout(() => {
                this.form.controls['additional_terms'].setValidators(Validators.required);
                this.form.controls['additional_terms'].updateValueAndValidity();
                this.form.controls['additional_terms'].patchValue(false);
              });
            } else {
              this.showAdditionalTermsSKCheckbox = false;
              this.form.controls['additional_terms'].setValidators(null);
              this.form.controls['additional_terms'].updateValueAndValidity();
              this.form.controls['additional_terms'].patchValue(false);
            }
          } else {
            this.form.controls['agreement'].patchValue(false);
            this.showGeneralTermsSKCheckbox = false;
            this.form.controls['general_terms'].patchValue(false);
          }
        });
      });
    if (this.helperService.isChristmasWeeks()) {
      this.logoSettings.normal = false;
      this.logoSettings.christmas = true;
    }
  }

  form = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
    agreement: [false, Validators.required],
    general_terms: [false],
    additional_terms: [false],
  });

  ngOnInit(): void {
    this.usernameValueChangeSub = this.form
      .controls['username']
      .valueChanges
      .subscribe(username => {
        this.checkUsernameSubject.next(username);
      });
  }

  submit() {
    if (!this.form.value['agreement']) {
      this.errorMessage = this.translationService.translate(
        'PLEASE_ACCEPT_GENERAL_TERMS'
      );
      return;
    }
    if (this.showGeneralTermsSKCheckbox && !this.form.value['general_terms']) {
      this.errorMessage = this.translationService.translate(
        'SK_ONLY_PLEASE_AGREE_GENERAL_TERMS'
      );
      return;
    }
    if (this.showAdditionalTermsSKCheckbox && !this.form.value['additional_terms']) {
      this.errorMessage = this.translationService.translate(
        'SK_ONLY_ADDITIONAL_CHECKBOX'
      );
      return;
    }
    this.helperService.validationScroll(this.element);

    if (this.form.valid) {
      this.submitted.emit(this.form.value);
    }
  }

  checkUsername() {
    const username = this.form.value['username'];
    this.checkUsernameSubject.next(username);
  }

  onDestroy() {
    if (this.checkUsernameSubject) {
      this.checkUsernameSubject.complete();
      this.checkUsernameSubject.unsubscribe();
    }
    if (this.usernameValueChangeSub) { this.usernameValueChangeSub.unsubscribe(); }
    if (this.usernameSub) { this.usernameSub.unsubscribe(); }
  }
}
