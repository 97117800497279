/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./status-chip.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/translate/translate.pipe";
import * as i3 from "../../../core/service/translation.service";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/common";
import * as i6 from "./status-chip.component";
var styles_StatusChipComponent = [i0.styles];
var RenderType_StatusChipComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatusChipComponent, data: {} });
export { RenderType_StatusChipComponent as RenderType_StatusChipComponent };
function View_StatusChipComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "chip chip-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.success)); _ck(_v, 1, 0, currVal_0); }); }
function View_StatusChipComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "chip chip-warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.warning)); _ck(_v, 1, 0, currVal_0); }); }
function View_StatusChipComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "chip chip-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.error)); _ck(_v, 1, 0, currVal_0); }); }
function View_StatusChipComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "chip chip-info"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.info)); _ck(_v, 1, 0, currVal_0); }); }
function View_StatusChipComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "app-status-chip"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusChipComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusChipComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusChipComponent_4)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusChipComponent_5)), i1.ɵdid(8, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.success; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.warning; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.error; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.info; _ck(_v, 8, 0, currVal_3); }, null); }
function View_StatusChipComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "app-status-chip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "chip"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "chip-success": 0, "chip-warning": 1, "chip-error": 2, "chip-info": 3, "chip-gray": 4 }), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "chip"; var currVal_1 = _ck(_v, 4, 0, (_co.type === "SUCCESS"), (_co.type === "WARNING"), (_co.type === "ERROR"), (_co.type === "INFO"), (_co.type === "GRAY")); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.text)); _ck(_v, 5, 0, currVal_2); }); }
export function View_StatusChipComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusChipComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusChipComponent_6)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.type; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.type; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_StatusChipComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-status-chip", [], null, null, null, View_StatusChipComponent_0, RenderType_StatusChipComponent)), i1.ɵdid(1, 114688, null, 0, i6.StatusChipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StatusChipComponentNgFactory = i1.ɵccf("app-status-chip", i6.StatusChipComponent, View_StatusChipComponent_Host_0, { success: "success", warning: "warning", error: "error", info: "info", type: "type", text: "text" }, {}, []);
export { StatusChipComponentNgFactory as StatusChipComponentNgFactory };
