import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, filter } from 'rxjs/operators';
import { of, forkJoin, Observable } from 'rxjs';

import * as TranslationActions from '../actions/translation.action';
import { TranslationActionTypes } from '../actions/translation.action';
import { TranslationService } from '@core/service/translation.service';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { Action, Store, select } from '@ngrx/store';
import { UserService } from '@app/core/user/user.service';
import { IUser } from '@app/auth/models/user';
import * as fromRoot from '@app/reducers';
import * as fromAuth from '@app/auth/store';

@Injectable()
export class TranslationEffects {
  constructor(
    private actions$: Actions,
    private storage: LocalStorageService,
    private store: Store<fromRoot.AppState>,
    private translationService: TranslationService
  ) {}

  @Effect() setNotTranslated$ = this.actions$.pipe(
    ofType(TranslationActionTypes.SetNotTranslatedCount),
    map((action: TranslationActions.SetNotTranslatedCount) => action.payload),
    switchMap(count => {
      return this.store.pipe(select(fromAuth.getUser));
    }),
    filter(usr => usr !== null),
    filter(usr => usr.is_staff),
    switchMap(
      (usr): Observable<number> => {
        return this.translationService.getNotTranslatedCount(
          usr.profile.company.country.language_code
        );
      }
    ),
    map(count => {
      return new TranslationActions.SetNotTranslatedCountLoaded(count);
    })
  );

  @Effect() setNotTranslatedEmails$ = this.actions$.pipe(
    ofType(TranslationActionTypes.SetNotTranslatedEmailsCount),
    map((action: TranslationActions.SetNotTranslatedEmailsCount) => action.payload),
    switchMap(count => {
      return this.store.pipe(select(fromAuth.getUser));
    }),
    filter(usr => usr !== null),
    filter(usr => usr.is_staff),
    switchMap(
      (usr): Observable<number> => {
        return this.translationService.getNotTranslatedEmailsCount(
          usr.profile.company.country.language_code
        );
      }
    ),
    map(count => {
      return new TranslationActions.SetNotTranslatedEmailsCountLoaded(count);
    })
  );
  
  @Effect() setNotApprovedUsers$ = this.actions$.pipe(
    ofType(TranslationActionTypes.SetNotApprovedUsersCount),
    map((action: TranslationActions.SetNotApprovedUsersCount) => action),
    switchMap(count => {
      return this.store.pipe(select(fromAuth.getUser));
    }),
    filter(usr => usr !== null),
    filter(usr => usr.is_staff),
    switchMap(
      (usr): Observable<number> => {
        return this.translationService.getNotApprovedUsersCount();
      }
    ),
    map(count => {
      return new TranslationActions.SetNotApprovedUsersCountLoaded(count);
    })
  );

  @Effect() setTranslations$ = this.actions$.pipe(
    ofType(TranslationActionTypes.SetTranslationLanguage),
    map((action: TranslationActions.SetTranslationLanguage) => action.payload),
    // todo do not change language if it's already loaded
    map((language?: string) => {
      const storedLanguage = this.storage.getItem('language');
      if (storedLanguage && (!language || storedLanguage === language)) {
        return new TranslationActions.TranslationLoadApi(storedLanguage);
      } else if (language && storedLanguage !== language) {
        this.storage.setItem('language', language);
        return new TranslationActions.TranslationLoadApi(language);
      } else {
        // Default
        this.storage.setItem('language', 'en');
        return new TranslationActions.TranslationLoadApi('en');
      }
    })
  );

  @Effect() loadTranslationsApi$ = this.actions$.pipe(
    ofType(TranslationActionTypes.TranslationLoadApi),
    map((action: TranslationActions.TranslationLoadApi) => action.payload),
    switchMap(language => {
      return forkJoin(
        this.translationService
          .loadTranslationApi(language)
          .pipe(
            catchError(error =>
              of(
                new TranslationActions.TranslationLoadApiFail({
                  error: error,
                  language: language
                })
              )
            )
          ),
        this.translationService
          .loadTranslationApi('en')
          .pipe(
            catchError(error =>
              of(
                new TranslationActions.TranslationLoadApiFail({
                  error: error,
                  language: 'en'
                })
              )
            )
          )
      );
    }),
    map(all_translations => {
      const translations = <any> all_translations[0];
      const en_translations = <any> all_translations[1];
      const translationsModified = {};
      if (
        translations &&
        translations.translations &&
        translations.translations.length > 0
      ) {
        translations.translations.forEach(
          ({ key, value }) => (translationsModified[key] = value)
        );
      }
      if (
        en_translations &&
        en_translations.translations &&
        en_translations.translations.length > 0
      ) {
        en_translations.translations.forEach(({ key, value }) => {
          if (!translationsModified[key]) { translationsModified[key] = value; }
        });
      }
      return new TranslationActions.TranslationLoadApiSuccess(
        translationsModified
      );
    })
  );

  @Effect() loadTranslationsApiFailed$ = this.actions$.pipe(
    ofType(TranslationActionTypes.TranslationLoadApiFail),
    map((action: TranslationActions.TranslationLoadApiFail) => action.payload),
    map(data => new TranslationActions.TranslationLoad(data.language))
  );

  @Effect() loadTranslations$ = this.actions$.pipe(
    ofType(TranslationActionTypes.TranslationLoad),
    map((action: TranslationActions.TranslationLoad) => action.payload),
    switchMap(language => {
      return this.translationService.loadTranslation(language).pipe(
        map(translations => {
          return new TranslationActions.TranslationLoadSuccess(translations);
        }),
        catchError(error =>
          of(new TranslationActions.TranslationLoadFail(error))
        )
      );
    })
  );
}
