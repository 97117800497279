import {
  LastRouteActions,
  LastRouteActionTypes
} from '../actions/last-route.action';

export interface LastRouteState {
  route: string;
}

const initialState: LastRouteState = {
  route: null
};

export function reducer(
  state = initialState,
  action: LastRouteActions
): LastRouteState {
  switch (action.type) {
    case LastRouteActionTypes.LastRouteLoad: {
      return {
        ...state,
        route: action.payload
      };
    }

    case LastRouteActionTypes.ResetLastRouteState: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export const getLastRoute = (state: LastRouteState) => state.route;
