import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api.service';
import { IUserCarBody, IUserCarsModel } from '@app/auth/models/user';
import { IUserCar } from '@app/auth/models/user';
import { map } from 'rxjs/internal/operators';

@Injectable()
export class UserCarsService {
  constructor(private apiService: ApiService) {}

  /**
   * Get user cars data from API.
   * @returns {Observable<any>}
   */
  getCars(): Observable<IUserCarsModel> {
    return this.apiService.get(`/users/cars/`).pipe(map(data => data));
  }

  getCarsByUser(userId: number): Observable<IUserCarsModel> {
    return this.apiService.get(`/users/${userId}/cars/`).pipe(map(data => data));
  }

  /**
   * Get user cars data from API.
   * @returns {Observable<any>}
   */
  getCar(id: number): Observable<IUserCar> {
    return this.apiService
      .get(`/users/cars/${id}/`)
      .pipe(map(data => data));
  }

  /**
   * Create new user shipping car.
   * @param {IUserCarBody} body
   * @returns {Observable<IUserCarsModel>}
   */
  addCars(body: IUserCarBody): Observable<IUserCar> {
    return this.apiService
      .post(`/users/cars/`, body)
      .pipe(map(data => data));
  }

  /**
   * Partial user car update.
   * @param {number} id
   * @param body
   * @returns {Observable<IUserCar>}
   */
  patchCar(id: number, body: any): Observable<IUserCar> {
    return this.apiService
      .patch(`/users/cars/${id}/`, body)
      .pipe(map(data => data));
  }

  /**
   * User car update.
   * @param {number} id
   * @param {IUserCarBody} body
   * @returns {Observable<IUserCar>}
   */
  updateCar(
    id: number,
    body: IUserCarBody
  ): Observable<IUserCar> {
    return this.apiService
      .patch(`/users/cars/${id}/`, body)
      .pipe(map(data => data));
  }

  /**
   * Remove user car.
   * @param {number} id
   * @returns {Observable<any>}
   */
  removeCar(id: number): Observable<any> {
    return this.apiService
      .delete(`/users/cars/${id}/`)
      .pipe(map(data => data));
  }
}
