var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, filter } from 'rxjs/operators';
import { of, forkJoin } from 'rxjs';
import * as TranslationActions from '../actions/translation.action';
import { TranslationActionTypes } from '../actions/translation.action';
import { TranslationService } from '@core/service/translation.service';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { Store, select } from '@ngrx/store';
import * as fromAuth from '@app/auth/store';
export class TranslationEffects {
    constructor(actions$, storage, store, translationService) {
        this.actions$ = actions$;
        this.storage = storage;
        this.store = store;
        this.translationService = translationService;
        this.setNotTranslated$ = this.actions$.pipe(ofType(TranslationActionTypes.SetNotTranslatedCount), map((action) => action.payload), switchMap(count => {
            return this.store.pipe(select(fromAuth.getUser));
        }), filter(usr => usr !== null), filter(usr => usr.is_staff), switchMap((usr) => {
            return this.translationService.getNotTranslatedCount(usr.profile.company.country.language_code);
        }), map(count => {
            return new TranslationActions.SetNotTranslatedCountLoaded(count);
        }));
        this.setNotTranslatedEmails$ = this.actions$.pipe(ofType(TranslationActionTypes.SetNotTranslatedEmailsCount), map((action) => action.payload), switchMap(count => {
            return this.store.pipe(select(fromAuth.getUser));
        }), filter(usr => usr !== null), filter(usr => usr.is_staff), switchMap((usr) => {
            return this.translationService.getNotTranslatedEmailsCount(usr.profile.company.country.language_code);
        }), map(count => {
            return new TranslationActions.SetNotTranslatedEmailsCountLoaded(count);
        }));
        this.setNotApprovedUsers$ = this.actions$.pipe(ofType(TranslationActionTypes.SetNotApprovedUsersCount), map((action) => action), switchMap(count => {
            return this.store.pipe(select(fromAuth.getUser));
        }), filter(usr => usr !== null), filter(usr => usr.is_staff), switchMap((usr) => {
            return this.translationService.getNotApprovedUsersCount();
        }), map(count => {
            return new TranslationActions.SetNotApprovedUsersCountLoaded(count);
        }));
        this.setTranslations$ = this.actions$.pipe(ofType(TranslationActionTypes.SetTranslationLanguage), map((action) => action.payload), 
        // todo do not change language if it's already loaded
        map((language) => {
            const storedLanguage = this.storage.getItem('language');
            if (storedLanguage && (!language || storedLanguage === language)) {
                return new TranslationActions.TranslationLoadApi(storedLanguage);
            }
            else if (language && storedLanguage !== language) {
                this.storage.setItem('language', language);
                return new TranslationActions.TranslationLoadApi(language);
            }
            else {
                // Default
                this.storage.setItem('language', 'en');
                return new TranslationActions.TranslationLoadApi('en');
            }
        }));
        this.loadTranslationsApi$ = this.actions$.pipe(ofType(TranslationActionTypes.TranslationLoadApi), map((action) => action.payload), switchMap(language => {
            return forkJoin(this.translationService
                .loadTranslationApi(language)
                .pipe(catchError(error => of(new TranslationActions.TranslationLoadApiFail({
                error: error,
                language: language
            })))), this.translationService
                .loadTranslationApi('en')
                .pipe(catchError(error => of(new TranslationActions.TranslationLoadApiFail({
                error: error,
                language: 'en'
            })))));
        }), map(all_translations => {
            const translations = all_translations[0];
            const en_translations = all_translations[1];
            const translationsModified = {};
            if (translations &&
                translations.translations &&
                translations.translations.length > 0) {
                translations.translations.forEach(({ key, value }) => (translationsModified[key] = value));
            }
            if (en_translations &&
                en_translations.translations &&
                en_translations.translations.length > 0) {
                en_translations.translations.forEach(({ key, value }) => {
                    if (!translationsModified[key]) {
                        translationsModified[key] = value;
                    }
                });
            }
            return new TranslationActions.TranslationLoadApiSuccess(translationsModified);
        }));
        this.loadTranslationsApiFailed$ = this.actions$.pipe(ofType(TranslationActionTypes.TranslationLoadApiFail), map((action) => action.payload), map(data => new TranslationActions.TranslationLoad(data.language)));
        this.loadTranslations$ = this.actions$.pipe(ofType(TranslationActionTypes.TranslationLoad), map((action) => action.payload), switchMap(language => {
            return this.translationService.loadTranslation(language).pipe(map(translations => {
                return new TranslationActions.TranslationLoadSuccess(translations);
            }), catchError(error => of(new TranslationActions.TranslationLoadFail(error))));
        }));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], TranslationEffects.prototype, "setNotTranslated$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], TranslationEffects.prototype, "setNotTranslatedEmails$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], TranslationEffects.prototype, "setNotApprovedUsers$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], TranslationEffects.prototype, "setTranslations$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], TranslationEffects.prototype, "loadTranslationsApi$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], TranslationEffects.prototype, "loadTranslationsApiFailed$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], TranslationEffects.prototype, "loadTranslations$", void 0);
