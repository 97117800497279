var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Observable } from 'rxjs';
import { ApiService } from '@core/api.service';
import { map } from 'rxjs/internal/operators';
import { DecoratorsService } from '@app/core/develop/decorators';
export class CartService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    /**
     * Get Cart data from API.
     * @param {string} with_expired
     * @returns {Observable<any>}
     */
    getCart(with_expired = false) {
        return this.apiService.get('/cart/', { with_expired: with_expired.toString() }).pipe(map(data => data));
    }
    /**
     * Get Expired Cart data from API.
     * @param {last_cart_id: string}
     * @returns {Observable<any>}
     */
    getCartExpired(last_cart_id) {
        const requestBody = {};
        if (last_cart_id) {
            requestBody.last_cart_id = last_cart_id;
        }
        return this.apiService.get('/cart/get-expired/', requestBody).pipe(map(data => data));
    }
    /**
     * Add product to cart.
     */
    addToCart(body, acknowledged = false) {
        return this.apiService.post('/cart/', Object.assign({}, body, { acknowledged })).pipe(map(data => data));
    }
    /**
     * Update cart product.
     * @param {number} id
     * @param {{card_number: string | number; quantity: number; with_expired: boolean}} body
     * @returns {Observable<any>}
     */
    updateCart(id, body) {
        return this.apiService.put(`/cart/${id}/`, body).pipe(map(data => data));
    }
    /**
     * Remove product from cart.
     * @param {number} id
     * @param {boolean} with_expired
     * @returns {Observable<any>}
     */
    removeFromCart(id) {
        return this.apiService.delete(`/cart/${id}/`).pipe(map(data => data));
    }
    /**
     * Remove expired product from cart.
     * @param {number} id
     * @returns {Observable<any>}
     */
    removeFromCartExpired(id) {
        return this.apiService.delete(`/cart/${id}/expired/`).pipe(map(data => data));
    }
}
__decorate([
    DecoratorsService.DevCache(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Observable)
], CartService.prototype, "getCart", null);
