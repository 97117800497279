import { IUser } from '@app/auth/models/user';
import * as fromUser from '../actions/user.action';
export interface UserState {
  user: IUser;
  loaded: boolean;
  loading: boolean;
}

export const initialState: UserState = {
  user: null,
  loaded: false,
  loading: false
};

export function reducer(
  state = initialState,
  action: fromUser.UserAction
): UserState {
  switch (action.type) {
    /** Load user */
    case fromUser.LOAD_USER: {
      return { ...state, loading: true };
    }
    case fromUser.LOAD_USER_SUCCESS: {
      return { ...state, loaded: true, loading: false, user: action.payload };
    }
    case fromUser.LOAD_USER_FAIL: {
      return { user: null, loaded: false, loading: false };
    }
    /** Update user */
    case fromUser.UPDATE_USER: {
      return { ...state, loading: true };
    }
    case fromUser.UPDATE_USER_SUCCESS: {
      return { ...state, loading: false, user: action.payload };
    }
    case fromUser.UPDATE_USER_FAIL: {
      return { ...state, loaded: false, loading: false };
    }
    /** Update user language */
    case fromUser.UPDATE_USER_LANGUAGE: {
      return { ...state, loading: true };
    }
    case fromUser.UPDATE_USER_LANGUAGE_SUCCESS: {
      return { ...state, loading: false, user: action.payload };
    }
    case fromUser.UPDATE_USER_LANGUAGE_FAIL: {
      return { ...state, loaded: false, loading: false };
    }
    /** Reset User */
    case fromUser.RESET_USER: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}

export const getUser = (state: UserState) => state.user;
export const getUserLoaded = (state: UserState) => state.loaded;
export const getUserLoading = (state: UserState) => state.loading;
