var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { of } from 'rxjs';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ProductActionTypes } from '../actions/product.action';
import { ProductService } from '@shared/services/product.service';
import { map, switchMap, catchError } from 'rxjs/operators';
import * as ProductActions from '../actions/product.action';
import { UserOperation } from '@app/shared/constants/user-operations';
export class ProductEffects {
    constructor(actions$, service) {
        this.actions$ = actions$;
        this.service = service;
        this.loadProduct$ = this.actions$.pipe(ofType(ProductActionTypes.ProductLoad), map((action) => ({ skn: action.payload, log: action.log })), switchMap(({ skn, log }) => {
            let info = '';
            if (UserOperation.shouldPost && log) {
                info = JSON.stringify({
                    id: UserOperation.TYPE,
                    query: UserOperation.query,
                });
            }
            return this.service.getProductWithInfo(skn, info).pipe(map(product => {
                if (product && product.stock && product.stock.length) {
                    product.stock.forEach(item => {
                        Object.keys(item).forEach(key => {
                            if (key.includes('price') && typeof item[key] === 'number') {
                                item[key] = item[key].toFixed(2);
                            }
                        });
                    });
                }
                if (product && product.stock && product.stock.length) {
                    product.stock
                        .sort((a, b) => a['quantity'] - b['quantity'])
                        .reverse();
                    product.stock.sort((a, b) => a['is_central'] - b['is_central']);
                }
                return new ProductActions.ProductLoadSuccess(product);
            }), catchError(error => of(new ProductActions.ProductLoadFail(error, 'Load'))));
        }));
        this.extendProduct$ = this.actions$.pipe(ofType(ProductActionTypes.ProductExtend), map((action) => ({ stock: action.payload, updateQuantity: action.updateQuantity, skn: action.skn })), switchMap(extendData => {
            // if (extendData.stock.card_number === 0) {
            //   return of(new ProductActions.ProductLoadFail('CARD_NOT_FOUND', 'Extend', extendData.stock, extendData.updateQuantity));
            // }
            return this.service
                .getProductPrice(extendData.stock.card_number, extendData.stock.warehouse || extendData.stock.warehouse_code, extendData.updateQuantity ? extendData.stock.is_central : true, extendData.stock.brand, extendData.skn, extendData.stock.deposit, extendData.stock.on_the_way, extendData.updateQuantity)
                .pipe(map(fullStock => {
                return new ProductActions.ProductExtendSuccess(fullStock, extendData.stock.card_number === 0);
            }), catchError(error => of(new ProductActions.ProductLoadFail(error, 'Extend', extendData.stock, extendData.updateQuantity))));
        }));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], ProductEffects.prototype, "loadProduct$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], ProductEffects.prototype, "extendProduct$", void 0);
