import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/core/api.service';
import { map } from 'rxjs/internal/operators';
import {
  ICountries,
  ICountryItem,
  ILanguageItem
} from '@core/models/language-switch';
import { DecoratorsService } from '../develop/decorators';

@Injectable()
export class LanguageService {
  languages: ILanguageItem[] = [
    {
      title: 'EN',
      value: 'en',
      image: `/assets/flags/svg/en.svg`
    }
  ];

  constructor(private apiService: ApiService) {}

  @DecoratorsService.DevCache()
  getLanguages(): Observable<ILanguageItem[]> {
    return this.apiService.get('/helper/countries/').pipe(
      map((data: ICountries) => {
        data.results.forEach((country: ICountryItem) => {
          if (country.translations_enabled) {
            this.languages.push({
              title: country.code.toUpperCase(),
              value: country.language_code,
              image: `/assets/flags/svg/${country.code}.svg`
            });
          }
        });
        return this.languages;
      })
    );
  }

  getLanguage(): Observable<ILanguageItem> {
    let language;
    return this.apiService.get('/helper/language/').pipe(
      map((data: ICountries) => {
        language = data.results[0].code;
        return language;
      })
    );
  }
}
