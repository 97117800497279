var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, catchError, mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserCarsService } from '@app/core/service/user-cars.service';
import * as fromActions from '../actions/user-cars.action';
export class UserCarsEffects {
    constructor(actions$, userCarsService) {
        this.actions$ = actions$;
        this.userCarsService = userCarsService;
        this.loadUserCar$ = this.actions$.pipe(ofType(fromActions.LOAD_USER_CARS), switchMap((data) => {
            return this.userCarsService.getCars().pipe(map(user => new fromActions.LoadUserCarsSuccess(user)), catchError(error => of(new fromActions.LoadUserCarsFail(error))));
        }));
        this.loadUserCarByUser$ = this.actions$.pipe(ofType(fromActions.LOAD_USER_CARS_BY_USER), switchMap((data) => {
            let userId = 0;
            if (data !== undefined && data !== null && data.payload && data.payload.userId > 0) {
                userId = data.payload.userId;
            }
            return this.userCarsService.getCarsByUser(userId).pipe(map(cars => new fromActions.LoadUserCarsSuccess(cars)), catchError(error => of(new fromActions.LoadUserCarsFail(error))));
        }));
        this.addUserCar$ = this.actions$.pipe(ofType(fromActions.ADD_USER_CAR), map((action) => action.payload), mergeMap(car => {
            return this.userCarsService.addCars(car).pipe(map(carItem => new fromActions.AddUserCarSuccess(carItem)), catchError(error => of(new fromActions.AddUserCarFail(error))));
        }));
        this.patchUserCar$ = this.actions$.pipe(ofType(fromActions.PATCH_USER_CAR), map((action) => action.payload), mergeMap(data => {
            return this.userCarsService
                .patchCar(data.id, data.body)
                .pipe(map(carItem => new fromActions.PatchUserCarSuccess(carItem)), catchError(error => of(new fromActions.PatchUserCarFail(error))));
        }));
        this.updateUserCar$ = this.actions$.pipe(ofType(fromActions.UPDATE_USER_CAR), map((action) => action.payload), mergeMap(data => {
            return this.userCarsService
                .updateCar(data.id, data.body)
                .pipe(map(carItem => new fromActions.UpdateUserCarSuccess(carItem)), catchError(error => of(new fromActions.UpdateUserCarFail(error))));
        }));
        this.removeUserCar$ = this.actions$.pipe(ofType(fromActions.REMOVE_USER_CAR), map((action) => action.payload), mergeMap(id => {
            return this.userCarsService.removeCar(id).pipe(map(() => new fromActions.RemoveUserCarSuccess()), catchError(error => of(new fromActions.RemoveUserCarFail(error))));
        }));
        this.setUserCar$ = this.actions$.pipe(ofType(fromActions.SET_USER_CAR), map((action) => action.payload), map(car => {
            return new fromActions.SetUserCarSuccess(car);
        }));
        this.handlePatchUserCarSuccess$ = this.actions$.pipe(ofType(fromActions.PATCH_USER_CAR_SUCCESS), map(() => {
            return new fromActions.LoadUserCars();
        }));
        this.handleUpdateUserCarSuccess$ = this.actions$.pipe(ofType(fromActions.UPDATE_USER_CAR_SUCCESS), map(() => {
            return new fromActions.LoadUserCars();
        }));
        this.handleRemoveUserCarSuccess$ = this.actions$.pipe(ofType(fromActions.REMOVE_USER_CAR_SUCCESS), map(() => {
            return new fromActions.LoadUserCars();
        }));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserCarsEffects.prototype, "loadUserCar$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserCarsEffects.prototype, "loadUserCarByUser$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserCarsEffects.prototype, "addUserCar$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserCarsEffects.prototype, "patchUserCar$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserCarsEffects.prototype, "updateUserCar$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserCarsEffects.prototype, "removeUserCar$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserCarsEffects.prototype, "setUserCar$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserCarsEffects.prototype, "handlePatchUserCarSuccess$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserCarsEffects.prototype, "handleUpdateUserCarSuccess$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserCarsEffects.prototype, "handleRemoveUserCarSuccess$", void 0);
