import * as Sentry from '@sentry/browser';
import {
  Observable,
  throwError,
} from 'rxjs';

import { Injectable } from '@angular/core';
import {
  IAuthenticate,
  IUser,
  IToken,
  IUSerChange
} from '@app/auth/models/user';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { ApiService } from '@core/api.service';
import * as fromRoot from '@app/reducers';
import { map, tap } from 'rxjs/internal/operators';
import { DecoratorsService } from '@app/core/develop/decorators';

@Injectable()
export class AuthService {
  lastRoute$: Observable<string>;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private store: Store<fromRoot.AppState>,
    private apiService: ApiService
  ) {}
  login({ username, password }: IAuthenticate, silenced = false): Observable<IToken> {
    return this.http
      .post<IToken>(environment.api_url + '/auth/token-auth/', {
        username,
        password,
        _ems_silenced: silenced,
      })
      .pipe(
        tap((res: IToken) => this.setSession(res.token)),
        catchError((error: any) => {
          return throwError(error);
        }),
      );
  }

  @DecoratorsService.DevCache()
  LoadUser() {
    const token = this.localStorageService.getItem('id_token');

    if (token) {
      return this.http
        .get<IUser>(environment.api_url + '/auth/me/')
        .pipe(
          tap((user) => {
            if (user.profile) {
              user.profile.is_user_interface_active = user.profile.status === 1 && !user.profile.force_reset_password;
            }
            Sentry.setUser({
              id: user.id,
              email: user.email,
              username: user.username,
              fid_name: user.profile ? user.profile.company.name : 'Not loaded',
              fid: user.profile ? user.profile.company.skuba_company_id : 'Not loaded',
            });
          }),
          catchError((error: any) => throwError(error.json()))
          );
    } else {
      return throwError('not logged in');
    }
  }

  updateUser(body: IUSerChange): Observable<IUser> {
    return this.apiService
      .post('/auth/me/change/', body)
      .pipe(
        tap((user) => {
          if (user.profile) {
            user.profile.is_user_interface_active = user.profile.status === 1 && !user.profile.force_reset_password;
          }
        },
        catchError((error: any) => throwError(error.json()))
        ),
      );
  }

  forcePasswordReset(new_password1, new_password2): Observable<any> {
    return this.apiService
      .post(`/auth/password/force_password_reset/`, {
        pass_not_same_check: '',
        new_password1,
        new_password2
      }, true)
      .pipe(
        map(data => data),
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            return throwError(this.getFirstError(error));
          }
          return throwError(error);
        }),
      );
  }

  changePassword(old_password, new_password1, new_password2): Observable<any> {
    return this.apiService
      .post(`/auth/password/change_password/`, {
        old_password,
        new_password1,
        new_password2
      }, true)
      .pipe(
        map(data => data),
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            return throwError(this.getFirstError(error));
          }
          return throwError(error);
        }),
      );
  }
  
  getFirstError(error: HttpErrorResponse) {
    let errMsg: string = '';
    let firstError = Object.keys(error.error).map(k => error.error[k])[0];
    if (firstError && firstError.length > 0) {
      firstError = firstError[0];
    }
    if (firstError) {
      errMsg = firstError;
    } else {
      errMsg = JSON.stringify(error.error);
    }
    return errMsg;
  }

  forgotPassword(body: { username: string }) {
    return this.apiService
      .post(`/auth/password/forgot_password/`, body)
      .pipe(map(data => data));
  }

  forgotUsername(body: { email: string }) {
    return this.apiService
      .post(`/auth/password/forgot_username/`, body)
      .pipe(map(data => data));
  }

  forgotPasswordReset(body: {
    new_password1: any;
    new_password2: any;
    user: string;
    token: string;
    _ems_silenced: boolean;
  }) {
    return this.apiService
      .post(`/auth/password/forgot_password_reset/`, body, true)
      .pipe(map(data => data));
  }

  @DecoratorsService.DevCache()
  checkToken(silenced = false) {
    const token = this.localStorageService.getItem('id_token');
    if (!token) {
      return throwError('not logged in');
    }
    return this.http.post<any>(environment.api_url + '/auth/token-verify/', {
      token: token,
      _ems_silenced: silenced,
    });
  }

  verifyEmail(body: { user: string; token: string }) {
    return this.apiService
      .post(`/email/verification/verify_email/`, body)
      .pipe(map(data => data));
  }

  resendVerificationEmail(obj = {}) {
    return this.apiService
      .post(`/email/verification/resend_verification_email/`, obj)
      .pipe(map(data => data));
  }

  private setSession(authResult: string) {
    // const expiresAt = authResult.expiresIn;
    this.localStorageService.setItem('id_token', authResult);
    const data: any = this.getDataFromToken(authResult);
    this.localStorageService.setItem(
      'expires_at',
      JSON.stringify(data.exp.valueOf())
    );
  }

  private urlBase64Decode(str) {
    let output = str.replace('-', '+').replace('_', '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        throw new Error('Illegal base64url string!');
    }

    return window.atob(output);
  }
  private getDataFromToken(token) {
    let data = {};
    if (typeof token !== 'undefined') {
      const encoded = token.split('.')[1];
      data = JSON.parse(this.urlBase64Decode(encoded));
    }

    return data;
  }
}
