<div class="overlay-container" *ngIf="status">
  <div class="dialog-container">
    <div class="overlay-pane" [ngStyle]="{'max-width': maxWidth}" [@dialog]>
      <div class="card-header" *ngIf="title">
        <i class="material-icons" *ngIf="titleIcon">{{titleIcon}}</i> <strong>{{title | translate}}</strong>
      </div>
      <div class="modal">
        <p *ngIf="message">{{message}}</p>
        <div class="modal-top-actions" *ngIf="(closeButton || confirmButton) && showActionsOnTop">
          <app-button text="{{'CLOSE' | translate}}" (click)="closeModal($event)" *ngIf="closeButton"></app-button>
          <app-button text="{{'CONFIRM' | translate}}" className="btn-red" (click)="confirmModal($event)" *ngIf="confirmButton"></app-button>
        </div>
        <div class="modal-content">
          <ng-content></ng-content>
        </div>
        <div class="modal-actions" *ngIf="closeButton || confirmButton">
          <app-button text="{{'CLOSE' | translate}}" (click)="closeModal($event)" *ngIf="closeButton"></app-button>
          <app-button text="{{'CONFIRM' | translate}}" className="btn-red" (click)="confirmModal($event)" *ngIf="confirmButton"></app-button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="overlay-backdrop" *ngIf="status && showOverlay"></div>
