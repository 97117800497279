import { ShippingActionTypes } from '../actions/index';
const ɵ0 = {
    first_name: null,
    last_name: null,
    company: null,
    phone_code: null,
    phone: null,
    email: null,
    shipping_id: null,
    delivery_condition: null,
    shipping_date: null,
    shipping_time: null,
    comment: null
};
export const initialState = {
    data: ɵ0,
    loaded: false,
    loading: false,
    updated: false
};
export function reducer(state = initialState, action) {
    switch (action.type) {
        case ShippingActionTypes.ShippingLoad: {
            return Object.assign({}, state, { loading: true });
        }
        case ShippingActionTypes.ShippingLoadSuccess: {
            return Object.assign({}, state, { loaded: true, loading: false, data: action.payload });
        }
        case ShippingActionTypes.ShippingLoadFail: {
            return {
                data: state.data,
                loading: false,
                loaded: false,
                updated: false
            };
        }
        case ShippingActionTypes.UpdateShipping: {
            return Object.assign({}, state, { loading: true });
        }
        case ShippingActionTypes.UpdateShippingSuccess: {
            return Object.assign({}, state, { loaded: true, loading: false, updated: true, data: action.payload });
        }
        case ShippingActionTypes.UpdateShippingFail: {
            return Object.assign({}, state);
        }
        case ShippingActionTypes.ResetShipping: {
            return Object.assign({}, initialState);
        }
        default: {
            return state;
        }
    }
}
export const getShipping = (state) => state.data;
export const getShippingLoaded = (state) => state.loaded;
export const getShippingLoading = (state) => state.loading;
export const getShippingUpdated = (state) => state.updated;
export { ɵ0 };
