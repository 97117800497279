var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Observable } from 'rxjs';
import { ApiService } from '@core/api.service';
import { map } from 'rxjs/internal/operators';
import { DecoratorsService } from '@app/core/develop/decorators';
export class StatisticsService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    getSearchHistory(body) {
        return this.apiService
            .get(`/statistics/searches/`, body)
            .pipe(map(data => data));
    }
}
__decorate([
    DecoratorsService.DevCache(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Observable)
], StatisticsService.prototype, "getSearchHistory", null);
