var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { SubGroupActionTypes } from '../actions/subGroup.action';
import { SubGroupService } from '@app/core/service/subGroup.service';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SubGroupActions from '../actions/subGroup.action';
export class SubGroupEffects {
    constructor(actions$, service) {
        this.actions$ = actions$;
        this.service = service;
        this.loadSubgroups$ = this.actions$.pipe(ofType(SubGroupActionTypes.SubGroupsLoad), mergeMap(() => {
            return this.service.getSubGroups().pipe(map(jobs => new SubGroupActions.SubGroupsLoadSuccess(jobs.results)), catchError(error => of(new SubGroupActions.SubGroupsLoadFail(error))));
        }));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], SubGroupEffects.prototype, "loadSubgroups$", void 0);
