import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export interface SpinnerState {
  show: boolean;
}

@Injectable()
export class SpinnerService {
  private spinnerSubject = new Subject<SpinnerState>();

  spinnerState = this.spinnerSubject.asObservable();

  timeoutHandler: any;

  constructor(@Optional() @SkipSelf() prior: SpinnerService) {
    if (prior) {
      return prior;
    }
  }

  show() {
    this.spinnerSubject.next(<SpinnerState> { show: true });

    this.timeoutHandler = setTimeout(() => {
      this.spinnerSubject.next(<SpinnerState> { show: false });
    }, 30000);
  }

  hide() {
    this.spinnerSubject.next(<SpinnerState> { show: false });
    clearTimeout(this.timeoutHandler);
  }

  getMessage(): Observable<any> {
    return this.spinnerSubject.asObservable();
  }
}
