import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/core/api.service';
import { map } from 'rxjs/internal/operators';
import { IShowcaseCatalogueTree, IShowcaseCataloguePogrhead } from '@app/pages/dashboard/models/showcase.model';
import { ISubGroupResult } from '../models/subgroup';
import { IGroup } from '../models/group';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { DecoratorsService } from '../develop/decorators';

@Injectable()
export class CatalogueService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  @DecoratorsService.DevCache()
  getBaseGroups(): Observable<any> {
    return this.apiService.getJSON('basegroups.json').pipe(map(data => data));
  }
  @DecoratorsService.DevCache()
  getGroupsByStock(): Observable<IGroup[]> {
    return this.http.get<IGroup[]>(environment.api_url + '/products/stock_sub_groups/');
    // return this.apiService.getJSON('groups.json').pipe(map(data => data));
  }
  @DecoratorsService.DevCache()
  getPogrHead(): Observable<IShowcaseCataloguePogrhead[]> {
    return this.apiService.getJSON('pogrhead.json').pipe(map(data => data));
  }
}
