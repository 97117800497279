import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ApiService } from './api.service';
import { NavigationComponent } from './navigation/navigation.component';
import { ProductService } from '../shared/services/product.service';
import { SpinnerModule } from './spinner/spinner.module';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { SharedModule } from '../shared/shared.module';
import { SearchService } from '../shared/services/search.service';
import { SelectPipe } from '../shared/ui/select/pipes/select.pipe';
import { httpInterceptorProviders } from '@app/core/http-interceptors';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { AppComponent } from '@app/core/containers/app.component';
import { LayoutComponent } from '@app/core/components/layout.component';
import { SubGroupService } from '@app/core/service/subGroup.service';
import { HelperService } from '@app/shared/services/helper.service';
import { CartGuard } from '@core/guards/cart-guard.service';
import { UserAddressesService } from '@core/service/user-addresses.service';
import { UserCarsService } from '@core/service/user-cars.service';
import { ToastComponent } from '@app/core/toast/toast.component';
import { ZendeskComponent } from '@core/components/zendesk/zendesk.component';
import { TawktoComponent } from '@core/components/tawkto/tawkto.component';
import { FooterModule } from '@core/footer/footer.module';
import { CartModule } from '@pages/cart/cart.module';
import { ScriptService } from '@core/scripts/script.service';
import { CartMiniContainerComponent } from '@core/components/cart-mini-container/cart-mini-container.component';
import { LanguageSwitchComponent } from '@core/components/language-switch/language-switch.component';
import { LanguageService } from '@core/service/language.service';
import { UserService } from '@core/user/user.service';
import { PageTitleService } from '@core/service/pageTitle.service';
import { CookiesPolicyModalComponent } from '@core/components/cookies-policy-modal/cookies-policy-modal.component';
import { VersionService } from '@shared/services/version.service';
import { SentryService } from '@shared/services/sentry.service';
import { CatalogueService } from '@core/service/catalogue.service';
import { VersionErrorComponent } from './components/version-error.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { CachedRequestService } from '@app/core/service/cached-req.service';
import { DecoratorsService } from './develop/decorators';

export const COMPONENTS = [
  AppComponent,
  NavigationComponent,
  LayoutComponent,
  ZendeskComponent,
  TawktoComponent,
  ToastComponent,
  LanguageSwitchComponent,
  CartMiniContainerComponent,
  VersionErrorComponent,
  CookiesPolicyModalComponent
];

@NgModule({
  imports: [CommonModule, RouterModule, SpinnerModule, FooterModule, CartModule, SharedModule, NgZorroAntdModule, ScrollingModule],
  exports: COMPONENTS,
  declarations: COMPONENTS,
  providers: [
    ApiService,
    ProductService,
    SearchService,
    SelectPipe,
    httpInterceptorProviders,
    LocalStorageService,
    CachedRequestService,
    SubGroupService,
    UserAddressesService,
    UserCarsService,
    HelperService,
    ScriptService,
    CartGuard,
    LanguageService,
    UserService,
    VersionService,
    SentryService,
    CatalogueService,
    PageTitleService,
    DecoratorsService,
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
