import { createSelector } from '@ngrx/store';

import * as fromCart from '../reducers/cart.reducer';
import * as fromFeature from '../reducers';

export const getProductsState = createSelector(
  fromFeature.getCartState,
  (state: fromFeature.CartState) => state.products
);

export const getCartProducts = createSelector(
  getProductsState,
  fromCart.getCartProducts
);

export const getCartLoaded = createSelector(
  getProductsState,
  fromCart.getCartLoaded
);

export const getCartUpdated = createSelector(
  getProductsState,
  fromCart.getCartUpdated
);

export const getCartLoading = createSelector(
  getProductsState,
  fromCart.getCartLoading
);

export const getCartRemoved = createSelector(
  getProductsState,
  fromCart.getCartLoading
);

