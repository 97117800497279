/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../ui/breadcrumbs/breadcrumbs.component.ngfactory";
import * as i3 from "../../ui/breadcrumbs/breadcrumbs.component";
import * as i4 from "@angular/router";
import * as i5 from "../../ui/back-button/back-button.component.ngfactory";
import * as i6 from "../../ui/back-button/back-button.component";
import * as i7 from "@angular/common";
import * as i8 from "../../pipes/translate/translate.pipe";
import * as i9 from "../../../core/service/translation.service";
import * as i10 from "@ngrx/store";
import * as i11 from "./error-page.component";
var styles_ErrorPageComponent = [i0.styles];
var RenderType_ErrorPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorPageComponent, data: { "animation": [{ type: 7, name: "fadeInDown", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0, transform: "translateY(-2rem)" }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateY(0rem)" }, offset: null }, timings: "300ms ease-in" }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0, transform: "translateY(-2rem)" }, offset: null }, timings: "300ms ease-out" }], options: null }], options: {} }] } });
export { RenderType_ErrorPageComponent as RenderType_ErrorPageComponent };
export function View_ErrorPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-breadcrumbs", [], null, null, null, i2.View_BreadcrumbsComponent_0, i2.RenderType_BreadcrumbsComponent)), i1.ɵdid(1, 114688, null, 0, i3.BreadcrumbsComponent, [i4.ActivatedRoute], { items: [0, "items"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-back-button", [], null, null, null, i5.View_BackButtonComponent_0, i5.RenderType_BackButtonComponent)), i1.ɵdid(3, 49152, null, 0, i6.BackButtonComponent, [i4.ActivatedRoute, i4.Router, i7.Location], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 20, "article", [["class", "template"]], [[24, "@fadeInDown", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 19, "div", [["class", "base-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 18, "div", [["class", "error-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h2", [["class", "error-page_title"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i9.TranslationService, i10.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ": '", "'"])), i1.ɵpid(131072, i8.TranslatePipe, [i9.TranslationService, i10.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 4, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "a", [["class", "home-icon"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(16, null, [" ", " "])), i1.ɵpid(131072, i8.TranslatePipe, [i9.TranslationService, i10.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(18, 0, null, null, 3, "a", [["class", "home-icon"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 1, "img", [["src", "assets/images/logo-skuba.png"]], [[8, "alt", 0]], null, null, null, null)), i1.ɵpid(131072, i8.TranslatePipe, [i9.TranslationService, i10.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(22, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(23, null, ["", ""])), i1.ɵpid(131072, i8.TranslatePipe, [i9.TranslationService, i10.Store, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.breadcrumbs; _ck(_v, 1, 0, currVal_0); var currVal_7 = "/"; _ck(_v, 15, 0, currVal_7); var currVal_11 = "/"; _ck(_v, 19, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = undefined; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("PAGE_NOT_FOUND_OOPS")); _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 11, 0, i1.ɵnov(_v, 12).transform("NOT_FOUND")); var currVal_4 = _co.urlPath; _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵnov(_v, 15).target; var currVal_6 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform("GET_ME_HOME")); _ck(_v, 16, 0, currVal_8); var currVal_9 = i1.ɵnov(_v, 19).target; var currVal_10 = i1.ɵnov(_v, 19).href; _ck(_v, 18, 0, currVal_9, currVal_10); var currVal_12 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("SKUBA_LOGO")); _ck(_v, 20, 0, currVal_12); var currVal_13 = i1.ɵunv(_v, 23, 0, i1.ɵnov(_v, 24).transform("PAGE_NOT_FOUND")); _ck(_v, 23, 0, currVal_13); }); }
export function View_ErrorPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-page", [], null, null, null, View_ErrorPageComponent_0, RenderType_ErrorPageComponent)), i1.ɵdid(1, 114688, null, 0, i11.ErrorPageComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorPageComponentNgFactory = i1.ɵccf("app-error-page", i11.ErrorPageComponent, View_ErrorPageComponent_Host_0, {}, {}, []);
export { ErrorPageComponentNgFactory as ErrorPageComponentNgFactory };
