import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';

import * as SimilarProductsActions from '../actions/similar-products.action';
import { SimilarProductsActionTypes } from '../actions/similar-products.action';
import { ProductService } from '@shared/services/product.service';

@Injectable()
export class SimilarProductsEffects {
  constructor(
    private actions$: Actions,
    private productService: ProductService
  ) {}

  @Effect() loadSubgroups$ = this.actions$.pipe(
    ofType(SimilarProductsActionTypes.SimilarProductsLoad),
    debounceTime(300),
    map((action: SimilarProductsActions.SimilarProductsLoad) => action.payload),
    switchMap(body => {
      return this.productService.boughtWith(body).pipe(
        map(
          history =>
            new SimilarProductsActions.SimilarProductsLoadSuccess(history)
        ),

        catchError(error =>
          of(new SimilarProductsActions.SimilarProductsLoadFail(error))
        )
      );
    })
  );
}
