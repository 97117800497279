<section [@fadeInDown] class="login-section">
  <app-back-button [shouldNavigateHome]="true"></app-back-button>
  <div class="base-container">
    <div class="card">
      <div class="card-body">
        <div class="align-center">
          <a href="/login" routerLink="/login">
            <img src="assets/images/logo-skuba.png" alt="{{'SKUBA_LOGO'| translate}}" class="home-logo form-logo">
          </a>
        </div>
        <h1 class="align-center">{{'REGISTER' | translate}}</h1>
        <form
          (submit)="submitForm(userForm)"
          [formGroup]="userForm"
          #formDir="ngForm"
          [ngClass]="{'not-submitted': !formSubmitAttempt}"
          [@fadeIn]>
          <h5 class="form-group-title">{{'ACCOUNT' | translate}}</h5>
          <div class="row form-group-half">
            <div class="col" *ngIf="showAdditionalFields">
              <ng-container *ngFor="let info of additionalFieldsUser">
                <div nz-col [nzSpan]="info.span">
                  <app-input
                    *ngIf="info.isText || info.isNumber"
                    [type]="info.isNumber ? 'number' : 'text'"
                    [formControlName]="info.formName"
                    [placeholder]="info.placeholder"
                    [label]="info.label"
                    [isRequired]="true">
                    <app-input-validation
                      className="error"
                      message="REQUIRED"
                      [condition]="(userForm.get(info.formName).touched || formDir.submitted) && userForm.get(info.formName).hasError('required')">
                    </app-input-validation>
                  </app-input>
                <app-select
                          *ngIf="info.isSelect"
                          [formControlName]="info.formName"
                          [label]="info.label"
                          [disablePlaceholder]="false"
                          [initialOption]="null"
                          [options]="info.selectValues"
                          [imgWithBorder] = "true"
                          [imgSmall] = "true"
                          [isRequired]="true">
                </app-select>
                </div>
                <div nz-row *ngIf="info.br"></div>
              </ng-container>
            </div>
            <div class="col" *ngIf="showAdditionalFields">
            </div>
            <div class="col">
              <app-input formControlName="username" placeholder="USERNAME" label="USERNAME" [focus]="true" [isRequired]="true">
                <app-input-validation
                  className="error"
                  message="REQUIRED"
                  [condition]="(userForm.get('username').touched || formDir.submitted) && userForm.get('username').hasError('required')">
                </app-input-validation>
                <app-input-validation
                  className="error"
                  message="VALID_USERNAME"
                  [condition]="(userForm.get('username').touched || userForm.get('username').dirty || formDir.submitted) && (userForm.get('username').hasError('usernameValidator'))">
                </app-input-validation>
                <app-input-validation
                  className="error"
                  message="VALID_USERNAME"
                  [condition]="(userForm.get('username').touched || formDir.submitted) && userForm.get('username').hasError('VALID_USERNAME')">
                </app-input-validation>
                <app-input-validation
                  className="error"
                  message="Username is already taken"
                  [condition]="(userForm.get('username').touched || formDir.submitted) && userForm.get('username').hasError('USERNAME_TAKEN')">
                </app-input-validation>
              </app-input>
              <nz-spin *ngIf="usernameCheckLoading" nzSimple></nz-spin>
            </div>
            <div class="col">
              <app-input formControlName="email" placeholder="EMAIL" label="EMAIL" [isRequired]="true">
                <app-input-validation
                  className="error"
                  message="EMAIL_VALIDATION_ERROR"
                  [condition]="(userForm.get('email').touched || formDir.submitted) && userForm.get('email').hasError('email')">
                </app-input-validation>
                <app-input-validation
                  className="error"
                  message="REQUIRED"
                  [condition]="(userForm.get('email').touched || formDir.submitted) && userForm.get('email').hasError('required')">
                </app-input-validation>
              </app-input>
            </div>
          </div>
          <div class="row form-group-half">
            <div class="col">
              <app-input formControlName="first_name" placeholder="FIRST_NAME" label="FIRST_NAME" [isRequired]="true">
                <app-input-validation
                  className="error"
                  message="REQUIRED"
                  [condition]="(userForm.get('first_name').touched || formDir.submitted) && userForm.get('first_name').hasError('required')">
                </app-input-validation>
                <app-input-validation
                  className="error"
                  [message]="'MAX_SYMBOLS' | translate:{ value: 30 }"
                  [condition]="(userForm.get('first_name').touched || formDir.submitted) && userForm.get('first_name').hasError('maxlength')">
                </app-input-validation>
              </app-input>
            </div>
            <div class="col">
              <app-input formControlName="last_name" placeholder="LAST_NAME" label="LAST_NAME" [isRequired]="true">
                <app-input-validation
                  className="error"
                  message="REQUIRED"
                  [condition]="(userForm.get('last_name').touched || formDir.submitted) && userForm.get('last_name').hasError('required')">
                </app-input-validation>
                <app-input-validation
                  className="error"
                  [message]="'MAX_SYMBOLS' | translate:{ value: 150 }"
                  [condition]="(userForm.get('last_name').touched || formDir.submitted) && userForm.get('last_name').hasError('maxlength')">
                </app-input-validation>
              </app-input>
            </div>
          </div>
          <div class="contacts row">
            <div class="phone-number">
              <div class="p-code col">
                <app-select formControlName="phone_code" label="PHONE_CODE"
                            [initialOption]="phoneCodeInitial"
                            [options]="countryPhoneCodes"
                            [isRequired]="true"></app-select>
              </div>
              <div class="p-number col">
                <app-input formControlName="phone" placeholder="PHONE_NR" label="PHONE_NR" [isRequired]="true">
                  <app-input-validation
                    className="error"
                    message="PHONE_FORMAT_ERROR"
                    [condition]="(userForm.get('phone').touched || formDir.submitted) && (userForm.get('phone').hasError('phoneValidator'))">
                  </app-input-validation>
                  <app-input-validation
                    className="error"
                    message="REQUIRED"
                    [condition]="(userForm.get('phone').touched || formDir.submitted) && (userForm.get('phone').hasError('required'))">
                  </app-input-validation>
                </app-input>
              </div>
            </div>
            <div class="skype">
              <div class="col">
                <app-input formControlName="skype" placeholder="SKYPE" label="SKYPE">
                  <app-input-validation
                    className="error"
                    message="REQUIRED"
                    [condition]="(userForm.get('skype').touched || formDir.submitted) && userForm.get('skype').hasError('required')">
                  </app-input-validation>
                  <app-input-validation
                    className="error"
                    [message]="'MAX_SYMBOLS' | translate:{ value: 30 }"
                    [condition]="(userForm.get('skype').touched || formDir.submitted) && userForm.get('skype').hasError('maxlength')">
                  </app-input-validation>
                </app-input>
              </div>
            </div>
          </div>
          <div class="row form-group-half">
            <div class="col">
              <app-input
                formControlName="password1"
                placeholder="PASSWORD"
                label="PASSWORD"
                type="password"
                [isRequired]="true">
                <app-input-validation
                  className="error"
                  message="REQUIRED"
                  [condition]="(userForm.get('password1').touched || formDir.submitted) && userForm.get('password1').hasError('required')">
                </app-input-validation>
                <app-input-validation
                  className="error"
                  message="PASSWORD_NOT_MATCH"
                  [condition]="(userForm.get('password2').touched || formDir.submitted) && userForm.get('password2').hasError('MatchPassword')">
                </app-input-validation>
                <app-input-validation
                  className="error"
                  message="PASS_MIN_LEN_6"
                  [condition]="(userForm.get('password1').touched || formDir.submitted) && userForm.get('password1').hasError('PASS_MIN_LEN_6')">
                </app-input-validation>
                <app-input-validation
                  className="error"
                  message="PASS_TOO_COMMON"
                  [condition]="(userForm.get('password1').touched || formDir.submitted) && userForm.get('password1').hasError('PASS_TOO_COMMON')">
                </app-input-validation>
              </app-input>
              <nz-spin *ngIf="passCheckLoading" nzSimple></nz-spin>
            </div>
            <div class="col">
              <app-input formControlName="password2"
                         placeholder="REPEAT_PASSWORD"
                         label="REPEAT_PASSWORD"
                         type="password"
                         [isRequired]="true">
                <app-input-validation
                  className="error"
                  message="REQUIRED"
                  [condition]="(userForm.get('password2').touched || formDir.submitted) && userForm.get('password2').hasError('required')">
                </app-input-validation>
                <app-input-validation
                  className="error"
                  message="PASSWORD_NOT_MATCH"
                  [condition]="(userForm.get('password2').touched || formDir.submitted) && userForm.get('password2').hasError('MatchPassword')">
                </app-input-validation>
              </app-input>
            </div>
          </div>
          <h5 class="form-group-title">{{'CLIENT' | translate}}</h5>
          <app-select formControlName="company_id"
                      label="COMPANY"
                      *ngIf="companies"
                      [disablePlaceholder]="false"
                      [initialOption]="companyInitial"
                      [options]="companies"
                      [imgWithBorder] = "true"
                      [imgSmall] = "true"
                      [isRequired]="true">
          </app-select>
          <app-select formControlName="shop_id"
                      label="COMPANY_BRANCH"
                      *ngIf="shops"
                      [disablePlaceholder]="false"
                      [initialOption]="shopInitial"
                      [options]="shops"
                      [imgWithBorder] = "true"
                      [imgSmall] = "true"
                      [isRequired]="true">
          </app-select>
          <span class="client-hint" *ngIf="!showAdditionalFields">{{'CLIENT_HINT_TEXT' | translate}}</span>
          <app-input formControlName="client_hint" placeholder="CLIENT_HINT" label="CLIENT_HINT" [isRequired]="true" *ngIf="!showAdditionalFields">
            <app-input-validation
              className="error"
              message="REQUIRED"
              [condition]="(userForm.get('client_hint').touched || formDir.submitted) && userForm.get('client_hint').hasError('required')">
            </app-input-validation>
            <app-input-validation
              className="error"
              [message]="'MAX_SYMBOLS' | translate:{ value: 100 }"
              [condition]="(userForm.get('client_hint').touched || formDir.submitted) && userForm.get('client_hint').hasError('maxlength')">
            </app-input-validation>
          </app-input>
          <ng-container *ngIf="showAdditionalFields">
            <!-- SK Fields -->
            <h5 class="form-group-title form-group-title-small">{{'ADDITIONAL_INFO' | translate}}</h5>
            <span class="additional-info-hint">{{'ADDITIONAL_INFO_EXPLAINED' | translate}}</span>
            <div nz-row nzGutter="16">
              <ng-container *ngFor="let info of additionalFields">
                <div nz-col [nzSpan]="info.span">
                  <app-input
                    *ngIf="info.isText || info.isNumber"
                    [type]="info.isNumber ? 'number' : 'text'"
                    [formControlName]="info.formName"
                    [placeholder]="info.placeholder"
                    [label]="info.label"
                    [isRequired]="info.required">
                    <app-input-validation
                      className="error"
                      message="REQUIRED"
                      [condition]="(userForm.get(info.formName).touched || formDir.submitted) && userForm.get(info.formName).hasError('required')">
                    </app-input-validation>
                  </app-input>
                <app-select
                          *ngIf="info.isSelect"
                          [formControlName]="info.formName"
                          [label]="info.label"
                          [disablePlaceholder]="false"
                          [initialOption]="null"
                          [options]="info.selectValues"
                          [imgWithBorder] = "true"
                          [imgSmall] = "true"
                          [isRequired]="true">
                </app-select>
                </div>
                <div nz-row *ngIf="info.br"></div>
              </ng-container>
            </div>
          </ng-container>

          <nz-alert
            *ngIf="customErrorsMsg"
            nzType="error"
            [nzMessage]="customErrorsMsg"
            nzShowIcon
            nzCloseable
            (nzOnClose)="clearFormErrors()"
          ></nz-alert>
          <div class="align-right">
            <div class="subscribe-to-emails">
              <input type="checkbox" placeholder="EMAIL_SUBSCRIPTION" label="EMAIL_SUBSCRIPTION" formControlName="subscribe_to_emails">
              <span class="subscribe-to-emails-text">
                {{'I_WANT_TO_SUBSCRIBE_TO_EMAILS' | translate}}
              </span>
            </div>

            <div class="agreement" *ngIf="showGeneralTermsCheckbox" [ngClass]="{'animated shake': generalTermsError}">
              <input type="checkbox" placeholder="GENERAL_TERMS" label="GENERAL_TERMS" formControlName="agreement">
              <span class="agreement-text" [ngClass]="{'error': generalTermsError}">
                <a href="/page/general-terms" routerLink="/page/general-terms" target="_blank">
                  {{'I_AGREE_WITH_GENERAL_TERMS' | translate}}
                </a>
              </span>
            </div>
            <div class="additional-restriction" *ngIf="showAdditionalRestrictionCheckbox" [ngClass]="{'animated shake': additionalRestrictionCheckboxError }">
              <input type="checkbox" placeholder="SK_ONLY_ADDITIONAL_CHECKBOX" label="SK_ONLY_ADDITIONAL_CHECKBOX" formControlName="additional_restriction">
              <span class="additional-restriction-text"  [ngClass]="{'error': additionalRestrictionCheckboxError }">
                {{'SK_ONLY_ADDITIONAL_CHECKBOX' | translate}}
              </span>
            </div>

            <app-button text="SAVE" className="btn-red"></app-button>
          </div>
        </form>

      </div>
    </div>
  </div>
</section>
