/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-validation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../pipes/translate/translate.pipe";
import * as i4 from "../../../../core/service/translation.service";
import * as i5 from "@ngrx/store";
import * as i6 from "./input-validation.component";
var styles_InputValidationComponent = [i0.styles];
var RenderType_InputValidationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputValidationComponent, data: {} });
export { RenderType_InputValidationComponent as RenderType_InputValidationComponent };
function View_InputValidationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["clear"]))], null, null); }
function View_InputValidationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "app-input-validation"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i4.TranslationService, i5.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputValidationComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "app-input-validation"; var currVal_1 = _co.className; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = (_co.className === "error"); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.message)); _ck(_v, 3, 0, currVal_2); }); }
export function View_InputValidationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputValidationComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.condition; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InputValidationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-input-validation", [], null, null, null, View_InputValidationComponent_0, RenderType_InputValidationComponent)), i1.ɵdid(1, 49152, null, 0, i6.InputValidationComponent, [], null, null)], null, null); }
var InputValidationComponentNgFactory = i1.ɵccf("app-input-validation", i6.InputValidationComponent, View_InputValidationComponent_Host_0, { className: "className", condition: "condition", message: "message" }, {}, []);
export { InputValidationComponentNgFactory as InputValidationComponentNgFactory };
