import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cookies-policy-modal',
  templateUrl: './cookies-policy-modal.component.html',
  styleUrls: ['./cookies-policy-modal.component.scss']
})
export class CookiesPolicyModalComponent implements OnInit {
  @Output() readonly updateStatus = new EventEmitter<boolean>();

  constructor(private router: Router) {}

  ngOnInit() {}

  clickAgree() {
    this.updateStatus.emit(true);
  }

  readMore() {
    this.router.navigate(['/cookies-policy']);
  }
}
