import { Injectable, OnDestroy } from '@angular/core';
import { ScriptStore } from '@core/scripts/script.store';
import { Store, select } from '@ngrx/store';
import * as fromAuth from '@app/auth/store';
import { Observable, Subscription } from 'rxjs';
import { IUser } from '@app/auth/models/user';

declare var document: any;

@Injectable()
export class ScriptService implements OnDestroy {
  private user$: Observable<IUser>;
  private userSub: Subscription;
  private scripts: any = {};

  constructor(private store: Store<fromAuth.AuthState>) {
    this.user$ = this.store.pipe(select(fromAuth.getUser));

    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach(script => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      // Resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // Load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {
            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }
          };
        } else {
          // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: 'Loaded' });

        this.user$.subscribe(user => {
          if (user && user.profile && user.profile.is_user_interface_active) {
            document.getElementsByTagName('head')[0].appendChild(script);
          }
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.userSub) { this.userSub.unsubscribe(); }
  }
}
