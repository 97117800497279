import { Action } from '@ngrx/store';

import { ISimilarProductsList } from '@shared/models/product.model';

export enum SimilarProductsActionTypes {
  SimilarProductsLoad = '[SimilarProducts] Load Search History',
  SimilarProductsLoadFail = '[SimilarProducts] Load Search History Fail',
  SimilarProductsLoadSuccess = '[SimilarProducts] Load Search History Success',
  ResetSimilarProductsState = '[SimilarProducts] Reset State'
}

export class SimilarProductsLoad implements Action {
  readonly type = SimilarProductsActionTypes.SimilarProductsLoad;

  constructor(public payload: any) {}
}

export class SimilarProductsLoadFail implements Action {
  readonly type = SimilarProductsActionTypes.SimilarProductsLoadFail;

  constructor(public payload: any) {}
}

export class SimilarProductsLoadSuccess implements Action {
  readonly type = SimilarProductsActionTypes.SimilarProductsLoadSuccess;

  constructor(public payload: ISimilarProductsList) {}
}

export class ResetSimilarProductsState implements Action {
  readonly type = SimilarProductsActionTypes.ResetSimilarProductsState;
}

export type SimilarProductsActions =
  | SimilarProductsLoad
  | SimilarProductsLoadFail
  | SimilarProductsLoadSuccess
  | ResetSimilarProductsState;
