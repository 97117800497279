import { Component, OnInit } from '@angular/core';
import { fadeIn, fadeInDown } from '../../animations';

/**
 * @whatItDoes returns unauth user header component.
 */
@Component({
  selector: 'app-unauth-header',
  templateUrl: './unauth-header.component.html',
  styleUrls: ['./unauth-header.component.scss'],
  animations: [fadeIn, fadeInDown]
})
export class UnauthHeaderComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
