<div class="cookies-policy-modal card">
  <div class="card-body">
    <p>{{'COOKIES_POLICY_INFO1' | translate}}</p>
    <br>
    <p>{{'COOKIES_POLICY_INFO2' | translate}}</p>
    <div class="modal-actions flex-align-center row">
      <div class="col">
        <app-button className="btn-red" text="AGREE" (click)="clickAgree()"></app-button>
      </div>
      <div class="col">
        <app-button className="btn-transparent" text="MORE_INFO" (click)="readMore()"></app-button>
      </div>
    </div>
  </div>
</div>
