import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map, take, filter } from 'rxjs/operators';
import * as fromRouter from '../../core/store/actions/router.action';
import * as fromAuth from '@app/auth/store';
import { AppState } from '@app/reducers';

@Injectable()
export class AdminStatusGuard implements CanActivate {

  constructor(private store: Store<AppState>) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(fromAuth.getUser),
      filter(user => !!user),
      map(user => {
        if (!user.is_staff) {
          this.store.dispatch(
            new fromRouter.Go({ path: ['/dashboard'] })
          );
          return false;
        }

        return true;
      }),
      take(1)
    );
  }
}
