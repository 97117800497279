<tr class="table-header nz-disable-tr">
  <th class="nz-disable-th" *ngIf="allowRequest"></th>
  <th class="nz-disable-th" *ngIf="checkbox" [ngStyle]="{'width.%': 5}"></th>
  <ng-container *ngFor="let map of columnMaps">
    <th class="nz-disable-th" [ngStyle]="map.style" *ngIf="!map.hide">
      <span (click)="clickSort(map)" [ngClass]="{sort: map.sort}">{{map.header | translate}}
        <i *ngIf="map.sort && (!map.sorted && !map.reversed)" class="material-icons">sort_by_alpha</i>
        <i *ngIf="map.sort && ((map.sorted || map.reversed) && !(map.sorted && map.reversed))" class="material-icons">arrow_drop_up</i>
        <i *ngIf="map.sort && map.sorted && map.reversed" class="material-icons">arrow_drop_down</i>
      </span>
    </th>
  </ng-container>
  <th class="nz-disable-th" *ngIf="viewButton"></th>
</tr>
<tr class="table-filters nz-disable-tr" *ngIf="group && filter" [formGroup]="group">
  <th class="nz-disable-th" *ngIf="allowRequest"></th>
  <th class="nz-disable-th" *ngIf="checkbox"></th>
  <th class="nz-disable-th" *ngFor="let map of columnMaps">
    <div *ngIf="map.filters">
      <label class="show-mobile">{{map.header | translate}}</label>
      <app-input-with-autocomplete *ngIf="map.filters.type === 'input'"
                 (autocompleteChange)="inputChanged($event, map)"
                 [formControlName]="map.filters.key" (keyup.enter)="searchOnEnterKeyPress($event, map.filters.type === 'input')">
        <app-datepicker
          *ngIf="map.filters.datepicker && languageSet"
          (outputEvents)="setDate($event, map.filters.key)">
        </app-datepicker>
      </app-input-with-autocomplete>
      <app-select
        *ngIf="map.filters.type === 'select'"
        [options]="map.filters.options"
        [formControlName]="map.filters.key" (update)="inputChanged($event, map)">
      </app-select>
    </div>
  </th>
  <th class="nz-disable-th" *ngIf="(actions || group) && filter" class="filter-actions">
    <div (click)="clickFilter()">
      <span>
        <i class="material-icons">filter_list</i>
        <span>{{'FILTER' | translate}}</span>
      </span>
    </div>
    <div (click)="resetFilter()">
      <span>
        <i class="material-icons">close</i>
        <span>{{'RESET' | translate}}</span>
      </span>
    </div>
  </th>
</tr>
