import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map, take, filter } from 'rxjs/operators';
import * as fromRouter from '../../core/store/actions/router.action';
import * as fromAuth from '@app/auth/store';
import { IUser } from '@app/auth/models/user';
import { AppState } from '@app/reducers';

@Injectable()
export class StatusGuard implements CanActivate {

  constructor(private store: Store<AppState>) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(fromAuth.getUser),
      // we wait util user gets authenticated, if its not, we just skip
      filter(user => !!user),
      map(user => {
        if (user && user.profile && user.profile.force_reset_password) {
          if (state.url === '/profile/user/change-pass') {
            return true;
          }
          this.store.dispatch(
            new fromRouter.Go({ path: ['/profile/user/change-pass'] })
          );

          return false;
        }
        if (user && user.profile && user.profile.status !== 1) {
          if (state.url === '/profile/user/info') {
            return true;
          }
          if (state.url === '/profile/user') {
            return true;
          }
          this.store.dispatch(
            new fromRouter.Go({ path: ['/profile/user/info'] })
          );

          return false;
        }

        return true;
      }),
      take(1)
    );
  }
}
