
    <div>
      <div style="padding: 5px; border-bottom: 1px solid grey">
        <b>Custom Proficiency Filter</b>
      </div>
      <div *ngFor="let proficiency of filterValues" style="margin-top: 3px">
        <label style="padding-left: 4px">
          <input type="radio" [checked]="selected === proficiency" (change)="onButtonPressed(proficiency)"/>
          {{proficiency.name}}
        </label>
      </div>
    </div>
  