export interface IToast {
  type: ToastType;
  message: string;
  skipReport?: boolean;
  persistent?: boolean;
}

export enum ToastType {
  Success,
  Error,
  Info,
  Warning
}
