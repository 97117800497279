/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unauth-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./unauth-header.component";
var styles_UnauthHeaderComponent = [i0.styles];
var RenderType_UnauthHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnauthHeaderComponent, data: { "animation": [{ type: 7, name: "fadeIn", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "400ms ease" }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "400ms ease" }], options: null }], options: {} }, { type: 7, name: "fadeInDown", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0, transform: "translateY(-2rem)" }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateY(0rem)" }, offset: null }, timings: "300ms ease-in" }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0, transform: "translateY(-2rem)" }, offset: null }, timings: "300ms ease-out" }], options: null }], options: {} }] } });
export { RenderType_UnauthHeaderComponent as RenderType_UnauthHeaderComponent };
export function View_UnauthHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "header", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "app-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "base-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "brand-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "a", [["class", "title-icon"], ["href", "/dashboard"], ["routerLink", "/dashboard"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "img", [["src", "assets/images/logo-skuba.png"]], null, null, null, null, null))], function (_ck, _v) { var currVal_2 = "/dashboard"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).target; var currVal_1 = i1.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_0, currVal_1); }); }
export function View_UnauthHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-unauth-header", [], null, null, null, View_UnauthHeaderComponent_0, RenderType_UnauthHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.UnauthHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnauthHeaderComponentNgFactory = i1.ɵccf("app-unauth-header", i4.UnauthHeaderComponent, View_UnauthHeaderComponent_Host_0, {}, {}, []);
export { UnauthHeaderComponentNgFactory as UnauthHeaderComponentNgFactory };
