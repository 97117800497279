import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as toast from '../../../core/store/actions/toast.action';
import { AuthService } from '../../services/auth.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import { Router } from '@angular/router';
import { HelperService } from '@shared/services/helper.service';

@Component({
  selector: 'app-forgot-user-form',
  templateUrl: './forgot-user-form.component.html',
  styleUrls: ['./forgot-user-form.component.scss']
})
export class ForgotUserFormComponent implements OnInit {
  forgotUserForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private store: Store<fromRoot.AppState>,
    private element: ElementRef,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm(): void {
    this.forgotUserForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]]
    });
  }

  submit() {
    this.helperService.validationScroll(this.element);

    if (this.forgotUserForm.valid) {
      this.authService
        .forgotUsername({
          email: this.forgotUserForm.value.email
        })
        .subscribe(() => {
          this.store.dispatch(new toast.ShowSuccessToast('SUCCESS'));

          this.router.navigate(['/auth/forgot-password']);
        });
    }
  }
}
