var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, catchError, mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserAddressesService } from '@app/core/service/user-addresses.service';
import * as fromActions from '../actions/user-addresses.action';
export class UserAddressesEffects {
    constructor(actions$, userAddressesService) {
        this.actions$ = actions$;
        this.userAddressesService = userAddressesService;
        this.loadUserAddress$ = this.actions$.pipe(ofType(fromActions.LOAD_USER_ADDRESSES), switchMap(() => {
            return this.userAddressesService.getShippingAddresses().pipe(map(user => new fromActions.LoadUserAddressesSuccess(user)), catchError(error => of(new fromActions.LoadUserAddressesFail(error))));
        }));
        this.addUserAddress$ = this.actions$.pipe(ofType(fromActions.ADD_USER_ADDRESS), map((action) => action.payload), mergeMap(address => {
            return this.userAddressesService.addShippingAddresses(address).pipe(map(addressItem => new fromActions.AddUserAddressSuccess(addressItem)), catchError(error => of(new fromActions.AddUserAddressFail(error))));
        }));
        this.patchUserAddress$ = this.actions$.pipe(ofType(fromActions.PATCH_USER_ADDRESS), map((action) => action.payload), mergeMap(data => {
            return this.userAddressesService
                .patchShippingAddress(data.id, data.body)
                .pipe(map(addressItem => new fromActions.PatchUserAddressSuccess(addressItem)), catchError(error => of(new fromActions.PatchUserAddressFail(error))));
        }));
        this.updateUserAddress$ = this.actions$.pipe(ofType(fromActions.UPDATE_USER_ADDRESS), map((action) => action.payload), mergeMap(data => {
            return this.userAddressesService
                .updateShippingAddress(data.id, data.body)
                .pipe(map(addressItem => new fromActions.UpdateUserAddressSuccess(addressItem)), catchError(error => of(new fromActions.UpdateUserAddressFail(error))));
        }));
        this.removeUserAddress$ = this.actions$.pipe(ofType(fromActions.REMOVE_USER_ADDRESS), map((action) => action.payload), mergeMap(id => {
            return this.userAddressesService.removeShippingAddress(id).pipe(map(() => new fromActions.RemoveUserAddressSuccess()), catchError(error => of(new fromActions.RemoveUserAddressFail(error))));
        }));
        this.setUserAddress$ = this.actions$.pipe(ofType(fromActions.SET_USER_ADDRESS), map((action) => action.payload), map(address => {
            return new fromActions.SetUserAddressSuccess(address);
        }));
        this.handlePatchUserAddressSuccess$ = this.actions$.pipe(ofType(fromActions.PATCH_USER_ADDRESS_SUCCESS), map(() => {
            return new fromActions.LoadUserAddresses();
        }));
        this.handleUpdateUserAddressSuccess$ = this.actions$.pipe(ofType(fromActions.UPDATE_USER_ADDRESS_SUCCESS), map(() => {
            return new fromActions.LoadUserAddresses();
        }));
        this.handleRemoveUserAddressSuccess$ = this.actions$.pipe(ofType(fromActions.REMOVE_USER_ADDRESS_SUCCESS), map(() => {
            return new fromActions.LoadUserAddresses();
        }));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserAddressesEffects.prototype, "loadUserAddress$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserAddressesEffects.prototype, "addUserAddress$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserAddressesEffects.prototype, "patchUserAddress$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserAddressesEffects.prototype, "updateUserAddress$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserAddressesEffects.prototype, "removeUserAddress$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserAddressesEffects.prototype, "setUserAddress$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserAddressesEffects.prototype, "handlePatchUserAddressSuccess$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserAddressesEffects.prototype, "handleUpdateUserAddressSuccess$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserAddressesEffects.prototype, "handleRemoveUserAddressSuccess$", void 0);
