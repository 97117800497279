import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { fadeIn, fadeInDown } from '@shared/animations';
import * as fromRoot from '@app/reducers';
import { Store, select } from '@ngrx/store';
import { Subscription, Observable } from 'rxjs';
import { PageComponent } from '@app/shared/models/page-component';

@Component({
  selector: 'app-forgot-password-reset-page',
  templateUrl: './forgot-password-reset-page.component.html',
  styleUrls: ['./forgot-password-reset-page.component.scss'],
  animations: [fadeInDown, fadeIn],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordResetPageComponent extends PageComponent implements OnInit, OnDestroy {
  user: string;
  token: string;
  router$: Observable<any>;
  routerSub: Subscription;

  constructor(private store: Store<fromRoot.AppState>) {
    super();
    this.router$ = this.store.pipe(select(fromRoot.getRouterState));
  }

  ngOnInit() {
    super.ngOnInit();
    this.routerSub = this.router$.subscribe(router => {
      if (router && router.state && router.state.params) {
        this.user = router.state.params.user;
        this.token = router.state.params.token;
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }
}
