<app-breadcrumbs [items]="breadcrumbs"></app-breadcrumbs>
<section [@fadeInDown]>
  <div class="base-container">
    <app-stepper [steps]="steps" [currentStep]="1"></app-stepper>
    <a *ngIf="notPaidOrderId > 0" [routerLink]="['/checkout/payment/', notPaidOrderId]" routerLinkActive="router-link-active" >
      <div class="flex-end row-sm">
        <nz-alert
          class="warning-message-full"
          nzType="warning"
          [nzDescription]="NotPaidOrderDesc"
        ></nz-alert>
        <ng-template #NotPaidOrderDesc>
            <i class="fas fa-history fa-fw"></i>
            <ng-container >
              {{ 'PAY_HAVE_UNFINISHED' | translate:{ value: paymentExpirationHours } }} <strong><a>{{notPaidOrderId}}</a></strong>
            </ng-container>
        </ng-template>
      </div>
    </a>
    <div class="cart-top">
      <app-cart-info-bar
        [sum_without_pvm]="(cartResponse$ | async).cart && (showLocalPrice && (cartResponse$ | async).cart.total_price_wo_vat || (cartResponse$ | async).cart.total_price_wo_vat_eur) || 0.00"
        [total_count]="this.totalQuantity">
      </app-cart-info-bar>
<!--      <div class="cart-expiration-notice">{{'CART_EXPIRES_AFTER' | translate}} <b>{{cart_expiration_final}}</b> {{'HOURS' | translate}}.</div>-->
      <div class="cart-expiration-notice">{{'CART_EXPIRES_AFTER' | translate}} <nz-countdown [nzValue]="deadline" [nzValueStyle]="{'font-size.px': 18, 'marginBottom.px': 4, 'marginLeft.px': 5}"></nz-countdown></div>
      <div class="cart-top-actions row-sm">
        <div class="col-sm">
          <app-button className="btn-white"
                      materialIcon="delete"
                      (click)="removeProducts()"
                      *ngIf="productsSelected.length > 0"
                      [title]="'DELETE' | translate"
                      [@fadeIn]>
          </app-button>
        </div>
        <div class="col-sm">
          <app-button className="btn-white" materialIcon="cached" (click)="refreshCart()" [title]="'REFRESH' | translate"></app-button>
        </div>
      </div>
    </div>
    <nz-spin [nzSpinning]="isCartSaving">
      <app-cart-list [productsFrom]="lastCartResponse.cart.items"
                     [updateProductNotes]="updateProductNotesSubject"
                     (productsUpdated)="goCheckoutSaved($event)"
                     (existNotUpdatedProductChange)="showSaveInsteadOfContinue = $event"
                     *ngIf="lastCartResponse && lastCartResponse.cart && lastCartResponse.cart.items"
                     (checkedList)="setCheckedList($event)" (productRemoved)="productRemoved()"
                     [preserveListSort]="preserveListSort"
                     (setPreserveSort)="preserveListSort = $event"
                     >
      </app-cart-list>
    </nz-spin>
    <div class="cart-bottom">
      <div>
        <div class="cart-bottom-actions">
          <app-button className="btn-white"
                      materialIcon="delete"
                      (click)="removeProducts()"
                      *ngIf="productsSelected.length > 0"
                      [title]="'DELETE' | translate"
                      [@fadeIn]>
          </app-button>
          <app-button
            *ngIf="enableCartSplitByItems && !showSaveInsteadOfContinue && productsSelected.length > 0"
            className="btn-red"
            text="CONFIRM_ONLY_SELECTED"
            (click)="goCheckout(true)"
            [title]="'CONFIRM_ONLY_SELECTED_DESC' | translate">
          </app-button>
        </div>
  
        <div class="flex-center flex-wrap row-sm" style="margin-left: 0px;">
          <ng-container *ngIf="(cartResponse$ | async).cart && (hasOnTheWayItem || hasDepositItem || hasDebtError || hasDebtWarning)">
            <nz-alert
              class="info-msg animated bounceIn"
              *ngIf="hasDebtError"
              nzType="error"
              [nzDescription]="ErrorDescription"
            ></nz-alert>
            <nz-alert
              class="info-msg animated bounceIn"
              *ngIf="hasOnTheWayItem || hasDepositItem || hasDebtWarning"
              nzType="warning"
              [nzDescription]="OnTheWayDescription"
            ></nz-alert>
            <ng-template #OnTheWayDescription>
              <i class="fas fa-truck fa-fw" *ngIf="hasOnTheWayItem"></i>
              <i class="fas fa-hand-holding-usd fa-fw" *ngIf="hasDepositItem"></i>
              <span class="fa-stack" style="vertical-align: top;" *ngIf="hasDebtWarning && showDebtIcons">
                <i class="far fa-credit-card fa-stack-1x" style="margin-left: 10px;"></i>
                <i class="fas fa-cut fa-stack-1x" style="margin-left: -7px;"></i>
              </span>
              <ng-container *ngIf="hasOnTheWayItem">
                <br>
                {{'ON_THE_WAY_WARNING' | translate}}
              </ng-container>
              <ng-container *ngIf="hasDepositItem">
                <br>
                {{'HAS_DEPOSIT_WARNING' | translate}}
              </ng-container>
              <ng-container *ngIf="hasNegativeBalance && !hasDebtError">
                <br>
                {{'BALANCE_NEGATIVE_ERROR' | translate: { balance_value: userBalance } }}
              </ng-container>
              <ng-container *ngIf="hasOverdueDebt && !hasDebtError">
                <br>
                {{'BALANCE_NOT_PAID_WARNING' | translate: { debt_value: userBalance } }}
              </ng-container>
              <ng-container *ngIf="hasPrePayNegativeBalance">
                <br>
                {{'PRE_PAY_BALANCE_NEGATIVE' | translate: { balance_value: userBalance } }}
              </ng-container>
              <ng-container *ngIf="hasCustomMessage">
                <br>
                {{customMessage | translate: { debt_value: userBalance } }}
              </ng-container>
              
            </ng-template>
            <ng-template #ErrorDescription>
              <span class="fa-stack" style="vertical-align: top;" *ngIf="hasDebtError && showDebtIcons">
                <i class="far fa-credit-card fa-stack-1x" style="margin-left: 10px;"></i>
                <i class="fas fa-cut fa-stack-1x" style="margin-left: -7px;"></i>
              </span>
              <ng-container *ngIf="hasNegativeBalance && !hasDebtWarning">
                <br>
                {{'BALANCE_NEGATIVE_ERROR' | translate: { balance_value: userBalance } }}
              </ng-container>
              <ng-container *ngIf="hasOverdueDebt && !hasDebtWarning">
                <br>
                {{'BALANCE_NOT_PAID_ERROR' | translate: { debt_value: userBalance + (knOverdueDebt ? ' [KN: ' + knOverdueDebt + ']' : '') } }}
              </ng-container>
            </ng-template>
          </ng-container>
          <nz-alert
            *ngIf="centralHolidayNotification.showNotification && hasCentralStock"
            class="info-msg animated bounceIn"
            nzType="warning"
            [nzDescription]="CentralHolidayDescription"
          ></nz-alert>
          <ng-template #CentralHolidayDescription>
            <i class="fas fa-star-of-life fa-fw" style="color: red"></i>
            <br>
            {{'HOLIDAY_IN_LITHUANIA' | translate:{date: centralHolidayNotification.holidayDate} }}
          </ng-template>
          <nz-alert
          *ngIf="localHolidayNotification.showNotification && hasLocalStock"
          class="info-msg animated bounceIn"
          nzType="warning"
          [nzDescription]="LocalHolidayDescription"
        ></nz-alert>
        <ng-template #LocalHolidayDescription>
          <i class="fas fa-star-of-life fa-fw" style="color: tomato"></i>
          <br>
          {{'LOCAL_COUNTRY_HOLIDAYS' | translate:{date: localHolidayNotification.holidayDate} }}
        </ng-template>
        </div>
      </div>
      <app-cart-summary *ngIf="(cartResponse$ | async).cart"
                        [products]="(cartResponse$ | async)"
                        [user]="(user$ | async)"
                        [showLocalPrice]="showLocalPrice">
      </app-cart-summary>
    </div>
    <div class="actions flex-end row-sm" *ngIf="totalQuantity">
      <div class="col-sm">
        <nz-spin [nzSpinning]="isSaving">
          <app-button className="btn-red" *ngIf="showSaveInsteadOfContinue" text="SAVE" (click)="goCheckout()"></app-button>
          <app-button className="btn-red" *ngIf="!showSaveInsteadOfContinue" text="CONTINUE" (click)="goCheckout()"></app-button>
        </nz-spin>
      </div>
    </div>
    <ng-container *ngIf="showItemsExpired && itemsExpiredExist">
      <hr />
      <app-button class="expired-cart-button" *ngIf="itemsExpiredExist" text="EXPIRED_CART_ITEMS" (click)="enableItemsExpired()"></app-button>
      <span class="col">{{ 'EXPIRED_CART_ITEMS_DESC' | translate }}</span>
      <div class="expired-items" *ngIf="showItemsExpiredList">
        <nz-spin [nzSpinning]="isCartSaving">
          <app-cart-list-expired [cart]="(cartResponse$ | async)" [itemsExpiredInitial]="itemsExpiredInitial"></app-cart-list-expired>
        </nz-spin>
      </div>
    </ng-container>
  </div>
</section>
