<footer [ngClass]="{'opened-footer': openedFooter}" *ngIf="contacts">
  <div class="base-container">
    <app-footer-contacts-list-other *ngIf="showAlternativeFooter"
      [openedFooter]="openedFooter"
      [contacts]="contacts"
      [currentLanguage]="currentLanguage">
    </app-footer-contacts-list-other>
    <app-footer-contacts-list *ngIf="!showAlternativeFooter"
      [openedFooter]="openedFooter"
      [contacts]="contacts"
      [currentLanguage]="currentLanguage">
    </app-footer-contacts-list>
    <p *ngIf="showPaymentOptions">
      {{'PAY_TAKE_PAYMENTS_FOOTER' | translate }}
      <img src="assets/images/payments/logo_apple_pay.svg" class="payment-method-logo">
      <img src="assets/images/payments/logo_google_pay.svg" class="payment-method-logo">
      <img src="assets/images/payments/logo_visa.gif" class="payment-method-logo">
      <img src="assets/images/payments/logo_mc.gif" class="payment-method-logo">
      <img src="assets/images/payments/logo_maestro.gif" class="payment-method-logo">
      <img src="assets/images/payments/logo_discover.jpg" class="payment-method-logo">
      <img src="assets/images/payments/logo_DC.jpg" class="payment-method-logo">
    </p>
  </div>
  <p class="footer-links">
    <a href="/cookies-policy" routerLink="/cookies-policy">{{'COOKIES_POLICY' | translate}}</a>
    <a *ngIf="languageCode !== 'sk'" href="/page/general-terms" routerLink="/page/general-terms">{{'GENERAL_TERMS' | translate}}</a>
    <a *ngIf="languageCode === 'sk'" href="https://ems.skuba.eu/media/GDPR_rules.pdf" target="_blank">{{'GENERAL_TERMS' | translate}}</a>
    <a href="/user-manual" routerLink="/user-manual">{{'USER_MANUAL' | translate}}</a>
    <a *ngIf="languageCode === 'pl'" href="/page/complaint" routerLink="/page/complaint">{{'COMPLAINT' | translate}}</a>
    <a *ngIf="languageCode === 'ro'" href="/page/warranty-documents" routerLink="/page/warranty-documents">{{'WARRANTY_DOCUMENTS' | translate}}</a>

    <a *ngIf="languageCode === 'sk'" href="https://ems.skuba.eu/media/General_trade_conditions.pdf" target="_blank">{{'SK_ONLY_GENERAL_TERMS' | translate}}</a>
    <a *ngIf="languageCode === 'sk'" href="https://ems.skuba.eu/media/Warranty_rules.pdf" target="_blank">{{'SK_ONLY_WARRANTY_DOC' | translate}}</a>
    <a *ngIf="languageCode === 'sk'" href="https://ems.skuba.eu/media/Rules_for_returning_goods.pdf" target="_blank">{{'SK_ONLY_RULES_RETURN_DOC' | translate}}</a>
    <a *ngIf="languageCode === 'sk'" href="https://ems.skuba.eu/media/Claim_FORMULAR.pdf" target="_blank">{{'SK_ONLY_CLAIM_FORM_DOC' | translate}}</a>
    <a *ngIf="languageCode === 'sk'" href="https://ems.skuba.eu/media/Formular_for_returning_goods.pdf" target="_blank">{{'SK_ONLY_RETURN_FORM_DOC' | translate}}</a>
    <a *ngIf="languageCode !== 'sk'" href="/page/return-policy-main" routerLink="/page/return-policy-main">{{'RETURN_POLICY' | translate}}</a>
  </p>
</footer>
