import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, Subscription, Subject } from 'rxjs';

import { BreadcrumbsItem } from '@app/shared/ui/breadcrumbs/breadcrumbs.model';
import { fadeIn, fadeInDown } from '@app/shared/animations';
import * as fromStore from '../store';
import * as fromOrder from '@core/store/actions/order.action';
import { CartItem, CartModel } from '@pages/cart/models/cart.model';
import * as fromRoot from '@app/reducers';
import { CartListComponent } from '@pages/cart/components/cart-list/cart-list.component';
import * as fromAuth from '@app/auth/store';
import { IUser } from '@app/auth/models/user';
import { OrderState } from '@core/store/reducers/order.reducer';
import { CustomCurrencyPipe } from '@app/shared/pipes/custom-currency/custom-currency';
import { CurrencyNamePipe } from '@app/shared/pipes/currency-name/currency-name';
import { Step } from '@app/shared/ui/stepper/stepper.model';
import { PageComponent } from '@app/shared/models/page-component';
import { CachedRequestService } from '@app/core/service/cached-req.service';
import { HelperService, HolidayNotificationProperties } from '@app/shared/services/helper.service';
import { PageScrollInstance, PageScrollService } from 'ngx-page-scroll';
import { flatten } from 'lodash';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  animations: [fadeIn, fadeInDown]
})
export class CartComponent extends PageComponent implements OnInit, OnDestroy {
  @ViewChild(CartListComponent, { static: false }) child: CartListComponent;

  /** Stepper items data */
  steps: Step[] = [
    { title: 'CART_STEP_CONFIRMATION', link: '1' },
    { title: 'ORDER_STEP_CONFIRMATION', link: '2' },
  ];

  /**
   * Page breadcrumbs items.
   * @type {{label: string; url: string}[]}
   */
  breadcrumbs: BreadcrumbsItem[] = [
    {
      label: 'CART',
      url: '/cart'
    }
  ];

  /** Products list from API */
  cartResponse$: Observable<CartModel>;

  /**
   * Selected products list.
   */
  productsSelected: CartItem[] = [];

  /**
   * Total product quantity in cart.
   * @type {number}
   */
  totalQuantity: number;
  totalDeposit = 0;
  totalDepositEur = 0;
  totalDepositWoVat = 0;
  totalDepositWoVatEur = 0;
  totalDepositVat = 0;
  totalDepositVatEur = 0;

  user$: Observable<IUser>;
  userSub: Subscription;
  user: IUser;
  cartHash: string;
  paymentExpirationHours = 24;
  notPaidOrderId = 0;

  order$: Observable<OrderState>;
  orderSub: Subscription;
  productsSub: Subscription;

  updateProductNotesSubject: Subject<void> = new Subject<void>();

  showSaveInsteadOfContinue = false;
  showItemsExpired = false;
  itemsExpiredExist = false;
  showItemsExpiredList = false;

  hasDebtError = false;
  hasDebtWarning = false;
  hasNegativeBalance = false;
  hasPrePayNegativeBalance = false;
  hasCustomMessage = false;
  customMessage = '';
  hasOverdueDebt = false;
  knOverdueDebt = '';
  userBalance = '';
  isSaving = false;
  isCartSaving = false;
  hasOnTheWayItem = false;
  hasDepositItem = false;
  isOrderCreating = false;
  showPaymentOptions = false;
  cartLoading$: Observable<boolean>;
  cartLoadingSub: Subscription;

  centralHolidaysSub: Subscription;
  localHolidaysSub: Subscription;
  localHolidayNotification: HolidayNotificationProperties = new HolidayNotificationProperties();
  centralHolidayNotification: HolidayNotificationProperties = new HolidayNotificationProperties();
  hasCentralStock = false;
  hasLocalStock = false;
  itemsExpiredInitial: CartItem[] = [];

  enableCartSplitByItems = false;
  showFifoStocks: boolean;

  showLocalPrice: boolean;
  cart_expiration_final: number;
  deadline;

  lastCartResponse: CartModel = null;
  preserveListSort = true;
  showDebtIcons = true;

  private destroyed$ = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private store: Store<fromRoot.AppState>,
    private customCurrencyPipe: CustomCurrencyPipe,
    private currencyNamePipe: CurrencyNamePipe,
    private cachedRequestService: CachedRequestService,
    private helperService: HelperService,
    private pageScrollService: PageScrollService,
  ) {
    super();
    this.store.dispatch(new fromStore.LoadCart(true));
    this.cartResponse$ = this.store.pipe(select(fromRoot.getCartProducts));
    this.user$ = this.store.pipe(select(fromAuth.getUser));
    this.order$ = this.store.pipe(select(fromRoot.getOrderState));
    this.cartLoading$ = this.store.pipe(select(fromRoot.getCartLoading));
  }

  setOperation() {
    // UserOperation.TYPE = PageOperation.Cart;
    // UserOperation.query = '';
  }
  ngOnInit() {
    super.ngOnInit();
    this.cartLoadingSub = this.cartLoading$.subscribe((loading) => {
      if (loading) {
        this.isCartSaving = true;
      } else {
        this.isCartSaving = false;
      }
    });
    this.userSub = this.user$.subscribe(user => {
      if (
        user &&
        user.profile &&
        user.profile.payment_order_expiration_hours
      ) {
        this.paymentExpirationHours = user.profile.payment_order_expiration_hours;
      }
      if (
        user &&
        user.profile
      ) {
        this.showPaymentOptions = false;
        if (user.profile.company.enable_payment_paysera) {
          this.showPaymentOptions = user.user_is_pre_payment || user.user_negative_balance || user.user_is_cash_only;
        }
        if (this.showPaymentOptions) {
          this.steps = [
            { title: 'CART_STEP_CONFIRMATION', link: '1' },
            { title: 'ORDER_STEP_CONFIRMATION', link: '2' },
            { title: 'PAYMENT_STEP_CONFIRMATION', link: '3' },
          ];
        } else {
          this.steps = [
            { title: 'CART_STEP_CONFIRMATION', link: '1' },
            { title: 'ORDER_STEP_CONFIRMATION', link: '2' },
          ];
        }
        this.enableCartSplitByItems = false;
        if (user.profile.company.enable_cart_split_by_items) {
          this.enableCartSplitByItems = true;
        }
        if (user.profile.company.hide_cards_in_stocks || user.user_settings.hide_cards_in_stocks) {
          this.showFifoStocks = true;
        } else {
          this.showFifoStocks = false;
        }
      }

      if (
        user &&
        user.user_settings
      ) {
        this.user = user;
        this.showLocalPrice = user.user_settings.show_local_currency;
        // this.cart_expiration_final = Math.round(user.user_settings.cart_expiration_final / 6) / 10;
        this.cart_expiration_final = 1000 * 60 * user.user_settings.cart_expiration_final;

        if (this.productsSub) {
          this.productsSub.unsubscribe();
        }
        this.productsSub = this.cartResponse$.subscribe(cartResponse => {
          this.lastCartResponse = cartResponse;
          this.totalQuantity = 0;
          this.totalDeposit = 0;
          this.totalDepositEur = 0;
          this.totalDepositWoVat = 0;
          this.totalDepositWoVatEur = 0;
          this.totalDepositVat = 0;
          this.totalDepositVatEur = 0;
          this.cartHash = null;
          this.hasCentralStock = false;
          this.hasLocalStock = false;
          this.deadline = Date.now() + 999;
          if (cartResponse.cart && cartResponse.cart.items && cartResponse.cart.items.length > 0) {
            const lastUpdateUnix = new Date(cartResponse.cart.last_updated).getTime();
            this.deadline = lastUpdateUnix + this.cart_expiration_final;
            this.hasOnTheWayItem = false;
            cartResponse.cart.items.map(product => {
              this.totalQuantity = this.totalQuantity + product.quantity;
              this.totalDeposit = +this.totalDeposit + +product.deposit * +product.quantity;
              this.totalDepositEur = +this.totalDepositEur + +product.deposit_eur * +product.quantity;
              this.totalDepositWoVat = +this.totalDepositWoVat + +product.deposit_wo_vat * +product.quantity;
              this.totalDepositWoVatEur = +this.totalDepositWoVatEur + +product.deposit_wo_vat_eur * +product.quantity;

              if (!this.hasOnTheWayItem && product.on_the_way) {
                this.hasOnTheWayItem = true;
              }
              if (!this.hasDepositItem && product.deposit > 0) {
                this.hasDepositItem = true;
              }
              if (product.is_central) {
                this.hasCentralStock = true;
              } else {
                this.hasLocalStock = true;
              }
            });
            this.totalDepositVat = this.totalDeposit - this.totalDepositWoVat;
            this.totalDepositVatEur = this.totalDepositEur - this.totalDepositWoVatEur;

            this.cartHash = cartResponse.cart.hash;
          }
          if (cartResponse.items_expired_initial.length > 0 || (this.itemsExpiredInitial && this.itemsExpiredInitial.length > 0)) {
            this.itemsExpiredExist = true;
          } else {
            this.itemsExpiredExist = false;
          }
          this.notPaidOrderId = 0;
          if (cartResponse.not_paid_order_id) {
            this.notPaidOrderId = cartResponse.not_paid_order_id;
          }
          if (this.itemsExpiredInitial.length === 0) {
            this.itemsExpiredInitial = cartResponse.items_expired_initial ? cartResponse.items_expired_initial : [];
          }
        });

        if (user.profile.company.country.code) {
          if (this.localHolidaysSub) {
            this.localHolidaysSub.unsubscribe();
          }
          this.localHolidaysSub = this.helperService.getHolidaysNotificationProperties(user.profile.company.country.code, false)
            .subscribe((properties) => {
              this.localHolidayNotification = properties;
            });
        }
        this.showDebtIcons = true;
        if (user.profile.company.country.code !== 'lt' ) {
          if (this.centralHolidaysSub) {
            this.centralHolidaysSub.unsubscribe();
          }
          this.centralHolidaysSub = this.helperService.getHolidaysNotificationProperties('lt', true)
            .subscribe((properties) => {
              this.centralHolidayNotification = properties;
            });
        } else {
          this.showDebtIcons = false;
        }
        this.showItemsExpired = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    if (this.productsSub) {
      this.productsSub.unsubscribe();
    }
    if (this.cartLoadingSub) {
      this.cartLoadingSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.localHolidaysSub) {
      this.localHolidaysSub.unsubscribe();
    }
    if (this.centralHolidaysSub) {
      this.centralHolidaysSub.unsubscribe();
    }
  }

  setCheckedList(list: CartItem[]) {
    this.productsSelected = list;
  }

  removeProducts() {
    this.productsSelected.map(product => {
      if (this.child) {
        this.child.removeProduct(product);
      }
    });
  }
  productRemoved() {
    this.productsSelected = [];
  }
  refreshCart() {
    this.hasDebtError = false;
    this.hasDebtWarning = false;
    this.hasNegativeBalance = false;
    this.hasOverdueDebt = false;
    this.preserveListSort = false;
    this.store.dispatch(new fromStore.LoadCart(true));
  }

  goCheckout(partial = false) {
    this.preserveListSort = true;
    if (this.showSaveInsteadOfContinue) {
      this.isSaving = true;
      this.updateProductNotesSubject.next();
    } else {
      this.goCheckoutSaved({ success: true, partial });
    }
  }

  enableItemsExpired () {
    this.showItemsExpiredList = !this.showItemsExpiredList;
    if (this.showItemsExpiredList) {
      setTimeout(() => {
        const pageScrollInstance: PageScrollInstance = PageScrollInstance.newInstance(
          {
            document: document,
            scrollTarget: '.expired-cart-button',
          }
        );
        this.pageScrollService.start(pageScrollInstance);
      });
    }
  }

  extractBalance(balance_error: string): Promise<string> {
    if (!this.user) {
      return Promise.resolve('-1');
    }
    if (balance_error.split(' ').length <= 2) {
      return Promise.reject('');
    }
    let balance = '';
    let balance_eur = '';
    if (balance_error) {
      balance = balance_error.split(' ')[1];
      balance_eur = balance_error.split(' ')[2];
    }
    if (balance) {
      if (this.user.user_settings.show_local_currency) {
        return this.customCurrencyPipe.transform(balance);
      } else {
        return this.customCurrencyPipe.transform(balance_eur);
      }
    } else {
      return Promise.resolve('0');
    }
  }

  goCheckoutSaved({ success, partial }: {success: boolean; partial: boolean } ) {
    if (success) {
      if (!this.isOrderCreating) {
        this.isOrderCreating = true;
        this.cachedRequestService.clearItem('cached-user-deliveries');
        let cartItemsToProcess: number[] = [];
        if (partial) {
          if (this.showFifoStocks) {
            cartItemsToProcess = flatten(this.productsSelected.map(v => v.reference_ids));
          } else {
            cartItemsToProcess = this.productsSelected.map(v => v.id);
          }
        }
        if (this.user.user_is_pre_payment) {
          this.store.dispatch(
            new fromOrder.CreateOrder({ cart_hash: this.cartHash }, true, cartItemsToProcess)
          );
        } else {
          this.store.dispatch(
            new fromOrder.CreateOrder({ cart_hash: this.cartHash }, this.hasDebtWarning, cartItemsToProcess)
          );
        }
        if (this.orderSub) {
          this.orderSub.unsubscribe();
        }
        this.orderSub = this.order$.subscribe(order => {

          if (this.orderSub) {
            this.orderSub.unsubscribe();
          }
          this.hasDebtError = false;
          this.hasDebtWarning = false;
          this.hasOverdueDebt = false;
          this.hasNegativeBalance = false;
          this.hasPrePayNegativeBalance = false;
          this.hasCustomMessage = false;
          this.customMessage = '';
          this.userBalance = '0';
          this.isSaving = false;
          let currency = '-';
          this.currencyNamePipe.transformInstant((v) => {
            currency = v.toString();
          });
          this.store.dispatch(new fromAuth.LoadUser());
          if (order.error && order.error.startsWith('OVERDUE_WARNING')) {
            this.hasDebtError = false;
            this.hasDebtWarning = true;

            this.hasOverdueDebt = true;
            this.hasNegativeBalance = false;
            this.isOrderCreating = false;
            this.extractBalance(order.error).then(data => {
              this.userBalance = `${data} ${currency}`;
            });
          } else if (order.error && order.error.startsWith('BALANCE_WARNING')) {
            this.hasDebtError = false;
            this.hasDebtWarning = true;

            this.hasOverdueDebt = false;
            this.hasNegativeBalance = true;
            this.isOrderCreating = false;
            this.extractBalance(order.error).then(data => {
              this.userBalance = `${data} ${currency}`;
            });
          } else if (order.error && order.error.startsWith('OVERDUE_DEBT')) {
            this.hasDebtError = true;
            this.hasDebtWarning = false;

            this.hasOverdueDebt = true;
            this.hasNegativeBalance = false;
            this.isOrderCreating = false;
            this.extractBalance(order.error).then(data => {
              this.userBalance = `${data} ${currency}`;
            });
            let knOverdue = '';
            knOverdue = order.error.split(' ')[3];
            if ( knOverdue !== undefined && knOverdue !== '0' && knOverdue !== '') {
              this.knOverdueDebt = knOverdue;
            }
          } else if (order.error && order.error.startsWith('BALANCE_NEGATIVE')) {
            this.hasDebtError = true;
            this.hasDebtWarning = false;

            this.hasOverdueDebt = false;
            this.hasNegativeBalance = true;
            this.isOrderCreating = false;
            this.extractBalance(order.error).then(data => {
              this.userBalance = `${data} ${currency}`;
            });
          } else if (order.error &&
              (
                order.error.startsWith('CASH_ONLY') ||
                order.error.startsWith('PRE_PAY_NO_CONTRACT')
              )
          ) {
            this.hasDebtWarning = true;

            this.hasCustomMessage = true;
            this.customMessage = order.error.split(' ')[0];
            this.extractBalance(order.error).then(data => {
              this.userBalance = `${data} ${currency}`;
            });

            this.isOrderCreating = false;
          } else if (order.error && order.error.startsWith('PRE_PAY_BALANCE_NEGATIVE')) {
            this.hasDebtWarning = true;

            this.hasPrePayNegativeBalance = true;
            this.isOrderCreating = false;
            this.extractBalance(order.error).then(data => {
              this.userBalance = `${data} ${currency}`;
            });
          } else if (
            !order.loaded &&
            order.error &&
            order.error === 'Cart hash does not match'
          ) {
            this.isOrderCreating = false;
            this.router.navigate(['/cart']);
            this.refreshCart();
          } else if (order.loaded && !order.loading) {
            this.isOrderCreating = false;
            this.router.navigate(['/checkout/order']);
          }
        }, (error) => {
          this.isOrderCreating = false;
          if (this.orderSub) {
            this.orderSub.unsubscribe();
          }
        });
      }
    } else {
      this.isSaving = false;
    }
  }
}
