import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { map, take, switchMap } from 'rxjs/operators';
import * as fromRouter from '../../core/store/actions/router.action';

import * as fromStore from '../store';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { ofType } from '@ngrx/effects';
import * as fromActions from '../store/actions';
import { ActionsSubject } from '@ngrx/store';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<fromStore.AuthState>,
    private localStorageService: LocalStorageService,
    private actionsSubject: ActionsSubject,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(fromStore.getLoggedIn),
      switchMap(authed => {
        if (!authed) {
          const token = this.localStorageService.getItem('id_token');
          if (token) {
            this.store.dispatch(new fromStore.SilentAuth(state.url));
            return this.actionsSubject.pipe(
              ofType(fromActions.AuthActionTypes.LoginSuccess, fromActions.AuthActionTypes.LoginFailure),
              map((action: fromActions.LoginSuccess | fromActions.LoginFailure) => ({ type: action.type })),
              switchMap(action => {
                if (action.type === fromActions.AuthActionTypes.LoginSuccess) {
                  return of(true);
                } else if (action.type === fromActions.AuthActionTypes.LoginFailure) {
                  this.store.dispatch(new fromRouter.Go({ path: ['/login'], query: {redirect: state.url} }));
                  return of(false);
                }
              })
            );
          } else {
            if (state.url.substring(0, 5) !== '/page') {
              this.store.dispatch(new fromRouter.Go({ path: ['/login'], query: {redirect: state.url} }));
              return of(false);
            }
          }
        }

        return of(true);
      }),
      take(1)
    );
  }
}
