import { Action } from '@ngrx/store';
import { ProductItem, ProductStockSimple } from '@shared/models/product.model';

export enum ProductActionTypes {
  ProductLoad = '[Product] Load Product',
  ProductLoadFail = '[Product] Load Product Fail',
  ProductLoadSuccess = '[Product] Load Product Success',
  ProductExtend = '[Product] Extend',
  ProductExtendSuccess = '[Product] Extend Success',
  ResetProductState = '[Product] Reset State'
}

export class ProductLoad implements Action {
  readonly type = ProductActionTypes.ProductLoad;

  constructor(public payload: string, public log = false) {}
}

export class ProductLoadFail implements Action {
  readonly type = ProductActionTypes.ProductLoadFail;

  constructor(public payload: any, public failAction: 'Load'|'Extend', public stock: any = null, public updateQuantity: boolean = false ) {}
}

export class ProductLoadSuccess implements Action {
  readonly type = ProductActionTypes.ProductLoadSuccess;

  constructor(public payload: ProductItem) {}
}

export class ProductExtend implements Action {
  readonly type = ProductActionTypes.ProductExtend;

  constructor(public payload: any, public updateQuantity: boolean, public skn: string = '') {}
}

export class ProductExtendSuccess implements Action {
  readonly type = ProductActionTypes.ProductExtendSuccess;

  constructor(public payload: ProductStockSimple, public withoutCard: boolean) {}
}

export class ResetProductState implements Action {
  readonly type = ProductActionTypes.ResetProductState;
}

export type ProductActions =
  | ProductLoad
  | ProductLoadFail
  | ProductLoadSuccess
  | ProductExtend
  | ProductExtendSuccess
  | ResetProductState;
