import { Component, ElementRef, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { AuthService } from '@app/auth/services/auth.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import * as toast from '@core/store/actions/toast.action';
import { Router } from '@angular/router';
import { HelperService } from '@shared/services/helper.service';
import { TranslationService } from '@app/core/service/translation.service';

@Component({
  selector: 'app-forgot-password-reset-form',
  templateUrl: './forgot-password-reset-form.component.html',
  styleUrls: ['./forgot-password-reset-form.component.scss']
})
export class ForgotPasswordResetFormComponent implements OnInit {
  passwordResetForm: FormGroup;
  errorMessage = '';
  @Input() user: string;
  @Input() token: string;

  constructor(
    private translationService: TranslationService,
    private cdRef: ChangeDetectorRef,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private store: Store<fromRoot.AppState>,
    private element: ElementRef,
    private helperService: HelperService
  ) {}

  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('new_password1').value;
    const confirmPassword = AC.get('new_password2').value;

    if (confirmPassword !== null) {
      if (password !== confirmPassword) {
        AC.get('new_password2').setErrors({ MatchPassword: true });
      } else {
        AC.get('new_password2').setErrors(null);
      }
    }
  }

  ngOnInit() {
    this.initForm();
  }

  initForm(): void {
    this.passwordResetForm = this.fb.group(
      {
        user: [this.user, Validators.required],
        token: [this.token, Validators.required],
        new_password1: [null, Validators.required],
        new_password2: [null, Validators.required]
      },
      {
        validator: ForgotPasswordResetFormComponent.MatchPassword
      }
    );
  }

  submit(formGroup: FormGroup) {
    this.helperService.validationScroll(this.element);

    if (formGroup.valid) {
      this.authService
        .forgotPasswordReset({
          new_password1: formGroup.value.new_password1,
          new_password2: formGroup.value.new_password2,
          user: formGroup.value.user,
          token: formGroup.value.token,
          _ems_silenced: true,
        })
        .subscribe(() => {
          this.store.dispatch(new toast.ShowSuccessToast('SUCCESS'));
          this.router.navigate(['/login']);
        }, (error) => {
          if (error.error && error.error.non_field_errors) {
            this.errorMessage = error.error.non_field_errors
            .map(v => {
              if (v === 'Invalid token') {
                return this.translationService.translate('PASSWORD_RESET_EXPIRED');
              } else {
                return this.translationService.translate(v);
              }
            })
            .join('; ');
          } else {
            this.errorMessage = `${error.status} ${error.statusText}`;
          }
          this.cdRef.detectChanges();
        });
    }
  }
}
