import {
  SimilarProductsActions,
  SimilarProductsActionTypes
} from '../actions/similar-products.action';

export interface SimilarProductsState {
  results: any;
  loaded: boolean;
  loading: boolean;
}

const initialState: SimilarProductsState = {
  results: [],
  loaded: false,
  loading: false
};

export function reducer(
  state = initialState,
  action: SimilarProductsActions
): SimilarProductsState {
  switch (action.type) {
    case SimilarProductsActionTypes.SimilarProductsLoad: {
      return {
        ...state,
        loading: true
      };
    }
    case SimilarProductsActionTypes.SimilarProductsLoadSuccess: {
      const SimilarProductsConst = action.payload.results;

      return {
        ...state,
        loading: false,
        loaded: true,
        results: SimilarProductsConst
      };
    }
    case SimilarProductsActionTypes.SimilarProductsLoadFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case SimilarProductsActionTypes.ResetSimilarProductsState: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export const getSimilarProducts = (state: SimilarProductsState) =>
  state.results;
export const getSimilarProductsLoading = (state: SimilarProductsState) =>
  state.loading;
export const getSimilarProductsLoaded = (state: SimilarProductsState) =>
  state.loaded;
