import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { v4 as uuid } from 'uuid';

import { ColumnActions, ColumnMap, ColumnSetting } from './models/table.model';
import { fadeInDown } from '../../animations';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppState } from '@app/reducers';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/reducers/index';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInDown]
})
export class TableComponent implements OnChanges, OnInit {
  /**
   * Table is generated from setting.
   * @type {boolean}
   */
  @Input() isConfigTable = true;

  /** Table body items */
  @Input() records: any[];

  /** Table title. */
  @Input() caption: string;

  /** Table header items (defines order) */
  @Input() settings: ColumnSetting[];

  /** Action buttons */
  @Input() actions: ColumnActions[];

  /** Checkbox column status */
  @Input() checkbox: boolean;

  /** Filter status status */
  @Input() filter = true;

  @Input() loading = false;

  /** Form group (optional) */
  @Input() group: FormGroup = new FormGroup({});

  /** Action click parameter */
  @Output() readonly actionParam = new EventEmitter();

  /** Action click type */
  @Output() readonly actionOutput = new EventEmitter();

  /** Filter reset */
  @Output() readonly filterReset = new EventEmitter();

  /** Clicked sort */
  @Output() readonly clickedSort = new EventEmitter();

  /** Clicked filter */
  @Output() readonly clickedFilter = new EventEmitter();

  /** Row clicked event */
  @Output() readonly rowClicked = new EventEmitter<any>();

  /** Final formed table data. */
  columnMaps: ColumnMap[];

  /** Unique id. */
  uuuid = uuid();

  /**
   * Status for checkbox created method.
   * @type {boolean}
   */
  checkboxCreated = false;

  /** Selected rows array from checkbox */
  selectedRows = [];

  /**
   * Output array of selected rows.
   * @type {EventEmitter<any[]>}
   */
  updateSelections = new EventEmitter<any[]>();

  /**
   * Checkbox list active status values.
   * @type {any[]}
   */
  checkboxStatusArray: boolean[] = [];

  languageSet$: Observable<boolean>;

  constructor(private router: Router, private store: Store<AppState>) {
    this.languageSet$ = this.store.pipe(select(fromRoot.getSetLanguageStatus));
  }

  ngOnInit() {
    const REGEX = /([\D][,.:;])([^\s\)\]\}\.\,\:\;])/g;
    if(this.records) {
      this.records.map(record => {
        if(record.sub_group_name) {
          record.sub_group_name = record.sub_group_name.replace(REGEX, '$1 $2');
        }
        if(record.name) {
          record.name = record.name.replace(REGEX, '$1 $2');
        }
      });
    }
  }

  /** Form new table data array on component params change */
  ngOnChanges(changes: SimpleChanges) {
    if (this.isConfigTable) {
      this.createCheckboxControl();

      if (this.settings) {
        this.columnMaps = this.settings.map(col => new ColumnMap(col));
      } else {
        this.columnMaps = Object.keys(this.records[0]).map(key => {
          return new ColumnMap({ primaryKey: key });
        });
      }
    }
  }

  createCheckboxControl() {
    if (this.checkbox && this.records.length > 0) {
      this.records.map(() => {
        this.checkboxStatusArray.push(false);
      });

      this.checkboxCreated = true;
    }
  }

  /**
   * Event for click action button.
   * @param {string | number} param
   * @param {string} type
   */
  clickAction(param: string | number, type: string) {
    this.actionOutput.emit({ param, type });
  }

  /**
   * Set date input value from date picker.
   * @param date
   * @param {string} inputName
   */
  setDate(date: any, inputName: string) {
    if (date.data && date.data.formatted) {
      this.group.controls[inputName].setValue(date.data.formatted);
    }
  }

  clickSort(header) {
    if (header.sort) {
      this.clickedSort.emit(header);
    }
  }

  /** Filter data reset method */
  resetFilter() {
    this.group.reset();
    this.filterReset.emit();
  }

  /**
   * Method on checkbox click.
   * @param event
   * @param {any[]} row
   * @param key
   */
  toggleCheckbox(event, row: any[], key) {
    const index = this.selectedRows.indexOf(row);

    if (index === -1) {
      this.selectedRows.push(row);
    } else {
      this.selectedRows.splice(index, 1);
    }

    this.checkboxStatusArray[key] = !this.checkboxStatusArray[key];
    this.updateSelections.emit(this.selectedRows);
  }

  navigateTo(url: string, status: boolean) {
    if (url && status) {
      return this.router.navigate([url]);
    }
  }

  filterOutput() {
    this.clickedFilter.emit();
  }
}
