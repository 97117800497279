import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { fadeIn, fadeInDown } from '@shared/animations';
import { IContact } from '@core/models/contact';

@Component({
  selector: 'app-footer-contacts-list',
  templateUrl: './footer-contacts-list.component.html',
  styleUrls: ['./footer-contacts-list.component.scss'],
  animations: [fadeIn, fadeInDown],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterContactsListComponent implements OnInit {
  @Input() openedFooter: boolean;
  @Input() contacts: IContact[];
  @Input() currentLanguage: string;

  constructor() {}

  ngOnInit() {}

  fieldSplit(field: string) {
    let fieldFormated = [];
    if (!field || field.length === 0) {
      return fieldFormated;
    }

    return (fieldFormated = field.split('<br>').map((item: string) => item.trim().replace('<br>','')));
  }

  phoneSplit(phones: string) {
    let phoneArray = [];

    if (!phones || phones.length === 0) {
      return phoneArray;
    }
    return (phoneArray = phones.split(',').map((item: string) => item.trim()));
  }
}
