var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api.service';
import { map } from 'rxjs/internal/operators';
import { DecoratorsService } from '../develop/decorators';
export class LanguageService {
    constructor(apiService) {
        this.apiService = apiService;
        this.languages = [
            {
                title: 'EN',
                value: 'en',
                image: `/assets/flags/svg/en.svg`
            }
        ];
    }
    getLanguages() {
        return this.apiService.get('/helper/countries/').pipe(map((data) => {
            data.results.forEach((country) => {
                if (country.translations_enabled) {
                    this.languages.push({
                        title: country.code.toUpperCase(),
                        value: country.language_code,
                        image: `/assets/flags/svg/${country.code}.svg`
                    });
                }
            });
            return this.languages;
        }));
    }
    getLanguage() {
        let language;
        return this.apiService.get('/helper/language/').pipe(map((data) => {
            language = data.results[0].code;
            return language;
        }));
    }
}
__decorate([
    DecoratorsService.DevCache(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Observable)
], LanguageService.prototype, "getLanguages", null);
