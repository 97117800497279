import { Injectable } from '@angular/core';
import { ApiService } from '@core/api.service';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/browser';
import { Integrations as ApmIntegrations } from '@sentry/apm';

@Injectable()
export class SentryService {
  constructor(private apiService: ApiService) {}

  public init() {
    if (environment.production) {
      const subscription = this.apiService
      .getJSON(
        'frontend.json',
        '/version/frontend.json?' + new Date().getTime()
      )
      .subscribe(
        data => {
          Sentry.init({
            dsn: 'https://079e83248f71487396dc961592039694@sentry.skuba.eu/6',
            release: data.commit.hash,
            environment: 'production',
            integrations: [
              new Sentry.Integrations.TryCatch({
                XMLHttpRequest: false,
              }),
              new ApmIntegrations.Tracing(),
            ],
            tracesSampleRate: 0.5
          });
          subscription.unsubscribe();
        },
        () => {
          Sentry.init({
            dsn: 'https://079e83248f71487396dc961592039694@sentry.skuba.eu/6',
            release: 'ErrorVersion',
            environment: 'production',
            integrations: [
              new Sentry.Integrations.TryCatch({
                XMLHttpRequest: false,
              }),
              new ApmIntegrations.Tracing(),
            ],
            tracesSampleRate: 0.5
          });
          subscription.unsubscribe();
        }
      );
    } else {
      Sentry.init({
        dsn: 'https://079e83248f71487396dc961592039694@sentry.skuba.eu/6',
        release: 'Local',
        environment: 'Local',
        integrations: [
          new Sentry.Integrations.TryCatch({
            XMLHttpRequest: false,
          }),
          new ApmIntegrations.Tracing(),
        ],
        tracesSampleRate: 0
      });
    }
  }
}
