import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Step } from '@shared/ui/stepper/stepper.model';
import { fadeIn } from '@shared/animations';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  animations: [fadeIn]
})
export class StepperComponent implements OnInit {
  /** Stepper items */
  @Input() steps: Step[];

  /**
   * Step number output event.
   * @type {EventEmitter<number>}
   */
  @Output() readonly clickedStep = new EventEmitter<number>();

  /**
   * Active default step.
   * @type {number}
   */
  @Input() currentStep = 1;

  /**
   * Let user go to higher steps.
   * @type {boolean}
   */
  @Input() enableGoUp = false;

  constructor() {}

  ngOnInit() {}

  /**
   * Step click action which emits selected number output.
   * @param {number} index
   */
  clickStep(index: number) {
    const clickStep = index + 1;

    if (!this.enableGoUp && clickStep > this.currentStep) {
      return;
    }

    this.currentStep = clickStep;

    this.clickedStep.emit(this.currentStep);
  }
}
