import * as fromShowcase from '../actions/showcase.action';
import { ShowcaseActionTypes } from '../actions';
import {
  IShowcase,
  IShowcaseGroup
} from '@pages/dashboard/models/showcase.model';

export interface ShowcaseState {
  data: IShowcase[];
  groups: IShowcaseGroup[];
  entities: { [id: number]: any };
  loaded: boolean;
  loading: boolean;
}

export const initialState: ShowcaseState = {
  data: [],
  groups: [],
  entities: {},
  loaded: false,
  loading: false
};

export function reducer(
  state = initialState,
  action: fromShowcase.ShowcaseAction
): ShowcaseState {
  switch (action.type) {
    case ShowcaseActionTypes.ShowcaseLoad: {
      return {
        ...state,
        loading: true
      };
    }
    case ShowcaseActionTypes.ShowcaseLoadSuccess: {
      let entities = [];

      if (action.payload) {
        const showcases = action.payload;
        entities = showcases.reduce(
          (entit: { [id: number]: IShowcase }, showcase: IShowcase) => {
            return {
              ...entit,
              [showcase.skn]: showcase
            };
          },
          {
            ...state.entities
          }
        );
      }

      return {
        ...state,
        loaded: true,
        loading: false,
        data: action.payload,
        entities: entities
      };
    }
    case ShowcaseActionTypes.ShowcaseLoadFail: {
      return {
        data: [],
        groups: [],
        entities: [],
        loading: false,
        loaded: false
      };
    }

    case ShowcaseActionTypes.LoadGroups: {
      return {
        ...state,
        loading: true
      };
    }
    case ShowcaseActionTypes.LoadGroupsSuccess: {
      const activeGroups = action.payload.filter(item => item.active);
      return {
        ...state,
        groups: activeGroups
      };
    }
    case ShowcaseActionTypes.LoadGroupsFail: {
      return {
        ...state,
        groups: []
      };
    }

    case ShowcaseActionTypes.ResetShowcaseState: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export const getShowcaseItems = (state: ShowcaseState) => state.data;
export const getShowcaseGroups = (state: ShowcaseState) => state.groups;
export const getShowcaseEntities = (state: ShowcaseState) => state.entities;
export const getShowcaseLoaded = (state: ShowcaseState) => state.loaded;
export const getShowcaseLoading = (state: ShowcaseState) => state.loading;
