import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { ApiService } from '@app/core/api.service';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import { IUser } from '@app/auth/models/user';
import { DecoratorsService } from '../develop/decorators';

@Injectable()
export class TranslationService implements OnDestroy {
  translations;
  translations$: Observable<any>;
  translationsSub: Subscription;
  activeLanguage: string;

  constructor(
    private apiService: ApiService,
    private store: Store<fromRoot.AppState>
  ) {
    this.translations$ = this.store.pipe(select(fromRoot.getTranslations));
    this.translationsSub = this.translations$.subscribe(translations => {
      this.translations = translations;
    });
  }

  @DecoratorsService.DevCache('', true)
  getNotTranslatedCount(language_code: string) {
    return this.apiService.get(
      `/translations/translations_count/${language_code}/`
    );
  }

  @DecoratorsService.DevCache()
  getNotTranslatedEmailsCount(language_code: string) {
    return this.apiService.get(
      `/translations/email_translations_count/`
    );
  }

  @DecoratorsService.DevCache()
  getNotApprovedUsersCount() {
    return this.apiService.get(
      `/translations/users_not_approved_count/`
    );
  }


  @DecoratorsService.DevCache('', true)
  loadTranslationApi(language: string) {
    return this.apiService.get(`/translations/translations/${language}/`);
  }

  loadTranslation(language: string) {
    return this.apiService.getJSON('json', `./assets/i18n/${language}.json`);
  }

  translate(code: string, params?: object) {
    if (params && this.translations[code]) {
      let result = this.translations[code];
      Object.keys(params).forEach(key => {
        result = result.split(`{{${key}}}`).join(params[key]);
      });
      return result;
    } else {
      // console.log(this.translations[code]);
      return this.translations[code] || code;
    }
  }

  ngOnDestroy() {
    if (this.translationsSub) { this.translationsSub.unsubscribe(); }
  }
}
