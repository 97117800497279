/**
 * @module AppModule
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserService } from './core/user/user.service';
import { AuthGuard } from '@app/auth/services/auth-guard.service';
import { StatusGuard } from '@app/auth/services/status-guard.service';
import { AdminStatusGuard } from './auth/services/admin-guard.service';

/**
 * Parent routes for the {@link AppRoutingModule}
 */
const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('@app/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard, StatusGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('@app/pages/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard, StatusGuard, AdminStatusGuard]
  },
  {
    path: 'traffic-analysis',
    loadChildren: () => import('@app/pages/traffic-analysis/traffic-analysis.module').then(m => m.TrafficAnalysisModule),
    canActivate: [AuthGuard, StatusGuard]
  },
  {
    path: 'catalogue',
    loadChildren: () => import('@app/pages/catalogue/catalogue.module').then(m => m.CatalogueModule),
    canActivate: [AuthGuard, StatusGuard]
  },
  {
    path: 'offers',
    loadChildren: () => import('@app/pages/offers/offers.module').then(m => m.OffersModule),
    canActivate: [AuthGuard, StatusGuard]
  },
  {
    path: 'translations',
    loadChildren:
      () => import('@app/pages/translations/translations.module').then(m => m.TranslationsModule),
    canActivate: [AuthGuard, StatusGuard]
  },
  {
    path: 'search',
    loadChildren: () => import('@app/pages/search/search.module').then(m => m.SearchModule),
    canActivate: [AuthGuard, StatusGuard]
  },
  {
    path: 'group',
    loadChildren: () => import('@app/pages/group/group.module').then(m => m.GroupModule),
    canActivate: [AuthGuard, StatusGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('@app/pages/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard, StatusGuard]
  },
  {
    path: 'tecdoc',
    loadChildren: () => import('@app/pages/tecdoc/tecdoc.module').then(m => m.TecdocModule),
    canActivate: [AuthGuard, StatusGuard]
  },
  {
    path: 'tecdoc-car-bus',
    loadChildren: () => import('@app/pages/tecdoc-car-bus/tecdoc.module').then(m => m.TecdocCarBusModule),
    canActivate: [AuthGuard, StatusGuard]
  },
  {
    path: 'cart',
    loadChildren: () => import('@app/pages/cart/cart.module').then(m => m.CartModule),
    canActivate: [AuthGuard, StatusGuard]
  },
  {
    path: 'checkout',
    loadChildren: () => import('@app/pages/checkout/checkout.module').then(m => m.CheckoutModule),
    canActivate: [AuthGuard, StatusGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('@app/pages/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard, StatusGuard]
  },
  {
    path: 'cookies-policy',
    loadChildren:
      () => import('@app/pages/cookies-policy/cookies-policy.module').then(m => m.CookiesPolicyModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-manual',
    loadChildren:
      () => import('@app/pages/user-manual/user-manual.module').then(m => m.UserManualModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'test',
    loadChildren: () => import('@app/pages/test/test.module').then(m => m.TestModule),
    canActivate: [AuthGuard, AdminStatusGuard]
  },
  {
    path: 'page',
    loadChildren: () => import('@app/pages/custom-page/custom-page.module').then(m => m.CustomPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'truck-catalog',
    loadChildren: () => import('@app/pages/truck-catalog/truck-catalog.module').then(m => m.TruckCatalogModule),
    canActivate: [AuthGuard, StatusGuard]
  },
  {
    path: 'custom-emails',
    loadChildren: () => import('@app/pages/custom-emails/custom-emails.module').then(m => m.CustomEmailsModule),
    canActivate: [AuthGuard, AdminStatusGuard]
  },
  {
    path: 'cookies-disabled',
    loadChildren: () => import('@app/pages/cookies-disabled-notice/cookies-disabled-notice.module')
      .then(m => m.CookiesDisabledNoticeModule)
  },
  {
    path: 'skuba-notifications',
    loadChildren: () => import('@app/pages/skuba-notifications/skuba-notifications.module').then(m => m.SkubaNotificationsModule)
  },
  {
    path: 'banners',
    loadChildren: () => import('@app/pages/banners/banners.module').then(m => m.BannersModule),
    canActivate: [AuthGuard, AdminStatusGuard]
  },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
];

/**
 * @whatItDoes The {@link AppRoutingModule} is a
 * [Routing Module](https://angular.io/docs/ts/latest/guide/router.html#routing-module) that
 * handles the app's routing concerns.
 * @consumers {@link AppModule}
 * @see [Angular 2 docs - Lazy loading modules with the Router]
 * (https://angular.io/docs/ts/latest/guide/ngmodule.html#lazy-load)
 *
 * The returned {@link AppRoutingModule} class is a Routing Module containing both the RouterModule
 * directives and the Dependency Injection providers that produce a configured Router.
 */
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [AuthGuard, StatusGuard, UserService]
})
export class AppRoutingModule {}
