import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '@app/core/api.service';
import {
  UserFutureShopSettings,
  UserOperationsLimits,
  UserShopSettings
} from '@app/pages/users/models/users.model';
import { IUserRegister, IUserClientReference, IUserModel, IUser } from '@app/auth/models/user';
import { map } from 'rxjs/internal/operators';
import {
  UserOperations,
  UserOtherSettings,
  UsersIp,
  UsersIpList,
  UsersLimitsResets
} from '@pages/users/models/users.model';
import { DecoratorsService } from '@app/core/develop/decorators';

@Injectable()
export class UsersService {
  /**
   * User status subject.
   * @type {Subject<string>}
   */
  public userStatusSubject = new BehaviorSubject<string>('');

  /** Status name */
  public status: Observable<string> = this.userStatusSubject.asObservable();

  constructor(private apiService: ApiService) {}

  @DecoratorsService.DevCache()
  getUsers(filters = null): Observable<any> {
    return this.apiService
      .get('/users/users/', filters)
      .pipe(map(data => data));
  }
  @DecoratorsService.DevCache()
  getUsersForRights(): Observable<IUser[]> {
    return this.apiService
      .get('/users/users/users_for_rights/')
      .pipe(map(data => data));
  }
  /**
   * Users list by filters params from API.
   * @param {any} filters
   * @returns {Observable<any>}
   */
  getUsersClients(main_client_id: number = null): Observable<IUserModel<IUserClientReference>> {
    return this.apiService
      .get('/shops/client_refs/', { main_client_id: main_client_id.toString(), })
      .pipe(map(data => data));
  }


  getUserActions(id: number): Observable<any> {
    return this.apiService
      .get(`/statistics/get_last_searches/${id}/`)
      .pipe(map(data => data));
  }
  getUsersActions(shop_id: string = null): Observable<any> {
    const shopFilterParam = shop_id ? { shop_id: shop_id, } : {};
    return this.apiService
      .get('/statistics/get_last_searches/', shopFilterParam)
      .pipe(map(data => data));
  }

  /**
   * Post method to create user contacted actvity history.
   * @param body
   * @returns {Observable<any>}
   */
  createUserContactedActions(
    // id: number,
    body: { action_history: any }
  ): Observable<any> {
    return this.apiService
      .post('/statistics/contacted_action_history/', body)
      .pipe(map(data => data));
  }


  /**
   * Specific user data by user id from API.
   * @param id
   * @returns {Observable<any>}
   */
  getUser(id): Observable<any> {
    return this.apiService.get(`/users/users/${id}/`).pipe(map(data => data));
  }

  /**
   * Update user by id from API.
   * @param id
   * @param body
   * @returns {Observable<any>}
   */
  updateUser(id, body): Observable<any> {
    return this.apiService
      .patch(`/users/users/${id}/`, body)
      .pipe(map(data => data));
  }

  /**
   * Create user.
   * @param body
   * @returns {Observable<any>}
   */
  createUser(body): Observable<any> {
    return this.apiService.post('/users/users/', body).pipe(map(data => data));
  }

  /**
   * Delete user.
   * @param id
   * @returns {Observable<any>}
   */
  deleteUser(id): Observable<any> {
    return this.apiService.delete(`/users/users/${id}`).pipe(map(data => data));
  }

  /**
   * Get status name by status id.
   * @param {number} status
   */
  getStatusName(status: number) {
    let name = '';

    switch (status) {
      case 1:
        name = 'ACTIVE';
        break;
      case 2:
        name = 'INACTIVE';
        break;
      case 3:
        name = 'BLOCKED';
        break;
      case 4:
        name = 'SUSPENDED';
        break;
      case 5:
        name = 'ARCHIVED';
        break;
      case 6:
        name = 'PENDING';
        break;
      default:
        name = 'ERROR';
    }

    this.userStatusSubject.next(name);
  }

  /**
   * Get phone codes from JSON.
   * @param json
   * @returns {Observable<any>}
   */
  getPhoneCodes(json): Observable<any> {
    return this.apiService.getJSON(`${json}`).pipe(map(data => data));
  }

  /**
   * Get user other settings form API.
   * @param id
   * @returns {Observable<any>}
   */
  getOtherSettings(id): Observable<UserOtherSettings> {
    return this.apiService
      .get(`/users/user_other_settings/${id}/`)
      .pipe(map(data => data));
  }

  /**
   * Put method to update user other settings.
   * @param id
   * @param body
   * @returns {Observable<any>}
   */
  updateOtherSettings(
    id: number,
    body: { user_settings: any }
  ): Observable<UserOtherSettings> {
    return this.apiService
      .put(`/users/user_other_settings/${id}/`, body)
      .pipe(map(data => data));
  }

  /**
   * POST method for update users shops settings.
   * @param {UserShopSettings} body
   * @returns {Observable<any>}
   */
  updateShopsSettings(body: UserShopSettings[]): Observable<any> {
    return this.apiService
      .post('/users/shops_settings/', body)
      .pipe(map(data => data));
  }

  /**
   * POST method for update_future users shops settings.
   * @param {UserFutureShopSettings} body
   * @returns {Observable<any>}
   */
  updateFutureShopsSettings(body: UserFutureShopSettings[]): Observable<any> {
    return this.apiService
      .post('/users/future_shops_settings/', body)
      .pipe(map(data => data));
  }


  /**
   * Update operation limits.
   * @param {{user: number}} body
   * @returns {Observable<UserOperations>}
   */
  updateOperationLimits(
    id: number,
    body: { user: number }
  ): Observable<UserOperations> {
    return this.apiService
      .patch(`/users/operation_limits/${id}/`, body)
      .pipe(map(data => data));
  }

  /**
   * Create operation limits.
   * @param {{user: number; action: number}} body
   * @returns {Observable<UserOperations>}
   */
  createOperationLimits(body: {
    user: number;
    action: number;
  }): Observable<UserOperations> {
    return this.apiService
      .post('/users/operation_limits/', body)
      .pipe(map(data => data));
  }

  /**
   * Get default operations list from API.
   * @returns {Observable<any>}
   */
  operations(): Observable<any> {
    return this.apiService.get('/users/operation/').pipe(map(data => data));
  }

  /**
   * Get user shops settings.
   * @param user_id
   * @returns {Observable<UserShopSettings[]>}
   */
  getShopsSettings(user_id: number): Observable<UserShopSettings[]> {
    return this.apiService
      .get(`/users/shops_settings/${user_id}/`)
      .pipe(map(data => data));
  }

  /**
   * Get user shops settings.
   * @param user_id
   * @returns {Observable<any>}
   */
  getFutureShopsSettings(user_id): Observable<any> {
    return this.apiService
      .get(`/users/future_shops_settings/${user_id}/`)
      .pipe(map(data => data));
  }

  /**
   * Get list of available limit operations.
   * @returns {Observable<UserOperations>}
   */
  getOperationActions(): Observable<UserOperations> {
    return this.apiService
      .get('/users/operation_limits/list-actions/')
      .pipe(map(data => data));
  }

  /**
   * Get list of available reset parameters.
   * @returns {Observable<UsersLimitsResets>}
   */
  getOperationsLimitsResets(): Observable<UsersLimitsResets> {
    return this.apiService
      .get('/users/operation_limits/list-resets/')
      .pipe(map(data => data));
  }

  /**
   * Get user operations limits.
   * @param body
   * @returns {Observable<UserOperationsLimits>}
   */
  getOperationLimits(body): Observable<UserOperationsLimits> {
    return this.apiService
      .get('/users/operation_limits/', body)
      .pipe(map(data => data));
  }

  getIpList(body: any): Observable<UsersIpList> {
    return this.apiService
      .get('/users/remote_addresses/', body)
      .pipe(map(data => data));
  }

  addIp(body: {
    address: string;
    blocked: boolean;
    user: number;
  }): Observable<UsersIp> {
    return this.apiService
      .post('/users/remote_addresses/', body)
      .pipe(map(data => data));
  }

  updateIpStatus(
    id: number,
    body: { address: string; blocked: boolean; user: number }
  ): Observable<UsersIp> {
    return this.apiService
      .patch(`/users/remote_addresses/${id}/`, body)
      .pipe(map(data => data));
  }

  removeIp(ipId: number): Observable<any> {
    return this.apiService
      .delete(`/users/remote_addresses/${ipId}/`)
      .pipe(map(data => data));
  }

  /**
   * Register new user.
   * @param {IUserRegister} body
   * @returns {Observable<any>}
   */
  register(body: IUserRegister): Observable<any> {
    return this.apiService
      .post('/users/register/', body, true)
      .pipe(map(data => data));
  }

  /**
   * Send message for specified user.
   * @param {number} id
   * @param {{subject: string; message: string; force?: boolean}} body
   * @returns {Observable<any>}
   */
  message(
    id: number,
    body: { subject: string; message: string; force?: boolean }
  ): Observable<any> {
    return this.apiService
      .post(`/users/users/${id}/message/`, body)
      .pipe(map(data => data));
  }
}
