<div class="login-container" [@fadeInDown]>
  <div class="form">
    <img *ngIf="logoSettings.normal" src="assets/images/logo-skuba.png" alt="{{'SKUBA_LOGO'| translate}}" class="login-logo">
    <img *ngIf="logoSettings.christmas" src="assets/images/logo-skuba-christmas.png" alt="{{'SKUBA_LOGO'| translate}}" class="login-logo christmas-logo">
    <nz-alert
      *ngIf="infoMessage"
      nzType="info"
      [nzMessage]="infoMessage | translate"
      nzShowIcon
      nzCloseable
      (nzOnClose)="infoMessage = ''"
    ></nz-alert>
    <form class="login-form" (ngSubmit)="submit()" [formGroup]="form" #formDir="ngForm" novalidate>
      <div class="form-group">
        <app-input
          type="text" placeholder="USERNAME" label="USERNAME" formControlName="username" [focus]="true" (focusout)="checkUsername()">
            <app-input-validation
              className="error"
              message="REQUIRED"
              [condition]="(form.get('username').touched || formDir.submitted) && form.get('username').hasError('required')">
            </app-input-validation>
        </app-input>
      </div>
      <div class="form-group">
        <app-input type="password" placeholder="PASSWORD" label="PASSWORD" formControlName="password">
          <app-input-validation
            className="error"
            message="REQUIRED"
            [condition]="(form.get('password').touched || formDir.submitted) && form.get('password').hasError('required')">
          </app-input-validation>
        </app-input>
      </div>

      <div class="form-group agreement">
        <input type="checkbox" placeholder="GENERAL_TERMS" label="GENERAL_TERMS" formControlName="agreement">
        <span class="agreement-text">
          <a href="/page/general-terms" routerLink="/page/general-terms" target="_blank">
            {{'I_AGREE_WITH_GENERAL_TERMS' | translate}}
          </a>
        </span>
      </div>
      <div class="form-group agreement" *ngIf="showGeneralTermsSKCheckbox">
        <input type="checkbox" placeholder="SK_ONLY_I_AGREE_GENERAL_TERMS" label="SK_ONLY_I_AGREE_GENERAL_TERMS" formControlName="general_terms">
        <span class="agreement-text">
          <a href="/page/general-terms-2" routerLink="/page/general-terms-2" target="_blank">
            {{'SK_ONLY_I_AGREE_GENERAL_TERMS' | translate}}
          </a>
        </span>
      </div>
      <div class="form-group agreement" *ngIf="showAdditionalTermsSKCheckbox">
        <input type="checkbox" placeholder="SK_ONLY_ADDITIONAL_CHECKBOX" label="SK_ONLY_ADDITIONAL_CHECKBOX" formControlName="additional_terms">
        <span class="agreement-text">
          <a href="/page/general-terms-2" routerLink="/page/general-terms-2" target="_blank">
            {{'SK_ONLY_ADDITIONAL_CHECKBOX' | translate}}
          </a>
        </span>
      </div>
      
      <div class="form-group login-btn">
        <nz-spin [nzSpinning]="isPending" [nzDelay]="1">
          <app-button [className]="'btn-red'" text="LOGIN" [disabled]="!form.value['agreement'] || (showGeneralTermsSKCheckbox && !form.value['general_terms'])|| (showAdditionalTermsSKCheckbox && !form.value['additional_terms'])"></app-button>
        </nz-spin>
      </div>
      <nz-alert
        *ngIf="errorMessage"
        nzType="error"
        [nzMessage]="errorMessage | translate"
        nzShowIcon
        nzCloseable
        (nzOnClose)="errorMessage = ''"
      ></nz-alert>
    </form>
    <hr *ngIf="canRegister">
    <p *ngIf="canRegister">
      <span>{{'DONT_HAVE_ACCOUNT' | translate}}</span>&nbsp;<a href="/register"
                                                               routerLink="/register">{{'REGISTER' | translate}}</a>
    </p>
    <p><a href="/auth/forgot-password" routerLink="/auth/forgot-password">{{'FORGOT_PASSWORD_Q' | translate}}</a></p>
    <p><a href="/auth/forgot-user" routerLink="/auth/forgot-user">{{'FORGOT_USERNAME_Q' | translate}}</a></p>
    <p class="general-terms"><a href="/page/general-terms" routerLink="/page/general-terms">{{'GENERAL_TERMS' | translate}}</a></p>
    <p class="general-terms" *ngIf="showGeneralTermsSKCheckbox"><a href="/page/general-terms-2" routerLink="/page/general-terms-2">{{'SK_ONLY_GENERAL_TERMS' | translate}}</a></p>
  </div>
</div>
