import { OnDestroy } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import * as fromRoot from '@app/reducers';
import { select, Store } from '@ngrx/store';
import * as fromAuth from '@app/auth/store';
import { Observable, Subscription } from 'rxjs';

@Pipe({
  name: 'currencyName'
})
export class CurrencyNamePipe implements PipeTransform, OnDestroy {
  user$: Observable<any>;
  userSub: Subscription;
  user: any;
  showLocalPrice: any;
  showLocalPriceSet = false;
  showLocalPriceError = false;

  currency = '';
  currencySet = false;
  currencyError = false;

  constructor(private store: Store<fromRoot.AppState>) {
    this.user$ = this.store.pipe(select(fromAuth.getUser));
    this.userSub = this.user$.subscribe(user => {
      this.user = user;
      if (user && user.user_settings) {
        this.showLocalPrice = user.user_settings.show_local_currency;
        this.showLocalPriceSet = true;
        this.showLocalPriceError = false;
      }
      if (
        user &&
        user.profile &&
        user.profile.client &&
        user.profile.client.company &&
        user.profile.client.company.country
      ) {
        this.currency = user.profile.client.company.country.currency;
        this.currencySet = true;
        this.currencyError = false;
      }
    });
  }

  deferExecution(
    resolve: (value?: string | PromiseLike<string>) => void,
    count: number,
    instant = false
  ) {
    if (this.showLocalPriceError || this.currencyError) {
      resolve('-');
    }
    if (this.showLocalPriceSet && this.currencySet) {
      resolve(
          (this.showLocalPrice &&
            this.currency) ||
          'EUR'
        );
    } else {
      if (count > 10) {
        console.error(
          'currencyName.deferExecution.error: user had defered execution more than 10 times.'
        );
        resolve('-');
      } else {
        if (instant) {
          resolve('-');
        } else {
          window.setTimeout(() => {
            this.deferExecution(resolve, count + 1);
          }, 300);
        }
      }
    }
  }

  transform(currency: string): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.deferExecution(resolve, 0);
    });
    return promise;
  }

  transformInstant(
    resolve: (value?: string | PromiseLike<string>) => void
  ) {
    this.deferExecution(resolve, 0, true);
  }
  ngOnDestroy(): void {
    if(this.userSub){
      this.userSub.unsubscribe();
    }
  }
}
