import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { SpinnerState, SpinnerService } from './spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnDestroy, OnInit {
  visible = false;

  /**
   * Defines threhold for not to diplay if time is less than 500ms.
   * @type {number}
   * @private
   */
  _threshold = 500;

  /**
   * Accepts external threshold
   * @param {number} value
   */
  @Input()
  public set threshold(value: number) {
    this._threshold = value;
  }

  /**
   * @description
   * @readonly
   * @type {number}
   * @memberof Ng4LoadingSpinnerComponent
   */
  public get threshold(): number {
    return this._threshold;
  }

  private subscription: Subscription;

  constructor(private spinnerService: SpinnerService) {
    this.createServiceSubscription();
  }

  ngOnInit() {
    this.subscription = this.spinnerService.spinnerState.subscribe(
      (state: SpinnerState) => (this.visible = state.show)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Create service subscription
   * @memberof Ng4LoadingSpinnerComponent
   */
  createServiceSubscription() {
    let timer: any;

    this.subscription = this.spinnerService.getMessage().subscribe(show => {
      if (show.show) {
        if (timer) {
          return;
        }
        timer = setTimeout(
          function() {
            timer = null;
            this.visible = true;
          }.bind(this),
          this.threshold
        );
      } else {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        this.visible = false;
      }
    });
  }
}
