import { Injectable } from '@angular/core';
import { ApiService } from '@core/api.service';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';

const cachedKeys = {
  // 'tecdoc_options': true,
  // 'id_token': true,
  'cached-list': true,
  'cached-groups': true,
  'cached-user-deliveries': true,
  'cached-user-pickup-delivery-options': true,
  'cached-user-deliveries-by-order': true,
};
const cachedKeysList = Object.keys(cachedKeys);
type CachedKeys = keyof typeof cachedKeys;
@Injectable()
export class CachedRequestService {
  private plateNumberStatus = new BehaviorSubject<any>({found: false, reload: false, state: 0, value: ''});
  plateNumberStatus$ = this.plateNumberStatus.asObservable();


  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService
  ) {}

  hasKey(key: CachedKeys): boolean {
    this.checkForKey(key);
    return this.localStorageService.hasKey(key);
  }

  getItem(key: CachedKeys) {
    this.checkForKey(key);
    return this.localStorageService.getItem(key);
  }

  setItem(key: CachedKeys, value: any) {
    this.checkForKey(key);
    this.localStorageService.setItem(key, value);
  }

  clearItem(key: CachedKeys) {
    this.checkForKey(key);
    return this.localStorageService.clearItem(key);
  }
  checkForKey(key: CachedKeys) {
    if (!cachedKeys[key]) {
      throw new Error('CACHE_KEY_ERROR');
    }
  }

  clearItems() {
    cachedKeysList.forEach(key => {
      this.localStorageService.clearItem(key);
    });
  }
}
