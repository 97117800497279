import {
  AfterViewInit,
  Component, ElementRef, EventEmitter,
  forwardRef, Input, Output, ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { BaseInputComponent } from '@shared/ui/input/base-input.component';

/**
 * @whatItDoes Returns input ui component.
 */
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // Unavoidable ref in component
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => InputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      // Unavoidable ref in component
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent extends BaseInputComponent implements AfterViewInit, ControlValueAccessor {
  /**
   * Accepts any input type, defaults to `text`
   * @type {string}
   */
  @Input() type = 'text';

  /** Allows custom options (e.g. search input styling) */
  @Input() special: string;

  /** attr.aria-label (optional) */
  @Input() label: string;

  /** Form control name */
  @Input() name: string;

  /**
   * Placeholder (optional
   * @type {string}
   */
  @Input() placeholder = '';

  /** Min value length */
  @Input() minLength: number;

  /**
   * Disabled attribute (optional)
   * @type {boolean}
   */
  @Input() disabledState = false;

  /**
   * Hide input (optional)
   * @type {boolean}
   */
  @Input() hiddenState = false;

  /**
   * Margin bottom (optional)
   * @type {boolean}
   */
  @Input() marginBottom = true;

  /** Input focus (optional) */
  @Input() focus = false;

  /** Input required (optional) */
  @Input() isRequired = false;

  /**
   * Input value reset (optional)
   * @type {boolean}
   */
  @Input() reset = false;

  /**
   * Explanation (optional)
   * @type {string}
   */
  @Input() explanation = '';

  /** Get native input element ref */
  @ViewChild('input', { static: false }) vc: ElementRef;
}
