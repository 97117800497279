import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy, ApplicationRef, WrappedValue } from '@angular/core';
import { TranslationService } from '@core/service/translation.service';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import { Observable, Subscription } from 'rxjs';

let COUNT_CALLS = 0;

@Pipe({
  name: 'translatePure'
})
export class TranslatePurePipe implements PipeTransform, OnDestroy {
  translations$: Observable<any>;
  translationsSub: Subscription;
  translationSequence = 0;
  translationSequenceLast = 0;
  translationKey = 'NO_TRANSLATION';
  translationValue = 'NO_TRANSLATION';
  destroyed = false;
  constructor(
    private translationService: TranslationService,
    private store: Store<fromRoot.AppState>,
    private _ref: ChangeDetectorRef,
    private _appRef: ApplicationRef,
  ) {
    this.translations$ = this.store.pipe(select(fromRoot.getTranslations));
    this.translationsSub = this.translations$.subscribe(
      translations => {
        if (this.destroyed) {
          console.error('TranslatePipe: Running on destroyed component...');
          return;
        }
        this.translationSequence = this.translationSequence + 1;
        console.warn(`We call mark for check ${this.translationSequence}`);
        console.warn(`[${this.translationKey}, ${this.translationValue}, ${translationService.translate(this.translationKey)}]`);
        setTimeout(() => {
          if (!this.destroyed) {
            // console.warn(_ref);
            // _ref.detectChanges();
            _ref.markForCheck();
            // _appRef.tick();
          }
        });
        setTimeout(() => {
          _ref.markForCheck();
          // _appRef.tick();
        });
      });
    }
  transform(key: string, param): string | WrappedValue {
    if (this.destroyed) {
      console.error('TranslatePipe: Running on destroyed component...');
      return '';
    }
    // if (this.translationSequence === this.translationSequenceLast) {
    //   return this.translationValue;
    // }
    console.warn(`Translating....${key}`);
    if (COUNT_CALLS / 100 > 1) {
      console.warn('We call pipe 10x...');
      COUNT_CALLS = 0;
    }
    COUNT_CALLS = COUNT_CALLS + 1;
    this.translationKey = key;
    this.translationValue = this.translationService.translate(key, param);
    this.translationSequenceLast = this.translationSequence;

    if (this.translationSequence !== this.translationSequenceLast) {
      return WrappedValue.wrap(this.translationValue);
    }
    return this.translationValue;
  }

  ngOnDestroy() {
    this.destroyed = true;
    this.translationsSub.unsubscribe();
  }
}
