import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import * as fromRoot from '@app/reducers';
import { select, Store } from '@ngrx/store';
import * as fromAuth from '@app/auth/store';
import { Observable, Subscription } from 'rxjs';
const MAX_MSG_TO_SENTRY = 10;
@Pipe({
  name: 'useLocalCurrency'
})
export class UseLocalCurrency implements PipeTransform, OnDestroy {
  user$: Observable<any>;
  userSub: Subscription;
  user: any;
  showLocalPrice: any;
  showLocalPriceSet = false;
  showLocalPriceError = false;
  private msgCount = 0;
  constructor(private store: Store<fromRoot.AppState>) {
    this.user$ = this.store.pipe(select(fromAuth.getUser));
    this.userSub = this.user$.subscribe(
      user => {
        this.user = user;

        if (user && user.user_settings) {
          this.showLocalPrice = user.user_settings.show_local_currency;
          this.showLocalPriceSet = true;
          this.showLocalPriceError = false;
        }
      },
      () => {
        console.error(
          'showLocalCurrency.deferExecution.error: user pipe subscription returned error.'
        );
        this.showLocalPriceError = true;
      }
    );
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }

  deferExecution(
    resolve: (value?: boolean | PromiseLike<boolean>) => void,
    count: number
  ) {
    if (this.showLocalPriceError) {
      resolve(false);
    }
    if (this.showLocalPriceSet) {
      resolve(this.showLocalPrice);
    } else {
      if (count > 10) {
        console.error(
          'showLocalCurrency.deferExecution.error: user had defered execution more than 10 times.'
        );
      } else {
        window.setTimeout(() => {
          this.deferExecution(resolve, count + 1);
        }, 150);
      }
    }
  }

  transform(currency: string): Promise<boolean> {
    const promise = new Promise<boolean>((resolve, reject) => {
      this.deferExecution(resolve, 0);
    });
    return promise;
  }
}
