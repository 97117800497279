var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import * as shippingActions from '../actions/shipping.action';
export class ShippingEffects {
    constructor(actions$) {
        this.actions$ = actions$;
        // @Effect()
        // loadShipping$ = this.actions$.ofType(checkoutActions.ShippingActionTypes.ShippingLoad).pipe(
        //   mergeMap(() => {
        //     return this.cartService.getShipping().pipe(
        //       map(data => new checkoutActions.LoadShippingSuccess(data)),
        //       catchError(error => of(new checkoutActions.LoadShippingFail(error)))
        //     );
        //   })
        // );
        // @Effect()
        // updateShipping$ = this.actions$.ofType(checkoutActions.ShippingActionTypes.UpdateShipping).pipe(
        //   map((action: checkoutActions.UpdateShipping) => action.payload),
        //   mergeMap((product) => {
        //     return this.cartService
        //       .updateShipping(product.id, product)
        //       .pipe(
        //         map(productItem => new checkoutActions.UpdateShippingSuccess(productItem)),
        //         catchError(error => of(new checkoutActions.UpdateShippingFail(error)))
        //       );
        //   })
        // );
        // @Effect()
        // loadShipping$ = this.actions$
        //   .ofType(shippingActions.ShippingActionTypes.ShippingLoad)
        //   .pipe(
        //     map((action: shippingActions.LoadShipping) => action.payload),
        //     map(shipping => {
        //       return new shippingActions.LoadShippingSuccess(shipping);
        //     })
        //   );
        this.updateShipping$ = this.actions$
            .pipe(ofType(shippingActions.ShippingActionTypes.UpdateShipping), map((action) => action.payload), map(shipping => {
            return new shippingActions.UpdateShippingSuccess(shipping);
        }));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], ShippingEffects.prototype, "updateShipping$", void 0);
