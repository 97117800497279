import { Action } from '@ngrx/store';

export enum LayoutActionTypes {
  OpenHamburger = '[Layout] Open Hamburger',
  CloseHamburger = '[Layout] Close Hamburger',
  ShowSearchIcon = '[Layout] Show Search Icon',
  HideSearchIcon = '[Layout] Hide Search Icon',
  ResetLayoutState = '[Layout] Reset State'
}

export class OpenHamburger implements Action {
  readonly type = LayoutActionTypes.OpenHamburger;
}

export class CloseHamburger implements Action {
  readonly type = LayoutActionTypes.CloseHamburger;
}

export class ShowSearchIcon implements Action {
  readonly type = LayoutActionTypes.ShowSearchIcon;
}

export class HideSearchIcon implements Action {
  readonly type = LayoutActionTypes.HideSearchIcon;
}

export class ResetLayoutState implements Action {
  readonly type = LayoutActionTypes.ResetLayoutState;
}

export type LayoutActions =
  | OpenHamburger
  | CloseHamburger
  | ShowSearchIcon
  | HideSearchIcon
  | ResetLayoutState;
