<div class="search-nav" *ngIf="searchNavStatus" [@fadeInDown]>
  <form (ngSubmit)="search(products)"
        [formGroup]="products" novalidate
        [ngClass]="{'not-submitted': !formSubmitAttempt}"
        #searchFormDir="ngForm">
    <div class="search-row">
      <div class="base-container">
        <div class="search-bar row">
          <app-favorite-products></app-favorite-products>

          <div class="search-input">
            <app-input-with-autocomplete formControlName="search"
                       placeholder="PRODUCT_SEARCH"
                       (autocompleteChange)="setNameAutocomplete()"
                       [autocompleteData]="this.nameSuggestionItems"
                       [focus]="autofocus"
                       [defaultAutocomplete]="false"
                       [reset]="true"
                       [highlight]="false"
                       *ngIf="viewInit">
              <app-input-validation
                *ngIf="products.get('search')"
                className="error"
                message="{{'MIN_SYMBOLS' | translate:min_symbols}}"
                [condition]="(searchFormDir.submitted) && (products.get('search').hasError('minlength') || products.get('search').hasError('required'))">
              </app-input-validation>
            </app-input-with-autocomplete>
          </div>
          <div class="search-button col">
            <app-button
              icon="icon-search"
              className="{{disableSearch ? 'btn-disabled' : 'btn-red'}}"
              text="SEARCH_IT"
              [disabled]="disableSearch"></app-button>
          </div>
          <nz-radio-group [nzButtonStyle]="'solid'" (ngModelChange)="setQuickChoices($event)" [(ngModel)]="quickSelectChoice" [ngModelOptions]="{ standalone: true }" [nzSize]="'small'">
            <label nz-tooltip [nzTooltipTitle]="'SET_DEFAULT_PARAMS' | translate" [nzMouseEnterDelay]="0.01" [nzMouseLeaveDelay]="0.01" class="search-quick-choice-default" nz-radio-button nzValue="DEFAULT" ></label>
            <label [title]="'SKN' | translate" class="search-quick-choice-skn" nz-radio-button nzValue="SKN" >{{'SKN' | translate}}</label>
            <label [title]="'CARD' | translate" class="search-quick-choice-card" nz-radio-button nzValue="CARD" >{{'CARD' | translate}}</label>
          </nz-radio-group>
        </div>
      </div>
      <div class="search-details" *ngIf="true">
        <div class="base-container">
          <div class="search-details-block">
            <div class="full-width" nzType="flex" nzJustify="space-between" nzAlign="middle" nz-row>
              <div nz-col nzSpan="4" class="app-text-small bold">{{'DETAIL_SEARCH_PARAMS' | translate}}</div>
              <div nz-col nzSpan="13">
                <div class="search-checkboxes">
                  <app-checkbox
                    name="context_state"
                    formControlName="context_state"
                    label="CONTEXT">
                  </app-checkbox>
                  <app-checkbox
                    name="codes_state"
                    formControlName="codes_state"
                    label="OE_CODE">
                  </app-checkbox>
                  <app-checkbox
                    name="skn_state"
                    formControlName="skn_state"
                    label="SKN_CODE">
                  </app-checkbox>
                  <app-checkbox
                    name="card_number_state"
                    formControlName="card_number_state"
                    label="CARD_NR">
                  </app-checkbox>
                  <app-checkbox
                    name="tec_doc_state"
                    formControlName="tec_doc_state"
                    label="TEC_DOC">
                  </app-checkbox>
                </div>
              </div>
              <div nz-col nzSpan="6">
              <nz-form-item class="subgroup-select-form-item" *ngIf="options">
                <nz-select
                  nzShowSearch
                  nzAllowClear
                  [nzPlaceHolder]="'CHOOSE_SUBGROUP' | translate"
                  formControlName="sub_group"
                  [nzFilterOption]="subGroupFilter"
                  [nzCustomTemplate]="custom">
                  <nz-option *ngFor="let option of options" nzCustomContent [nzLabel]="option.name" [nzValue]="option.val">
                    <span [title]="option.name">
                      {{option.name}} <span style="position: absolute;right: 4px;font-size: 12px;top: 0px;">{{option.val}}</span>
                    </span>
                  </nz-option>
                </nz-select>
                <ng-template #custom let-selected>
                  {{selected.nzLabel}} <span style="position: absolute;right: 4px;font-size: 12px;top: -4px;">{{selected.nzValue}}</span>
                </ng-template>
              </nz-form-item >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
