import { ShowcaseActionTypes } from '../actions';
const ɵ0 = [];
export const initialState = {
    data: ɵ0,
    groups: [],
    entities: {},
    loaded: false,
    loading: false
};
export function reducer(state = initialState, action) {
    switch (action.type) {
        case ShowcaseActionTypes.ShowcaseLoad: {
            return Object.assign({}, state, { loading: true });
        }
        case ShowcaseActionTypes.ShowcaseLoadSuccess: {
            let entities = [];
            if (action.payload) {
                const showcases = action.payload;
                entities = showcases.reduce((entit, showcase) => {
                    return Object.assign({}, entit, { [showcase.skn]: showcase });
                }, Object.assign({}, state.entities));
            }
            return Object.assign({}, state, { loaded: true, loading: false, data: action.payload, entities: entities });
        }
        case ShowcaseActionTypes.ShowcaseLoadFail: {
            return {
                data: [],
                groups: [],
                entities: [],
                loading: false,
                loaded: false
            };
        }
        case ShowcaseActionTypes.LoadGroups: {
            return Object.assign({}, state, { loading: true });
        }
        case ShowcaseActionTypes.LoadGroupsSuccess: {
            const activeGroups = action.payload.filter(item => item.active);
            return Object.assign({}, state, { groups: activeGroups });
        }
        case ShowcaseActionTypes.LoadGroupsFail: {
            return Object.assign({}, state, { groups: [] });
        }
        case ShowcaseActionTypes.ResetShowcaseState: {
            return Object.assign({}, initialState);
        }
        default: {
            return state;
        }
    }
}
export const getShowcaseItems = (state) => state.data;
export const getShowcaseGroups = (state) => state.groups;
export const getShowcaseEntities = (state) => state.entities;
export const getShowcaseLoaded = (state) => state.loaded;
export const getShowcaseLoading = (state) => state.loading;
export { ɵ0 };
