import { Injectable, Pipe } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthService } from '@app/auth/services/auth.service';
import * as fromActions from '../actions';
import { switchMap, catchError, tap, mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { Router } from '@angular/router';
import * as toast from '@core/store/actions/toast.action';
import { Store } from '@ngrx/store';
import { AppState } from '@app/reducers';
import { AuthActionTypes, LoadUser } from '@app/auth/store/actions';
import * as fromRouter from '@core/store/actions/router.action';
import { SubGroupsLoad } from '@core/store/actions/subGroup.action';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  @Effect() LoadUser$ = this.actions$.pipe(
    ofType(fromActions.LOAD_USER),
    switchMap(() => {
      return this.authService.LoadUser().pipe(
        map(user => new fromActions.LoadUserSuccess(user)),
        catchError(error => of(new fromActions.LoadUserFail(error)))
      );
    })
  );

  @Effect() updateUser$ = this.actions$.pipe(
    ofType(fromActions.UPDATE_USER),
    map((action: fromActions.UpdateUser) => action.payload),
    mergeMap(data => {
      return this.authService.updateUser(data).pipe(
        map(user => new fromActions.UpdateUserSuccess(user)),
        catchError(error => of(new fromActions.UpdateUserFail(error)))
      );
    })
  );

  @Effect() updateUserLanguage$ = this.actions$.pipe(
    ofType(fromActions.UPDATE_USER_LANGUAGE),
    map((action: fromActions.UpdateUserLanguage) => action.payload),
    mergeMap(data => {
      return this.authService.updateUser(data).pipe(
        map(user => new fromActions.UpdateUserLanguageSuccess(user)),
        catchError(error => of(new fromActions.UpdateUserLanguageFail(error)))
      );
    })
  );

  @Effect() handleUpdateUserSuccess$ = this.actions$.pipe(
    ofType(fromActions.UPDATE_USER_SUCCESS),
    switchMap(() => [new toast.ShowSuccessToast('SUCCESS')])
  );

  // @Effect({ dispatch: false })
  // handleUserSuccess$ = this.actions$.pipe(
  //   ofType(fromActions.LOAD_USER_SUCCESS),
  //   tap(() => {
  //     this.router.navigate(['/catalogue']);
  //   })
  // );

  @Effect({ dispatch: false }) handleUserError$ = this.actions$.pipe(
    ofType(fromActions.LOAD_USER_FAIL),
    tap(() => console.log('ulf'))
  );
}
