import { Action } from '@ngrx/store';
import { IToast } from '@app/core/models/toast';

/**
 * For each action type in an action group, make a simple
 * enum object for all of this group's action types.
 */
export enum ToastActionTypes {
  ShowToast = '[Toast] ShowToast',
  ShowErrorToast = '[Toast] Show Error Toast',
  ShowSuccessToast = '[Toast] Show Success Toast',
  ShowWarningToast = '[Toast] Show Warning Toast',
  ResetToastState = '[Toast] Reset State'
}

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 */
export class ShowToast implements Action {
  readonly type = ToastActionTypes.ShowToast;

  constructor(public payload: IToast) {}
}

export class ShowErrorToast implements Action {
  readonly type = ToastActionTypes.ShowErrorToast;

  constructor(public payload: string, public skipReport = false) {}
}
export class ShowSuccessToast implements Action {
  readonly type = ToastActionTypes.ShowSuccessToast;

  constructor(public payload: string) {}
}
export class ShowWarningToast implements Action {
  readonly type = ToastActionTypes.ShowWarningToast;

  constructor(public payload: string, public persistent = false) {}
}

export class ResetToastState implements Action {
  readonly type = ToastActionTypes.ResetToastState;
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type ToastActions =
  | ShowToast
  | ShowErrorToast
  | ShowSuccessToast
  | ShowWarningToast
  | ResetToastState;
