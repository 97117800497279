import { LogOperation, PageOperation, UserOperation } from '../constants/user-operations';
import { OnInit } from '@angular/core';

export class PageComponent implements LogOperation, OnInit {
  setOperation() {
    // child must implement to state if this page opening could result entering to SKN
    // UserOperation.TYPE = PageOperation.None;
    // UserOperation.navigation_id = window.history.state;
    // UserOperation.query = '';
  }
  ngOnInit(): void {
    this.setOperation();
  }
}
