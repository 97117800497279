/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forgot-password-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/ui/back-button/back-button.component.ngfactory";
import * as i3 from "../../../shared/ui/back-button/back-button.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "../../components/forgot-password-form/forgot-password-form.component.ngfactory";
import * as i7 from "../../components/forgot-password-form/forgot-password-form.component";
import * as i8 from "@angular/forms";
import * as i9 from "../../services/auth.service";
import * as i10 from "@ngrx/store";
import * as i11 from "../../../shared/services/helper.service";
import * as i12 from "./forgot-password-page.component";
var styles_ForgotPasswordPageComponent = [i0.styles];
var RenderType_ForgotPasswordPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotPasswordPageComponent, data: { "animation": [{ type: 7, name: "fadeInDown", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0, transform: "translateY(-2rem)" }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateY(0rem)" }, offset: null }, timings: "300ms ease-in" }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0, transform: "translateY(-2rem)" }, offset: null }, timings: "300ms ease-out" }], options: null }], options: {} }, { type: 7, name: "fadeIn", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "400ms ease" }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "400ms ease" }], options: null }], options: {} }] } });
export { RenderType_ForgotPasswordPageComponent as RenderType_ForgotPasswordPageComponent };
export function View_ForgotPasswordPageComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "section", [], [[24, "@fadeInDown", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "base-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-back-button", [], null, null, null, i2.View_BackButtonComponent_0, i2.RenderType_BackButtonComponent)), i1.ɵdid(4, 49152, null, 0, i3.BackButtonComponent, [i4.ActivatedRoute, i4.Router, i5.Location], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-forgot-password-form", [], null, null, null, i6.View_ForgotPasswordFormComponent_0, i6.RenderType_ForgotPasswordFormComponent)), i1.ɵdid(6, 114688, null, 0, i7.ForgotPasswordFormComponent, [i8.FormBuilder, i9.AuthService, i4.Router, i10.Store, i1.ElementRef, i11.HelperService], null, null)], function (_ck, _v) { _ck(_v, 6, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_ForgotPasswordPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgot-password-page", [], null, null, null, View_ForgotPasswordPageComponent_0, RenderType_ForgotPasswordPageComponent)), i1.ɵdid(1, 114688, null, 0, i12.ForgotPasswordPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotPasswordPageComponentNgFactory = i1.ɵccf("app-forgot-password-page", i12.ForgotPasswordPageComponent, View_ForgotPasswordPageComponent_Host_0, {}, {}, []);
export { ForgotPasswordPageComponentNgFactory as ForgotPasswordPageComponentNgFactory };
