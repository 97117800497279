var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { ISubGroupResult } from '@app/core/models/subgroup';
import { environment } from '@environments/environment';
import { DecoratorsService } from '../develop/decorators';
export class SubGroupService {
    constructor(http) {
        this.http = http;
    }
    getSubGroups(filters = null) {
        return this.http
            .get(environment.api_url + '/products/sub_groups/?limit=2000')
            .pipe(catchError((error) => throwError(error.json())));
    }
}
__decorate([
    DecoratorsService.DevCache(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Observable)
], SubGroupService.prototype, "getSubGroups", null);
