<div class="cart-mini-container"
     *ngIf="(products$ | async).cart
            && (products$ | async).cart.items
            && (products$ | async).cart.items.length
            && (products$ | async).cart.items.length > 0">
  <nz-spin [nzSpinning]="isCartSaving">
    <div class="cart-mini-content shadow-1">
      <app-cart-list-mini [productsFrom]="(products$ | async).cart.items"
                          [updateProductNotes]="updateProductNotesSubject"
                          (productsUpdated)="goCheckoutSaved($event)"
                          (existNotUpdatedProductChange)="showSaveInsteadOfContinue = $event"
                          (selectOpen)="selecting($event)">
      </app-cart-list-mini>
      <div class="cart-bottom">
        <app-cart-summary
          [products]="(products$ | async)"
          [user]="(user$ | async)"
          [showLocalPrice]="showLocalPrice"
          [showJustOneSum]="true">
        </app-cart-summary>
      </div>
      <div class="actions flex-end" *ngIf="totalQuantity">
        <a [routerLink]="['/cart']">
          <app-button
            class="collapse-icon"
            icon="fas fa-shopping-cart"
            iconBig="true"
          ></app-button>
        </a>
        <app-button className="btn-red" *ngIf="showSaveInsteadOfContinue" text="SAVE" (click)="goCheckout()"></app-button>
        <app-button className="btn-red" *ngIf="!showSaveInsteadOfContinue" text="CONTINUE" (click)="goCheckout()"></app-button>
      </div>
      <div class="info-messages">
        <ng-container *ngIf="hasDebtError || hasOnTheWayItem || hasDepositItem || hasDebtWarning">
          <nz-alert
            class="info-msg animated bounceIn"
            *ngIf="hasDebtError"
            nzType="error"
            [nzDescription]="ErrorDescription"
          ></nz-alert>
          <nz-alert
            class="info-msg animated bounceIn"
            *ngIf="hasOnTheWayItem || hasDepositItem || hasDebtWarning"
            nzType="warning"
            [nzDescription]="OnTheWayDescription"
          ></nz-alert>
          <ng-template #OnTheWayDescription>
            <i class="fas fa-truck fa-fw" *ngIf="hasOnTheWayItem"></i>
            <i class="fas fa-hand-holding-usd fa-fw" *ngIf="hasDepositItem"></i>
            <span class="fa-stack" style="vertical-align: top;" *ngIf="hasDebtWarning && showDebtIcons">
              <i class="far fa-credit-card fa-stack-1x" style="margin-left: 10px;"></i>
              <i class="fas fa-cut fa-stack-1x" style="margin-left: -7px;"></i>
            </span>
            <ng-container *ngIf="hasOnTheWayItem">
              <br>
              {{'ON_THE_WAY_WARNING' | translate}}
            </ng-container>
            <ng-container *ngIf="hasDepositItem">
              <br>
              {{'HAS_DEPOSIT_WARNING' | translate}}
            </ng-container>
            <ng-container *ngIf="hasNegativeBalance && !hasDebtError">
              <br>
              {{'BALANCE_NEGATIVE_ERROR' | translate: { balance_value: userBalance } }}
            </ng-container>
            <ng-container *ngIf="hasOverdueDebt && !hasDebtError">
              <br>
              {{'BALANCE_NOT_PAID_WARNING' | translate: { debt_value: userBalance } }}
            </ng-container>
            <ng-container *ngIf="hasPrePayNegativeBalance">
              <br>
              {{'PRE_PAY_BALANCE_NEGATIVE' | translate: { balance_value: userBalance } }}
            </ng-container>
            <ng-container *ngIf="hasCustomMessage">
              <br>
              {{customMessage | translate: { debt_value: userBalance } }}
            </ng-container>
  
          </ng-template>
          <ng-template #ErrorDescription>
            <span class="fa-stack" style="vertical-align: top;" *ngIf="hasDebtError && showDebtIcons">
              <i class="far fa-credit-card fa-stack-1x" style="margin-left: 10px;"></i>
              <i class="fas fa-cut fa-stack-1x" style="margin-left: -7px;"></i>
            </span>
            <ng-container *ngIf="hasNegativeBalance && !hasDebtWarning">
              <br>
              {{'BALANCE_NEGATIVE_ERROR' | translate: { balance_value: userBalance } }}
            </ng-container>
            <ng-container *ngIf="hasOverdueDebt && !hasDebtWarning">
              <br>
              {{'BALANCE_NOT_PAID_ERROR' | translate: { debt_value: userBalance + (knOverdueDebt ? ' [KN: ' + knOverdueDebt + ']' : '') } }}
            </ng-container>
          </ng-template>
        </ng-container>
        
        <nz-alert
          *ngIf="centralHolidayNotification.showNotification && hasCentralStock"
          class="info-msg animated bounceIn"
          nzType="warning"
          [nzDescription]="CentralHolidayDescription"
        ></nz-alert>
        <ng-template #CentralHolidayDescription>
          <i class="fas fa-star-of-life fa-fw" style="color: red"></i>
          <br>
          {{'HOLIDAY_IN_LITHUANIA' | translate:{date: centralHolidayNotification.holidayDate} }}
        </ng-template>
        <nz-alert
        *ngIf="localHolidayNotification.showNotification && hasLocalStock"
        class="info-msg animated bounceIn"
        nzType="warning"
        [nzDescription]="LocalHolidayDescription"
      ></nz-alert>
      <ng-template #LocalHolidayDescription>
        <i class="fas fa-star-of-life fa-fw" style="color: tomato"></i>
        <br>
        {{'LOCAL_COUNTRY_HOLIDAYS' | translate:{date: localHolidayNotification.holidayDate} }}
      </ng-template>
      </div>
    </div>
  </nz-spin>
</div>