import { Action } from '@ngrx/store';
import { IUser, IUSerChange } from '@app/auth/models/user';

export const LOAD_USER = '[Auth] Load User';
export const LOAD_USER_FAIL = '[Auth] Load User Fail';
export const LOAD_USER_SUCCESS = '[Auth] Load User Success';
export const UPDATE_USER = '[Auth] Update User';
export const UPDATE_USER_FAIL = '[Auth] Update User Fail';
export const UPDATE_USER_SUCCESS = '[Auth] Update User Success';
export const UPDATE_USER_LANGUAGE = '[Auth] Update User Language';
export const UPDATE_USER_LANGUAGE_FAIL = '[Auth] Update User Language Fail';
export const UPDATE_USER_LANGUAGE_SUCCESS =
  '[Auth] Update User Language Success';
export const RESET_USER = '[Auth] Reset User';

export class LoadUser implements Action {
  readonly type = LOAD_USER;
}

export class LoadUserFail implements Action {
  readonly type = LOAD_USER_FAIL;

  constructor(public payload: any) {}
}

export class LoadUserSuccess implements Action {
  readonly type = LOAD_USER_SUCCESS;

  constructor(public payload: IUser) {}
}

export class UpdateUser implements Action {
  readonly type = UPDATE_USER;

  constructor(public payload: IUSerChange) {}
}

export class UpdateUserFail implements Action {
  readonly type = UPDATE_USER_FAIL;

  constructor(public payload: any) {}
}

export class UpdateUserSuccess implements Action {
  readonly type = UPDATE_USER_SUCCESS;

  constructor(public payload: IUser) {}
}

export class UpdateUserLanguage implements Action {
  readonly type = UPDATE_USER_LANGUAGE;

  constructor(public payload: IUSerChange) {}
}

export class UpdateUserLanguageFail implements Action {
  readonly type = UPDATE_USER_LANGUAGE_FAIL;

  constructor(public payload: any) {}
}

export class UpdateUserLanguageSuccess implements Action {
  readonly type = UPDATE_USER_LANGUAGE_SUCCESS;

  constructor(public payload: IUser) {}
}

export class ResetUser implements Action {
  readonly type = RESET_USER;
}

export type UserAction =
  | LoadUser
  | LoadUserFail
  | LoadUserSuccess
  | UpdateUser
  | UpdateUserFail
  | UpdateUserSuccess
  | UpdateUserLanguage
  | UpdateUserLanguageFail
  | UpdateUserLanguageSuccess
  | ResetUser;
