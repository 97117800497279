import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { tap, map, exhaustMap, catchError, switchMap, debounceTime } from 'rxjs/operators';
import * as fromRouter from '../../../core/store/actions/router.action';

import { AuthService } from '../../services/auth.service';
import {
  Login,
  SilentLogin,
  LoginSuccess,
  LoginFailure,
  AuthActionTypes,
  LoadUser,
  LoginRedirect,
  LoadUserFail,
  Logout,
  LoginSuccessRedirect,
  AuthReset,
  ResetUser,
  Silent
} from '../actions';
import { IAuthenticate } from '../../models/user';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { ResetSubGroupsState } from '@app/core/store/actions/subGroup.action';
import { ResetCartState } from '@app/pages/cart/store';
import { ResetLayoutState } from '@app/core/store/actions/layout.action';
import { ResetCountriesState } from '@app/core/store/actions/country.action';
import { ResetUserAddressState } from '@app/core/store/actions/user-addresses.action';
import { ResetProductState } from '@app/core/store/actions/product.action';
import { ResetToastState } from '@app/core/store/actions/toast.action';
import { ResetOrdersState } from '@core/store/actions/order.action';
import { ResetSidebarState } from '@core/store/actions/sidebar.action';
import { ResetShowcaseState } from '@pages/dashboard/store';
import { ResetShipping } from '@pages/checkout/store';
import { ResetSearchHistoryState } from '@core/store/actions/search-history.action';
import { ResetLastRouteState } from '@core/store/actions/last-route.action';
import { CachedRequestService } from '@app/core/service/cached-req.service';

@Injectable()
export class AuthEffects {


  @Effect() login$ = this.actions$.pipe(
    ofType(AuthActionTypes.Login),
    map((action: Login) => action.payload),
    switchMap((auth: IAuthenticate) =>
      this.loginImplementation(auth, false)
      )
  );

  @Effect() silentLogin$ = this.actions$.pipe(
    ofType(AuthActionTypes.SilentLogin),
    map((action: SilentLogin) => action.payload),
    switchMap((auth: IAuthenticate) =>
      this.loginImplementation(auth, true)
    )
  );

  // @Effect()
  // login$ = this.actions$
  //   .ofType(AuthActions.Login)
  //   .switchMap(action => {
  //     return this.authService
  //       .login(action.payload)
  //       .pipe(
  //         map(() => new LoginSuccessRedirect()),
  //         catchError(error =>
  //           of(new LoginFailure(error.error.non_field_errors))
  //         )
  //       );
  //   });

  @Effect() loginSuccess$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoginSuccess),
    switchMap(() => [new LoadUser()])
  );

  @Effect() loginSuccessNoRedirect$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoginSuccessRedirect),
    switchMap(() => [new LoadUser()])
  );

  @Effect() loginRedirect$ = this.actions$.pipe(
    ofType(AuthActionTypes.LoginRedirect),
    map(() => new fromRouter.Go({ path: ['/login'] }))
  );

  @Effect() logout$ = this.actions$.pipe(
    ofType(AuthActionTypes.Logout),
    tap(() => {
      this.cachedRequestService.clearItems();
      this.localStorageService.clearItem('tecdoc_options');
      this.localStorageService.clearItem('id_token');
    }),
    switchMap(() => [
      new LoadUserFail(''),
      // This should preserve current url, simpler solutions is to just not do redirect, and cleared token will do its job
      // new LoginRedirect(),
      new AuthReset(),
      new ResetUser(),
      new ResetCartState(),
      new ResetLayoutState(),
      new ResetSubGroupsState(),
      new ResetCountriesState(),
      new ResetUserAddressState(),
      new ResetProductState(),
      new ResetOrdersState(),
      new ResetShowcaseState(),
      new ResetShipping(),
      new ResetSidebarState(),
      new ResetToastState(),
      new ResetSearchHistoryState(),
      new ResetLastRouteState()
    ]),
    tap(() => {
      setTimeout(() => {
        window.location.reload();
      });
    })
  );

  @Effect() silentAuth$ = this.actions$.pipe(
    ofType(AuthActionTypes.SilentAuth),
    debounceTime(100),
    exhaustMap(() => {
      return this.authService.checkToken().pipe(
        map(() => new LoginSuccess()),
        catchError(() => {
          return of(new Logout());
        })
      );
    })
  );

  @Effect() silentAuthNoAction$ = this.actions$.pipe(
    ofType(AuthActionTypes.SilentAuthNoAction),
    debounceTime(100),
    exhaustMap(() => {
      return this.authService.checkToken(true).pipe(
        map(() => new LoginSuccess()),
        catchError(() => {
          return of(new Silent());
        })
      );
    })
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private cachedRequestService: CachedRequestService,
  ) {}

  loginImplementation(auth: IAuthenticate, silenced: boolean) {
    return this.authService.login(auth, silenced).pipe(
      map(() => new LoginSuccessRedirect()),
      catchError(error =>
        of(new LoginFailure(error.error && error.error.non_field_errors))
      ));
  }
}
