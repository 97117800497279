var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ProductOrderHistoryActions from '../actions/product-order-history.action';
import { ProductOrderHistoryActionTypes } from '../actions/product-order-history.action';
import { OrderService } from '@pages/checkout/services/order.service';
export class ProductOrderHistoryEffects {
    constructor(actions$, orderService) {
        this.actions$ = actions$;
        this.orderService = orderService;
        this.loadSubgroups$ = this.actions$.pipe(ofType(ProductOrderHistoryActionTypes.ProductOrderHistoryLoad), debounceTime(300), map((action) => action.payload), switchMap(body => {
            return this.orderService.getBySkn(body).pipe(map(history => new ProductOrderHistoryActions.ProductOrderHistoryLoadSuccess(history)), catchError(error => of(new ProductOrderHistoryActions.ProductOrderHistoryLoadFail(error))));
        }));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], ProductOrderHistoryEffects.prototype, "loadSubgroups$", void 0);
