/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { Action } from '@ngrx/store';
import { IOrderUpdateMany } from '@app/shared/models';
import { Order } from '@app/core/models/order';

export enum OrderActionTypes {
  OrderEmpty = '[Order] Order Empty',
  OrderLoad = '[Order] Load Order',
  OrderLoadFail = '[Order] Load Order Fail',
  OrderLoadSuccess = '[Order] Load Order Success',
  UpdateOrder = '[Order] Update Order',
  UpdateOrderSuccess = '[Order] Update Order Success',
  UpdateOrderFail = '[Order] Update Order Fail',
  CreateOrder = '[Order] Create Order',
  CreateOrderSuccess = '[Order] Create Order Success',
  CreateOrderFail = '[Order] Create Order Fail',
  UpdateOrderStatus = '[Order] Update Order Status',
  UpdateOrderStatusSuccess = '[Order] Update Order Status Success',
  UpdateOrderStatusFail = '[Order] Update Order Status Fail',
  UpdateOrderMany = '[Order] Update Order Many',
  UpdateOrderManySuccess = '[Order] Update Order Many Success',
  UpdateOrderManySingleSuccess = '[Order] Update Order Many Multiple Success',
  UpdateOrderManyFail = '[Order] Update Order Many Fail',
  UpdateOrderStatusMany = '[Order] Update Order Status',
  UpdateOrderStatusManySuccess = '[Order] Update Order Status Success',
  UpdateOrderStatusManyFail = '[Order] Update Order Status Fail',
  ResetOrdersState = '[Order] Reset State'
}

export class OrderEmpty implements Action {
  readonly type = OrderActionTypes.OrderEmpty;
  constructor(public error: any = null) {}
}

/**
 * Load Order Actions
 */
export class LoadOrder implements Action {
  readonly type = OrderActionTypes.OrderLoad;
}

export class LoadOrderSuccess implements Action {
  readonly type = OrderActionTypes.OrderLoadSuccess;

  constructor(public payload: any) {}
}

export class LoadOrderFail implements Action {
  readonly type = OrderActionTypes.OrderLoadFail;

  constructor(public payload: any) {}
}

/**
 * Create Order from Order Actions
 */
export class CreateOrder implements Action {
  readonly type = OrderActionTypes.CreateOrder;

  constructor(public payload: any, public acknowledged: boolean = false, public cartItemsIds: number[] = []) {}
}

export class CreateOrderSuccess implements Action {
  readonly type = OrderActionTypes.CreateOrderSuccess;

  constructor(public payload: any) {}
}

export class CreateOrderFail implements Action {
  readonly type = OrderActionTypes.CreateOrderFail;

  constructor(public payload: any) {}
}

/**
 * Update Order from Order Actions
 */
export class UpdateOrder implements Action {
  readonly type = OrderActionTypes.UpdateOrder;

  constructor(public payload: any) {}
}

export class UpdateOrderSuccess implements Action {
  readonly type = OrderActionTypes.UpdateOrderSuccess;

  constructor(public payload: any) {}
}

export class UpdateOrderFail implements Action {
  readonly type = OrderActionTypes.UpdateOrderFail;

  constructor(public payload: any) {}
}

/**
 * Update Order from Order Actions
 */
export class UpdateOrderStatus implements Action {
  readonly type = OrderActionTypes.UpdateOrderStatus;

  constructor(public payload: any) {}
}

export class UpdateOrderStatusSuccess implements Action {
  readonly type = OrderActionTypes.UpdateOrderStatusSuccess;

  constructor(public payload: any) {}
}

export class UpdateOrderStatusFail implements Action {
  readonly type = OrderActionTypes.UpdateOrderStatusFail;

  constructor(public payload: any) {}
}

/**
 * Update Order from Order Actions
 */
export class UpdateOrderMany implements Action {
  readonly type = OrderActionTypes.UpdateOrderMany;

  constructor(public payload: IOrderUpdateMany, public singleOrder: boolean) {}
}

export class UpdateOrderManySuccess implements Action {
  readonly type = OrderActionTypes.UpdateOrderManySuccess;

  constructor(public payload: Order[]) {}
}

export class UpdateOrderManySingleSuccess implements Action {
  readonly type = OrderActionTypes.UpdateOrderManySingleSuccess;

  constructor(public orders: Order[]) {}
}

export class UpdateOrderManyFail implements Action {
  readonly type = OrderActionTypes.UpdateOrderManyFail;

  constructor(public payload: any) {}
}

/**
 * Update Order from Order Actions
 */
export class UpdateOrderStatusMany implements Action {
  readonly type = OrderActionTypes.UpdateOrderStatusMany;

  constructor(public payload: any) {}
}

export class UpdateOrderStatusManySuccess implements Action {
  readonly type = OrderActionTypes.UpdateOrderStatusManySuccess;

  constructor(public payload: any) {}
}

export class UpdateOrderStatusManyFail implements Action {
  readonly type = OrderActionTypes.UpdateOrderStatusManyFail;

  constructor(public payload: any) {}
}

export class ResetOrdersState implements Action {
  readonly type = OrderActionTypes.ResetOrdersState;
}
export type OrderActions =
  | LoadOrder
  | LoadOrderSuccess
  | LoadOrderFail
  | CreateOrder
  | CreateOrderSuccess
  | CreateOrderFail
  | UpdateOrder
  | UpdateOrderSuccess
  | UpdateOrderFail
  | UpdateOrderStatus
  | UpdateOrderStatusSuccess
  | UpdateOrderStatusFail
  | UpdateOrderMany
  | UpdateOrderManySuccess
  | UpdateOrderManyFail
  | UpdateOrderStatusMany
  | UpdateOrderStatusManySuccess
  | UpdateOrderStatusManyFail
  | ResetOrdersState;
