<div class="app-spinner" [class.is-active]="visible">
  <div class="app-fading-circle">
    <div class="app-circle1 app-circle"></div>
    <div class="app-circle2 app-circle"></div>
    <div class="app-circle3 app-circle"></div>
    <div class="app-circle4 app-circle"></div>
    <div class="app-circle5 app-circle"></div>
    <div class="app-circle6 app-circle"></div>
    <div class="app-circle7 app-circle"></div>
    <div class="app-circle8 app-circle"></div>
    <div class="app-circle9 app-circle"></div>
    <div class="app-circle10 app-circle"></div>
    <div class="app-circle11 app-circle"></div>
    <div class="app-circle12 app-circle"></div>
  </div>
</div>
