import { Component, Input, ElementRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnDestroy, OnChanges {
  /**
   * Class which defines button color.
   * 1) btn-red
   * 2) btn-heather
   * 3) btn-white
   * 4) btn-disabled
   * 5) btn-transparent
   * 6) btn-transparent-text
   */
  @Input() className: string;

  /**
   * Button type attribute.
   */
  @Input() type: string;

  /**
   * Disable button attribute.
   */
  @Input() disabled: boolean;

  /**
   * Button title.
   */
  @Input() text: string;

  /**
   * Button icon (optional).
   */
  @Input() icon: string;

  /**
   * Button material icon (optional).
   */
  @Input() materialIcon: string;

  @Input() active = false;

  @Input() activeClickableYellow = false;

  @Input() iconBig = false;

  @Input() buttonStyle;
  constructor(
    private elRef: ElementRef
  ) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      this.outerElementUpdates();
    }
  }
  outerElementUpdates() {
    if (this.disabled) {
      (this.elRef.nativeElement as HTMLElement).style.pointerEvents = 'none';
    } else {
      (this.elRef.nativeElement as HTMLElement).style.pointerEvents = '';
    }
  }
  ngOnDestroy() {
    
  }
}
