import { Pipe, PipeTransform } from '@angular/core';
import { IGroupSelectParent } from '@shared/ui/group-select/models/group-select.model';

@Pipe({
  name: 'groupSelect'
})
export class GroupSelectPipe implements PipeTransform {
  parentGroup: IGroupSelectParent[];
  filteredArray: IGroupSelectParent[];

  transform(object, group_name: string, name: string, value: string): any {
    this.parentGroup = [];
    this.filteredArray = [];

    object.map(item => {
      let hasParent = false;

      this.parentGroup.map((parentItem, parentKey) => {
        if (parentItem.group_name === item[group_name]) {
          this.parentGroup[parentKey].groups.push({
            name: item[name],
            value: item[value]
          });

          hasParent = true;
        }
      });

      if (!hasParent) {
        this.parentGroup.push({
          group_name: item[group_name],
          groups: [
            {
              name: item[name],
              value: item[value]
            }
          ]
        });
      }
    });

    return this.parentGroup;
  }
}
