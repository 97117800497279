import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, catchError, mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserAddressesService } from '@app/core/service/user-addresses.service';
import * as fromActions from '../actions/user-addresses.action';
import * as fromUser from '@app/auth/store/actions';

@Injectable()
export class UserAddressesEffects {
  constructor(
    private actions$: Actions,
    private userAddressesService: UserAddressesService
  ) {}

  @Effect() loadUserAddress$ = this.actions$.pipe(
    ofType(fromActions.LOAD_USER_ADDRESSES),
    switchMap(() => {
      return this.userAddressesService.getShippingAddresses().pipe(
        map(user => new fromActions.LoadUserAddressesSuccess(user)),
        catchError(error => of(new fromActions.LoadUserAddressesFail(error)))
      );
    })
  );

  @Effect() addUserAddress$ = this.actions$.pipe(
    ofType(fromActions.ADD_USER_ADDRESS),
    map((action: fromActions.AddUserAddress) => action.payload),
    mergeMap(address => {
      return this.userAddressesService.addShippingAddresses(address).pipe(
        map(addressItem => new fromActions.AddUserAddressSuccess(addressItem)),
        catchError(error => of(new fromActions.AddUserAddressFail(error)))
      );
    })
  );

  @Effect() patchUserAddress$ = this.actions$.pipe(
    ofType(fromActions.PATCH_USER_ADDRESS),
    map((action: fromActions.PatchUserAddress) => action.payload),
    mergeMap(data => {
      return this.userAddressesService
        .patchShippingAddress(data.id, data.body)
        .pipe(
          map(
            addressItem => new fromActions.PatchUserAddressSuccess(addressItem)
          ),
          catchError(error => of(new fromActions.PatchUserAddressFail(error)))
        );
    })
  );

  @Effect() updateUserAddress$ = this.actions$.pipe(
    ofType(fromActions.UPDATE_USER_ADDRESS),
    map((action: fromActions.UpdateUserAddress) => action.payload),
    mergeMap(data => {
      return this.userAddressesService
        .updateShippingAddress(data.id, data.body)
        .pipe(
          map(
            addressItem => new fromActions.UpdateUserAddressSuccess(addressItem)
          ),
          catchError(error => of(new fromActions.UpdateUserAddressFail(error)))
        );
    })
  );

  @Effect() removeUserAddress$ = this.actions$.pipe(
    ofType(fromActions.REMOVE_USER_ADDRESS),
    map((action: fromActions.RemoveUserAddress) => action.payload),
    mergeMap(id => {
      return this.userAddressesService.removeShippingAddress(id).pipe(
        map(() => new fromActions.RemoveUserAddressSuccess()),
        catchError(error => of(new fromActions.RemoveUserAddressFail(error)))
      );
    })
  );

  @Effect() setUserAddress$ = this.actions$.pipe(
    ofType(fromActions.SET_USER_ADDRESS),
    map((action: fromActions.SetUserAddress) => action.payload),
    map(address => {
      return new fromActions.SetUserAddressSuccess(address);
    })
  );

  @Effect() handlePatchUserAddressSuccess$ = this.actions$.pipe(
    ofType(fromActions.PATCH_USER_ADDRESS_SUCCESS),
    map(() => {
      return new fromActions.LoadUserAddresses();
    })
  );

  @Effect() handleUpdateUserAddressSuccess$ = this.actions$.pipe(
    ofType(fromActions.UPDATE_USER_ADDRESS_SUCCESS),
    map(() => {
      return new fromActions.LoadUserAddresses();
    })
  );

  @Effect() handleRemoveUserAddressSuccess$ = this.actions$.pipe(
    ofType(fromActions.REMOVE_USER_ADDRESS_SUCCESS),
    map(() => {
      return new fromActions.LoadUserAddresses();
    })
  );
}
