import {
  Component,
  EventEmitter,
  Input,
  Output,
  HostListener,
  SimpleChanges,
  OnDestroy,
  OnChanges
} from '@angular/core';

import { animate, style, transition, trigger } from '@angular/animations';
import { escape } from '@app/shared/models/const.model';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  animations: [
    trigger('dialog', [
      transition('void => *', [style({ transform: 'scale3d(.3, .3, .3)' }), animate(100)]),
      transition('* => void', [animate(100, style({ transform: 'scale3d(.0, .0, .0)' }))])
    ])
  ]
})
export class ModalComponent implements OnChanges, OnDestroy {
  /** Show/hide modal status */
  @Input() status: boolean;

  /** Should disable background scroll */
  @Input() shouldDisableScroll = false;

  /** Modal top title */
  @Input() title: string;

  /** Modal top title icon */
  @Input() titleIcon: string;

  /** Main modal body text */
  @Input() message: string;

  /**
   * Overlay show status.
   * @type {boolean}
   */
  @Input() showOverlay = false;

  /**
   * Show close button.
   * @type {boolean}
   */
  @Input() closeButton = true;

  /**
   * Show confirm button;
   * @type {boolean}
   */
  @Input() confirmButton = true;

  /**
   * Ability close modal on escape;
   * @type {boolean}
   */
  @Input() closeOnEscape = true;

  @Input() showActionsOnTop = false;

  /**
   * Modal max width style.
   * @type {string}
   */
  @Input() maxWidth = '500px';

  /**
   * Status change after action in modal component.
   * @type {EventEmitter<boolean>}
   */
  @Output() readonly outputStatus = new EventEmitter<boolean>();

  /**
   * Output after user confirms action.
   * @type {EventEmitter<boolean>}
   */
  @Output() readonly confirm = new EventEmitter<boolean>();

  /**
   * Listen for keyboard key and closes modal on ESCAPE
   * @param event
   */
  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    // eslint-disable-next-line deprecation/deprecation
    if (event.keyCode === escape) {
      if (this.closeOnEscape) {
        this.closeModal(event);
      }
    }
  }

  /**
   * Method sets modal status to false.
   * @param event
   */
  closeModal(event) {
    this.outputStatus.emit(true);
  }

  /**
   * Pass output that action was confirmed after modal button click.
   * @param event
   */
  confirmModal(event) {
    this.outputStatus.emit(true);
    this.confirm.emit(true);
  }


  modalShouldBeOpened() {
    if (this.shouldDisableScroll) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('modal-opened');
    }
  }
  modalShouldBeClosed() {
    if (this.shouldDisableScroll) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.remove('modal-opened');
    }
  }

  ngOnChanges(data: SimpleChanges) {
    if (data.status && data.status.currentValue) {
      // Modal opened
      this.modalShouldBeOpened();
    } else if (data.status && !data.status.currentValue && data.status.previousValue) {
      // Modal closed
      this.modalShouldBeClosed();
    }
  }

  ngOnDestroy() {
    this.modalShouldBeClosed();
  }
}
