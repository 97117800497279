import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '@core/api.service';
import { CartItemsExpiredResult, CartModel } from '@pages/cart/models/cart.model';
import { map } from 'rxjs/internal/operators';
import { CartItemUpdate } from '@app/shared/models/product.model';
import { DecoratorsService } from '@app/core/develop/decorators';

@Injectable()
export class CartService {
  constructor(private apiService: ApiService) {}

  /**
   * Get Cart data from API.
   * @param {string} with_expired
   * @returns {Observable<any>}
   */
  @DecoratorsService.DevCache()
  getCart(with_expired = false): Observable<CartModel> {
    return this.apiService.get('/cart/', { with_expired: with_expired.toString() }).pipe(map(data => data));
  }

  /**
   * Get Expired Cart data from API.
   * @param {last_cart_id: string}
   * @returns {Observable<any>}
   */
  getCartExpired(last_cart_id: string): Observable<CartItemsExpiredResult> {
    const requestBody: { last_cart_id?: string } = {};
    if (last_cart_id) {
      requestBody.last_cart_id = last_cart_id;
    }
    return this.apiService.get('/cart/get-expired/', requestBody).pipe(map(data => data));
  }

  /**
   * Add product to cart.
   */
  addToCart(body: {
    product_id: number;
    card_number: number;
    quantity: number;
    additional_info: string;
  }, acknowledged = false): Observable<CartModel> {
    return this.apiService.post('/cart/', { ...body, acknowledged }).pipe(map(data => data));
  }

  /**
   * Update cart product.
   * @param {number} id
   * @param {{card_number: string | number; quantity: number; with_expired: boolean}} body
   * @returns {Observable<any>}
   */
  updateCart(
    id: number,
    body: CartItemUpdate
  ): Observable<CartModel> {
    return this.apiService.put(`/cart/${id}/`, body).pipe(map(data => data));
  }

  /**
   * Remove product from cart.
   * @param {number} id
   * @param {boolean} with_expired
   * @returns {Observable<any>}
   */
  removeFromCart(id: number): Observable<any> {
    return this.apiService.delete(`/cart/${id}/`).pipe(map(data => data));
  }

  /**
   * Remove expired product from cart.
   * @param {number} id
   * @returns {Observable<any>}
   */
  removeFromCartExpired(id: number): Observable<any> {
    return this.apiService.delete(`/cart/${id}/expired/`).pipe(map(data => data));
  }
}
