/**
 * @module AppModule
 */ /** */
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router, RouterState, NavigationEnd } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import * as fromAuth from '@app/auth/store';
import * as layout from '@core/store/actions/layout.action';
import { Observable, Subscription } from 'rxjs';
import * as fromCart from '@pages/cart/store';
import * as LanguageActions from '@core/store/actions/language.action';
import * as TranslationActions from '@core/store/actions/translation.action';
import { PageScrollConfig, PageScrollInstance, PageScrollService } from 'ngx-page-scroll';
import { DOCUMENT } from '@angular/common';
import { IUser } from '@app/auth/models/user';
import { UserService } from '@core/user/user.service';
import * as fromSearchHistory from '@core/store/actions/search-history.action';
import * as fromLastRoute from '@core/store/actions/last-route.action';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { HelperService } from '@shared/services/helper.service';
import { LanguageService } from '@core/service/language.service';
import { fadeIn, fadeInDown } from '@shared/animations';
import { VersionService } from '@shared/services/version.service';
import { SentryService } from '@shared/services/sentry.service';
import { TranslationService } from '@core/service/translation.service';
import { PageTitleService } from '@core/service/pageTitle.service';
import { filter } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
/**
 * @whatItDoes Returns the {@link AppComponent} view.
 * @consumers {@link AppModule}
 */
@Component({
  selector: 'app-skuba-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
  animations: [fadeIn, fadeInDown]
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  showMobileMenu$: Observable<boolean>;
  showSearchIcon$: Observable<boolean>;
  loggedIn$: Observable<boolean>;
  cart$: Observable<boolean>;
  loggedIn: boolean;
  router$: Observable<any>;
  routerSub: Subscription;
  routerEventsSub: Subscription;
  user$: Observable<IUser>;
  user: IUser;
  userSub: Subscription;
  showCookiesPolicy = true;
  pages: boolean;

  isLocalhost = false;
  isUnsupportedBrowser = false;
  /** Scrollable container */
  @ViewChild('container', { static: false }) private container: ElementRef;

  @HostBinding('class.app-skuba-root') hostClass = false;
  routeSub: Subscription;
  isLogin = false;
  layoutStyle = {
    'background-image': '',
    'background-size': '',
    'background-repeat': ''
  };
  fontSize = 20;
  showSuggestApp = false;

  screenSizeMediaListener: MediaQueryList;
  screenSizeMediaCallbackFix: (ev: { readonly matches: boolean;readonly media: string; }) => any;
  showCartMiniAndLanguage = false;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private element: ElementRef,
    private store: Store<fromRoot.AppState>,
    private pageScrollService: PageScrollService,
    private localStorage: LocalStorageService,
    private router: Router,
    private helperService: HelperService,
    private languageService: LanguageService,
    private versionService: VersionService,
    private sentryService: SentryService,
    private cd: ChangeDetectorRef,
    private pageTitleService: PageTitleService,
    private translationService: TranslationService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.showMobileMenu$ = this.store.pipe(select(fromRoot.getShowHamburgerMenu));
    this.loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
    this.showSearchIcon$ = this.store.pipe(select(fromRoot.getShowSearchIcon));
    this.cart$ = this.store.pipe(select(fromRoot.getCartLoaded));
    this.router$ = this.store.pipe(select(fromRoot.getRouterState));
    this.user$ = this.store.pipe(select(fromAuth.getUser));

    PageScrollConfig.defaultDuration = 0;
    PageScrollConfig.defaultInterruptible = false;
  }

  ngOnInit() {
    const cookieEnabled = this.checkCookiesEnabled();
    if (!cookieEnabled) {
      this.routerSub = this.router$.subscribe(route => {
        if (route && route.state && route.state.url) {
          if (route.state.url.substring(0, 17) !== '/cookies-disabled') {
            this.router.navigate(['/cookies-disabled']);
          }
        } else {
          this.router.navigate(['/cookies-disabled']);
        }
      });
    } else {
      this.clearDjangoCookies();
      this.showMessageAboutIE();
      this.sentryService.init();
      if (window.location.hostname) {
        const host = window.location.hostname.toLowerCase();
        if (
          host.startsWith('localhost') ||
          host.startsWith('http://localhost') ||
          host.startsWith('127.0.0.1') ||
          host.startsWith('http://127.0.0.1')
        ) {
          this.isLocalhost = true;
        } else {
          this.isLocalhost = false;
          this.versionService.init();
        }
      }
      this.hostClass = true;
      const currentLanguage = this.localStorage.getItem('language') || 'en';
      this.applyCurrentLanguage(currentLanguage);
      // if (currentLanguage) {
      //   this.applyCurrentLanguage(currentLanguage);
      // } else {
      //   currentLanguage = 'lt';
      //   this.applyCurrentLanguage(currentLanguage);
      //   this.routerEventsSub = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe( (navEnd:NavigationEnd) => {
      //     const pathName = navEnd.urlAfterRedirects;
      //     if (pathName.startsWith('/register') || pathName.startsWith('/login')) {
      //       this.languageService.getLanguage().subscribe(
      //         language => {
      //           if (language !== currentLanguage) {
      //             this.applyCurrentLanguage(language, true);
      //           }
      //         },
      //         error => {
      //           this.routerEventsSub.unsubscribe();
      //         }
      //       );
      //     }
      //   });
      // }
      this.helperService.updateCookiesPolicy();

      this.helperService.cookiesPolicy$.subscribe(status => {
        this.showCookiesPolicy = status;
      });
      
      this.screenSizeMediaListener = window.matchMedia("(max-width: 1350px)");
      this.screenSizeMediaCallbackFix = this.screenSize1350MediaCallback(this);
      this.screenSizeMediaListener.addListener(this.screenSizeMediaCallbackFix);
      this.screenSizeMediaCallbackFix(this.screenSizeMediaListener);
    }

    setTimeout(() => {
      this.loggedIn$.subscribe(isLoggedIn => {
        setTimeout(() => {
          this.loggedIn = !!isLoggedIn;
        });

        if (isLoggedIn) {
          if (this.userSub) { this.userSub.unsubscribe(); }
          this.userSub = this.user$.subscribe(user => {
            this.user = user;
            if (user && user.profile && user.profile.force_reset_password) {
              if (this.router.url === '/profile/user/change-pass') {
                // do nothing as we allow to change user password
              } else {
                this.router.navigate(['/profile/user/change-pass']);
              }
            } else if (user && user.profile && user.profile.status !== 1) {
              if (
                this.router.url === '/profile/user' ||
                this.router.url === '/profile/user/info') {
                // do nothing as we allow to change user info
              } else {
                this.router.navigate(['/profile/user/info']);
              }
            } else if (user && user.profile) {
              this.loadCartStore();
              // TODO: uncomment when we start ems-mobile
              this.showSuggestApp = this.checkIfMobileVersionSupported(user) && this.isMobile() &&
              (
                user.id === 84 ||
                user.id === 121 ||
                user.id === 140
              );
              this.store.dispatch(new fromSearchHistory.SearchHistoryLoad());
            }
          });
        } else {
          if (this.userSub) { this.userSub.unsubscribe(); }
        }
        this.cd.detectChanges();
      });
    });
  }
  isMobile() {
    if (typeof screen.orientation !== 'undefined') {
      return true;
    }
    return false;
  };

  applyCurrentLanguage(currentLanguage, unsubscribeRouterEvents: boolean = false) {
    this.store.dispatch(new LanguageActions.SetActiveLanguage(currentLanguage));
    this.store.dispatch(new LanguageActions.LanguagesLoad());
    this.store.dispatch(new TranslationActions.SetTranslationLanguage(currentLanguage));
    if (this.routerEventsSub && unsubscribeRouterEvents) {
      this.routerEventsSub.unsubscribe();
    }
  }

  checkCookiesEnabled() {
    let cookieEnabled = navigator.cookieEnabled ? true : false;
    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = document.cookie.indexOf('testcookie') !== -1 ? true : false;
    }
    return cookieEnabled;
  }

  showMessageAboutIE() {
    if (this.isIE()) {
      this.isUnsupportedBrowser = true;
    }
  }

  clearDjangoCookies() {
    // Clear django cookies. We use rest api and sometimes cookies are set and do not allow to post results.
    document.cookie = 'sessionid=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = 'csrftoken=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
  checkIfMobileVersionSupported(user: IUser) {
    if (
      user && user.profile &&
      !user.profile.company.enable_payment_tatrac &&
      user.user_settings.enable_basket_logic) {
      return true;
    }
    return false;
  }
  openApp() {
    const date = new Date();
    date.setSeconds(date.getSeconds() + 30);
    const token = this.localStorage.getItem('id_token');
    const domain = (this.isLocalhost ? '' : 'domain=skuba.eu;');
    document.cookie = 'skuba-id_token=' + token + ';' + domain + ' expires=' + date + ';sameSite=Strict; Secure;';
    window.location.href = this.isLocalhost ? 'https://localhost:8080/' : 'https://emsm.skuba.eu/';
  }

  continue() {
    this.showSuggestApp = false;
  }

  setFontSize() {
    const size = ((window.innerWidth + (window.innerHeight * 2)) / 3);
    this.fontSize = Math.round(size * 0.04);
  }

  onResize(event) {
    this.setFontSize();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setFontSize();
    }, 0);
    this.routeSub = this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(routeEvent => {
      const routeSerializer = new fromRoot.CustomSerializer();
      const routeState = routeSerializer.serialize(this.router.routerState.snapshot);
      this.pageTitleService.setTitle({ state: routeState });
    });
    this.routerSub = this.router$.subscribe(route => {
      if (route && route.state && route.state.url) {
        this.toggleBackgroundImage(route);
        if (
          route.state.url.substring(0, 6) !== '/login' &&
          route.state.url.substring(0, 9) !== '/register' &&
          route.state.url.substring(0, 5) !== '/auth' &&
          route.state.url.substring(0, 6) !== '/email' &&
          route.state.url.substring(0, 7) !== '/logout'
        ) {
          this.pages = true;
          this.store.dispatch(new fromLastRoute.LastRouteLoad(route.state.url));
        } else {
          this.pages = false;
        }
      }
      this.scroll();
    });
  }

  toggleBackgroundImage(route) {
    if (
      route.state.url.substring(0, 6) === '/login' ||
      route.state.url.substring(0, 9) === '/register' ||
      route.state.url.substring(0, 5) === '/auth' ||
      route.state.url.substring(0, 6) === '/email' ||
      route.state.url.substring(0, 7) === '/logout'
    ) {
      this.layoutStyle = {
        'background-image': 'url(\'/assets/images/skuba_bg.png\')',
        // 'background-size': 'contain',
        // 'background-repeat': 'round'
        'background-size': '',
        'background-repeat': ''
      };
    } else {
      this.layoutStyle = {
        'background-image': '',
        'background-size': '',
        'background-repeat': ''
      };
    }
  }

  toggleHamburgerMenu($event: boolean) {
    if ($event) {
      this.store.dispatch(new layout.CloseHamburger());
    } else {
      this.store.dispatch(new layout.OpenHamburger());
    }
  }

  loadCartStore() {
    this.cart$.subscribe(loaded => {
      if (!loaded && this.loggedIn) {
        this.userSub = this.user$.subscribe(user => {
          if (user && user.profile && user.profile.is_user_interface_active) {
            this.disableUppercaseStyle(user);
            this.setErrorDebugInfo(user);
            this.store.dispatch(new fromCart.LoadCart(true));
          }
        });
      }
    });
  }
  setErrorDebugInfo(user: IUser) {
    Sentry.setUser({
      id: user.id,
      email: user.email,
      username: user.username,
      fid_name: user.profile ? user.profile.company.name : 'Not loaded',
      fid: user.profile ? user.profile.company.skuba_company_id : 'Not loaded',
    });
  }
  disableUppercaseStyle(user: IUser) {
    const uppercaseStyle = document.getElementById('sk-no-uppercase-style-setting');
    if (uppercaseStyle) {
      uppercaseStyle.remove();
    }
    if (user.hide_uppercase_style) {
      const style = document.createElement('style');
      style.setAttribute('id', 'sk-no-uppercase-style-setting');
      style.type = 'text/css';
      style.innerHTML = '* { text-transform: none !important; }';
      document.getElementsByTagName('head')[0].appendChild(style);
    } else {

    }
  }

  scroll(): void {
    const pageScrollInstance: PageScrollInstance = PageScrollInstance.newInstance({
      document: this.document,
      scrollTarget: '.app-skuba-root',
      scrollingViews: this.container ? [this.container.nativeElement] : null
    });
    this.pageScrollService.start(pageScrollInstance);
  }
  isIE() {
    const ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); // IE 11

    return (msie > 0 || trident > 0);
  }

  isActive() {
  }

  hideModal() {
    this.localStorage.setItem('cookiesPolicy', false);
    this.helperService.updateCookiesPolicy();
  }

  ngOnDestroy() {
    if (this.routerEventsSub) {
      this.routerEventsSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    this.screenSizeMediaListener.removeListener(this.screenSizeMediaCallbackFix)
  }
  screenSize1350MediaCallback(that: AppComponent): (ev: { readonly matches: boolean;readonly media: string; }) => any {
    return function(ev: { readonly matches: boolean;readonly media: string; }) {
      if (ev.matches) {
        // console.log('Screen is small.');
        that.showCartMiniAndLanguage = true;
      } else {
        // console.log('Screen is big.');
        that.showCartMiniAndLanguage = false;
      }
      that.cdRef.detectChanges();
    };
  }
}
