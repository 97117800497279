import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterEmpty'
})
export class FilterEmptyPipe implements PipeTransform {
  transform(obj: any): any {
    const filteredParams = {};

    Object.keys(obj).filter((key, index) => {
      if (obj[key] !== null && obj[key] !== 'null' && obj[key] !== '') {
        filteredParams[key] = obj[key];
      }
    });

    return filteredParams;
  }
}
