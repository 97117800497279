import { Action } from '@ngrx/store';

export enum LastRouteActionTypes {
  LastRouteLoad = '[LastRoute] Load Last Route',
  ResetLastRouteState = '[LastRoute] Reset State'
}

export class LastRouteLoad implements Action {
  readonly type = LastRouteActionTypes.LastRouteLoad;

  constructor(public payload: string) {}
}

export class ResetLastRouteState implements Action {
  readonly type = LastRouteActionTypes.ResetLastRouteState;
}

export type LastRouteActions = LastRouteLoad | ResetLastRouteState;
