import { AfterViewInit, ElementRef, } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BaseInputComponent } from '@shared/ui/input/base-input.component';
/**
 * @whatItDoes Returns input ui component.
 */
export class InputComponent extends BaseInputComponent {
    constructor() {
        super(...arguments);
        /**
         * Accepts any input type, defaults to `text`
         * @type {string}
         */
        this.type = 'text';
        /**
         * Placeholder (optional
         * @type {string}
         */
        this.placeholder = '';
        /**
         * Disabled attribute (optional)
         * @type {boolean}
         */
        this.disabledState = false;
        /**
         * Hide input (optional)
         * @type {boolean}
         */
        this.hiddenState = false;
        /**
         * Margin bottom (optional)
         * @type {boolean}
         */
        this.marginBottom = true;
        /** Input focus (optional) */
        this.focus = false;
        /** Input required (optional) */
        this.isRequired = false;
        /**
         * Input value reset (optional)
         * @type {boolean}
         */
        this.reset = false;
        /**
         * Explanation (optional)
         * @type {string}
         */
        this.explanation = '';
    }
}
