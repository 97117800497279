import { Pipe, PipeTransform } from '@angular/core';
import { CustomNumberPipeService } from '@app/shared/services/custom-number.service';

@Pipe({
  name: 'customCurrency'
})

export class CustomCurrencyPipe implements PipeTransform {

  constructor(private customNumberPipeService: CustomNumberPipeService){}

  transform(number: any, decimal: number = 2, symbol: string = '', format: string = '%s%v'): Promise<string> {
    const promise = new Promise<string>((resolve, reject) => {
      this.customNumberPipeService.deferExecution(resolve, 0, number, decimal, symbol, format, true);
    });
    return promise;
  }
}
