import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as LanguageActions from '../actions/language.action';
import { LanguageActionTypes } from '../actions/language.action';
import { LanguageService } from '@core/service/language.service';

@Injectable()
export class LanguageEffects {
  constructor(
    private actions$: Actions,
    private languageService: LanguageService
  ) {}

  @Effect() loadSubgroups$ = this.actions$.pipe(
    ofType(LanguageActionTypes.LanguagesLoad),
    switchMap(() => {
      return this.languageService.getLanguages().pipe(
        map(language => new LanguageActions.LanguagesLoadSuccess(language)),

        catchError(error => of(new LanguageActions.LanguagesLoadFail(error)))
      );
    })
  );
}
