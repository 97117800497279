import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import * as fromSearchHistory from '@core/store/actions/search-history.action';
import { Observable, Subscription } from 'rxjs';
import {
  ISearchHistoryItem,
} from '@shared/models/search-history.model';
import { Router } from '@angular/router';
import { FilterEmptyPipe } from '@shared/pipes/filter-empty/filter-empty.pipe';
import * as fromSidebar from '@core/store/actions/sidebar.action';
import { IUser } from '@app/auth/models/user';
import * as fromAuth from '@app/auth/store';

@Component({
  selector: 'app-sidebar-search-history',
  templateUrl: './sidebar-search-history.component.html',
  styleUrls: ['./sidebar-search-history.component.scss']
})
export class SidebarSearchHistoryComponent implements OnInit, OnDestroy {
  @Input() height: number;
  @Input() history: ISearchHistoryItem[];

  user$: Observable<IUser>;
  userSub: Subscription;
  constructor(
    private store: Store<fromRoot.AppState>,
    private filterEmpty: FilterEmptyPipe,
    private router: Router
  ) {
    this.user$ = this.store.pipe(select(fromAuth.getUser));
  }

  ngOnInit() {
    this.userSub = this.user$.subscribe((user) => {
      if (user && user.profile && user.profile.is_user_interface_active) {
        this.store.dispatch(new fromSearchHistory.SearchHistoryLoad());
      }
    });

  }

  navigateTo(params: any) {
    if (params.number) {
      this.router.navigate([
        '/search/by_car/',
        params.number,
        params.search
      ]);
    } else {
      this.router.navigate(['/search', this.filterEmpty.transform(params)]);
    }
    this.store.dispatch(new fromSidebar.CloseSidebar());
  }

  ngOnDestroy() {
    if (this.userSub) { this.userSub.unsubscribe(); }
  }
}
