/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkbox.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../pipes/translate/translate.pipe";
import * as i3 from "../../../core/service/translation.service";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/common";
import * as i6 from "@angular/forms";
import * as i7 from "./checkbox.component";
import * as i8 from "../../pipes/boolean/boolean.pipe";
var styles_CheckboxComponent = [i0.styles];
var RenderType_CheckboxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckboxComponent, data: {} });
export { RenderType_CheckboxComponent as RenderType_CheckboxComponent };
function View_CheckboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.icon.class, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.icon.name; _ck(_v, 1, 0, currVal_1); }); }
function View_CheckboxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.label)); _ck(_v, 1, 0, currVal_0); }); }
export function View_CheckboxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "app-checkbox"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onValueChange(!_co.val) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["class", "checkbox"], ["type", "checkbox"]], [[8, "id", 0], [8, "value", 0], [8, "checked", 0], [8, "disabled", 0]], [[null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.handleKeyboardEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckboxComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckboxComponent_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.icon; _ck(_v, 4, 0, currVal_5); var currVal_6 = _co.label; _ck(_v, 6, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.name, "-checkbox"); var currVal_1 = _co.val; var currVal_2 = _co.val; var currVal_3 = _co.disabled; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "", _co.name, "-checkbox"); _ck(_v, 2, 0, currVal_4); }); }
export function View_CheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-checkbox", [], null, null, null, View_CheckboxComponent_0, RenderType_CheckboxComponent)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.CheckboxComponent]), i1.ɵdid(2, 49152, null, 0, i7.CheckboxComponent, [i8.BooleanPipe], null, null)], null, null); }
var CheckboxComponentNgFactory = i1.ɵccf("app-checkbox", i7.CheckboxComponent, View_CheckboxComponent_Host_0, { label: "label", icon: "icon", name: "name", val: "val", disabled: "disabled" }, { changed: "changed" }, []);
export { CheckboxComponentNgFactory as CheckboxComponentNgFactory };
