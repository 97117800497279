import {
  LanguageActions,
  LanguageActionTypes
} from '../actions/language.action';
import { ILanguageItem } from '@core/models/language-switch';

export interface LanguagesState {
  activeLanguage: string;
  languageSet: boolean;
  entities: { [id: string]: any };
  loaded: boolean;
  loading: boolean;
}

const initialState: LanguagesState = {
  activeLanguage: 'en',
  languageSet: false,
  entities: {},
  loaded: false,
  loading: false
};

export function reducer(
  state = initialState,
  action: LanguageActions
): LanguagesState {
  switch (action.type) {
    case LanguageActionTypes.LanguagesLoad: {
      return {
        ...state,
        loading: true
      };
    }
    case LanguageActionTypes.LanguagesLoadSuccess: {
      const countries = action.payload;
      const entities = countries.reduce(
        (entit: { [id: string]: ILanguageItem }, language: ILanguageItem) => {
          return {
            ...entit,
            [language.value]: language
          };
        },
        {
          ...state.entities
        }
      );

      return {
        ...state,
        loading: false,
        loaded: true,
        entities: entities
      };
    }
    case LanguageActionTypes.LanguagesLoadFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case LanguageActionTypes.SetActiveLanguage: {
      return {
        ...state,
        languageSet: true,
        activeLanguage: action.payload
      };
    }

    case LanguageActionTypes.ResetLanguagesState: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export const getLanguageEntities = (state: LanguagesState) => state.entities;
export const getLanguagesLoading = (state: LanguagesState) => state.loading;
export const getLanguagesLoaded = (state: LanguagesState) => state.loaded;
export const getActiveLanguage = (state: LanguagesState) =>
  state.activeLanguage;
export const getSetLanguageStatus = (state: LanguagesState) =>
  state.languageSet;
