import { Injectable } from '@angular/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap, tap } from 'rxjs/operators';

import * as orderActions from '../actions/order.action';
import { of } from 'rxjs';
import { OrderService } from '@pages/checkout/services/order.service';
import { Router } from '@angular/router';
import * as fromCart from '@pages/cart/store';
import { Store } from '@ngrx/store';

@Injectable()
export class OrderEffects {

  @Effect() createOrder$ = this.actions$.pipe(
    ofType(orderActions.OrderActionTypes.CreateOrder),
    map((action: orderActions.CreateOrder) =>
      ({ data: action.payload, acknowledged: action.acknowledged, cartItemsIds: action.cartItemsIds })),
    mergeMap(({ data, acknowledged, cartItemsIds }) => this.orderService.createOrder(data, acknowledged, cartItemsIds).pipe(
      map(order => {
        const fixPrice = item => {
          Object.keys(item).forEach(key => {
            if (
              key.includes('price') &&
                (typeof item[key] === 'number' || typeof item[key] === 'string')
            ) {
              item[key] = Number(item[key]).toFixed(2);
            }
          });
        };
          // First fix order
        fixPrice(order);
        // Fix items
        if (order.items) {
          order.items.forEach(item => {
            fixPrice(item);
          });
        }

        return new orderActions.CreateOrderSuccess(order);
      }),
      catchError(error => of(new orderActions.CreateOrderFail(error)))
    ))
  );

  @Effect() updateOrder$ = this.actions$.pipe(
    ofType(orderActions.OrderActionTypes.UpdateOrder),
    map((action: orderActions.UpdateOrder) => action.payload),
    mergeMap(order => this.orderService.updateOrder(order.id, order).pipe(
      map(orderItem => {
        const fixPrice = item => {
          Object.keys(item).forEach(key => {
            if (
              key.includes('price') &&
                (typeof item[key] === 'number' || typeof item[key] === 'string')
            ) {
              item[key] = Number(item[key]).toFixed(2);
            }
          });
        };
          // First fix order
        fixPrice(orderItem);
        // Fix items
        orderItem.items.forEach(item => {
          fixPrice(item);
        });
        return new orderActions.UpdateOrderSuccess(orderItem);
      }),
      catchError(error => of(new orderActions.UpdateOrderFail(error)))
    ))
  );

  @Effect() updateOrderSuccess$ = this.actions$.pipe(
    ofType(orderActions.OrderActionTypes.UpdateOrderSuccess),
    map((action: orderActions.UpdateOrderSuccess) => action.payload),
    map(() => new orderActions.OrderEmpty()),
  );

  // @Effect() updateOrderStatus$ = this.actions$.pipe(
  //   ofType(orderActions.OrderActionTypes.UpdateOrderStatus),
  //   map((action: orderActions.UpdateOrderStatus) => action.payload),
  //   mergeMap(order => {
  //     return this.orderService.updateOrderStatus(order.id, order).pipe(
  //       map(orderItem => new orderActions.UpdateOrderStatusSuccess(orderItem)),
  //       catchError(error => of(new orderActions.UpdateOrderStatusFail(error)))
  //     );
  //   })
  // );

  @Effect() updateOrderMany$ = this.actions$.pipe(
    ofType(orderActions.OrderActionTypes.UpdateOrderMany),
    map((action: orderActions.UpdateOrderMany) => ({ ordersUpdate: action.payload, singleOrder: action.singleOrder })),
    mergeMap(update => this.orderService.updateOrderMany({ ...update.ordersUpdate, _ems_silenced: true}).pipe(
      map(orders => {
        const fixPrice = item => {
          Object.keys(item).forEach(key => {
            if (
              key.includes('price') &&
                (typeof item[key] === 'number' || typeof item[key] === 'string')
            ) {
              item[key] = Number(item[key]).toFixed(2);
            }
          });
        };
        orders.forEach(order => {
          // First fix order
          fixPrice(order);
          // Fix items
          order.items.forEach(item => {
            fixPrice(item);
          });
        });
        if (update.singleOrder) {
          return new orderActions.UpdateOrderManySingleSuccess(orders);
        } else {
          return new orderActions.UpdateOrderManySuccess(orders);
        }
      }),
      catchError(error => of(new orderActions.UpdateOrderManyFail(error)))
    ))
  );

  @Effect() updateOrderManySuccess$ = this.actions$.pipe(
    ofType(orderActions.OrderActionTypes.UpdateOrderManySuccess),
    map((action: orderActions.UpdateOrderManySuccess) => action.payload),
    map(() => new orderActions.OrderEmpty()),
  );
  @Effect() updateOrderManySingleSuccess$ = this.actions$.pipe(
    ofType(orderActions.OrderActionTypes.UpdateOrderManySingleSuccess),
    map((action: orderActions.UpdateOrderManySingleSuccess) => action.orders),
    map(() => new orderActions.OrderEmpty()),
  );

  @Effect() updateOrderManyFail$ = this.actions$.pipe(
    ofType(orderActions.OrderActionTypes.UpdateOrderManyFail),
    map((action: orderActions.UpdateOrderManyFail) => action.payload),
    map((error) => new orderActions.OrderEmpty(error)),
  );

  @Effect() updateOrderStatusMany$ = this.actions$.pipe(
    ofType(orderActions.OrderActionTypes.UpdateOrderStatusMany),
    map((action: orderActions.UpdateOrderStatusMany) => action.payload),
    mergeMap(orders => this.orderService.updateOrderStatusMany(orders).pipe(
      map(orderItem => new orderActions.UpdateOrderStatusManySuccess(orderItem)),
      catchError(error => of(new orderActions.UpdateOrderStatusManyFail(error)))
    ))
  );

  @Effect() handlePatchUserAddressSuccess$ = this.actions$.pipe(
    ofType(orderActions.OrderActionTypes.UpdateOrderStatusSuccess),
    map(() => new fromCart.LoadCart()),
  );

  constructor(
    private actions$: Actions,
    private orderService: OrderService,
    private router: Router,
    private store: Store<fromCart.CartState>
  ) {}
}
