<div class="sidebar">
  <div class="sidebar-menu" [ngClass]="{'sidebar-open': sidebarOpen}">
    <i
      nz-tooltip
      [nzTooltipTitle]="'SEARCH_HISTORY' | translate"
      [nzMouseEnterDelay]="0.01"
      [nzMouseLeaveDelay]="0.01"
      nzTooltipPlacement="topRight"
      [(nzVisible)]="searchPopoverVisible"
      [nzTooltipTrigger]="!sidebarOpen ? 'hover' : 'null'"
      class="material-icons shadow-1"
      (click)="toggleSidebar()"
      [ngClass]="{'item-active': sidebarOpen}">history</i>
  </div>

  <div class="sidebar-body shadow-2" [ngClass]="{'sidebar-open': sidebarOpen}">
    <i class="material-icons close-sidebar" (click)="closeSidebar()">close</i>
    <div class="sidebar-box">
      <app-sidebar-search-history [history]="history" [height]="height" ></app-sidebar-search-history>
    </div>
  </div>
</div>
