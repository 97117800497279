import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CartRoutingModule } from './cart-routing.module';
import { CartComponent } from './containers/cart.component';
import { CartMiniComponent } from './containers/cart-mini/cart-mini.component';
import { SharedModule } from '@shared/shared.module';
import { CartInfoBarComponent } from './components/cart-info-bar/cart-info-bar.component';
import { CartListComponent } from './components/cart-list/cart-list.component';
import { CartListExpiredComponent } from './components/cart-list-expired/cart-list-expired.component';
import { CartListMiniComponent } from './components/cart-list-mini/cart-list-mini.component';
import { CartSummaryComponent } from './components/cart-summary/cart-summary.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { CustomCurrencyPipe } from '@app/shared/pipes/custom-currency/custom-currency';
import { CustomNumberPipe } from '@app/shared/pipes/custom-number/custom-number';
import { CurrencyNamePipe } from '@app/shared/pipes/currency-name/currency-name';
import { CartHelperService } from './services/carthelper.service';

@NgModule({
  imports: [CommonModule, SharedModule, CartRoutingModule, NgZorroAntdModule, SatPopoverModule],
  declarations: [
    CartComponent,
    CartMiniComponent,
    CartInfoBarComponent,
    CartListComponent,
    CartListExpiredComponent,
    CartListMiniComponent,
    CartSummaryComponent,
  ],
  providers: [
    CustomCurrencyPipe,
    CustomNumberPipe,
    CurrencyNamePipe,
    CartHelperService,
  ],
  exports: [
    CartComponent,
    CartMiniComponent,
  ]
})
export class CartModule {}
