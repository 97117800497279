<app-breadcrumbs [items]="breadcrumbs"></app-breadcrumbs>
<app-back-button></app-back-button>
<article class="template" [@fadeInDown]>
  <div class="base-container">
    <div class="error-wrapper">
      <h2 class="error-page_title">{{'PAGE_NOT_FOUND_OOPS' | translate}} </h2>
      <h1>{{'NOT_FOUND' | translate}}: '{{urlPath}}'</h1>
      <h2>
        <a class="home-icon" routerLink="/">
          {{'GET_ME_HOME' | translate}}
        </a>
      </h2>
      <a class="home-icon" routerLink="/">
        <img src="assets/images/logo-skuba.png" [alt]="'SKUBA_LOGO' | translate">
      </a>
      <p>{{'PAGE_NOT_FOUND' | translate}}</p>
    </div>
  </div>
</article>
