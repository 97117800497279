import {
  ProductOrderHistoryActions,
  ProductOrderHistoryActionTypes
} from '../actions/product-order-history.action';

export interface ProductOrderHistoryState {
  results: any;
  loaded: boolean;
  loading: boolean;
  productHistoryOpen: boolean;
}

const initialState: ProductOrderHistoryState = {
  results: [],
  loaded: false,
  loading: false,
  productHistoryOpen: false
};

export function reducer(
  state = initialState,
  action: ProductOrderHistoryActions
): ProductOrderHistoryState {
  switch (action.type) {
    case ProductOrderHistoryActionTypes.ProductOrderHistoryLoad: {
      return {
        ...state,
        loading: true
      };
    }
    case ProductOrderHistoryActionTypes.ProductOrderHistoryLoadSuccess: {
      const searchHistoryConst = action.payload;

      return {
        ...state,
        loading: false,
        loaded: true,
        results: searchHistoryConst
      };
    }
    case ProductOrderHistoryActionTypes.ProductOrderHistoryLoadFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case ProductOrderHistoryActionTypes.ResetProductOrderHistoryState: {
      return {
        ...initialState
      };
    }

    case ProductOrderHistoryActionTypes.OpenProductOrderHistory: {
      return {
        ...state,
        productHistoryOpen: true
      };
    }

    default: {
      return state;
    }
  }
}

export const getProductOrderHistory = (state: ProductOrderHistoryState) =>
  state.results;
export const getProductOrderHistoryLoading = (
  state: ProductOrderHistoryState
) => state.loading;
export const getProductOrderHistoryLoaded = (state: ProductOrderHistoryState) =>
  state.loaded;
export const getProductOrderHistoryOpen = (state: ProductOrderHistoryState) =>
  state.productHistoryOpen;
