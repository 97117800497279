import { CountryActions, CountryActionTypes } from '../actions/country.action';

import { Country } from '@shared/models/country.model';

export interface CountryState {
  entities: { [id: string]: any };
  loaded: boolean;
  loading: boolean;
}

const initialState: CountryState = {
  entities: {},
  loaded: false,
  loading: false
};

export function reducer(
  state = initialState,
  action: CountryActions
): CountryState {
  switch (action.type) {
    case CountryActionTypes.CountriesLoad: {
      return {
        ...state,
        loading: true
      };
    }
    case CountryActionTypes.CountriesLoadSuccess: {
      const countries = action.payload;
      const entities = countries.reduce(
        (entit: { [id: string]: Country }, country: Country) => {
          return {
            ...entit,
            [country.code]: country
          };
        },
        {
          ...state.entities
        }
      );

      return {
        ...state,
        loading: false,
        loaded: true,
        entities: entities
      };
    }
    case CountryActionTypes.CountriesLoadFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case CountryActionTypes.ResetCountriesState: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export const getCountriesEntities = (state: CountryState) => state.entities;
export const getCountriesLoading = (state: CountryState) => state.loading;
export const getCountriesLoaded = (state: CountryState) => state.loaded;
