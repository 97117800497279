import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
  Router
} from '@angular/router';
import { ComponentRef } from '@angular/core';
import * as Sentry from '@sentry/browser';

interface RouteStates {
  max: number;
  handles: { [handleKey: string]: DetachedRouteHandle };
  handleKeys: string[];
  handleTimeout?: number;
  whenHandleInserted?: number;
}

export interface OnShouldReuseRoute {
  onShouldReuseRoute(): boolean;
}

function getResolvedUrl(route: ActivatedRouteSnapshot): string {
  return route.pathFromRoot
    .map(v => v.url.map(segment => segment.toString()).join('/'))
    .filter(v => v)
    .join('/');
}

function getConfiguredUrl(route: ActivatedRouteSnapshot): string {
  return (
    '/' +
    route.pathFromRoot
      .filter(v => v.routeConfig)
      .filter(v => v.routeConfig.path)
      .map(v => v.routeConfig.path)
      .join('/')
  );
}

export class DetailedReuseStrategy implements RouteReuseStrategy {
  private routes: { [routePath: string]: RouteStates } = {
    '/catalogue': { max: 1, handles: {}, handleKeys: [], handleTimeout: 1000 * 60 * 60 },  /* 60 min cache for component */
    '/profile/invoices': { max: 1, handles: {}, handleKeys: [], handleTimeout: 1000 * 60 * 5  }, /*  5 min cache for component */
    '/dashboard': { max: 1, handles: {}, handleKeys: [], handleTimeout: 1000 * 60 * 60 },  /* 60 min cache for component */
    '/search/:id': { max: 1, handles: {}, handleKeys: [], handleTimeout: 1000 * 60 * 5  }, /*  5 min cache for component */
    '/tecdoc/car-selection': { max: 1, handles: {}, handleKeys: [], handleTimeout: 1000 * 60 * 15  }, /*  15 min cache for component */
    '/tecdoc-car-bus/car-selection': { max: 1, handles: {}, handleKeys: [], handleTimeout: 1000 * 60 * 15  }, /*  15 min cache for component */
    '/tecdoc/product-selection': { max: 1, handles: {}, handleKeys: [], handleTimeout: 1000 * 60 * 15  }, /*  15 min cache for component */
    '/profile/order-history': { max: 1, handles: {}, handleKeys: [], handleTimeout: 1000 * 60 * 15  }, /*  15 min cache for component */
  };

  /** Determines if this route (and its subtree) should be detached to be reused later */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return !!this.routes[getConfiguredUrl(route)];
  }

  private getStoreKey(route: ActivatedRouteSnapshot) {
    const baseUrl = getResolvedUrl(route);

    // this works, as ActivatedRouteSnapshot has only every one children ActivatedRouteSnapshot
    // as you can't have more since urls like `/project/1,2` where you'd want to display 1 and 2 project at the
    // same time
    const childrenParts = [];
    let deepestChild = route;
    while (deepestChild.firstChild) {
      deepestChild = deepestChild.firstChild;
      childrenParts.push(deepestChild.url.join('/'));
    }

    // it's important to separate baseUrl with childrenParts so we don't have collisions.
    return baseUrl + '' + childrenParts.filter(v => v).join('/');
  }

  /**
   * Stores the detached route.
   *
   * Storing a `null` value should erase the previously stored value.
   */
  store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle | null
  ): void {
    if (route.routeConfig) {
      const config = this.routes[getConfiguredUrl(route)];
      if (config) {
        const storeKey = this.getStoreKey(route);
        if (handle) {
          if (!config.handles[storeKey]) {
            // add new handle
            if (config.handleKeys.length >= config.max) {
              this.removeRoute(config);
            }
            config.handles[storeKey] = handle;
            config.handleKeys.push(storeKey);
          }
          config.whenHandleInserted = Date.now();
        } else {
          // we do not delete old handles on request, as we define when the handle dies
        }
      }
    }
  }

  /** Determines if this route (and its subtree) should be reattached */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    if (route.routeConfig) {
      const config = this.routes[getConfiguredUrl(route)];

      if (config) {
        if (config.handleTimeout && config.whenHandleInserted) {
          if (config.whenHandleInserted + config.handleTimeout < Date.now()) {
            return false;
          }
        }
        const storeKey = this.getStoreKey(route);

        if (!!config.handles[storeKey]) {
          if (config && config.handles) {
            const reusableRouteInstance = (<OnShouldReuseRoute> config.handles[storeKey]['componentRef'].instance);
            if (reusableRouteInstance && reusableRouteInstance.onShouldReuseRoute) {
              const shouldReuse = reusableRouteInstance.onShouldReuseRoute();
              if (!shouldReuse) {
                this.removeRoute(config);
              }
              return shouldReuse;
            }
          }
        } else {
          // new handle will be created, we need to check if cach will not overflow
          while (config.handleKeys.length >= config.max) {
            this.removeRoute(config);
          }
        }

        return !!config.handles[storeKey];
      }
    }

    return false;
  }

  /** Remove route */
  removeRoute(config: RouteStates) {
    const oldestUrl = config.handleKeys[0];
    config.handleKeys.splice(0, 1);

    // this is important to work around memory leaks, as Angular will never destroy the Component
    // on its own once it got stored in our router strategy.
    const oldHandle = config.handles[oldestUrl] as {
      componentRef: ComponentRef<any>;
    };
    oldHandle.componentRef.destroy();
    delete config.handles[oldestUrl];
  }
  /** Retrieves the previously stored route */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    if (route.routeConfig) {
      const config = this.routes[getConfiguredUrl(route)];

      if (config) {
        const storeKey = this.getStoreKey(route);
        if (config.handles[storeKey]) {
          if (!config.handles[storeKey]['componentRef']) {
            Sentry.captureMessage('ReuseStrategy componentRef not found. Should investigate. _M_RT_EMAIL_');
            console.error('_M_RT_EMAIL_');
          }
          // FIXME: This causes it run 3x, but this method is called from base class...
          // const reusableRouteInstance = (<OnShouldReuseRoute> config.handles[storeKey]['componentRef'].instance);
          // if (reusableRouteInstance && reusableRouteInstance.onShouldReuseRoute) {
          //   console.warn("run retrieve onShouldReuseRoute", config);
          //   reusableRouteInstance.onShouldReuseRoute();
          // }
        }
        // (<OnRouteReuse>route.component).onRouteReuse();
        return config.handles[storeKey];
      }
    }

    return null;
  }

  /** Determines if `curr` route should be reused */
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    const shouldReuse = (
      getResolvedUrl(future) === getResolvedUrl(curr) &&
      future.routeConfig === curr.routeConfig
    );
    return shouldReuse;
  }
}
