import {
  Component, ElementRef, EventEmitter,
  forwardRef,
  HostListener, Input, Output, ViewChild,
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { BaseInputComponent } from '@shared/ui/input/base-input.component';

/**
 * @whatItDoes Returns input ui component.
 */
@Component({
  selector: 'app-input-with-autocomplete',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // Unavoidable ref in component
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => InputWithAutocompleteComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      // Unavoidable ref in component
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => InputWithAutocompleteComponent),
      multi: true
    }
  ]
})
export class InputWithAutocompleteComponent extends BaseInputComponent {
  /**
   * Accepts any input type, defaults to `text`
   * @type {string}
   */
  @Input() type = 'text';

  /** Allows custom options (e.g. search input styling) */
  @Input() special: string;

  /** attr.aria-label (optional) */
  @Input() label: string;

  /** Form control name */
  @Input() name: string;

  /**
   * Placeholder (optional
   * @type {string}
   */
  @Input() placeholder = '';

  /** Min value length */
  @Input() minLength: number;

  /**
   * Disabled attribute (optional)
   * @type {boolean}
   */
  @Input() disabledState = false;

  /**
   * Hide input (optional)
   * @type {boolean}
   */
  @Input() hiddenState = false;

  /**
   * Margin bottom (optional)
   * @type {boolean}
   */
  @Input() marginBottom = true;

  /**
   * Default input autocomplete status (optional)
   * @type {boolean}
   */
  @Input() defaultAutocomplete = true;

  /** Autocomplete data from parent (optional) */
  @Input() autocompleteData: any;

  /** Input focus (optional) */
  @Input() focus = false;

  /**
   * Input value reset (optional)
   * @type {boolean}
   */
  @Input() reset = false;

  /**
   * Input highlight status.
   * @type {boolean}
   */
  @Input() highlight = false;

  /** Get native input element ref */
  @ViewChild('input', { static: false }) vc: ElementRef;

  /**
   * Value change event emitter (optional).
   * @type {EventEmitter}
   */
  @Output() readonly autocompleteChange = new EventEmitter<string>();

  /**
   * Value change event emitter (optional).
   * @type {EventEmitter}
   */
  @Output() readonly autocomplete2Change = new EventEmitter<string>();

  /**
   * Value change event emitter (optional).
   * @type {EventEmitter}
   */
  @Output() readonly selectedAutocompleteItemEvent = new EventEmitter();

  /**
   * Close auto complete when click outside.
   * @param $event
   */
  @HostListener('document:click', ['$event']) clickedOutside($event) {
    if (this.element && this.element.nativeElement && !this.element.nativeElement.contains($event.target)) {
      this.autocompleteData = null;
    }
  }
}
