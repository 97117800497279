import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '@core/footer/containers/footer/footer.component';
import { FooterContactsListComponent } from '@core/footer/components/footer-contacts-list/footer-contacts-list.component';
import { FooterContactsListOtherComponent } from '@core/footer/components/footer-contacts-list-other/footer-contacts-list-other.component';
import { ContactsService } from '@core/service/contacts.service';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, SharedModule, RouterModule],
  exports: [FooterComponent, FooterContactsListComponent, FooterContactsListOtherComponent],
  declarations: [FooterComponent, FooterContactsListComponent, FooterContactsListOtherComponent],
  providers: [ContactsService]
})
export class FooterModule {}
