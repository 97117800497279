import { Order } from '@core/models/order';
import {
  OrderActions,
  OrderActionTypes
} from '@core/store/actions/order.action';

export interface OrderState {
  data: Order[];
  loaded: boolean;
  loading: boolean;
  error: string;
}

export const initialState: OrderState = {
  data: null,
  loaded: false,
  loading: false,
  error: null
};

export function reducer(
  state = initialState,
  action: OrderActions
): OrderState {
  switch (action.type) {
    case OrderActionTypes.OrderLoad: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case OrderActionTypes.OrderLoadSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        data: action.payload,
        error: null
      };
    }
    case OrderActionTypes.OrderLoadFail: {
      return {
        loading: false,
        loaded: false,
        data: null,
        error: action.payload
      };
    }

    case OrderActionTypes.CreateOrder: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case OrderActionTypes.CreateOrderSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        data: action.payload,
        error: null
      };
    }
    case OrderActionTypes.CreateOrderFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.payload
      };
    }

    case OrderActionTypes.UpdateOrder: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case OrderActionTypes.UpdateOrderSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        data: action.payload,
        error: null
      };
    }
    case OrderActionTypes.UpdateOrderFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };
    }

    case OrderActionTypes.UpdateOrderStatus: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case OrderActionTypes.UpdateOrderStatusSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        data: action.payload,
        error: null
      };
    }
    case OrderActionTypes.UpdateOrderStatusFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.payload
      };
    }

    case OrderActionTypes.UpdateOrderMany: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case OrderActionTypes.UpdateOrderManySuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        data: action.payload,
        error: null
      };
    }
    case OrderActionTypes.UpdateOrderManyFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload
      };
    }

    case OrderActionTypes.UpdateOrderStatusMany: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case OrderActionTypes.UpdateOrderStatusManySuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        data: action.payload,
        error: null
      };
    }
    case OrderActionTypes.UpdateOrderStatusManyFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.payload
      };
    }

    case OrderActionTypes.ResetOrdersState: {
      return {
        data: null,
        loaded: false,
        loading: false,
        error: null
      };
    }

    default: {
      return state;
    }
  }
}

export const getOrder = (state: OrderState) => state.data;
export const getOrderLoaded = (state: OrderState) => state.loaded;
export const getOrderLoading = (state: OrderState) => state.loading;
