import { Injectable } from '@angular/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap, debounceTime, distinct, delay, concatAll, toArray } from 'rxjs/operators';

import * as fromServices from '../services/cart.service';
import * as cartActions from '../actions/cart.action';
import { of, EMPTY } from 'rxjs';
import { UserOperation } from '@app/shared/constants/user-operations';

@Injectable()
export class CartEffects {

  @Effect() loadCart$ = this.actions$.pipe(
    ofType(cartActions.CartActionTypes.CartLoad),
    map((action: cartActions.LoadCart) => ({ with_expired: action.with_expired })),
    debounceTime(50),
    mergeMap(({with_expired}) => this.cartService.getCart(with_expired).pipe(
      map(data => new cartActions.LoadCartSuccess(data)),
      catchError(error => of(new cartActions.LoadCartFail(error)))
    ))
  );

  @Effect() addProduct$ = this.actions$.pipe(
    ofType(cartActions.CartActionTypes.AddProduct),
    map((action: cartActions.AddProduct) => ({ product: action.payload, acknowledged: action.acknowledged, oldQuantity: action.oldQuantity })),
    mergeMap(({product, acknowledged, oldQuantity}) => {
      let info = '';
      if (UserOperation.shouldPost) {
        info = JSON.stringify({
          id: UserOperation.TYPE,
          query: UserOperation.query,
        });
      }
      // FIXME: add type to product, as params merge with any produces any
      return this.cartService.addToCart({...product, additional_info: info }, acknowledged).pipe(
        map(productItem => new cartActions.AddProductSuccess(productItem, product, oldQuantity)),
        catchError(error => of(new cartActions.AddProductFail(error)))
      );
    })
  );

  @Effect() addProducts$ = this.actions$.pipe(
    ofType(cartActions.CartActionTypes.AddProducts),
    map((action: cartActions.AddProducts) => action.payload),
    map(action => action.map((update) => {
      let info = '';
      if (UserOperation.shouldPost) {
        info = JSON.stringify({
          id: UserOperation.TYPE,
          query: UserOperation.query,
        });
      }
      return this.cartService.addToCart({...update.update, additional_info: info }, update.acknowledged).pipe(
        delay(500),
        map(res => ({
          cart: res as any,
          error: false,
          data: undefined,
          update: update,
        })),
        catchError(error => of({ cart: undefined, error: true, data: error, update: update }))
      );
    })),
    mergeMap(obs => of(...obs).pipe(
      concatAll(),
      toArray(),
    )),
    map(results => new cartActions.AddProductsResult(results))
  );

  @Effect() updateProduct$ = this.actions$.pipe(
    ofType(cartActions.CartActionTypes.UpdateProduct),
    map((action: cartActions.UpdateProduct) => ({ update: action.update, skn: action.skn, product: action.product })),
    mergeMap(({update, skn, product}) => {
      let info = '';
      if (UserOperation.shouldPost) {
        info = JSON.stringify({
          id: UserOperation.TYPE,
          query: UserOperation.query,
        });
      }
      return this.cartService.updateCart(update.id, {...update, additional_info: info}).pipe(
        map(productItem => new cartActions.UpdateProductSuccess(productItem, skn, product, update)),
        catchError(error => of(new cartActions.UpdateProductFail(error)))
      );
    })
  );

  @Effect() updateProducts$ = this.actions$.pipe(
    ofType(cartActions.CartActionTypes.UpdateProducts),
    map((action: cartActions.UpdateProducts) => action.update),
    map(action => action.map((update) => this.cartService.updateCart(update.update.id, update.update).pipe(
      delay(500),
      map(res => ({
        cart: res as any,
        error: false,
        data: undefined,
        update: update,
      })),
      catchError(error => of({ cart: undefined, error: true, data: error, update: update }))
    ))),
    mergeMap(obs => of(...obs).pipe(
      concatAll(),
      toArray(),
    )),
    map(results => new cartActions.UpdateProductsResult(results))
  );

  @Effect() deleteProduct$ = this.actions$.pipe(
    ofType(cartActions.CartActionTypes.RemoveProduct),
    map((action: cartActions.RemoveProduct) => ({ product: action.payload })),
    distinct(),
    mergeMap(({product}) => this.cartService.removeFromCart(product.id).pipe(
      map(cart => new cartActions.RemoveProductSuccess(cart, product.skn)),
      catchError(error => of(new cartActions.RemoveProductFail(error)))
    ))
  );

  @Effect() deleteExpiredProduct$ = this.actions$.pipe(
    ofType(cartActions.CartActionTypes.RemoveExpiredProduct),
    map((action: cartActions.RemoveExpiredProduct) => ({ product: action.payload, silenced: action.silenced })),
    distinct(),
    mergeMap(({ product, silenced }) => this.cartService.removeFromCartExpired(product.id).pipe(
      map(cart => {
        if (silenced) {
          return EMPTY;
        } else {
          return new cartActions.RemoveExpiredProductSuccess(cart, product.skn);
        }
      }),
      catchError(error => {
        if (silenced) {
          return EMPTY;
        } else {
          return of(new cartActions.RemoveExpiredProductFail(error));
        }
      })
    ))
  );

  constructor(
    private actions$: Actions,
    private cartService: fromServices.CartService
  ) {}
}
