import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Observable, Subscription, Subject } from 'rxjs';

import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { fadeIn, fadeInDown } from '@shared/animations';
import { CartItem, CartItemsForm, CartItemFormItem } from '@pages/cart/models/cart.model';
import * as fromCart from '@app/pages/cart/store';
import * as fromRoot from '@app/reducers';
import { select, Store, ActionsSubject } from '@ngrx/store';
import * as fromStore from '@pages/cart/store';
import { Router } from '@angular/router';
import { AppState } from '@app/reducers';
import { environment } from '@environments/environment';
import { TranslationService } from '@core/service/translation.service';
import * as fromAuth from '@app/auth/store';
import { IUser, IUserClientReference } from '@app/auth/models/user';
import { NoteDeliverySelector, NOTE_DELIVERY_CONDITIONS } from '@app/pages/users/models/users.model';
import { CartItemUpdate, CartItemUpdateRequest } from '@app/shared/models/product.model';
import { debounceTime } from 'rxjs/operators';
import { CartHelperService } from '@app/pages/cart/services/carthelper.service';

@Component({
  selector: 'app-cart-list-mini',
  templateUrl: './cart-list-mini.component.html',
  styleUrls: ['./cart-list-mini.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn, fadeInDown]
})
export class CartListMiniComponent implements OnInit, OnDestroy, OnChanges {
  base_url = environment.api_url;

  /** Cart products */
  @Input() productsFrom: CartItem[] = [];

  /** Cart note update initalization */
  @Input() updateProductNotes: Subject<void>;

  products: CartItem[] = [];

  @Output() readonly productsUpdated = new EventEmitter();

  existNotUpdatedProduct = false;
  @Output() readonly existNotUpdatedProductChange = new EventEmitter<boolean>();
  @Output() readonly productRemoved = new EventEmitter<boolean>();
  @Output() readonly selectOpen = new EventEmitter<boolean>();
  /**
   * Cart form group.
   * @type {FormGroupTyped<CartItemsForm>}
   */
  cartForm: FormGroupTyped<CartItemsForm>;
  cartFormProductsFA: FormArrayTyped<CartItemFormItem> = new FormArray([]);

  isCartSaving = false;
  cartLoading$: Observable<boolean>;
  cartLoadingSub: Subscription;

  productLoadTimeout: any;
  updateProductNotesSub: Subscription;
  cartValuesChangeSub: Subscription;
  user: IUser;

  noteDeliveryValues: NoteDeliverySelector[];
  noteDeliveryValuesCentral: NoteDeliverySelector[];

  readonly productsUpdatedEvent = new EventEmitter<CartItem[]>();
  readonly productsUpdatedEventObservable = this.productsUpdatedEvent.asObservable();
  productsUpdatedEventObservableSub: Subscription;
  updateProductsSuccessSub: Subscription;

  showUserClientRef = false;
  userClientRefValues: IUserClientReference[] = [];
  userClientRefObject: {[id: number]: IUserClientReference} = {};

  userSub: Subscription;
  removeStateSub: Subscription;
  productFormSubs: { [key: number]: Subscription } = {};

  showPricesWithVat: boolean = false;
  showFifoStocks: boolean = false;
  updateProductsFifoSuccessSub: Subscription;
  cartHelperService: CartHelperService;

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private fb: FormBuilder,
    private translationService: TranslationService,
    private store: Store<AppState>,
    private ref: ChangeDetectorRef,
    private actionsSubject: ActionsSubject,
    ) {
    this.cartHelperService = new CartHelperService(translationService, store, actionsSubject);
    this.cartHelperService.registerComponent(this, 'cart-list-mini');
    this.cartLoading$ = this.store.pipe(select(fromRoot.getCartLoading));
    this.initNDO(null);
  }

  showLocalPrice: boolean;

  ngOnInit(): void {
    this.cartLoadingSub = this.cartLoading$.subscribe((loading) => {
      if (loading) {
        this.isCartSaving = true;
      } else {
        this.isCartSaving = false;
      }
    });
    this.userSub = this.store.pipe(select(fromAuth.getUser)).subscribe(user => {
      if (user && user.user_settings) {
        this.user = user;
      }
      if (user && user.user_settings && user.user_settings.show_local_currency) {
        this.showLocalPrice = user.user_settings.show_local_currency;
      }
      if (user && user.profile) {
        this.showPricesWithVat = user.profile.show_prices_with_vat;
      }
      if (user && user.profile.user_field_values && (user.profile.note_choice_on_add_cart || user.profile.company.note_choice_on_add_cart)) {
        this.initNDO(user);
      }
      if (user && (user.profile.company.hide_cards_in_stocks || user.user_settings.hide_cards_in_stocks)) {
        this.showFifoStocks = true;
      }
      this.initClientReferences(user);

      if (this.updateProductNotesSub) { this.updateProductNotesSub.unsubscribe(); }
      this.updateProductNotesSub = this.updateProductNotes.subscribe(() => {
        this.saveAllProducts();
      });

      if (this.productsUpdatedEventObservableSub) { this.productsUpdatedEventObservableSub.unsubscribe(); }
      this.productsUpdatedEventObservableSub = this.productsUpdatedEventObservable
        .pipe(debounceTime(200))
        .subscribe(productsFrom => {
          this.initProducts();
        });
      this.initForm();
      if (this.showFifoStocks) {
        if (this.updateProductsFifoSuccessSub) { this.updateProductsFifoSuccessSub.unsubscribe(); }
        this.updateProductsFifoSuccessSub = this.cartHelperService.registerProductUpdateFifoProcessing();
      } else {
        // Called when products were updated
        if (this.updateProductsSuccessSub) { this.updateProductsSuccessSub.unsubscribe(); }
        this.updateProductsSuccessSub = this.cartHelperService.registerProductUpdateProcessing();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.productsFrom) {
      this.productsUpdatedEvent.emit(changes.productsFrom.currentValue);
    }
  }

  trackByFn(index, item: CartItem) {
    return `${item.card}-${item.is_central}-${item.warehouse}-${item.note}-${item.ndo}`;
    // return index; // or item.id
  }

  showUpdateSummary(previousValue: CartItem[], currentValue: CartItem[]) {
    // FIXME: for now we disable this notifications, as it does not work as expected.
    // const cartItem = this.cartForm.get('products').get(index.toString());
    // if (cartItem) {
    //   const quantity = cartItem.get<number>('quantity');
    //   const card = cartItem.get('card');

    //   this.products.map(product => {
    //     if (card.value === product.card) {
    //       // If cart returns smaller stock quantity.
    //       if (+quantity.value !== +product.quantity) {
    //         this.store.dispatch(
    //           new toast.ShowWarningToast(
    //             this.translationService.translate('PRODUCT_QUANTITY_NOT', {
    //               name: `${product.name} (${product.card})`
    //             })
    //           )
    //         );
    //         quantity.setValue(+product.quantity);
    //       }
    //     }
    //   });
    // }
  }

  initClientReferences(user) {
    if (user && user.profile.selected_client_references.length > 0) {
      this.userClientRefValues = [{ client: user.profile.client }, ...user.profile.selected_client_references];
      this.userClientRefObject = this.userClientRefValues.reduce((a, value) => {
        a[value.client.id] = value;
        return a;
      }, {});
      this.showUserClientRef = true;
      this.ref.markForCheck();
    }
  }

  initNDO(loggedInUser: IUser) {
    this.noteDeliveryValues =
      NOTE_DELIVERY_CONDITIONS
      .filter(ndo => !ndo.showOnlyOnCentral)
      .map(ndo => ({
        label: this.translationService.translate(ndo.label),
        value: ndo.formName,
        isDefault: ndo.isDefault,
      }));
    this.noteDeliveryValuesCentral =
      NOTE_DELIVERY_CONDITIONS
      .map(ndo => ({
        label: this.translationService.translate(ndo.label),
        value: ndo.formName,
        isDefault: ndo.isDefault,
      }));
    if (loggedInUser && loggedInUser.profile.user_field_values && (loggedInUser.profile.note_choice_on_add_cart || loggedInUser.profile.company.note_choice_on_add_cart)) {
      this.noteDeliveryValues = this.noteDeliveryValues
      .filter(ndo => {
        if (loggedInUser.profile.user_field_values.filter(ufv => ufv.key === ndo.value).length) {
          return true;
        }
        return false;
      });
      this.noteDeliveryValuesCentral = this.noteDeliveryValuesCentral
      .filter(ndo => {
        if (loggedInUser.profile.user_field_values.filter(ufv => ufv.key === ndo.value).length) {
          return true;
        }
        return false;
      });
      // this.ref.detectChanges();
    }
  }
  initForm(): void {
    if (this.productLoadTimeout) {
      clearTimeout(this.productLoadTimeout);
    }
    this.productLoadTimeout = setTimeout(() => {
      const products: FormArrayTyped<CartItemFormItem> = new FormArray([]);
      this.cartFormProductsFA = products;
      this.cartForm = new FormGroup({
        products: products
      }) as FormGroupTyped<CartItemsForm>;
      this.initProducts();
      if (!this.cdRef['destroyed']) {
        this.cdRef.detectChanges();
      } else {
        // TODO: this is called... we need to check that this would never be called....
        // console.debug('actions on destrayed object');
      }
    });
  }

  initProducts(): void {
    this.setProducts(this.productsFrom);
    for (const key in this.productFormSubs) {
      if (this.productFormSubs.hasOwnProperty(key)) {
        if (this.productFormSubs[key]) { this.productFormSubs[key].unsubscribe(); }
      }
    }
    this.removeProductFormGroups();
    this.products.map((product, index) => {
      this.cartFormProductsFA.push(
        this.fb.group({
          id: +product.id,
          name: product.name,
          card: product.card,
          weight: product.weight,
          height: product.height,
          dimensions: product.dimensions,
          picture_url: product.picture_url,
          skn: product.skn,
          product_id: product.product_id,
          manufacturer_name: product.manufacturer_name,
          sub_group_name: product.sub_group_name,
          // price: product.price,
          quantity: +product.quantity,
          note: [product.note, Validators.maxLength(26)],
          ndo: [product.ndo],
          client_ref_id: [product.client_ref_id],
          checked: false,
          warehouse: product.warehouse,
          is_central: product.is_central,
          on_the_way: product.on_the_way,
          is_carried: product.is_carried,
        })
      );
    });
    this.updateExistNotUpdatedProduct();
    this.cartFormProductsFA.controls.map((control: AbstractControlTyped<CartItemFormItem>, index) => {
      if (this.productFormSubs[index]) {
        this.productFormSubs[index].unsubscribe();
      }
      this.productFormSubs[index] = control
        .valueChanges
        .pipe(debounceTime(100))
        .subscribe((productFormValue => {
        this.products[index].are_clean_fields = true;
        this.products[index].are_clean_field_note = true;
        if (
          productFormValue.quantity !== this.products[index].quantity ||
          productFormValue.ndo !== this.products[index].ndo ||
          productFormValue.client_ref_id !== this.products[index].client_ref_id
          ) {
          this.products[index].are_clean_fields = false;
          control.markAsTouched();
        }
        if (productFormValue.note !== this.products[index].note) {
          this.products[index].are_clean_fields = false;
          this.products[index].are_clean_field_note = false;
          control.markAsTouched();
        }

        this.updateExistNotUpdatedProduct();

        this.ref.markForCheck();
      }));
    });
  }
  updateExistNotUpdatedProduct() {
    setTimeout(() => {
      this.existNotUpdatedProduct = this.setExistNotUpdatedProduct();
    });
  }
  private removeProductFormGroups() {
    while (this.cartFormProductsFA.controls.length > 0) {
      this.cartFormProductsFA.removeAt(
        this.cartFormProductsFA.controls.length - 1
      );
    }
  }

  setProducts(products: CartItem[]) {
    if (this.showFifoStocks) {
      this.products = this.cartHelperService.getProductsByBrandForCart(products);
    } else {
      this.products = this.cartHelperService.getProductsByCardForCart(products);
    }
  }

  getUserClientSelectorValue(id) {
    const clientRef = this.userClientRefObject[id];
    if (clientRef) {
      return `${clientRef.client.skuba_client_id} - ${clientRef.client.name}`;
    }
    return id;
  }

  updateProduct(cartItemFormItem: CartItemFormItem, index: number): void {
    if (this.cartFormProductsFA
      .at(index)
      .get<string>('note')
      .hasError('maxlength')) {
        return;
      }
    const data: CartItemUpdate = {
      id: +cartItemFormItem.id,
      product_id: +cartItemFormItem.product_id,
      card_number: +cartItemFormItem.card,
      warehouse_code: cartItemFormItem.warehouse,
      quantity: +cartItemFormItem.quantity,
      note: cartItemFormItem.note,
      ndo: cartItemFormItem.ndo,
      client_ref_id: cartItemFormItem.client_ref_id,
      is_central: cartItemFormItem.is_central,
      is_carried: cartItemFormItem.is_carried,
      on_the_way: cartItemFormItem.on_the_way,
    };

    if (this.showFifoStocks) {
      const currentStock = this.products.filter(cartItem => {
        return cartItem.id == cartItemFormItem.id;
      })[0];
      data.skn = currentStock.skn;
      data.deposit = currentStock.deposit;
      data.brand = currentStock.brand;
      data.quantity -= currentStock.quantity;
      data.oldQuantity = currentStock.quantity;
    }
    this.store.dispatch(new fromStore.UpdateProducts([{
      update: data,
      skn: cartItemFormItem.skn,
      product: null,
      matchId: 'cart-list-mini',
    }]));
  }

  removeProduct(product: CartItem): void {
    this.store.dispatch(new fromCart.RemoveProduct(product));
    this.productRemoved.emit(true);
  }

  viewProduct(skn: string): void {
    this.router.navigate([`/search/${skn}`]);
  }

  setExistNotUpdatedProduct(): boolean {
    for (let i = 0; i < this.products.length; i++) {
      if (
        this.products[i] &&
        !this.products[i].are_clean_fields
      ) {
        this.existNotUpdatedProductChange.emit(true);
        return true;
      }
    }
    this.existNotUpdatedProductChange.emit(false);
    return false;
  }

  saveAllProducts() {
    if (this.existNotUpdatedProduct) {
      const savedProducts = [];
      const productsToUpdate: CartItemUpdateRequest[] = [];
      for (let i = 0; i < this.products.length; i++) {
        const product = this.products[i];
        if (
          product &&
          !product.are_clean_fields
        ) {
          savedProducts.push(product);
          const cartItemFormItem = this.cartForm.value.products[i];
          const data: CartItemUpdate = {
            id: +product.id,
            product_id: +product.product_id,
            card_number: +product.card,
            warehouse_code: product.warehouse,
            quantity: +cartItemFormItem.quantity,
            note: cartItemFormItem.note,
            ndo: cartItemFormItem.ndo,
            client_ref_id: cartItemFormItem.client_ref_id,
            is_central: product.is_central,
            is_carried: product.is_carried,
            on_the_way: product.on_the_way,
          };
          if (this.showFifoStocks) {
            data.skn = product.skn;
            data.deposit = product.deposit;
            data.brand = product.brand;
            data.quantity -= product.quantity;
            data.oldQuantity = product.quantity;
          }
          productsToUpdate.push({ update: data, skn: product.skn, product, matchId: 'cart-list-mini', });
          this.existNotUpdatedProduct = true;
          this.existNotUpdatedProductChange.emit(true);
        }
      }
      if (savedProducts.length <= 0) {
        this.existNotUpdatedProduct = false;
        this.existNotUpdatedProductChange.emit(false);
      } else {
        this.store.dispatch(
          new fromStore.UpdateProducts(
            productsToUpdate.map(ptu => ({
              update: ptu.update,
              skn: ptu.skn,
              product: null,
              matchId: 'cart-list-mini',
            }))
          )
        );
      }
    }
  }

  selecting(selectOpen: boolean) {
    this.selectOpen.emit(selectOpen);
  }

  validateQuantity(index: number, product: CartItem) {
    let inputNum = +this.cartFormProductsFA.at(index).get<number>('quantity').value;
    if (!Number.isNaN(inputNum)) {
      if (inputNum < 0) {
        inputNum = 0;
      }
      if (inputNum % product.increment !== 0 || inputNum === 0) {
        let roundedQuantity = inputNum + product.increment - (inputNum % product.increment);
        if (roundedQuantity < product.increment) {
          roundedQuantity = product.increment;
        }
        this.cartFormProductsFA
          .at(index)
          .get<number>('quantity')
          .setValue(roundedQuantity);
      }
    } else {
      this.cartFormProductsFA
        .at(index)
        .get<number>('quantity')
        .setValue(product.increment);
    }
  }
  /**
   *  This is for saving cart on real-time changes
   */
  // updateCartOnChanges(changes) {
  //   for (let index in changes.products) {
  //     if (this.products && this.products[index]) {

  //       const product: CartItem = this.products[index];

  //       if (changes.products[index].quantity && product.quantity !== changes.products[index].quantity) {
  //         product.quantity = changes.products[index].quantity;
  //         this.updateProduct(changes.products[index], Number(index), true);
  //       }

  //       else if (changes.products[index].ndo && product.ndo !== changes.products[index].ndo) {
  //         product.ndo = changes.products[index].ndo;
  //         this.updateProduct(changes.products[index], Number(index), true);
  //       }

  //       else if (changes.products[index].client_ref_id && product.client_ref_id !== changes.products[index].client_ref_id) {
  //         product.client_ref_id = changes.products[index].client_ref_id;
  //         this.updateProduct(changes.products[index], Number(index), true);
  //       }

  //       else if (changes.products[index].note !== undefined
  //                && changes.products[index].note.length >= 0
  //                && product.note !== changes.products[index].note) {

  //         clearTimeout(this.noteSetTimeout);
  //         const controlNote = (<FormArray>this.cartForm.controls['products']).controls[index.toString()].controls['note'];

  //         this.noteSetTimeout = setTimeout(() => {
  //           // controlNote.disable();
  //           product.note = changes.products[index].note;
  //           this.updateProduct(changes.products[index], Number(index), true);

  //           if (this.cartLoadingSub2) {this.cartLoadingSub2.unsubscribe()}
  //           this.cartLoadingSub2 = this.cartLoading$.subscribe((loading) => {
  //             if (loading) {
  //               controlNote.disable({emitEvent:false});
  //             } else {
  //               controlNote.enable({emitEvent:false});
  //               this.cartLoadingSub2.unsubscribe();
  //             }
  //           });
  //           // controlNote.enable();
  //         }, 500);

  //       }
  //     }
  //   }
  // }
  ngOnDestroy(): void {
    if (this.updateProductsSuccessSub) { this.updateProductsSuccessSub.unsubscribe(); }
    if (this.updateProductsFifoSuccessSub) { this.updateProductsFifoSuccessSub.unsubscribe(); }
    if (this.userSub) { this.userSub.unsubscribe(); }
    if (this.removeStateSub) { this.removeStateSub.unsubscribe(); }
    if (this.updateProductNotesSub) { this.updateProductNotesSub.unsubscribe(); }
    if (this.cartValuesChangeSub) { this.cartValuesChangeSub.unsubscribe(); }
    if (this.productsUpdatedEventObservableSub) { this.productsUpdatedEventObservableSub.unsubscribe(); }
    for (const key in this.productFormSubs) {
      if (this.productFormSubs.hasOwnProperty(key)) {
        if (this.productFormSubs[key]) { this.productFormSubs[key].unsubscribe(); }
      }
    }
  }
}
