import { createSelector } from '@ngrx/store';

import * as fromShowcase from '../reducers/showcase.reducer';
import * as fromFeature from '../reducers';

export const getShowcaseState = createSelector(
  fromFeature.getDashboardState,
  (state: fromFeature.ShowcaseState) => state.showcase
);

export const getShowcaseItems = createSelector(
  getShowcaseState,
  fromShowcase.getShowcaseItems
);

export const getShowcaseEntities = createSelector(
  getShowcaseState,
  fromShowcase.getShowcaseEntities
);

export const getShowcaseLoaded = createSelector(
  getShowcaseState,
  fromShowcase.getShowcaseLoaded
);
