<div class="app-breadcrumbs">
  <div class="base-container inner-floating-container">
    <div class="breadcrumbs" *ngIf="items">
      <div><a routerLink="/dashboard" class="breadcrumb">{{'HOME' | translate}}</a></div>
      <div *ngFor="let breadcrumb of items" class="breadcrumb">
        <span>></span> <a [routerLink]="getBreadcrumbs(breadcrumb)" [@fadeIn]>{{breadcrumb.label | translate}}</a>
      </div>
    </div>
  </div>
</div>
