/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./register-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/ng-zorro-antd/spin/ng-zorro-antd-spin.ngfactory";
import * as i3 from "ng-zorro-antd/spin";
import * as i4 from "ng-zorro-antd/core";
import * as i5 from "../../components/register/register-form.component.ngfactory";
import * as i6 from "../../components/register/register-form.component";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/router";
import * as i9 from "../../../shared/services/shop.service";
import * as i10 from "@ngrx/store";
import * as i11 from "../../../shared/services/helper.service";
import * as i12 from "../../../core/local-storage/local-storage.service";
import * as i13 from "../../../core/service/translation.service";
import * as i14 from "../../../core/api.service";
import * as i15 from "./register-page.component";
import * as i16 from "../../../shared/ui/select/pipes/select.pipe";
import * as i17 from "../../../pages/users/services/users.service";
var styles_RegisterComponent = [i0.styles];
var RenderType_RegisterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegisterComponent, data: {} });
export { RenderType_RegisterComponent as RenderType_RegisterComponent };
export function View_RegisterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "nz-spin", [], [[2, "ant-spin-nested-loading", null]], null, null, i2.View_NzSpinComponent_0, i2.RenderType_NzSpinComponent)), i1.ɵdid(1, 770048, null, 0, i3.NzSpinComponent, [i4.NzConfigService, i1.ChangeDetectorRef], { nzSpinning: [0, "nzSpinning"] }, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "app-skuba-register-form", [], null, [[null, "registrationSubmitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("registrationSubmitted" === en)) {
        var pd_0 = (_co.onSubmitRegistration($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_RegisterFormComponent_0, i5.RenderType_RegisterFormComponent)), i1.ɵdid(3, 770048, null, 0, i6.RegisterFormComponent, [i7.FormBuilder, i8.Router, i9.ShopService, i10.Store, i1.ElementRef, i11.HelperService, i12.LocalStorageService, i13.TranslationService, i14.ApiService], { countryPhoneCodesInitial: [0, "countryPhoneCodesInitial"], countriesIn: [1, "countriesIn"], companiesInitial: [2, "companiesInitial"], presetCompany: [3, "presetCompany"], backendError$: [4, "backendError$"] }, { registrationSubmitted: "registrationSubmitted" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isSaving; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.countryPhoneCodes; var currVal_3 = _co.countriesSelect; var currVal_4 = _co.companiesSelect; var currVal_5 = _co.presetCompanyValue; var currVal_6 = _co.error$; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = !i1.ɵnov(_v, 1).nzSimple; _ck(_v, 0, 0, currVal_0); }); }
export function View_RegisterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-skuba-register", [], null, null, null, View_RegisterComponent_0, RenderType_RegisterComponent)), i1.ɵdid(1, 114688, null, 0, i15.RegisterComponent, [i8.Router, i10.Store, i14.ApiService, i11.HelperService, i16.SelectPipe, i17.UsersService, i9.ShopService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegisterComponentNgFactory = i1.ɵccf("app-skuba-register", i15.RegisterComponent, View_RegisterComponent_Host_0, {}, {}, []);
export { RegisterComponentNgFactory as RegisterComponentNgFactory };
