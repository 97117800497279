import { ElementRef, EventEmitter, } from '@angular/core';
import { BaseInputComponent } from '@shared/ui/input/base-input.component';
/**
 * @whatItDoes Returns input ui component.
 */
export class InputWithAutocompleteComponent extends BaseInputComponent {
    constructor() {
        super(...arguments);
        /**
         * Accepts any input type, defaults to `text`
         * @type {string}
         */
        this.type = 'text';
        /**
         * Placeholder (optional
         * @type {string}
         */
        this.placeholder = '';
        /**
         * Disabled attribute (optional)
         * @type {boolean}
         */
        this.disabledState = false;
        /**
         * Hide input (optional)
         * @type {boolean}
         */
        this.hiddenState = false;
        /**
         * Margin bottom (optional)
         * @type {boolean}
         */
        this.marginBottom = true;
        /**
         * Default input autocomplete status (optional)
         * @type {boolean}
         */
        this.defaultAutocomplete = true;
        /** Input focus (optional) */
        this.focus = false;
        /**
         * Input value reset (optional)
         * @type {boolean}
         */
        this.reset = false;
        /**
         * Input highlight status.
         * @type {boolean}
         */
        this.highlight = false;
        /**
         * Value change event emitter (optional).
         * @type {EventEmitter}
         */
        this.autocompleteChange = new EventEmitter();
        /**
         * Value change event emitter (optional).
         * @type {EventEmitter}
         */
        this.autocomplete2Change = new EventEmitter();
        /**
         * Value change event emitter (optional).
         * @type {EventEmitter}
         */
        this.selectedAutocompleteItemEvent = new EventEmitter();
    }
    /**
     * Close auto complete when click outside.
     * @param $event
     */
    clickedOutside($event) {
        if (this.element && this.element.nativeElement && !this.element.nativeElement.contains($event.target)) {
            this.autocompleteData = null;
        }
    }
}
