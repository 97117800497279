/**
 * @module AppModule
 * @preferred
 */
import * as Sentry from '@sentry/browser';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule, ApplicationRef, Inject, NgZone, Injector } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en_gb from '@angular/common/locales/en-GB';

import './core/rxjs-extensions';
import { AppRoutingModule } from './/app-routing.module';
import { CoreModule } from './core/core.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { NgxGalleryModule } from 'ngx-gallery';
import { NgxPageScrollModule } from 'ngx-page-scroll';

import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule, Store } from '@ngrx/store';
import { metaReducers, reducers, CustomSerializer, AppState } from '@app/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';
import { AuthModule } from './auth/auth.module';
import { EffectsModule } from '@ngrx/effects';
import { AppComponent } from '@app/core/containers/app.component';
import { effects } from '@app/core/store/effects';
import { createNewHosts, createInputTransfer, removeNgStyles } from '@angularclass/hmr';
import { take } from 'rxjs/operators';
import { SearchByCarService } from '@shared/services/search-by-car.service';
import { RouteReuseStrategy } from '@angular/router';
import { DetailedReuseStrategy } from './shared/strategies/detailed-reuse.strategy';
import { HelperService } from './shared/services/helper.service';
import { NZ_I18N, en_GB, NZ_DATE_CONFIG } from 'ng-zorro-antd/i18n';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { CanDeactivateGuard } from './shared/services/can-deactivate-guard.service';
import { ServiceWorkerModule } from '@angular/service-worker';

registerLocaleData(en_gb);

export class RavenErrorHandler implements ErrorHandler {
  constructor(@Inject(NgZone) private ngZone: NgZone, @Inject(Injector) private injector: Injector) { }

  private get helpers(): HelperService {
      return this.injector.get(HelperService);
  }

  handleError(err: any): void {
    if (err && err.toString && err.toString().includes('Error: Loading chunk')) {
      this.ngZone.run(() => {
        this.helpers.showVersionErrorModal();
      });
    }
    let canPostToRaven = true;
    if (err && err.toString && err.toString().includes('Error: Loading chunk')) {
      canPostToRaven = false;
    } else if (err && err.toString && err.toString().includes('Signature has expired')) {
      canPostToRaven = false;
    } else if (err && err.toString && err.toString().includes('You do not have permission to')) {
      canPostToRaven = false;
    }
    if (environment.production && canPostToRaven) {
      Sentry.captureException(err);
    } else if (!environment.production) {
      console.error(err);
    }
  }
}

/**
 * @whatItDoes The root module class that is bootstraped by the `main.ts` file.
 * @see [Angular 2 docs - the application root module]
 * (https://angular.io/docs/ts/latest/guide/ngmodule.html#root-module)
 */
@NgModule({
  imports: [
    AppRoutingModule,
    CoreModule,
    AuthModule.forRoot(),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, { metaReducers, runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),

    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    /*
     They stateKey defines the name of the state used by the router-store reducer.
     This matches the key defined in the map of reducers
     */
    StoreRouterConnectingModule.forRoot(),

    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument({
      name: 'NgRx DevTools',
      logOnly: !environment.production
    }),

    EffectsModule.forRoot(effects),
    NgxGalleryModule,
    SharedModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPageScrollModule,
    NgZorroAntdModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true, registrationStrategy: 'registerImmediately' }),
  ],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: ErrorHandler, useClass: RavenErrorHandler },
    SearchByCarService,
    { provide: RouteReuseStrategy, useClass: DetailedReuseStrategy },
    { provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } },
    { provide: NZ_I18N, useValue: en_GB },
    [CanDeactivateGuard]
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public appRef: ApplicationRef, public store: Store<AppState>) {}

  hmrOnInit(store) {
    if (!store || !store.state) {
      return;
    }
    console.log('HMR store', store);
    console.log('store.state:', store.state);

    this.store.dispatch({
      type: 'SET_ROOT_STATE',
      payload: store.state
    });

    if ('restoreInputValues' in store) {
      const restore = store.restoreInputValues;
      setTimeout(() => restore(), 500);
    }
    // change detection
    this.appRef.tick();
    delete store.state;
    delete store.restoreInputValues;
  }

  hmrOnDestroy(store) {
    const cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
    // recreate elements
    store.disposeOldHosts = createNewHosts(cmpLocation);
    // inject your AppStore and grab state then set it on store
    this.store.pipe(take(1)).subscribe(s => (store.state = s));
    store.state = Object.assign({}, store.state);
    // save input values
    store.restoreInputValues = createInputTransfer();
    // remove styles
    removeNgStyles();
  }

  hmrAfterDestroy(store) {
    // display new elements
    store.disposeOldHosts();
    delete store.disposeOldHosts;
    // anything you need done the component is removed
  }
}
