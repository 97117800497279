<h3>{{'SEARCH_HISTORY' | translate}}</h3>
<div class="search-history-container" [ngStyle]="{'height.px': height - 244}">
  <div class="search-history">
    <div
      (click)="navigateTo(item.params)"
      class="sh-item"
      *ngFor="let item of history">
      <strong
        class="search-query"
        [title]="item.title"
      >{{item.title}}</strong>
      <span
        nz-popover
        nzType="primary"
        [nzContent]="searchQueryTemplate"
        [nzMouseEnterDelay]="0.01"
        [nzMouseLeaveDelay]="0.01"
        nzPopoverPlacement="right"
        style="display: inline;">
        {{item.params.number}}
        <i *ngIf="item.params.number" class="icon-st icon-truck svg-icon flaticon-frontal-truck"></i>
        <i class="material-icons material-pointer">info</i>
      </span>
      <ng-template #searchQueryTemplate>
        <span *ngIf="item.params.number">
          <span>{{'SEARCH_BY_CAR' | translate}}:</span>
          <strong>{{item.params.number}}</strong>
        </span>
        <app-checkbox
          class="search-checkbox"
          disabled="disabled"
          [val]="true"
          *ngIf="item.params.context_state"
          name="context_state"
          label="CONTEXT">
        </app-checkbox>
        <app-checkbox
        class="search-checkbox"
          disabled="disabled"
          [val]="true"
          *ngIf="item.params.codes_state"
          name="codes_state"
          label="OE_CODE">
        </app-checkbox>
        <app-checkbox
        class="search-checkbox"
          disabled="disabled"
          [val]="true"
          *ngIf="item.params.skn_state"
          name="skn_state"
          label="SKN_CODE">
        </app-checkbox>
        <app-checkbox
        class="search-checkbox"
          disabled="disabled"
          [val]="true"
          *ngIf="item.params.card_number_state"
          name="card_number_state"
          label="CARD_NR">
        </app-checkbox>
        <app-checkbox
        class="search-checkbox"
          disabled="disabled"
          [val]="true"
          *ngIf="item.params.tec_doc_state"
          name="tec_doc_state"
          label="TEC_DOC">
        </app-checkbox>
        <span *ngIf="item.params.sub_group">
          <span>{{'SUBGROUP' | translate}}:</span>
          <strong>
            {{item.params.sub_group}}
          </strong>
        </span>
      </ng-template>
    </div>
  </div>
</div>
