<div class="card">
  <div class="card-body text-center">
    <div class="align-center">
      <a href="/login" routerLink="/login">
        <img src="assets/images/logo-skuba.png" alt="{{'SKUBA_LOGO'| translate}}" class="home-logo form-logo">
      </a>
    </div>
    <h2>{{'FORGOT_RESET' | translate}}</h2>
    <form class="forgot-password-form"
          (ngSubmit)="submit(passwordResetForm)"
          [formGroup]="passwordResetForm"
          novalidate
          #formDir="ngForm">
      <app-input
        formControlName="new_password1"
        placeholder="NEW_PASSWORD"
        label="NEW_PASSWORD"
        type="password"
        [focus]="true">
        <app-input-validation
          className="error"
          message="REQUIRED"
          [condition]="(passwordResetForm.get('new_password1').touched || formDir.submitted) && passwordResetForm.get('new_password1').hasError('required')">
        </app-input-validation>
      </app-input>
      <app-input formControlName="new_password2"
                 placeholder="REPEAT_PASSWORD"
                 label="REPEAT_PASSWORD"
                 type="password">
        <app-input-validation
          className="error"
          message="REQUIRED"
          [condition]="(passwordResetForm.get('new_password2').touched || formDir.submitted) && passwordResetForm.get('new_password2').hasError('required')">
        </app-input-validation>
      </app-input>
      <nz-alert
        *ngIf="passwordResetForm.controls['new_password2'].errors?.MatchPassword"
        nzType="error"
        [nzMessage]="'PASSWORD_NOT_MATCH' | translate"
        nzShowIcon
      ></nz-alert>

      <nz-alert
        *ngIf="errorMessage"
        nzType="error"
        [nzMessage]="errorMessage"
        nzShowIcon
        nzCloseable
        (nzOnClose)="errorMessage = ''"
      ></nz-alert>
      <br/>
      <div class="form-group align-center">
        <app-button [className]="'btn-red'" text="CONFIRM"></app-button>
      </div>
    </form>
  </div>
</div>
