import { Select } from '../../select/select.model';

export class ColumnSetting {
  internalKey?: string;
  primaryKey: string;
  header?: string;
  type?: string;
  navigate?: boolean;
  email_verified?: string;
  alternativeKeys?: string[];
  filters?: {
    type: string;
    key: string;
    datepicker?: boolean;
    options?: Select[];
  };
  style?: any;
  sort?: boolean;
  sortField?: string;
  sorted?: boolean;
  reversed?: boolean;
  bold?: boolean;
  hide?: boolean;
  showInquire?: boolean;
  isFormatedPrice ?= false;
  depositPrimaryKey?: string;
  depositHeader?: string;
}

export class ColumnActions {
  id: string | number;
  icon: string;
  type: string;
}

export class TableActionOutput {
  param: string | number;
  type: string;
}

export class TableStatus {
  name: string;
  type: string;
}

export class ColumnMap {
  primaryKey: string;
  private _header: string;
  private _format: string;
  private _type: string;
  private _email_verified: string;
  private _navigate: boolean;
  private _filters?: {
    type?: string;
    key?: string;
    datepicker?: boolean;
    options?: Select[];
  };
  alternativeKeys?: string[];
  sort: boolean;
  sortField: string;
  sorted: boolean;
  reversed: boolean;

  constructor(settings) {
    this.primaryKey = settings.primaryKey;
    this.header = settings.header;
    this.format = settings.format;
    this.type = settings.type;
    this.email_verified = settings.email_verified;
    this.navigate = settings.navigate;
    this.filters = settings.filters;
    this.alternativeKeys = settings.alternativeKeys;
    this.sort = settings.sort;
    this.sortField = settings.sortField;
    this.sorted = settings.sorted;
    this.reversed = settings.reversed;
  }

  set header(setting: string) {
    this._header = setting;
    // this._header = setting ?
    //   setting :
    //   this.primaryKey.slice(0, 1).toUpperCase() +
    //   this.primaryKey.replace(/_/g, ' ' ).slice(1)
  }

  get header() {
    return this._header;
  }

  set format(setting: string) {
    this._format = setting ? setting : 'default';
  }

  set type(type: string) {
    this._type = type ? type : 'default';
  }

  set filters(filter: any) {
    this._filters = filter ? filter : null;
  }

  set navigate(status: boolean) {
    this._navigate = status ? status : false;
  }

  set email_verified(name: string) {
    this._email_verified = name ? name : 'email_verified';
  }

  get format() {
    return this._format;
  }

  get type() {
    return this._type;
  }

  get email_verified() {
    return this._email_verified;
  }

  get filters() {
    return this._filters;
  }

  get navigate() {
    return this._navigate;
  }

  access = function(object: any) {
    if (object[this.primaryKey] || !this.alternativeKeys) {
      return this.primaryKey;
    }
    for (const key of this.alternativeKeys) {
      if (object[key]) {
        return key;
      }
    }

    return this.primaryKey;
  };
}
