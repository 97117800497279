<div class="datepicker-open-label" *ngIf="hasOpenLabel" (click)="toggle()">
  <span>{{'OPEN_DATEPICKER' | translate}}</span>
</div>
<div class="datepicker-open" (click)="toggle()">
  <i class="material-icons">date_range</i>
</div>
<div class="datepicker-container u-is-unselectable">
  <!--<div class="datepicker-input-container">-->
  <!--<input type="text" class="datepicker-input" [value]="value | json">-->
  <!--<div class="datepicker-input-icon" (click)="toggle()">-->
  <!--<i class="ion-ios-calendar-outline"></i>-->
  <!--</div>-->
  <!--</div>-->
  <div class="datepicker-calendar" *ngIf="opened">
    <div class="datepicker-calendar-container">
      <div *ngIf="!yearPicker">
        <div class="datepicker-calendar-month-section">
          <i class="icon-prev" (click)="prevMonth()"></i>
          <span class="month-title" (click)="openYearPicker($event)">{{ currentDate.format(options.monthTitleFormat) }}</span>
          <i class="icon-next" (click)="nextMonth()"></i>
        </div>
        <div class="datepicker-calendar-day-names">
          <span>{{ options.weekDays['M'] }}</span>
          <span>{{ options.weekDays['T'] }}</span>
          <span>{{ options.weekDays['W'] }}</span>
          <span>{{ options.weekDays['Th'] }}</span>
          <span>{{ options.weekDays['F'] }}</span>
          <span>{{ options.weekDays['Sa'] }}</span>
          <span>{{ options.weekDays['S'] }}</span>
        </div>
        <div class="datepicker-calendar-days-container">
            <span class="day" *ngFor="let d of days; let i = index"
                  (click)="selectDate($event, d.momentObj)"
                  [ngClass]="{ 
                    'disabled': !d.enabled || (disableToday && d.today) ? true : false,
                    'selected': d.selected,
                    'today': !disableToday ? d.today : false,
                    'disableToday': (
                      (disableToday && d.selected && d.today
                      ) ||
                      !disableToday
                      ) ? false : d.today}"
              >
              {{ d.day }}
            </span>
        </div>
        <div class="datepicker-buttons" *ngIf="!options.autoApply">
          <button type="button" class="a-button u-is-secondary u-is-small" (click)="!disableToday ? today() : nextWeek()">
            <ng-container *ngIf="!disableToday">{{'TODAY' | translate}}</ng-container>
            <ng-container *ngIf="disableToday">{{'NEXT_WEEK' | translate}}</ng-container>
          </button>
          <button type="button" class="a-button u-is-primary u-is-small" (click)="close()">Apply  </button>
        </div>
      </div>
      <div *ngIf="yearPicker">
        <div class="datepicker-calendar-years-container">
            <span class="year" *ngFor="let y of years; let i = index" (click)="selectYear($event, y)">
              {{ y }}
            </span>
        </div>
      </div>
    </div>
  </div>
</div>
