<div class="card">
  <div class="card-body text-center">
    <div class="align-center">
      <a href="/login" routerLink="/login">
        <img src="assets/images/logo-skuba.png" alt="{{'SKUBA_LOGO'| translate}}" class="home-logo form-logo">
      </a>
    </div>
    <h2>{{'FORGOT_PASSWORD_Q' | translate}}</h2>
    <p class="fp-description">{{'FORGOT_PASSWORD_DESCRIPTION' | translate}}</p>
    <form class="forgot-password-form"
          (ngSubmit)="submit()"
          [formGroup]="forgotPasswordForm"
          novalidate
          #formDir="ngForm">
      <app-input
        formControlName="username"
        placeholder="USERNAME"
        label="USERNAME"
        [focus]="true">
        <app-input-validation
          className="error"
          message="REQUIRED"
          [condition]="(forgotPasswordForm.get('username').touched || formDir.submitted) && forgotPasswordForm.get('username').hasError('required')">
        </app-input-validation>
      </app-input>
      <div class="form-group align-center">
        <app-button [className]="'btn-red'" text="CONFIRM"></app-button>
      </div>
    </form>
  </div>
</div>
