import { Action } from '@ngrx/store';
import { IUserAddressesModel } from '@app/auth/models/user';
import { IUserAddress, IUserAddressBody } from '@app/auth/models/user';

export const LOAD_USER_ADDRESSES = '[User Addresses] Load user addresses';
export const LOAD_USER_ADDRESSES_SUCCESS =
  '[User Addresses] Load user addresses Success';
export const LOAD_USER_ADDRESSES_FAIL =
  '[User Addresses] Load user addresses Fail';
export const ADD_USER_ADDRESS = '[User Addresses] Add user address';
export const ADD_USER_ADDRESS_SUCCESS =
  '[User Addresses] Add user address Success';
export const ADD_USER_ADDRESS_FAIL = '[User Addresses] Add user address Fail';
export const SET_USER_ADDRESS = '[User Addresses] Set user address';
export const SET_USER_ADDRESS_SUCCESS =
  '[User Addresses] Set user address Success';
export const SET_USER_ADDRESS_FAIL = '[User Addresses] Set user address Fail';
export const PATCH_USER_ADDRESS = '[User Addresses] Patch user address';
export const PATCH_USER_ADDRESS_SUCCESS =
  '[User Addresses] Patch user address Success';
export const PATCH_USER_ADDRESS_FAIL =
  '[User Addresses] Patch user address Fail';
export const UPDATE_USER_ADDRESS = '[User Addresses] Update user address';
export const UPDATE_USER_ADDRESS_SUCCESS =
  '[User Addresses] Update user address Success';
export const UPDATE_USER_ADDRESS_FAIL =
  '[User Addresses] Update user address Fail';
export const REMOVE_USER_ADDRESS = '[User Addresses] Remove user address';
export const REMOVE_USER_ADDRESS_SUCCESS =
  '[User Addresses] Remove user address Success';
export const REMOVE_USER_ADDRESS_FAIL =
  '[User Addresses] Remove user address Fail';
export const RESET_USER_ADDRESSES_STATE = '[User Addresses] Reset State';

export class LoadUserAddresses implements Action {
  readonly type = LOAD_USER_ADDRESSES;
}

export class LoadUserAddressesSuccess implements Action {
  readonly type = LOAD_USER_ADDRESSES_SUCCESS;

  constructor(public payload: IUserAddressesModel) {}
}

export class LoadUserAddressesFail implements Action {
  readonly type = LOAD_USER_ADDRESSES_FAIL;

  constructor(public payload: any) {}
}

export class AddUserAddress implements Action {
  readonly type = ADD_USER_ADDRESS;

  constructor(public payload: IUserAddressBody) {}
}

export class AddUserAddressSuccess implements Action {
  readonly type = ADD_USER_ADDRESS_SUCCESS;

  constructor(public payload: IUserAddress) {}
}

export class AddUserAddressFail implements Action {
  readonly type = ADD_USER_ADDRESS_FAIL;

  constructor(public payload: any) {}
}

export class SetUserAddress implements Action {
  readonly type = SET_USER_ADDRESS;

  constructor(public payload: IUserAddress) {}
}

export class SetUserAddressSuccess implements Action {
  readonly type = SET_USER_ADDRESS_SUCCESS;

  constructor(public payload: IUserAddress) {}
}

export class SetUserAddressFail implements Action {
  readonly type = SET_USER_ADDRESS_FAIL;

  constructor(public payload: any) {}
}

export class PatchUserAddress implements Action {
  readonly type = PATCH_USER_ADDRESS;

  constructor(public payload: any) {}
}

export class PatchUserAddressSuccess implements Action {
  readonly type = PATCH_USER_ADDRESS_SUCCESS;

  constructor(public payload: IUserAddress) {}
}

export class PatchUserAddressFail implements Action {
  readonly type = PATCH_USER_ADDRESS_FAIL;

  constructor(public payload: any) {}
}

export class UpdateUserAddress implements Action {
  readonly type = UPDATE_USER_ADDRESS;

  constructor(public payload: { id: number; body: IUserAddressBody }) {}
}

export class UpdateUserAddressSuccess implements Action {
  readonly type = UPDATE_USER_ADDRESS_SUCCESS;

  constructor(public payload: IUserAddress) {}
}

export class UpdateUserAddressFail implements Action {
  readonly type = UPDATE_USER_ADDRESS_FAIL;

  constructor(public payload: any) {}
}

export class RemoveUserAddress implements Action {
  readonly type = REMOVE_USER_ADDRESS;

  constructor(public payload: number) {}
}

export class RemoveUserAddressSuccess implements Action {
  readonly type = REMOVE_USER_ADDRESS_SUCCESS;
}

export class RemoveUserAddressFail implements Action {
  readonly type = REMOVE_USER_ADDRESS_FAIL;

  constructor(public payload: any) {}
}

export class ResetUserAddressState implements Action {
  readonly type = RESET_USER_ADDRESSES_STATE;
}

export type UserAddressesAction =
  | LoadUserAddresses
  | LoadUserAddressesFail
  | LoadUserAddressesSuccess
  | AddUserAddress
  | AddUserAddressFail
  | AddUserAddressSuccess
  | SetUserAddress
  | SetUserAddressFail
  | SetUserAddressSuccess
  | PatchUserAddress
  | PatchUserAddressFail
  | PatchUserAddressSuccess
  | UpdateUserAddress
  | UpdateUserAddressFail
  | UpdateUserAddressSuccess
  | RemoveUserAddress
  | RemoveUserAddressFail
  | RemoveUserAddressSuccess
  | ResetUserAddressState;
