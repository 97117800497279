/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./verification-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/ui/back-button/back-button.component.ngfactory";
import * as i3 from "../../../shared/ui/back-button/back-button.component";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "../../components/verification-info/verification-info.component.ngfactory";
import * as i7 from "../../components/verification-info/verification-info.component";
import * as i8 from "./verification-page.component";
import * as i9 from "@ngrx/store";
import * as i10 from "../../services/auth.service";
var styles_VerificationPageComponent = [i0.styles];
var RenderType_VerificationPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VerificationPageComponent, data: { "animation": [{ type: 7, name: "fadeInDown", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0, transform: "translateY(-2rem)" }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateY(0rem)" }, offset: null }, timings: "300ms ease-in" }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0, transform: "translateY(-2rem)" }, offset: null }, timings: "300ms ease-out" }], options: null }], options: {} }, { type: 7, name: "fadeIn", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "400ms ease" }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "400ms ease" }], options: null }], options: {} }] } });
export { RenderType_VerificationPageComponent as RenderType_VerificationPageComponent };
export function View_VerificationPageComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [], [[24, "@fadeInDown", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-back-button", [], null, null, null, i2.View_BackButtonComponent_0, i2.RenderType_BackButtonComponent)), i1.ɵdid(2, 49152, null, 0, i3.BackButtonComponent, [i4.ActivatedRoute, i4.Router, i5.Location], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "base-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-verification-info", [], null, null, null, i6.View_VerificationInfoComponent_0, i6.RenderType_VerificationInfoComponent)), i1.ɵdid(5, 114688, null, 0, i7.VerificationInfoComponent, [i4.Router], { status: [0, "status"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.verifySuccess; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_VerificationPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-verification-page", [], null, null, null, View_VerificationPageComponent_0, RenderType_VerificationPageComponent)), i1.ɵdid(1, 245760, null, 0, i8.VerificationPageComponent, [i9.Store, i1.ChangeDetectorRef, i10.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VerificationPageComponentNgFactory = i1.ɵccf("app-verification-page", i8.VerificationPageComponent, View_VerificationPageComponent_Host_0, {}, {}, []);
export { VerificationPageComponentNgFactory as VerificationPageComponentNgFactory };
