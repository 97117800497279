<div class="app-stepper row" *ngIf="steps">
  <ng-container *ngFor="let step of steps; let i = index">
    <div class="step col ripple"
         [ngClass]="{'active-step': i + 1 === currentStep, 'completed-step': i + 1 < currentStep}"
         (click)="clickStep(i)">
      <div class="step-container">
        <div class="step-number shadow-1">
          <span *ngIf="i + 1 >= currentStep" [@fadeIn]>{{i + 1}}</span>
          <span *ngIf="i + 1 < currentStep" [@fadeIn]><i class="material-icons">done</i></span>
        </div>
        <div class="step-title">{{step.title | translate}}</div>
      </div>
    </div>
    <div class="step-divider" *ngIf="i !== steps.length - 1"></div>
  </ng-container>
</div>
