import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges
} from '@angular/core';
import { CartModel } from '@pages/cart/models/cart.model';
import { IUser } from '@app/auth/models/user';
import { OrdersSummary } from '@app/shared/services/orders-helpers.service';

@Component({
  selector: 'app-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartSummaryComponent implements OnChanges {

  summary: OrdersSummary = {
    sum_without_pvm: 0,
    wat: 0,
    sum: 0,
    watPrecent: 0,
    deliveryPrice: 0,
    currency: '',
  };

  showPricesWithVat: boolean = false;

  @Input() products: CartModel;
  @Input() user: IUser;
  @Input() showLocalPrice: boolean;
  @Input() showJustOneSum?: boolean = false;

  constructor() {}

  ngOnChanges() {
    this.summary.sum_without_pvm = 0;
    this.summary.wat = 0;
    this.summary.sum = 0;
    this.summary.watPrecent = 0;
    if (this.user && this.user.profile) {
      this.summary.watPrecent = this.user.profile.company.country.vat;
      this.showPricesWithVat = this.user.profile.show_prices_with_vat;
    }
    if (this.products && this.products.cart) {
      const cart = this.products.cart;
      if (this.showLocalPrice) {
        this.summary.sum_without_pvm = cart.total_price_wo_vat + cart.total_deposit_wo_vat;
        this.summary.wat = cart.total_price_vat + cart.total_deposit_vat;
        this.summary.sum = cart.total_price + cart.total_deposit;
      } else {
        this.summary.sum_without_pvm = cart.total_price_wo_vat_eur + cart.total_deposit_wo_vat_eur;
        this.summary.wat = cart.total_price_vat_eur + cart.total_deposit_vat_eur;
        this.summary.sum = cart.total_price_eur + cart.total_deposit_eur;
      }
    }
  }
}
