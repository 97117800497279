var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AuthService } from '@app/auth/services/auth.service';
import * as fromActions from '../actions';
import { switchMap, catchError, tap, mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { Router } from '@angular/router';
import * as toast from '@core/store/actions/toast.action';
import { Store } from '@ngrx/store';
export class UserEffects {
    constructor(actions$, authService, store, localStorageService, router) {
        this.actions$ = actions$;
        this.authService = authService;
        this.store = store;
        this.localStorageService = localStorageService;
        this.router = router;
        this.LoadUser$ = this.actions$.pipe(ofType(fromActions.LOAD_USER), switchMap(() => {
            return this.authService.LoadUser().pipe(map(user => new fromActions.LoadUserSuccess(user)), catchError(error => of(new fromActions.LoadUserFail(error))));
        }));
        this.updateUser$ = this.actions$.pipe(ofType(fromActions.UPDATE_USER), map((action) => action.payload), mergeMap(data => {
            return this.authService.updateUser(data).pipe(map(user => new fromActions.UpdateUserSuccess(user)), catchError(error => of(new fromActions.UpdateUserFail(error))));
        }));
        this.updateUserLanguage$ = this.actions$.pipe(ofType(fromActions.UPDATE_USER_LANGUAGE), map((action) => action.payload), mergeMap(data => {
            return this.authService.updateUser(data).pipe(map(user => new fromActions.UpdateUserLanguageSuccess(user)), catchError(error => of(new fromActions.UpdateUserLanguageFail(error))));
        }));
        this.handleUpdateUserSuccess$ = this.actions$.pipe(ofType(fromActions.UPDATE_USER_SUCCESS), switchMap(() => [new toast.ShowSuccessToast('SUCCESS')]));
        // @Effect({ dispatch: false })
        // handleUserSuccess$ = this.actions$.pipe(
        //   ofType(fromActions.LOAD_USER_SUCCESS),
        //   tap(() => {
        //     this.router.navigate(['/catalogue']);
        //   })
        // );
        this.handleUserError$ = this.actions$.pipe(ofType(fromActions.LOAD_USER_FAIL), tap(() => console.log('ulf')));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserEffects.prototype, "LoadUser$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserEffects.prototype, "updateUser$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserEffects.prototype, "updateUserLanguage$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], UserEffects.prototype, "handleUpdateUserSuccess$", void 0);
__decorate([
    Effect({ dispatch: false }),
    __metadata("design:type", Object)
], UserEffects.prototype, "handleUserError$", void 0);
