import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api.service';
import { IUserAddressBody, IUserAddressesModel } from '@app/auth/models/user';
import { IUserAddress } from '@app/auth/models/user';
import { map } from 'rxjs/internal/operators';

@Injectable()
export class UserAddressesService {
  constructor(private apiService: ApiService) {}

  /**
   * Get user addresses data from API.
   * @returns {Observable<any>}
   */
  getShippingAddresses(): Observable<IUserAddressesModel> {
    return this.apiService.get(`/users/addresses/`).pipe(map(data => data));
  }

  /**
   * Get user addresses data from API.
   * @returns {Observable<any>}
   */
  getShippingAddress(id: number): Observable<IUserAddress> {
    return this.apiService
      .get(`/users/addresses/${id}/`)
      .pipe(map(data => data));
  }

  /**
   * Create new user shipping address.
   * @param {IUserAddressBody} body
   * @returns {Observable<IUserAddressesModel>}
   */
  addShippingAddresses(body: IUserAddressBody): Observable<IUserAddress> {
    return this.apiService
      .post(`/users/addresses/`, body)
      .pipe(map(data => data));
  }

  /**
   * Partial user address update.
   * @param {number} id
   * @param body
   * @returns {Observable<IUserAddress>}
   */
  patchShippingAddress(id: number, body: any): Observable<IUserAddress> {
    return this.apiService
      .patch(`/users/addresses/${id}/`, body)
      .pipe(map(data => data));
  }

  /**
   * User address update.
   * @param {number} id
   * @param {IUserAddressBody} body
   * @returns {Observable<IUserAddress>}
   */
  updateShippingAddress(
    id: number,
    body: IUserAddressBody
  ): Observable<IUserAddress> {
    return this.apiService
      .patch(`/users/addresses/${id}/`, body)
      .pipe(map(data => data));
  }

  /**
   * Remove user address.
   * @param {number} id
   * @returns {Observable<any>}
   */
  removeShippingAddress(id: number): Observable<any> {
    return this.apiService
      .delete(`/users/addresses/${id}/`)
      .pipe(map(data => data));
  }
}
