import { Injectable } from '@angular/core';
import { Observable, of, EMPTY } from 'rxjs';
import { ApiService } from '@core/api.service';
import { DeliveryTypes, PickupDeliveryTimeOptionResponse } from '@pages/checkout/models/shipping.model';
import { Order } from '@core/models/order';
import { IOrderStatuses } from '@shared/models';
import { IOrderList } from '@shared/models/order.model';
import { map, switchMap } from 'rxjs/internal/operators';
import { CachedRequestService } from '@app/core/service/cached-req.service';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';

const getStatusName = (status: number): string => {
  let statusName = '';
  switch (status) {
    case -2:
      statusName = 'ERROR';
      break;
    case -1:
      statusName = 'ERROR';
      break;
    case 0:
      statusName = 'ERROR';
      break;
    case 1:
      statusName = 'WARNING';
      break;
    case 2:
      statusName = 'WARNING';
      break;
    case 3:
      statusName = 'WARNING';
      break;
    case 4:
      statusName = 'WARNING';
      break;
    case 5:
      statusName = 'SUCCESS';
      break;
    case 6:
      statusName = 'SUCCESS';
      break;
    default:
      statusName = 'ERROR';
  }

  return statusName;
};
@Injectable()
export class OrderService {
  deliveryAndPickupOptions$: Observable<any>;
  deliveryTypes$: Observable<any>;
  deliveryTypesOrder$: { [key: number]: Observable<any> } = {};
  constructor(
    private apiService: ApiService,
    private cachedRequestService: CachedRequestService,
    private localStorageService: LocalStorageService,
  ) {
    this.deliveryAndPickupOptions$ = this.apiService
      .get('/orders/delivery_types/get-pickup-delivery-times/')
      .pipe(map(data => {
        this.cachedRequestService.setItem('cached-user-pickup-delivery-options', data);
        return data;
      }));
    this.deliveryTypes$ = this.apiService
      .get('/orders/delivery_types/')
      .pipe(map(data => {
        this.cachedRequestService.setItem('cached-user-deliveries', data);
        return data;
      }));
    this.deliveryTypesOrder$ = {};
    if (!this.cachedRequestService.hasKey('cached-user-deliveries-by-order')) {
      this.cachedRequestService.setItem('cached-user-deliveries-by-order', this.deliveryTypesOrder$);
    }
  }

  /**
   * Get pickup delivery options from API.
   *
   * !We cache to not make any additional requests to server and not cache in later layers.
   * Also, we save request in constructor to not make multiple requests to backend.
   *
   * @returns {Observable<PickupDeliveryTimeOptionResponse>}
   */
  initDeliveryAndPickupOptions(): Observable<PickupDeliveryTimeOptionResponse> {
    return of(false).pipe(
      switchMap(state => {
        if (this.cachedRequestService.hasKey('cached-user-pickup-delivery-options')) {
          return of(this.cachedRequestService.getItem('cached-user-pickup-delivery-options'));
        } else {
          return this.deliveryAndPickupOptions$;
        }
      })
    );

  }

  getLastSavedDelivery() {
    if (this.localStorageService.hasKey('saved-user-pickup-delivery-option')) {
      return this.localStorageService.getItem('saved-user-pickup-delivery-option');
    }
    return undefined;
  }
  setLastSavedDelivery(condition: string) {
    return this.localStorageService.setItem('saved-user-pickup-delivery-option', condition);
  }

  /**
   * Get delivery types list from API.
   * @returns {Observable<any>}
   */
  getDeliveryTypes(order_id: number | null = null): Observable<DeliveryTypes> {
    return of(false).pipe(
      switchMap(state => {
        if (order_id) {
          const cachedObj = this.cachedRequestService.getItem('cached-user-deliveries-by-order');
          if (cachedObj[order_id]) {
            return of(cachedObj[order_id]);
          } else {
            this.deliveryTypesOrder$[order_id] = this.apiService
              .get('/orders/delivery_types/', { order_id: order_id.toString() })
              .pipe(map(data => {
                const cachedObjSave = this.cachedRequestService.getItem('cached-user-deliveries-by-order');
                cachedObjSave[order_id] = data;
                this.cachedRequestService.setItem('cached-user-deliveries-by-order', cachedObjSave);
                return data;
              }));
            return this.deliveryTypesOrder$[order_id];
          }
        }
        if (this.cachedRequestService.hasKey('cached-user-deliveries')) {
          return of(this.cachedRequestService.getItem('cached-user-deliveries'));
        } else {
          return this.deliveryTypes$;
        }
      })
    );
  }

  /**
   * cart_hash required.
   * @param {{cart_hash: string}} body
   * @returns {Observable<Order>}
   */
  createOrder(body: { cart_hash: string }, acknowledged = false, cartItemsIds = []): Observable<Order> {
    return this.apiService
      .post('/orders/orders/', { ...body, acknowledged, _ems_silenced: true, cart_items_ids: cartItemsIds })
      .pipe(map(data => data));
  }

  /**
   * Order id and cart_hash required.
   * @param id
   * @param {{cart_hash: string}} body
   * @returns {Observable<Order>}
   */
  updateOrder(id, body: { cart_hash: string }): Observable<Order> {
    return this.apiService
      .put(`/orders/orders/${id}/`, body)
      .pipe(map(data => data));
  }

  /**
   * Order status change.
   * @param id
   * @param {{status: string; cart_hash: string}} body
   * @returns {Observable<Order>}
   */
  updateOrderStatus(
    id,
    body: { status: string; cart_hash: string }
  ): Observable<Order> {
    return this.apiService
      .post(`/orders/orders/${id}/update_status/`, body)
      .pipe(map(data => data));
  }

  /**
   * Order id and cart_hash required.
   * @param id
   * @param {{cart_hash: string}} body
   * @returns {Observable<Order>}
   */
  updateOrderMany(body: { orders: any[]; note: string; _ems_silenced: boolean }): Observable<Order[]> {
    return this.apiService
      .post('/orders/orders/update-many/', body, true)
      .pipe(map(data => data));
  }

  /**
   * Order status change.
   * @param id
   * @param {{status: string; cart_hash: string}} body
   * @returns {Observable<Order>}
   */
  updateOrderStatusMany(
    body: { orders: any[]; status: number }
  ): Observable<Order[]> {
    return this.apiService
      .post('/orders/orders/update-status-many/', body)
      .pipe(map(data => data));
  }

  getBySkn(body: any): Observable<IOrderList> {
    return this.apiService
      .get('/orders/orderitems-by-skn/', body)
      .pipe(map(data => data));
  }
  // TODO: Remove this from here and backend
  getOrderStatuses(): Observable<IOrderStatuses> {
    return this.apiService
      .get('/orders/orders/list-statuses/')
      .pipe(map(data => data));
  }

  getStatusName(status: number): string {
    return getStatusName(status);
  }

  getPaymentStatusName(status: number): string {
    let statusName = '';

    switch (status) {
      case -2:
        statusName = 'ERROR';
        break;
      case -1:
        statusName = 'ERROR';
        break;
      case 0:
        statusName = 'WARNING';
        break;
      case 1:
        statusName = 'WARNING';
        break;
      case 2:
        statusName = 'WARNING';
        break;
      case 3:
        statusName = 'SUCCESS';
        break;
      default:
        statusName = 'ERROR';
    }

    return statusName;
  }
}

export { getStatusName };
