import { Action } from '@ngrx/store';
import { ISubGroup } from '@app/core/models/subgroup';

export enum SubGroupActionTypes {
  SubGroupsLoad = '[SubGroup] Load Subgroups',
  SubGroupsLoadFail = '[SubGroup] Load Subgroups Fail',
  SubGroupsLoadSuccess = '[SubGroup] Load Subgroups Success',
  ResetSubGroupsState = '[SubGroup] Reset State'
}

export class SubGroupsLoad implements Action {
  readonly type = SubGroupActionTypes.SubGroupsLoad;

  constructor() {}
}

export class SubGroupsLoadFail implements Action {
  readonly type = SubGroupActionTypes.SubGroupsLoadFail;
  constructor(public payload: any) {}
}

export class SubGroupsLoadSuccess implements Action {
  readonly type = SubGroupActionTypes.SubGroupsLoadSuccess;
  constructor(public payload: ISubGroup[]) {}
}

export class ResetSubGroupsState implements Action {
  readonly type = SubGroupActionTypes.ResetSubGroupsState;
}

export type SubGroupActions =
  | SubGroupsLoad
  | SubGroupsLoadFail
  | SubGroupsLoadSuccess
  | ResetSubGroupsState;
