import { Action } from '@ngrx/store';
import { IUserCarsModel } from '@app/auth/models/user';
import { IUserCar, IUserCarBody } from '@app/auth/models/user';

export const LOAD_USER_CARS = '[User Cars] Load user cars';
export const LOAD_USER_CARS_SUCCESS =
  '[User Cars] Load user cars Success';
export const LOAD_USER_CARS_FAIL =
  '[User Cars] Load user cars Fail';
export const LOAD_USER_CARS_BY_USER = '[User Cars] Load cars by user';
export const ADD_USER_CAR = '[User Cars] Add user car';
export const ADD_USER_CAR_SUCCESS =
  '[User Cars] Add user car Success';
export const ADD_USER_CAR_FAIL = '[User Cars] Add user car Fail';
export const SET_USER_CAR = '[User Cars] Set user car';
export const SET_USER_CAR_SUCCESS =
  '[User Cars] Set user car Success';
export const SET_USER_CAR_FAIL = '[User Cars] Set user car Fail';
export const PATCH_USER_CAR = '[User Cars] Patch user car';
export const PATCH_USER_CAR_SUCCESS =
  '[User Cars] Patch user car Success';
export const PATCH_USER_CAR_FAIL =
  '[User Cars] Patch user car Fail';
export const UPDATE_USER_CAR = '[User Cars] Update user car';
export const UPDATE_USER_CAR_SUCCESS =
  '[User Cars] Update user car Success';
export const UPDATE_USER_CAR_FAIL =
  '[User Cars] Update user car Fail';
export const REMOVE_USER_CAR = '[User Cars] Remove user car';
export const REMOVE_USER_CAR_SUCCESS =
  '[User Cars] Remove user car Success';
export const REMOVE_USER_CAR_FAIL =
  '[User Cars] Remove user car Fail';
export const RESET_USER_CARS_STATE = '[User Cars] Reset State';

export class LoadUserCars implements Action {
  readonly type = LOAD_USER_CARS;
}

export class LoadUserCarsSuccess implements Action {
  readonly type = LOAD_USER_CARS_SUCCESS;

  constructor(public payload: IUserCarsModel) {}
}

export class LoadUserCarsFail implements Action {
  readonly type = LOAD_USER_CARS_FAIL;

  constructor(public payload: any) {}
}

export class LoadUserCarsByUser implements Action {
  readonly type = LOAD_USER_CARS_BY_USER;
  constructor(public payload: { userId: number}) {}
}

export class AddUserCar implements Action {
  readonly type = ADD_USER_CAR;

  constructor(public payload: IUserCarBody) {}
}

export class AddUserCarSuccess implements Action {
  readonly type = ADD_USER_CAR_SUCCESS;

  constructor(public payload: IUserCar) {}
}

export class AddUserCarFail implements Action {
  readonly type = ADD_USER_CAR_FAIL;

  constructor(public payload: any) {}
}

export class SetUserCar implements Action {
  readonly type = SET_USER_CAR;

  constructor(public payload: IUserCar) {}
}

export class SetUserCarSuccess implements Action {
  readonly type = SET_USER_CAR_SUCCESS;

  constructor(public payload: IUserCar) {}
}

export class SetUserCarFail implements Action {
  readonly type = SET_USER_CAR_FAIL;

  constructor(public payload: any) {}
}

export class PatchUserCar implements Action {
  readonly type = PATCH_USER_CAR;

  constructor(public payload: any) {}
}

export class PatchUserCarSuccess implements Action {
  readonly type = PATCH_USER_CAR_SUCCESS;

  constructor(public payload: IUserCar) {}
}

export class PatchUserCarFail implements Action {
  readonly type = PATCH_USER_CAR_FAIL;

  constructor(public payload: any) {}
}

export class UpdateUserCar implements Action {
  readonly type = UPDATE_USER_CAR;

  constructor(public payload: { id: number; body: IUserCarBody }) {}
}

export class UpdateUserCarSuccess implements Action {
  readonly type = UPDATE_USER_CAR_SUCCESS;

  constructor(public payload: IUserCar) {}
}

export class UpdateUserCarFail implements Action {
  readonly type = UPDATE_USER_CAR_FAIL;

  constructor(public payload: any) {}
}

export class RemoveUserCar implements Action {
  readonly type = REMOVE_USER_CAR;

  constructor(public payload: number) {}
}

export class RemoveUserCarSuccess implements Action {
  readonly type = REMOVE_USER_CAR_SUCCESS;
}

export class RemoveUserCarFail implements Action {
  readonly type = REMOVE_USER_CAR_FAIL;

  constructor(public payload: any) {}
}

export class ResetUserCarState implements Action {
  readonly type = RESET_USER_CARS_STATE;
}

export type UserCarsAction =
  | LoadUserCars
  | LoadUserCarsFail
  | LoadUserCarsSuccess
  | LoadUserCarsByUser
  | AddUserCar
  | AddUserCarFail
  | AddUserCarSuccess
  | SetUserCar
  | SetUserCarFail
  | SetUserCarSuccess
  | PatchUserCar
  | PatchUserCarFail
  | PatchUserCarSuccess
  | UpdateUserCar
  | UpdateUserCarFail
  | UpdateUserCarSuccess
  | RemoveUserCar
  | RemoveUserCarFail
  | RemoveUserCarSuccess
  | ResetUserCarState;
