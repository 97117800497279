var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as showcaseActions from '../actions/showcase.action';
import { ShowcaseService } from '@pages/dashboard/services/showcase.service';
export class ShowcaseEffects {
    constructor(actions$, showcaseService) {
        this.actions$ = actions$;
        this.showcaseService = showcaseService;
        this.loadShowcase$ = this.actions$.pipe(ofType(showcaseActions.ShowcaseActionTypes.ShowcaseLoad), mergeMap(() => {
            return this.showcaseService.getShowcases().pipe(map(data => new showcaseActions.LoadShowcaseSuccess(data)), catchError(error => of(new showcaseActions.LoadShowcaseFail(error))));
        }));
        this.loadGroups$ = this.actions$.pipe(ofType(showcaseActions.ShowcaseActionTypes.LoadGroups), mergeMap(() => {
            return this.showcaseService.getGroups().pipe(map(data => new showcaseActions.LoadGroupsSuccess(data)), catchError(error => of(new showcaseActions.LoadGroupsFail(error))));
        }));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], ShowcaseEffects.prototype, "loadShowcase$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], ShowcaseEffects.prototype, "loadGroups$", void 0);
