import * as fromShipping from './shipping.reducer';

import {
  ActionReducerMap, createFeatureSelector
} from '@ngrx/store';

export interface CheckoutState {
  shipping: fromShipping.ShippingState;
}

export const reducers: ActionReducerMap<CheckoutState> = {
  shipping: fromShipping.reducer,
};

export const getCheckoutState = createFeatureSelector<CheckoutState>(
  'checkout'
);

