import { IUserCarsModel } from '@app/auth/models/user';
import * as fromUserCars from '../actions/user-cars.action';
import { IUserCar } from '@app/auth/models/user';

export interface UserCarsState {
  cars: IUserCarsModel;
  entities: { [id: string]: any };
  car: IUserCar;
  carId: number;
  loaded: boolean;
  loading: boolean;
  addSuccess: boolean;
  error: string;
}

export const initialState: UserCarsState = {
  cars: null,
  entities: {},
  car: null,
  carId: null,
  loaded: false,
  loading: false,
  addSuccess: false,
  error: null
};

export function reducer(
  state = initialState,
  action: fromUserCars.UserCarsAction
): UserCarsState {
  switch (action.type) {
    case fromUserCars.LOAD_USER_CARS: {
      return {
        ...state,
        loading: true,
        addSuccess: false,
        error: null
      };
    }
    case fromUserCars.LOAD_USER_CARS_SUCCESS: {
      let car = state.car;
      let carId = state.carId;

      const entities = action.payload.results.reduce(
        (entit: { [id: string]: IUserCar }, car: IUserCar) => {
          return {
            ...entit,
            [car.id]: car
          };
        },
        {
          ...state.entities
        }
      );

      action.payload.results.map(car => {
        if (state.carId === null) {
          car = car;
          carId = car.id;
        }
      });

      return {
        ...state,
        cars: action.payload,
        entities: entities,
        car: car,
        carId: carId,
        loaded: true,
        loading: false,
        addSuccess: false,
        error: null
      };
    }
    case fromUserCars.LOAD_USER_CARS_FAIL: {
      return {
        loaded: false,
        loading: false,
        cars: null,
        entities: {},
        car: null,
        carId: null,
        addSuccess: false,
        error: action.payload
      };
    }
    case fromUserCars.LOAD_USER_CARS_BY_USER: {
      return {
        ...state,
        loading: true,
        addSuccess: false,
        error: null
      };
    }
    case fromUserCars.ADD_USER_CAR: {
      return {
        ...state,
        loading: true,
        addSuccess: false
      };
    }
    case fromUserCars.ADD_USER_CAR_SUCCESS: {
      return {
        ...state,
        car: action.payload,
        carId: action.payload.id,
        loaded: true,
        loading: false,
        addSuccess: true,
        error: null
      };
    }
    case fromUserCars.ADD_USER_CAR_FAIL: {
      return {
        ...state,
        loaded: true,
        loading: false,
        addSuccess: false,
        error: action.payload
      };
    }

    case fromUserCars.SET_USER_CAR: {
      return {
        ...state,
        loading: true,
        addSuccess: false,
        error: null
      };
    }
    case fromUserCars.SET_USER_CAR_SUCCESS: {
      return {
        ...state,
        car: action.payload,
        carId: action.payload ? action.payload.id : null,
        loaded: true,
        loading: false,
        addSuccess: false,
        error: null
      };
    }
    case fromUserCars.SET_USER_CAR_FAIL: {
      return {
        ...state,
        cars: null,
        loaded: true,
        loading: false,
        addSuccess: false,
        error: action.payload.detail
      };
    }

    case fromUserCars.PATCH_USER_CAR:
    case fromUserCars.UPDATE_USER_CAR:
    case fromUserCars.REMOVE_USER_CAR: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case fromUserCars.PATCH_USER_CAR_SUCCESS:
    case fromUserCars.UPDATE_USER_CAR_SUCCESS:
    case fromUserCars.REMOVE_USER_CAR_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null
      };
    }
    case fromUserCars.PATCH_USER_CAR_FAIL:
    case fromUserCars.UPDATE_USER_CAR_FAIL:
    case fromUserCars.REMOVE_USER_CAR_FAIL: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: action.payload.detail
      };
    }

    case fromUserCars.RESET_USER_CARS_STATE: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export const getUserCars = (state: UserCarsState) => state.cars;
export const getUserCarEntities = (state: UserCarsState) =>
  state.entities;
export const getUserCar = (state: UserCarsState) =>
  state.car;
export const addUserCar = (state: UserCarsState) => state.addSuccess;
export const addUserCarError = (state: UserCarsState) => state.error;
export const getUserCarsLoaded = (state: UserCarsState) =>
  state.loaded;
export const getUserCarsLoading = (state: UserCarsState) =>
  state.loading;
