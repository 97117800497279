import {
  Pipe,
  PipeTransform,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { TranslationService } from '@core/service/translation.service';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import { Observable, Subscription } from 'rxjs';

const COUNT_CALLS = 0;

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform, OnDestroy {
  translations$: Observable<any>;
  translationsSub: Subscription;
  translationSequence = 0;
  translationSequenceLast = 0;
  translationKey = 'NO_TRANSLATION';
  translationValue = 'NO_TRANSLATION';
  destroyed = false;
  constructor(
    private translationService: TranslationService,
    private store: Store<fromRoot.AppState>,
    private _ref: ChangeDetectorRef
  ) {
    this.translations$ = this.store.pipe(select(fromRoot.getTranslations));
    this.translationsSub = this.translations$.subscribe(translations => {
      if (this.destroyed) {
        console.error('TranslatePipe: Running on destroyed component...');
        return;
      }
      this.translationSequence = this.translationSequence + 1;
      setTimeout(() => {
        if (!this.destroyed) {
          // console.warn(_ref);
          // _ref.detectChanges();
          _ref.markForCheck();
          // _appRef.tick();
        }
      });
      setTimeout(() => {
        _ref.markForCheck();
        // _appRef.tick();
      });
    });
  }
  transform(key: string, param = {}): string {
    if (this.destroyed) {
      console.error('TranslatePipe: Running on destroyed component...');
      return '';
    }
    if (
      this.translationSequence === this.translationSequenceLast &&
      this.translationKey === key &&
      this.translationKey !== 'NO_TRANSLATION'
    ) {
      return this.translationValue;
    }
    this.translationKey = key;
    this.translationValue = this.translationService.translate(key, param);
    this.translationSequenceLast = this.translationSequence;
    return this.translationValue;
  }

  ngOnDestroy() {
    this.destroyed = true;
    this.translationsSub.unsubscribe();
  }
}
