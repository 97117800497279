import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';

import * as SearchHistoryActions from '../actions/search-history.action';
import { SearchHistoryActionTypes } from '../actions/search-history.action';
import { StatisticsService } from '@shared/services/statistics.service';

@Injectable()
export class SearchHistoryEffects {
  constructor(
    private actions$: Actions,
    private statisticsService: StatisticsService
  ) {}

  @Effect() loadSubgroups$ = this.actions$.pipe(
    ofType(SearchHistoryActionTypes.SearchHistoryLoad),
    debounceTime(300),
    switchMap(() => {
      return this.statisticsService.getSearchHistory({ limit: 20 }).pipe(
        map(
          history => new SearchHistoryActions.SearchHistoryLoadSuccess(history)
        ),

        catchError(error =>
          of(new SearchHistoryActions.SearchHistoryLoadFail(error))
        )
      );
    })
  );
}
