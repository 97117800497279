import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ContactsActions from '../actions/contacts.action';
import { ContactsActionTypes } from '../actions/contacts.action';
import { ContactsService } from '@core/service/contacts.service';

@Injectable()
export class ContactsEffects {
  constructor(
    private actions$: Actions,
    private contactsService: ContactsService
  ) {}

  @Effect() loadContacts$ = this.actions$.pipe(
    ofType(ContactsActionTypes.ContactsLoad),
    switchMap(() => {
      return this.contactsService.getContacts().pipe(
        map(contacts => new ContactsActions.ContactsLoadSuccess(contacts)),
        catchError(error => of(new ContactsActions.ContactsLoadFail(error)))
      );
    })
  );
}
