import { Injectable } from '@angular/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap, debounceTime } from 'rxjs/operators';

import * as shippingActions from '../actions/shipping.action';

@Injectable()
export class ShippingEffects {
  constructor(private actions$: Actions) {}

  // @Effect()
  // loadShipping$ = this.actions$.ofType(checkoutActions.ShippingActionTypes.ShippingLoad).pipe(
  //   mergeMap(() => {
  //     return this.cartService.getShipping().pipe(
  //       map(data => new checkoutActions.LoadShippingSuccess(data)),
  //       catchError(error => of(new checkoutActions.LoadShippingFail(error)))
  //     );
  //   })
  // );

  // @Effect()
  // updateShipping$ = this.actions$.ofType(checkoutActions.ShippingActionTypes.UpdateShipping).pipe(
  //   map((action: checkoutActions.UpdateShipping) => action.payload),
  //   mergeMap((product) => {
  //     return this.cartService
  //       .updateShipping(product.id, product)
  //       .pipe(
  //         map(productItem => new checkoutActions.UpdateShippingSuccess(productItem)),
  //         catchError(error => of(new checkoutActions.UpdateShippingFail(error)))
  //       );
  //   })
  // );

  // @Effect()
  // loadShipping$ = this.actions$
  //   .ofType(shippingActions.ShippingActionTypes.ShippingLoad)
  //   .pipe(
  //     map((action: shippingActions.LoadShipping) => action.payload),
  //     map(shipping => {
  //       return new shippingActions.LoadShippingSuccess(shipping);
  //     })
  //   );

  @Effect() updateShipping$ = this.actions$
  .pipe(
      ofType(shippingActions.ShippingActionTypes.UpdateShipping),
      map((action: shippingActions.UpdateShipping) => action.payload),
      map(shipping => {
        return new shippingActions.UpdateShippingSuccess(shipping);
      })
    );
}
