import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { ProductList } from '../models';
import { ProductService } from './product.service';

@Injectable()
export class SearchService {
  private searchNavStatus = new BehaviorSubject<boolean>(true);
  currentSearchStatus = this.searchNavStatus.asObservable();

  productList: ProductList;

  constructor(private productService: ProductService) {}

  toggleSearch(status) {
    this.searchNavStatus.next(!status);
  }

  setProductList(filter) {
    return this.productService
      .getList(filter)
      .subscribe((productList: ProductList) => {
        this.productList = productList;
      });
  }

  getProductList() {
    return this.productList;
  }
}
