import {
  TranslationActions,
  TranslationActionTypes
} from '../actions/translation.action';

export interface TranslationState {
  language: string;
  translationSet: boolean;
  translations: { [key: string]: string };
  loadedApi: boolean;
  loadingApi: boolean;
  loaded: boolean;
  loading: boolean;
  notTranslatedKeys: number;
  notTranslatedEmailsKeys: number;
  notApprovedUsersCount: number;
}

const initialState: TranslationState = {
  language: 'en',
  translationSet: false,
  translations: {},
  loadedApi: false,
  loadingApi: false,
  loaded: false,
  loading: false,
  notTranslatedKeys: 0,
  notTranslatedEmailsKeys: 0,
  notApprovedUsersCount: 0,
};

export function reducer(
  state = initialState,
  action: TranslationActions
): TranslationState {
  switch (action.type) {
    case TranslationActionTypes.SetNotTranslatedCountLoaded: {
      return {
        ...state,
        notTranslatedKeys: action.payload
      };
    }
    case TranslationActionTypes.SetNotTranslatedEmailsCountLoaded: {
      return {
        ...state,
        notTranslatedEmailsKeys: action.payload
      };
    }
    case TranslationActionTypes.SetNotApprovedUsersCountLoaded: {
      return {
        ...state,
        notApprovedUsersCount: action.payload
      };
    }
    case TranslationActionTypes.SetTranslationLanguage: {
      return {
        ...state,
        translationSet: true,
        language: action.payload
      };
    }
    case TranslationActionTypes.TranslationLoadApi: {
      return {
        ...state,
        loadingApi: true
      };
    }
    case TranslationActionTypes.TranslationLoadApiSuccess: {
      const translations = action.payload;

      return {
        ...state,
        loadingApi: false,
        loadedApi: true,
        translations: translations
      };
    }
    case TranslationActionTypes.TranslationLoadApiFail: {
      return {
        ...state,
        loadingApi: false,
        loadedApi: false
      };
    }
    case TranslationActionTypes.TranslationLoad: {
      return {
        ...state,
        loading: true
      };
    }
    case TranslationActionTypes.TranslationLoadSuccess: {
      const translations = action.payload;

      return {
        ...state,
        loading: false,
        loaded: true,
        translations: translations
      };
    }
    case TranslationActionTypes.TranslationLoadFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case TranslationActionTypes.ResetTranslationState: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export const getTranslations = (state: TranslationState) => state.translations;
export const getTranslationLoading = (state: TranslationState) => state.loading;
export const getTranslationLoaded = (state: TranslationState) => state.loaded;
export const getTranslationLanguage = (state: TranslationState) =>
  state.language;
export const getNotTranslatedKeysCount = (state: TranslationState) =>
  state.notTranslatedKeys;
export const getNotTranslatedEmailsKeysCount = (state: TranslationState) =>
  state.notTranslatedEmailsKeys;
export const getNotApprovedUsersCount = (state: TranslationState) =>
  state.notApprovedUsersCount;
