import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss']
})
export class StatusChipComponent implements OnInit {
  /**
   * Chip success text.
   */
  @Input() success: string;

  /**
   * Chip warning text.
   */
  @Input() warning: string;

  /**
   * Chip error text.
   */
  @Input() error: string;

  /**
   * Chip info text.
   */
  @Input() info: string;

  @Input() type: string = '';
  @Input() text: string = '';

  constructor() {}

  ngOnInit() {}
}
