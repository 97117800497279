import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {
  constructor(private route: ActivatedRoute, private router: Router, private location: Location) {}

  @Input() shouldNavigateHome ? = false;
  @Input() shouldNavigateOther ? = false;
  @Input() otherPath ? = '/';

  /** Navigate back */
  backClick() {
    if (this.shouldNavigateHome) {
      this.navigateHome();
    } else {
      this.location.back();
    }
  }

  navigateHome() {
    this.router.navigate(['/']);
  }
}
