import { LocalStorageService } from '../local-storage/local-storage.service';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class DecoratorsService {
  static DevCache(cacheKeyPostfix: string = '', useFirstArgAsKeyElement = false) {
    const localStorageService = new LocalStorageService();
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
      // To enable cache for development, comment next line
      return descriptor;
      if (!target.constructor.name && !descriptor.value.name) {
        throw Error('Can\'t fetch key');
      }
      const cacheKey = target.constructor.name + '_' + descriptor.value.name + '_' + cacheKeyPostfix;
      console.log('DevCache(): called');
      const originalMethod = descriptor.value;

      descriptor.value = function (...args) {
        const computedKey = cacheKey + (useFirstArgAsKeyElement ? args[0] : '');
        const constCached = localStorageService.getItem(`sk_dev_cch_${computedKey}`);
        if (constCached) {
          console.warn(`Loading from cache: sk_dev_cch_${computedKey}`);
          return of(constCached);
        }

        let result: Observable<any> = originalMethod.apply(this, args);

        console.warn(`Saving to cache: sk_dev_cch_${computedKey}`);
        result = result.pipe(
          tap(data => localStorageService.setItem(`sk_dev_cch_${computedKey}`, data)),
        );

        return result;
      };
      return descriptor;
    };
  }
}
