import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { DropdownComponent } from './ui/dropdown/dropdown.component';
import { ProductStatePipe } from './pipes/product-state/product-state.pipe';
import { EllipsisPipe } from './pipes/ellipsis/ellipsis.pipe';
import { TranslatePipe } from './pipes/translate/translate.pipe';
import { TranslatePurePipe } from './pipes/translate/translate.pure.pipe';
import { SearchInputComponent } from './ui/search-input/search-input.component';
import { SearchNavComponent } from './components/search-nav/search-nav.component';
import { SearchByCarNavComponent } from './components/search-by-car-nav/search-by-car-nav.component';
import { InputComponent } from './ui/input/input.component';
import { InputWithAutocompleteComponent } from './ui/input/input-with-autocomplete.component';
import { SelectComponent } from './ui/select/select.component';
import { ButtonComponent } from './ui/button/button.component';
import { InputValidationComponent } from './ui/input/input-validation/input-validation.component';
import { SelectPipe } from './ui/select/pipes/select.pipe';
import { CheckboxComponent } from './ui/checkbox/checkbox.component';
import { RadioComponent } from './ui/radio/radio.component';
import { InputAutocompleteComponent } from './ui/input/input-autocomplete/input-autocomplete.component';
import { PaginationComponent } from './ui/pagination/pagination.component';
import { TableComponent } from './ui/table/table.component';
import { BreadcrumbsComponent } from './ui/breadcrumbs/breadcrumbs.component';
import { TextareaComponent } from './ui/textarea/textarea.component';
import { TabsComponent } from './ui/tabs/tabs.component';
import { StatusChipComponent } from './ui/status-chip/status-chip.component';
import { UserStatusPipe } from './pipes/user-status/user-status.pipe';
import { ModalComponent } from './ui/modal/modal.component';
import { DatePickerComponent } from './ui/datepicker/datepicker.component';
import { FilterEmptyPipe } from './pipes/filter-empty/filter-empty.pipe';
import { UserIpStatusPipe } from './pipes/user-ip-status/user-ip-status.pipe';
import { CollapseComponent } from './ui/collapse/collapse.component';
import { BackButtonComponent } from './ui/back-button/back-button.component';
import { BooleanPipe } from './pipes/boolean/boolean.pipe';
import { SelectSearchPipe } from './ui/select/pipes/select-search.pipe';
import { TecdocListComponent } from './components/tecdoc-list/tecdoc-list.component';
import { CapitalizePipe } from './pipes/capitalize/capitalize.pipe';
import { StepperComponent } from './ui/stepper/stepper.component';
import { HelperService } from '@shared/services/helper.service';
import { FileService } from '@shared/services/file.service';
import { CartEffects } from '@pages/cart/store';
import { EffectsModule } from '@ngrx/effects';
import { CartService } from '@pages/cart/store/services/cart.service';
import { OrderService } from '@pages/checkout/services/order.service';
import { DropdownItemComponent } from './ui/dropdown/dropdown-item/dropdown-item.component';
import { TableColumnRoleComponent } from './ui/table/components/table-column-role/table-column-role.component';
import { GroupSelectComponent } from './ui/group-select/group-select.component';
import { GroupSelectPipe } from './ui/group-select/pipes/group-select.pipe';
import { OrderInfoComponent } from '@shared/components/order-info/order-info.component';
import { OrderItemsComponent } from '@shared/components/order-items/order-items.component';
import { TableHeaderComponent } from './ui/table/components/table-header/table-header.component';
import { SidebarComponent } from './components/sidebar/containers/sidebar.component';
import { SidebarItemComponent } from './components/sidebar/components/sidebar-item/sidebar-item.component';
import { SidebarSearchHistoryComponent } from './components/sidebar/components/sidebar-search-history/sidebar-search-history.component';
import { StatisticsService } from '@shared/services/statistics.service';
import { DateSubstringPipe } from './pipes/date-substring/date-substring.pipe';
import { SlideToggleComponent } from './ui/slide-toggle/slide-toggle.component';
import { OrderStatusComponent } from './components/order-status/order-status.component';
import { OrderStatusStepsComponent } from './components/order-status-steps/order-status-steps.component';
import { TranslationService } from '@core/service/translation.service';
import { CustomCurrencyPipe } from '@shared/pipes/custom-currency/custom-currency';
import { CustomNumberPipe } from '@shared/pipes/custom-number/custom-number';
import { CurrencyNamePipe } from '@shared/pipes/currency-name/currency-name';
import { UseLocalCurrency } from '@shared/pipes/use-local-currency/use-local-currency';
import { LargeTextComponent } from './components/large-text/large-text.component';
import { UnauthHeaderComponent } from '@shared/components/unauth-header/unauth-header.component';
import { PopoverButtonComponent } from '@shared/ui/popover-button/popover-button.component';
import { SatPopoverModule } from '@ncstate/sat-popover';
import { AccordionComponent } from '@shared/ui/accordion/accordion.component';
import { PanelComponent } from '@shared/ui/accordion/components/panel/panel.component';
import { OveralFilterComponent } from '@shared/components/overal-filter/overal-filter.component';
import { ProductTileComponent } from '@shared/components/product-tile/product-tile.component';
import { CatalogueComponent } from '@shared/components/catalogue/catalogue.component';
import { ErrorPageComponent } from '@shared/components/error-page/error-page.component';
import { FavoriteProductsComponent } from '@shared/components/favorite-products/favorite-products.component';
import { ChoiceViewComponent } from '@shared/components/choice-view/choice-view.component';
import { OnlineUsersComponent } from '@shared/components/online-users.component';
import { OrderZiticityComponent } from '@shared/components/order-ziticity/order-ziticity.component';
import { ProductNamePipe } from '@shared/pipes/product-name/product-name.pipe';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { OrderSummaryComponent } from './components/order-summary/order-summary.component';
import { OrderCheckoutSummaryComponent } from './components/order-checkout-summary/order-checkout-summary.component';
import { OrderPaymentComponent } from '@shared/components/order-payment/order-payment.component';
import { DebounceClickDirective } from './directives/debounce-directive';
import { ThrottleClickDirective } from './directives/throttle-directive';
import { CataloguePhotoComponent } from './components/catalogue-photo/catalogue-photo.component';
import { AgTableComponent } from './ui/ag-table/ag-table.component';
import { ShowcaseService } from '@pages/dashboard/services/showcase.service';
import { AgGridModule } from '@ag-grid-community/angular-legacy';
import { ChoiceFilter } from './ui/ag-table/choice.component.filter';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    EffectsModule.forFeature([CartEffects]),
    SatPopoverModule,
    FormsModule,
    NgZorroAntdModule,
    AgGridModule.withComponents([ChoiceFilter]),
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownComponent,
    SelectPipe,
    ProductStatePipe,
    EllipsisPipe,
    SearchInputComponent,
    SearchNavComponent,
    SearchByCarNavComponent,
    InputComponent,
    InputWithAutocompleteComponent,
    InputAutocompleteComponent,
    InputValidationComponent,
    SelectComponent,
    ButtonComponent,
    CheckboxComponent,
    RadioComponent,
    PaginationComponent,
    TableComponent,
    BreadcrumbsComponent,
    TextareaComponent,
    TabsComponent,
    StatusChipComponent,
    ModalComponent,
    UserStatusPipe,
    DatePickerComponent,
    FilterEmptyPipe,
    CollapseComponent,
    BackButtonComponent,
    TecdocListComponent,
    CapitalizePipe,
    StepperComponent,
    GroupSelectComponent,
    GroupSelectPipe,
    OrderInfoComponent,
    OrderItemsComponent,
    TableHeaderComponent,
    SidebarComponent,
    SidebarSearchHistoryComponent,
    SlideToggleComponent,
    OrderStatusComponent,
    OrderStatusStepsComponent,
    CustomCurrencyPipe,
    CustomNumberPipe,
    CurrencyNamePipe,
    UseLocalCurrency,
    TranslatePipe,
    TranslatePurePipe,
    LargeTextComponent,
    UnauthHeaderComponent,
    PopoverButtonComponent,
    AccordionComponent,
    PanelComponent,
    OveralFilterComponent,
    ProductTileComponent,
    CatalogueComponent,
    CataloguePhotoComponent,
    ErrorPageComponent,
    FavoriteProductsComponent,
    ChoiceViewComponent,
    OnlineUsersComponent,
    OrderZiticityComponent,
    ProductNamePipe,
    OrderSummaryComponent,
    OrderPaymentComponent,
    OrderCheckoutSummaryComponent,
    AgTableComponent,
  ],
  declarations: [
    DropdownComponent,
    SelectPipe,
    ProductStatePipe,
    EllipsisPipe,
    SearchInputComponent,
    SearchNavComponent,
    SearchByCarNavComponent,
    InputComponent,
    InputWithAutocompleteComponent,
    InputAutocompleteComponent,
    InputValidationComponent,
    SelectComponent,
    ButtonComponent,
    CheckboxComponent,
    RadioComponent,
    PaginationComponent,
    TableComponent,
    BreadcrumbsComponent,
    TextareaComponent,
    TabsComponent,
    StatusChipComponent,
    ModalComponent,
    UserStatusPipe,
    DatePickerComponent,
    FilterEmptyPipe,
    UserIpStatusPipe,
    CollapseComponent,
    BackButtonComponent,
    BooleanPipe,
    SelectSearchPipe,
    TecdocListComponent,
    CapitalizePipe,
    StepperComponent,
    DropdownItemComponent,
    TableColumnRoleComponent,
    GroupSelectComponent,
    GroupSelectPipe,
    OrderInfoComponent,
    OrderItemsComponent,
    TableHeaderComponent,
    SidebarComponent,
    SidebarItemComponent,
    SidebarSearchHistoryComponent,
    DateSubstringPipe,
    SlideToggleComponent,
    OrderStatusComponent,
    OrderStatusStepsComponent,
    TranslatePipe,
    TranslatePurePipe,
    CurrencyNamePipe,
    UseLocalCurrency,
    CustomCurrencyPipe,
    CustomNumberPipe,
    LargeTextComponent,
    UnauthHeaderComponent,
    PopoverButtonComponent,
    AccordionComponent,
    PanelComponent,
    OveralFilterComponent,
    ProductTileComponent,
    CatalogueComponent,
    CataloguePhotoComponent,
    ErrorPageComponent,
    FavoriteProductsComponent,
    ChoiceViewComponent,
    OnlineUsersComponent,
    OrderZiticityComponent,
    ProductNamePipe,
    OrderSummaryComponent,
    OrderPaymentComponent,
    OrderCheckoutSummaryComponent,
    DebounceClickDirective,
    ThrottleClickDirective,
    AgTableComponent,
    ChoiceFilter,
  ],
  providers: [
    UseLocalCurrency,
    FilterEmptyPipe,
    BooleanPipe,
    CapitalizePipe,
    SelectSearchPipe,
    HelperService,
    FileService,
    CartService,
    OrderService,
    StatisticsService,
    GroupSelectPipe,
    TranslatePipe,
    TranslatePurePipe,
    TranslationService,
    DateSubstringPipe,
    ShowcaseService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
