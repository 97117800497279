<div class="app-checkbox" (change)="onValueChange(!val)">
  <input class="checkbox"
         id="{{name}}-checkbox"
         type="checkbox"
         [value]="val"
         [checked]="val"
         (keydown)="handleKeyboardEvent($event)"
         [disabled]="disabled">
  <label for="{{name}}-checkbox">
    <i *ngIf="icon" class="{{icon.class}}">{{icon.name}}</i>
    <span *ngIf="label">{{label | translate}}</span>
  </label>
</div>

