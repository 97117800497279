<div class="card">
  <div class="card-body">
    <div class="align-center">
      <a href="/login" routerLink="/login">
        <img src="assets/images/logo-skuba.png" alt="{{'SKUBA_LOGO'| translate}}" class="home-logo form-logo">
      </a>
    </div>
    <h1>{{'EMAIL_VERIFICATION' | translate}}</h1>
    <p *ngIf="status">{{'EMAIL_VERIFICATION_DESCRIPTION' | translate}}</p>
    <p *ngIf="!status"><strong>{{'VERIFICATION_ERROR' | translate}}</strong></p>
    <div *ngIf="status" class="align-right verification-action">
      <app-button className="btn-red" text="CONFIRM" (click)="continue()"></app-button>
    </div>
  </div>
</div>
