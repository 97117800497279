import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateSubstring'
})
export class DateSubstringPipe implements PipeTransform {
  transform(date: string, start: number = 0, end: number = 10): string {
    if (date) {
      return date.substring(start, end);
    }

    return null;
  }
}
