import {
  HelperActions,
  HelperActionTypes
} from '../actions/helper.action';

export interface HelperState {
  showVersionErrorModal: boolean;
}

const initialState: HelperState = {
  showVersionErrorModal: false
};

export function reducer(
  state = initialState,
  action: HelperActions
): HelperState {
  switch (action.type) {
    case HelperActionTypes.VersionErrorModal: {
      return {
        ...state,
        showVersionErrorModal: true
      };
    }
    case HelperActionTypes.HideVersionErrorModal: {
      return {
        ...state,
        showVersionErrorModal: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getVersionErrorModalState = (state: HelperState) => state;
