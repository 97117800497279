import { AuthActions, AuthActionTypes } from '../actions/auth.action';

export interface State {
  error: string | null;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

export function reducer(state = initialState, action: AuthActions): State {
  switch (action.type) {
    case AuthActionTypes.SilentLogin:
    case AuthActionTypes.Login: {
      return { ...state, error: null, pending: true };
    }
    case AuthActionTypes.LoginSuccessRedirect:
    case AuthActionTypes.LoginSuccess: {
      return {
        ...state,
        error: null,
        pending: false
      };
    }
    case AuthActionTypes.LoginFailure: {
      return {
        ...state,
        error: action.payload,
        pending: false
      };
    }
    default: {
      return state;
    }
  }
}

export const getError = (state: State) => state.error;
export const getPending = (state: State) => state.pending;
