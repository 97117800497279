import { Action } from '@ngrx/store';
import { IOrderList } from '@shared/models';

export enum ProductOrderHistoryActionTypes {
  ProductOrderHistoryLoad = '[ProductOrderHistory] Load Product Order History',
  ProductOrderHistoryLoadFail = '[ProductOrderHistory] Load Product Order History Fail',
  ProductOrderHistoryLoadSuccess = '[ProductOrderHistory] Load Product Order History Success',
  ResetProductOrderHistoryState = '[ProductOrderHistory] Reset State',
  OpenProductOrderHistory = '[ProductOrderHistory] Open Product Order History'
}

export class ProductOrderHistoryLoad implements Action {
  readonly type = ProductOrderHistoryActionTypes.ProductOrderHistoryLoad;

  constructor(public payload: { skn: string; limit: number; offset: number }) {}
}

export class ProductOrderHistoryLoadFail implements Action {
  readonly type = ProductOrderHistoryActionTypes.ProductOrderHistoryLoadFail;

  constructor(public payload: any) {}
}

export class ProductOrderHistoryLoadSuccess implements Action {
  readonly type = ProductOrderHistoryActionTypes.ProductOrderHistoryLoadSuccess;

  constructor(public payload: IOrderList) {}
}

export class ResetProductOrderHistoryState implements Action {
  readonly type = ProductOrderHistoryActionTypes.ResetProductOrderHistoryState;
}

export class OpenProductOrderHistory implements Action {
  readonly type = ProductOrderHistoryActionTypes.OpenProductOrderHistory;
}

export type ProductOrderHistoryActions =
  | ProductOrderHistoryLoad
  | ProductOrderHistoryLoadFail
  | ProductOrderHistoryLoadSuccess
  | ResetProductOrderHistoryState
  | OpenProductOrderHistory;
