import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verification-info',
  templateUrl: './verification-info.component.html',
  styleUrls: ['./verification-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerificationInfoComponent implements OnInit {
  @Input() status: boolean;

  constructor(private router: Router) {}

  ngOnInit() {}

  continue() {
    return this.router.navigate(['dashboard']);
  }
}
