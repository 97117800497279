import { Pipe, PipeTransform } from '@angular/core';
import { CustomNumberPipeService } from '@app/shared/services/custom-number.service';

@Pipe({
    name: 'customNumber'
})

export class CustomNumberPipe implements PipeTransform{

    constructor(private customNumberPipeService: CustomNumberPipeService){}

    transform(number: any, decimal: number = 2): Promise<string> {
        const promise = new Promise<string>((resolve, reject) => {
            this.customNumberPipeService.deferExecution(resolve, 0, number, decimal, '', '');
        });
        return promise;
    }
}