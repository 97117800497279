import { ISubGroup } from '@app/core/models/subgroup';
import {
  SubGroupActions,
  SubGroupActionTypes
} from '../actions/subGroup.action';
import { init } from 'protractor/built/launcher';

export interface SubGroupState {
  subGroups: ISubGroup[];
  loaded: boolean;
  loading: boolean;
}

const initialState: SubGroupState = {
  subGroups: [],
  loaded: false,
  loading: false
};

export function reducer(
  state = initialState,
  action: SubGroupActions
): SubGroupState {
  switch (action.type) {
    case SubGroupActionTypes.SubGroupsLoad: {
      return {
        ...state,
        loading: true
      };
    }
    case SubGroupActionTypes.SubGroupsLoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        subGroups: action.payload.filter(x => x.name !== null)
      };
    }
    case SubGroupActionTypes.SubGroupsLoadFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }

    case SubGroupActionTypes.ResetSubGroupsState: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export const getSubGroupsEntities = (state: SubGroupState) => state.subGroups;
export const getSubGroupsLoading = (state: SubGroupState) => state.loading;
export const getSubGroupsLoaded = (state: SubGroupState) => state.loaded;
