import {
  SidebarActions,
  SidebarActionTypes
} from '@app/core/store/actions/sidebar.action';

export interface SidebarState {
  sidebarOpen: boolean;
  activeItems: any;
}

const initialState: SidebarState = {
  sidebarOpen: false,
  activeItems: []
};

export function reducer(
  state: SidebarState = initialState,
  action: SidebarActions
): SidebarState {
  switch (action.type) {
    case SidebarActionTypes.CloseSidebar:
      return {
        ...state,
        sidebarOpen: false,
        activeItems: []
      };

    case SidebarActionTypes.OpenSidebar:
      return {
        ...state,
        sidebarOpen: true,
        activeItems: [0]
      };

    case SidebarActionTypes.ToggleSidebar:
      let activeItems = [];

      if (action.payload) {
        activeItems = [0];
      }

      return {
        ...state,
        sidebarOpen: action.payload,
        activeItems: activeItems
      };

    case SidebarActionTypes.ResetSidebarState:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
export const getShowSidebar = (state: SidebarState) => state.sidebarOpen;
export const getActiveItems = (state: SidebarState) => state.activeItems;
