import { forwardRef } from '@angular/core';
import { Routes } from '@angular/router';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { StatusGuard } from './services/status-guard.service';
import { AdminStatusGuard } from './services/admin-guard.service';
import { LoginPageComponent } from './containers/login-page/login-page.component';
import { LoginFormComponent } from './components/login/login-form.component';
import { RegisterComponent } from '@app/auth/containers/register-page/register-page.component';
import { RegisterFormComponent } from '@app/auth/components/register/register-form.component';
import { ShopService } from '@shared/services/shop.service';
import { UsersService } from '@pages/users/services/users.service';
import { VerificationPageComponent } from '@app/auth/containers/verification-page/verification-page.component';
import { VerificationInfoComponent } from '@app/auth/components/verification-info/verification-info.component';
import { ForgotPasswordPageComponent } from '@app/auth/containers/forgot-password-page/forgot-password-page.component';
import { ForgotPasswordFormComponent } from '@app/auth/components/forgot-password-form/forgot-password-form.component';
import { ForgotPasswordResetPageComponent } from '@app/auth/containers/forgot-password-reset-page/forgot-password-reset-page.component';
import { ForgotPasswordResetFormComponent } from '@app/auth/components/forgot-password-reset-form/forgot-password-reset-form.component';
import { ForgotUserPageComponent } from '@app/auth/containers/forgot-user-page/forgot-user-page.component';
import { ForgotUserFormComponent } from '@app/auth/components/forgot-user-form/forgot-user-form.component';
import { ErrorPageComponent } from '@shared/components/error-page/error-page.component';
import { LanguageGuard } from './services/language-guard.service';
export const COMPONENTS = [
    LoginPageComponent,
    LoginFormComponent,
    RegisterComponent,
    RegisterFormComponent,
    VerificationPageComponent,
    VerificationInfoComponent,
    ForgotPasswordPageComponent,
    ForgotPasswordFormComponent,
    ForgotPasswordResetPageComponent,
    ForgotPasswordResetFormComponent,
    ForgotUserPageComponent,
    ForgotUserFormComponent
];
export class AuthModule {
    static forRoot() {
        return {
            // eslint-disable-next-line @angular-eslint/no-forward-ref
            ngModule: forwardRef(() => (RootAuthModule)),
            providers: [
                AuthService,
                AuthGuard,
                AdminStatusGuard,
                UsersService,
                ShopService,
                StatusGuard,
                LanguageGuard,
            ]
        };
    }
}
const ɵ0 = {
    translationKey: 'LOGIN',
    paramsKey: ''
}, ɵ1 = {
    translationKey: 'LOGOUT'
}, ɵ2 = {
    translationKey: 'REGISTER'
}, ɵ3 = {
    translationKey: 'EMAIL_VERIFICATION'
}, ɵ4 = {
    translationKey: 'FORGOT_PASSWORD_Q'
}, ɵ5 = {
    translationKey: 'FORGOT_USERNAME_Q'
}, ɵ6 = {
    translationKey: 'FORGOT_RESET'
};
const routes = [
    {
        path: 'login',
        component: LoginPageComponent,
        data: ɵ0,
        canActivate: [LanguageGuard]
    },
    {
        path: 'logout',
        component: LoginPageComponent,
        data: ɵ1
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: ɵ2
    },
    {
        path: 'email/verification/:user/:token',
        component: VerificationPageComponent,
        data: ɵ3
    },
    {
        path: 'auth/forgot-password',
        component: ForgotPasswordPageComponent,
        data: ɵ4
    },
    {
        path: 'auth/forgot-user',
        component: ForgotUserPageComponent,
        data: ɵ5
    },
    {
        path: 'auth/password/forgot_password_reset/:user/:token',
        component: ForgotPasswordResetPageComponent,
        data: ɵ6
    },
    { path: '**', component: ErrorPageComponent }
];
export class RootAuthModule {
}
export const routedComponents = [
    LoginPageComponent,
    RegisterComponent,
    VerificationPageComponent,
    ForgotPasswordPageComponent,
    ForgotPasswordResetPageComponent,
    ForgotUserPageComponent
];
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
