import * as fromCart from './cart.reducer';

import {
  ActionReducerMap, createFeatureSelector
} from '@ngrx/store';

export interface CartState {
  products: fromCart.CartState;
}

export const reducers: ActionReducerMap<CartState> = {
  products: fromCart.reducer,
};

export const getCartState = createFeatureSelector<CartState>(
  'cart'
);

