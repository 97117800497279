var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SimilarProductsActions from '../actions/similar-products.action';
import { SimilarProductsActionTypes } from '../actions/similar-products.action';
import { ProductService } from '@shared/services/product.service';
export class SimilarProductsEffects {
    constructor(actions$, productService) {
        this.actions$ = actions$;
        this.productService = productService;
        this.loadSubgroups$ = this.actions$.pipe(ofType(SimilarProductsActionTypes.SimilarProductsLoad), debounceTime(300), map((action) => action.payload), switchMap(body => {
            return this.productService.boughtWith(body).pipe(map(history => new SimilarProductsActions.SimilarProductsLoadSuccess(history)), catchError(error => of(new SimilarProductsActions.SimilarProductsLoadFail(error))));
        }));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], SimilarProductsEffects.prototype, "loadSubgroups$", void 0);
