import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import { Subject, Subscription, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '@app/auth/services/auth.service';
import { fadeIn, fadeInDown } from '@shared/animations';
import { PageComponent } from '@app/shared/models/page-component';

@Component({
  selector: 'app-verification-page',
  templateUrl: './verification-page.component.html',
  styleUrls: ['./verification-page.component.scss'],
  animations: [fadeInDown, fadeIn],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VerificationPageComponent extends PageComponent implements OnInit, OnDestroy {
  verifySuccess = false;
  router$: Observable<any>;
  routerSub: Subscription;
  authSub: Subscription;

  constructor(
    private store: Store<fromRoot.AppState>,
    private ref: ChangeDetectorRef,
    private authService: AuthService
  ) {
    super();
    this.router$ = this.store.pipe(select(fromRoot.getRouterState));
  }

  ngOnInit() {
    super.ngOnInit();
    this.routerSub = this.router$.subscribe(router => {
      if (router && router.state && router.state.params) {
        const user = router.state.params.user;
        const token = router.state.params.token;

        this.authSub = this.authService
          .verifyEmail({
            user: user,
            token: token
          })
          .subscribe(
            () => {
              if (this.routerSub) {
                this.routerSub.unsubscribe();
              }
              this.verifySuccess = true;
              this.ref.detectChanges();
            },
            () => {
              if (this.routerSub) {
                this.routerSub.unsubscribe();
              }
              this.verifySuccess = false;
              this.ref.detectChanges();
            }
          );
      }
    });
  }

  ngOnDestroy() {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }
}
