import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  Router
} from '@angular/router';

@Injectable()
export class LanguageGuard implements CanActivate {
  languageFilter: string[] = [
    'en', 'bg', 'el', 'cs', 'et', 'fi', 'hr', 'hu', 'it', 'lv', 'nl', 'pl', 'ro', 'sr', 'ru', 'sv', 'sl', 'sk', 'uk'
  ];
  constructor(
    private router: Router,
  ) {
  }
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const url = route.url[0].path;
    const qlanguage = route.queryParams['language'];
    if (qlanguage && typeof(qlanguage) === 'string') {
      if (qlanguage === 'all') {
        return true;
      } else {
        if (this.languageFilter && this.languageFilter.includes(qlanguage.toLowerCase())) {
          return true;
        } else {
          const redirectPath = url;
          const newParams = {language: 'en'};
          const currentParams = route.queryParams;
          const mergedqueryParams = { ...currentParams, ...newParams };
          this.router.navigate([redirectPath], {queryParams: { ...mergedqueryParams } , queryParamsHandling: 'merge'});
          return false;
        }
      }
    } else {
      return true;
    }
  }
}
