<div class="nav-cart-button" *ngIf="!cartIsOpen && user && user.user_settings && user.user_settings.enable_basket_logic">
  <nz-spin [nzSpinning]="isLoadingCart" 
            [ngStyle]="{'width': '63px'}"
            (mouseenter)="mouseEnter()" 
            (mouseleave)="mouseLeave()"
            style="width: 63px;float: right;top: -53px;"
            >
    <a [routerLink]="['/cart']">
      <nz-badge [nzCount]="totalQuantity">
          <app-button
            class="collapse-icon"
            className="btn-r-small"
            icon="fas fa-shopping-cart"
          ></app-button>
      </nz-badge>
    </a>
  </nz-spin>
  <div [ngClass]="{'nav-cart-and-toast': true, 'cart-mini-hidden': !cartMiniShowUp}">
    <div class="nav-cart-and-toast-container">
      <app-cart-mini *ngIf="user && user.user_settings && user.user_settings.enable_basket_mini && !cartIsOpen"
                      [ngClass]="{
                        'cart-mini-show-up': cartMiniShowUp,
                        'cart-mini-max-height': cartMiniMaxHeight,
                        'cart-mini-display': cartMiniDisplay
                      }"
                      (mouseenter)="mouseEnter()"
                      (mouseleave)="mouseLeave()"
                      (selectOpen)="selecting($event)">
      </app-cart-mini>
      <app-toast></app-toast>
    </div>
  </div>
</div>
<app-toast *ngIf="!user || !user.user_settings || !user.user_settings.enable_basket_logic"></app-toast>