import { IUser, IToken } from '@app/auth/models/user';
import { AuthActions, AuthActionTypes } from '../actions/auth.action';
import { ActionReducer } from '@ngrx/store';
import { AppState } from '@app/reducers';
export interface State {
  loggedIn: boolean;
  returnUrl: string;
}

export const initialState: State = {
  loggedIn: false,
  returnUrl: null
};

export function reducer(state = initialState, action: AuthActions): State {
  switch (action.type) {
    case AuthActionTypes.LoginSuccess: {
      return {
        ...state,
        loggedIn: true
      };
    }
    case AuthActionTypes.SilentAuth: {
      return {
        ...state,
        returnUrl: action.payload
      };
    }
    case AuthActionTypes.SilentAuthNoAction: {
      return {
        ...state,
        returnUrl: action.payload
      };
    }
    case AuthActionTypes.Logout: {
      return initialState;
    }
    case AuthActionTypes.LoginUpdate: {
      return {
        ...state,
        loggedIn: true
      };
    }
    case AuthActionTypes.AuthReset: {
      return {
        ...initialState
      };
    }
    default: {
      return state;
    }
  }
}

export const getLoggedIn = (state: State) => state.loggedIn;
