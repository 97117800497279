var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnDestroy } from '@angular/core';
import { ApiService } from '@app/core/api.service';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import { DecoratorsService } from '../develop/decorators';
export class TranslationService {
    constructor(apiService, store) {
        this.apiService = apiService;
        this.store = store;
        this.translations$ = this.store.pipe(select(fromRoot.getTranslations));
        this.translationsSub = this.translations$.subscribe(translations => {
            this.translations = translations;
        });
    }
    getNotTranslatedCount(language_code) {
        return this.apiService.get(`/translations/translations_count/${language_code}/`);
    }
    getNotTranslatedEmailsCount(language_code) {
        return this.apiService.get(`/translations/email_translations_count/`);
    }
    getNotApprovedUsersCount() {
        return this.apiService.get(`/translations/users_not_approved_count/`);
    }
    loadTranslationApi(language) {
        return this.apiService.get(`/translations/translations/${language}/`);
    }
    loadTranslation(language) {
        return this.apiService.getJSON('json', `./assets/i18n/${language}.json`);
    }
    translate(code, params) {
        if (params && this.translations[code]) {
            let result = this.translations[code];
            Object.keys(params).forEach(key => {
                result = result.split(`{{${key}}}`).join(params[key]);
            });
            return result;
        }
        else {
            // console.log(this.translations[code]);
            return this.translations[code] || code;
        }
    }
    ngOnDestroy() {
        if (this.translationsSub) {
            this.translationsSub.unsubscribe();
        }
    }
}
__decorate([
    DecoratorsService.DevCache('', true),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TranslationService.prototype, "getNotTranslatedCount", null);
__decorate([
    DecoratorsService.DevCache(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TranslationService.prototype, "getNotTranslatedEmailsCount", null);
__decorate([
    DecoratorsService.DevCache(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], TranslationService.prototype, "getNotApprovedUsersCount", null);
__decorate([
    DecoratorsService.DevCache('', true),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String]),
    __metadata("design:returntype", void 0)
], TranslationService.prototype, "loadTranslationApi", null);
