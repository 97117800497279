import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import * as fromAuth from '@app/auth/store';
import accounting from 'accounting-js';

@Injectable({
  providedIn: 'root',
})

export class CustomNumberPipeService implements OnDestroy {
  user$: Observable<any>;
  userSub: Subscription;
  user: any;

  // countryThousandsSeparatorSpace = new Set(['ua','ru','by','lt','lv','fi','ee','bg','sk','pl','hu','cz'])
  countryThousandsSeparatorDot = new Set(['gr', 'ro', 'si', 'rs', 'se', 'it', 'hr'])

  country = '';
  countrySet = false;
  countryError = false;

  constructor(private store: Store<fromRoot.AppState>) {
    this.user$ = this.store.pipe(select(fromAuth.getUser));
    this.userSub = this.user$.subscribe(user => {
      this.user = user;
      if (
        user &&
        user.profile &&
        user.profile.client &&
        user.profile.client.company &&
        user.profile.client.company.country
      ) {
        this.country = user.profile.client.company.country.code;
        this.countrySet = true;
        this.countryError = false;
      }
    });
  }

  deferExecution(
    resolve: (value?: string | PromiseLike<string>) => void,
    count: number,
    number: any,
    decimal: number,
    symbol: string = '',
    format: string = '%s%v',
    money: boolean = false
  ) {
    if (this.countryError) {
      resolve(number);
    }
    if (this.countrySet) {
      if (typeof number === 'string' || typeof number === 'number'){
        if (money){
          resolve(accounting.formatMoney(number, {
          precision : decimal,
          thousand : this.countryThousandsSeparatorDot.has(this.country) ? "." : " ",
          decimal : ',',
          symbol : symbol,
          format : format
          }));
        } else {
          resolve(accounting.formatNumber(number, {
          precision : decimal,
          thousand : this.countryThousandsSeparatorDot.has(this.country) ? "." : " ",
          decimal : ","
          }));
        }
      }
    } else {
      if (count > 10) {
        console.error(
          'customCurrency.deferExecution.error: user had defered execution more than 10 times.'
        );
        resolve(number);
      } else {
        window.setTimeout(() => {
          this.deferExecution(resolve, count + 1, number, decimal, symbol, format, money);
        }, 300);
      }
    }
  }

  ngOnDestroy(): void {
    if(this.userSub){
      this.userSub.unsubscribe();
    }
  }
}
