import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolean'
})
export class BooleanPipe implements PipeTransform {
  transform(value: string | boolean): boolean {
    if (value === true || value === 'true') {
      return true;
    } else if (value === false || value === 'false') {
      return false;
    }

    return false;
  }
}
