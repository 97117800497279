import {
  ActionReducerMap,
  createSelector,
  createFeatureSelector,
  ActionReducer,
  MetaReducer
} from '@ngrx/store';
import { environment } from '@environments/environment';
import * as fromRouter from '@ngrx/router-store';
import * as fromLayout from '../core/store/reducers/layout.reducer';
import * as fromUserAdresses from '../core/store/reducers/user-addresses.reducer';
import * as fromUserCars from '../core/store/reducers/user-cars.reducer';
import * as fromToast from '../core/store/reducers/toast.reducer';
import * as fromOrder from '../core/store/reducers/order.reducer';
import * as fromSubGroups from '../core/store/reducers/subGroup.reducer';
import * as fromProduct from '../core/store/reducers/product.reducer';
import * as fromCart from '../pages/cart/store/reducers/cart.reducer';
import * as fromCountries from '../core/store/reducers/country.reducer';
import * as fromContacts from '../core/store/reducers/contacts.reducer';
import * as fromLanguages from '../core/store/reducers/language.reducer';
import * as fromTranslation from '../core/store/reducers/translation.reducer';
import * as fromSidebar from '../core/store/reducers/sidebar.reducer';
import * as fromSearchHistory from '../core/store/reducers/search-history.reducer';
import * as fromLastRoute from '../core/store/reducers/last-route.reducer';
import * as fromProductOrderHistory from '../core/store/reducers/product-order-history.reducer';
import * as fromSimilarProducts from '../core/store/reducers/similar-products.reducer';
import * as fromHelper from '@core/store/reducers/helper.reducer';

import {
  RouterStateSnapshot,
  Params,
  ActivatedRouteSnapshot
} from '@angular/router';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */

import { RouterStateSerializer } from '@ngrx/router-store';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

// import * as fromLayout from '../core/reducers/layout';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface AppState {
  layout: fromLayout.LayoutState;
  userAddresses: fromUserAdresses.UserAddressesState;
  userCars: fromUserCars.UserCarsState;
  cart: fromCart.CartState;
  subGroups: fromSubGroups.SubGroupState;
  countries: fromCountries.CountryState;
  product: fromProduct.ProductState;
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  helper: fromHelper.HelperState,
  lastRoute: fromLastRoute.LastRouteState;
  toast: fromToast.ToastState;
  order: fromOrder.OrderState;
  contacts: fromContacts.ContactsState;
  languages: fromLanguages.LanguagesState;
  translation: fromTranslation.TranslationState;
  sidebar: fromSidebar.SidebarState;
  searchHistory: fromSearchHistory.SearchHistoryState;
  productOrderHistory: fromProductOrderHistory.ProductOrderHistoryState;
  similarProducts: fromSimilarProducts.SimilarProductsState;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<AppState> = {
  subGroups: fromSubGroups.reducer,
  countries: fromCountries.reducer,
  cart: fromCart.reducer,
  product: fromProduct.reducer,
  userAddresses: fromUserAdresses.reducer,
  userCars: fromUserCars.reducer,
  layout: fromLayout.reducer,
  router: fromRouter.routerReducer,
  helper: fromHelper.reducer,
  lastRoute: fromLastRoute.reducer,
  toast: fromToast.reducer,
  order: fromOrder.reducer,
  contacts: fromContacts.reducer,
  languages: fromLanguages.reducer,
  translation: fromTranslation.reducer,
  sidebar: fromSidebar.reducer,
  searchHistory: fromSearchHistory.reducer,
  productOrderHistory: fromProductOrderHistory.reducer,
  similarProducts: fromSimilarProducts.reducer
};

// console.log all actions
export function logger(
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> {
  return function(state: AppState, action: any): AppState {
    // console.log('state', state);
    // console.log('action', action);

    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [logger]
  : [];

/**
 * Layout Reducers
 */
export const getLayoutState = createFeatureSelector<fromLayout.LayoutState>(
  'layout'
);

export const getShowHamburgerMenu = createSelector(
  getLayoutState,
  fromLayout.getShowHamburger
);
export const getShowSearchIcon = createSelector(
  getLayoutState,
  fromLayout.getShowSearchIcon
);

/**
 * Sidebar Reducers
 */
export const getSidebarState = createFeatureSelector<fromSidebar.SidebarState>(
  'sidebar'
);

export const getShowSidebar = createSelector(
  getSidebarState,
  fromSidebar.getShowSidebar
);

export const getSidebarActiveItems = createSelector(
  getSidebarState,
  fromSidebar.getActiveItems
);

/**
 * Search History Reducers
 */
export const getSearchHistoryState = createFeatureSelector<
  fromSearchHistory.SearchHistoryState
>('searchHistory');

export const getSearchHistory = createSelector(
  getSearchHistoryState,
  fromSearchHistory.getSearchHistory
);

/**
 * Languages Reducers
 */
export const getLanguagesState = createFeatureSelector<
  fromLanguages.LanguagesState
>('languages');

export const getLanguageEntities = createSelector(
  getLanguagesState,
  fromLanguages.getLanguageEntities
);

export const getAllLanguages = createSelector(
  getLanguageEntities,
  entities => {
    return Object.keys(entities).map(value => entities[value]);
  }
);

export const getActiveLanguage = createSelector(
  getLanguagesState,
  fromLanguages.getActiveLanguage
);

export const getSetLanguageStatus = createSelector(
  getLanguagesState,
  fromLanguages.getSetLanguageStatus
);

/**
 * Translation Reducers
 */
export const getTranslationState = createFeatureSelector<
  fromTranslation.TranslationState
>('translation');

export const getTranslations = createSelector(
  getTranslationState,
  fromTranslation.getTranslations
);

export const getNotTranslatedKeysCount = createSelector(
  getTranslationState,
  fromTranslation.getNotTranslatedKeysCount
);

export const getNotTranslatedEmailsKeysCount = createSelector(
  getTranslationState,
  fromTranslation.getNotTranslatedEmailsKeysCount
);

export const getNotApprovedUsersCount = createSelector(
  getTranslationState,
  fromTranslation.getNotApprovedUsersCount
);

/**
 * User Addresses Reducers
 */
export const getUserAddressesState = createFeatureSelector<
  fromUserAdresses.UserAddressesState
>('userAddresses');

export const getUserAddresses = createSelector(
  getUserAddressesState,
  fromUserAdresses.getUserAddresses
);

export const getUserAddressEntities = createSelector(
  getUserAddressesState,
  fromUserAdresses.getUserAddressEntities
);

export const getUserAddress = createSelector(
  getUserAddressesState,
  fromUserAdresses.getUserAddress
);

export const addUserAddress = createSelector(
  getUserAddressesState,
  fromUserAdresses.addUserAddress
);

export const addUserAddressError = createSelector(
  getUserAddressesState,
  fromUserAdresses.addUserAddressError
);

/**
 * User Cars Reducers
 */
export const getUserCarsState = createFeatureSelector<
  fromUserCars.UserCarsState
  >('userCars');

export const getUserCars = createSelector(
  getUserCarsState,
  fromUserCars.getUserCars
);

export const getUserCarEntities = createSelector(
  getUserCarsState,
  fromUserCars.getUserCarEntities
);

export const getUserCar = createSelector(
  getUserCarsState,
  fromUserCars.getUserCar
);

export const addUserCar = createSelector(
  getUserCarsState,
  fromUserCars.addUserCar
);

export const addUserCarError = createSelector(
  getUserCarsState,
  fromUserCars.addUserCarError
);

/**
 * Subgroups Reducers
 */
export const getSubGroupState = createFeatureSelector<
  fromSubGroups.SubGroupState
>('subGroups');

export const getSubGroupsEntities = createSelector(
  getSubGroupState,
  fromSubGroups.getSubGroupsEntities
);

/**
 * Country Reducers
 */
export const getCountryState = createFeatureSelector<
  fromCountries.CountryState
>('countries');

export const getCountryEntities = createSelector(
  getCountryState,
  fromCountries.getCountriesEntities
);

export const getCountriesLoaded = createSelector(
  getCountryState,
  fromCountries.getCountriesLoaded
);

export const getCountriesAll = createSelector(
  getCountryEntities,
  entities => {
    return Object.keys(entities).map(card => entities[card]);
  }
);

/**
 * Contacts Reducers
 */
export const getContactsState = createFeatureSelector<
  fromContacts.ContactsState
>('contacts');

export const getContactsEntities = createSelector(
  getContactsState,
  fromContacts.getContactsEntities
);

/**
 * Products Reducers
 */
export const getProductState = createFeatureSelector<fromProduct.ProductState>(
  'product'
);

export const getProduct = createSelector(
  getProductState,
  fromProduct.getProduct
);

/**
 * Toast Reducers
 */
export const getToastState = createFeatureSelector<fromToast.ToastState>(
  'toast'
);

export const getToast = createSelector(
  getToastState,
  fromToast.getToaster
);

/**
 * Cart Reducers
 */
export const getCartState = createFeatureSelector<fromCart.CartState>('cart');

export const getCartProducts = createSelector(
  getCartState,
  fromCart.getCartProducts
);

export const getCartLoaded = createSelector(
  getCartState,
  fromCart.getCartLoaded
);

export const getCartUpdated = createSelector(
  getCartState,
  fromCart.getCartUpdated
);

export const getCartLoading = createSelector(
  getCartState,
  fromCart.getCartLoading
);
/**
 * Order Reducers
 */
export const getOrderState = createFeatureSelector<fromOrder.OrderState>(
  'order'
);

export const getOrder = createSelector(
  getOrderState,
  fromOrder.getOrder
);

/**
 * Helper Reducers
 */
export const getHelperState = createFeatureSelector<
  fromHelper.HelperState
>('helper');

export const getHelper = createSelector(
  getHelperState,
  fromHelper.getVersionErrorModalState
);

/**
 * Products Reducers
 */
export const getLastRouteState = createFeatureSelector<
  fromLastRoute.LastRouteState
>('lastRoute');

export const getLastRoute = createSelector(
  getLastRouteState,
  fromLastRoute.getLastRoute
);

/**
 * Product Order History Reducers
 */
export const getProductOrderHistoryState = createFeatureSelector<
  fromProductOrderHistory.ProductOrderHistoryState
>('productOrderHistory');

export const getProductOrderHistory = createSelector(
  getProductOrderHistoryState,
  fromProductOrderHistory.getProductOrderHistory
);

export const getProductOrderHistoryOpen = createSelector(
  getProductOrderHistoryState,
  fromProductOrderHistory.getProductOrderHistoryOpen
);

/**
 * Similar Products Reducers
 */
export const getSimilarProductsState = createFeatureSelector<
  fromSimilarProducts.SimilarProductsState
>('similarProducts');

export const getSimilarProducts = createSelector(
  getSimilarProductsState,
  fromSimilarProducts.getSimilarProducts
);

/**
 * Custom router serialization
 */
export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
  data: any;
}

export const getRouterState = createFeatureSelector<
  fromRouter.RouterReducerState<RouterStateUrl>
>('router');

export const getCurrentUrl = createSelector(
  getRouterState,
  (state: fromRouter.RouterReducerState<RouterStateUrl>) => state.state.url
);

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const { params, data } = state;
    return { url, queryParams, params, data };
  }
}
