import { OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromAuth from '@app/auth/store';
import accounting from 'accounting-js';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class CustomNumberPipeService {
    constructor(store) {
        this.store = store;
        // countryThousandsSeparatorSpace = new Set(['ua','ru','by','lt','lv','fi','ee','bg','sk','pl','hu','cz'])
        this.countryThousandsSeparatorDot = new Set(['gr', 'ro', 'si', 'rs', 'se', 'it', 'hr']);
        this.country = '';
        this.countrySet = false;
        this.countryError = false;
        this.user$ = this.store.pipe(select(fromAuth.getUser));
        this.userSub = this.user$.subscribe(user => {
            this.user = user;
            if (user &&
                user.profile &&
                user.profile.client &&
                user.profile.client.company &&
                user.profile.client.company.country) {
                this.country = user.profile.client.company.country.code;
                this.countrySet = true;
                this.countryError = false;
            }
        });
    }
    deferExecution(resolve, count, number, decimal, symbol = '', format = '%s%v', money = false) {
        if (this.countryError) {
            resolve(number);
        }
        if (this.countrySet) {
            if (typeof number === 'string' || typeof number === 'number') {
                if (money) {
                    resolve(accounting.formatMoney(number, {
                        precision: decimal,
                        thousand: this.countryThousandsSeparatorDot.has(this.country) ? "." : " ",
                        decimal: ',',
                        symbol: symbol,
                        format: format
                    }));
                }
                else {
                    resolve(accounting.formatNumber(number, {
                        precision: decimal,
                        thousand: this.countryThousandsSeparatorDot.has(this.country) ? "." : " ",
                        decimal: ","
                    }));
                }
            }
        }
        else {
            if (count > 10) {
                console.error('customCurrency.deferExecution.error: user had defered execution more than 10 times.');
                resolve(number);
            }
            else {
                window.setTimeout(() => {
                    this.deferExecution(resolve, count + 1, number, decimal, symbol, format, money);
                }, 300);
            }
        }
    }
    ngOnDestroy() {
        if (this.userSub) {
            this.userSub.unsubscribe();
        }
    }
}
CustomNumberPipeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomNumberPipeService_Factory() { return new CustomNumberPipeService(i0.ɵɵinject(i1.Store)); }, token: CustomNumberPipeService, providedIn: "root" });
