
    <nz-modal
      [nzStyle]="{ top: '20px' }"
      [(nzVisible)]="isVisibleVersionErrorModal"
      [nzTitle]="'INTERNET_VERSION_ERROR' | translate"
      (nzOnOk)="handleVROk()"
      (nzOnCancel)="handleVRCancel()"
      [nzMaskClosable]="false"
      [nzOkText]="'REFRESH' | translate"
      [nzClosable]="false"
      [nzCancelText]="'DELETE_CANCEL_TEXT' | translate"
    >
      <p>{{ 'INTERNET_VERSION_ERROR_DETAIL' | translate }}</p>
    </nz-modal>
  