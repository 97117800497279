/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forgot-password-reset-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../components/forgot-password-reset-form/forgot-password-reset-form.component.ngfactory";
import * as i3 from "../../components/forgot-password-reset-form/forgot-password-reset-form.component";
import * as i4 from "../../../core/service/translation.service";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/router";
import * as i7 from "../../services/auth.service";
import * as i8 from "@ngrx/store";
import * as i9 from "../../../shared/services/helper.service";
import * as i10 from "../../../shared/ui/back-button/back-button.component.ngfactory";
import * as i11 from "../../../shared/ui/back-button/back-button.component";
import * as i12 from "@angular/common";
import * as i13 from "./forgot-password-reset-page.component";
var styles_ForgotPasswordResetPageComponent = [i0.styles];
var RenderType_ForgotPasswordResetPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotPasswordResetPageComponent, data: { "animation": [{ type: 7, name: "fadeInDown", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0, transform: "translateY(-2rem)" }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateY(0rem)" }, offset: null }, timings: "300ms ease-in" }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0, transform: "translateY(-2rem)" }, offset: null }, timings: "300ms ease-out" }], options: null }], options: {} }, { type: 7, name: "fadeIn", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "400ms ease" }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "400ms ease" }], options: null }], options: {} }] } });
export { RenderType_ForgotPasswordResetPageComponent as RenderType_ForgotPasswordResetPageComponent };
function View_ForgotPasswordResetPageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgot-password-reset-form", [], null, null, null, i2.View_ForgotPasswordResetFormComponent_0, i2.RenderType_ForgotPasswordResetFormComponent)), i1.ɵdid(1, 114688, null, 0, i3.ForgotPasswordResetFormComponent, [i4.TranslationService, i1.ChangeDetectorRef, i5.FormBuilder, i6.Router, i7.AuthService, i8.Store, i1.ElementRef, i9.HelperService], { user: [0, "user"], token: [1, "token"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; var currVal_1 = _co.token; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ForgotPasswordResetPageComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "section", [], [[24, "@fadeInDown", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "base-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-back-button", [], null, null, null, i10.View_BackButtonComponent_0, i10.RenderType_BackButtonComponent)), i1.ɵdid(4, 49152, null, 0, i11.BackButtonComponent, [i6.ActivatedRoute, i6.Router, i12.Location], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "align-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ForgotPasswordResetPageComponent_1)), i1.ɵdid(7, 16384, null, 0, i12.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.user && _co.token); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_ForgotPasswordResetPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgot-password-reset-page", [], null, null, null, View_ForgotPasswordResetPageComponent_0, RenderType_ForgotPasswordResetPageComponent)), i1.ɵdid(1, 245760, null, 0, i13.ForgotPasswordResetPageComponent, [i8.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotPasswordResetPageComponentNgFactory = i1.ɵccf("app-forgot-password-reset-page", i13.ForgotPasswordResetPageComponent, View_ForgotPasswordResetPageComponent_Host_0, {}, {}, []);
export { ForgotPasswordResetPageComponentNgFactory as ForgotPasswordResetPageComponentNgFactory };
