import { Action } from '@ngrx/store';
import { IAuthenticate } from '../../models/user';

export enum AuthActionTypes {
  Login = '[Auth] Login',
  SilentLogin = '[Auth] Silent login',
  Logout = '[Auth] Logout',
  LoginSuccess = '[Auth] Login Success',
  LoginSuccessRedirect = '[Auth] Login Success with redirect',
  LoginFailure = '[Auth] Login Failure',
  LoginRedirect = '[Auth] Login Redirect',
  LoginUpdate = '[Auth] Login Update',
  Silent = '[Auth] silent',
  SilentAuth = '[Auth] trying to auth silently',
  SilentAuthNoAction = '[Auth] trying to auth silently without logout',
  AuthReset = '[Auth] Reset Auth store parameters'
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public payload: IAuthenticate) {}
}

export class SilentLogin implements Action {
  readonly type = AuthActionTypes.SilentLogin;

  constructor(public payload: IAuthenticate) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;
}
export class LoginSuccessRedirect implements Action {
  readonly type = AuthActionTypes.LoginSuccessRedirect;
}
export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;

  constructor(public payload: any) {}
}

export class LoginRedirect implements Action {
  readonly type = AuthActionTypes.LoginRedirect;
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class LoginUpdate implements Action {
  readonly type = AuthActionTypes.LoginUpdate;
}

export class Silent implements Action {
  readonly type = AuthActionTypes.Silent;
}

export class SilentAuth implements Action {
  readonly type = AuthActionTypes.SilentAuth;
  constructor(public payload: any) {}
}
export class SilentAuthNoAction implements Action {
  readonly type = AuthActionTypes.SilentAuthNoAction;
  constructor(public payload: any) {}
}

export class AuthReset implements Action {
  readonly type = AuthActionTypes.AuthReset;
}

export type AuthActions =
  | Login
  | LoginSuccess
  | LoginFailure
  | LoginRedirect
  | LoginSuccessRedirect
  | Logout
  | LoginUpdate
  | Silent
  | SilentAuth
  | SilentAuthNoAction
  | SilentLogin
  | AuthReset;
