import * as fromCart from '../actions/cart.action';
import { CartActionTypes } from '../actions';
import { CartItem, CartModel } from '@pages/cart/models/cart.model';
import { UpdateResult } from '@app/shared/models/product.model';

export interface CartState {
  products: CartModel;
  loaded: boolean;
  loading: boolean;
  updated: boolean;
}

export const initialState: CartState = {
  products: { cart: null, not_paid_order_id: 0, items_expired_initial: []},
  loaded: false,
  loading: false,
  updated: true
};

export function reducer(
  state = initialState,
  action: fromCart.CartAction
): CartState {
  switch (action.type) {
    case CartActionTypes.CartLoad: {
      return {
        ...state,
        loading: true
      };
    }
    case CartActionTypes.CartLoadSuccess: {
      let entities = [];

      if (action.payload.cart) {
        const products = action.payload.cart.items;
        entities = products.reduce(
          (entit: { [id: number]: CartItem }, product: CartItem) => {
            return {
              ...entit,
              [product.card]: product
            };
          },
          {
          }
        );
      }

      return {
        ...state,
        loaded: true,
        loading: false,
        products: action.payload,
      };
    }
    case CartActionTypes.CartLoadFail: {
      return {
        products: { cart: null, not_paid_order_id: 0, items_expired_initial: [] },
        loading: false,
        loaded: false,
        updated: true
      };
    }

    case CartActionTypes.UpdateProducts:
    case CartActionTypes.AddProduct:
    case CartActionTypes.UpdateProduct:
    case CartActionTypes.RemoveExpiredProduct:
    case CartActionTypes.RemoveProduct: {
      return {
        ...state,
        loading: true,
        updated: false
      };
    }

    case CartActionTypes.AddProductSuccess:
    case CartActionTypes.UpdateProductSuccess:
    case CartActionTypes.RemoveExpiredProductSuccess:
    case CartActionTypes.RemoveProductSuccess: {

      return {
        ...state,
        loaded: true,
        loading: false,
        updated: true,
        products: action.cart,
      };
    }

    case CartActionTypes.AddProductFail:
    case CartActionTypes.UpdateProductFail:
    case CartActionTypes.RemoveExpiredProductFail:
    case CartActionTypes.RemoveProductFail: {
      return {
        ...state,
        updated: true
      };
    }
    case CartActionTypes.ResetCartState: {
      return {
        ...initialState
      };
    }
    case CartActionTypes.AddProductsResult:
    case CartActionTypes.UpdateProductsResult: {
      let lastCartResult = (action.payload.slice() as UpdateResult[]).reverse().filter((res: UpdateResult) => !res.error).map(res => res.cart)[0];
      if (!lastCartResult) {
        lastCartResult = state.products;
      }
      return {
        ...state,
        loaded: true,
        loading: false,
        updated: true,
        products: lastCartResult
      };
    }

    default: {
      return state;
    }
  }
}

export const getCartProducts = (state: CartState) => state.products;
export const getCartLoaded = (state: CartState) => state.loaded;
export const getCartLoading = (state: CartState) => state.loading;
export const getCartUpdated = (state: CartState) => state.updated;
