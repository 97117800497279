import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'select'
})
export class SelectPipe implements PipeTransform {
  /**
   * Form select data.
   * @param data
   * @param {string} name
   * @param {string} value
   * @returns {any}
   */
  transform(data, name: string, value: string): any {
    const newData = [];

    data.map(item => {
      newData.push({
        name: item[name],
        val: item[value]
      });
    });

    return newData;
  }
}
