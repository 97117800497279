import { Component } from '@angular/core';

import { IFilterAngularComp } from '@ag-grid-community/angular-legacy';
import { IDoesFilterPassParams, IFilterParams } from '@ag-grid-community/core';

@Component({
  selector: 'app-choice-filter',
  template: `
    <div>
      <div style="padding: 5px; border-bottom: 1px solid grey">
        <b>Custom Proficiency Filter</b>
      </div>
      <div *ngFor="let proficiency of filterValues" style="margin-top: 3px">
        <label style="padding-left: 4px">
          <input type="radio" [checked]="selected === proficiency" (change)="onButtonPressed(proficiency)"/>
          {{proficiency.name}}
        </label>
      </div>
    </div>
  `,
  styles: [''],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ChoiceFilter implements IFilterAngularComp {
  public filterValues = [
    {
      name: 'All',
      v: null
    },
    {
      name: 'Yes',
      v: true
    },
    {
      name: 'No',
      v: false
    },
  ];

  public selected: any = this.filterValues[0];

  public params: IFilterParams;

  constructor() {
  }

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  public onButtonPressed(proficiency: any) {
    this.selected = proficiency;
    this.params.filterChangedCallback();
  }

  public doesFilterPass(params) {
    if (this.selected.v === null) {
      return true;
    }
    const value = this.params.valueGetter(params);
    let valueAsBool = false;
    if (value === true || value === 'true') {
      valueAsBool = true;
    } else if (value === false || value === 'false') {
      valueAsBool = false;
    }

    return valueAsBool === this.selected.v;
  }

  public isFilterActive() {
    return this.selected !== this.filterValues[0];
  }

  getModel(): any {
    if (!this.isFilterActive()) {
      return null;
    }

    return { value: this.selected.v };
  }

  setModel(model: any): void {
    this.selected = model ? this.filterValues.filter(f => f.v === model.value) : this.filterValues[0];
  }
}
