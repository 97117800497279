import { Component, Input, OnInit } from '@angular/core';
import { fadeIn, fadeInDown } from '../../animations';
import { ApiService } from '@core/api.service';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { IShowcase } from '@pages/dashboard/models/showcase.model';

@Component({
  selector: 'app-favorite-products',
  templateUrl: './favorite-products.component.html',
  styleUrls: ['./favorite-products.component.scss'],
  animations: [fadeIn, fadeInDown]
})
export class FavoriteProductsComponent implements OnInit {
  base_url = environment.api_url;

  @Input() skn: string | number;
  favoriteProducts = [] as IShowcase[];
  favoritesModalStatus = false;
  isFavorite = false;
  currentlyEditedNote = {} as IShowcase;
  currentlyEditedNoteText = '';
  currentlyEditedNoteTextLoading = false;
  constructor(
    private router: Router,
    private apiService: ApiService,
  ) {}

  ngOnInit() {
    if (this.skn) {
      this.checkIsFavorite(this.skn);
    }
  }

  checkIsFavorite(skn) {
    this.apiService.get(`/products/is-favorite/${skn}/`).subscribe(isFavorite => {
      this.isFavorite = isFavorite;
    });
  }

  handleFavoriteClick() {
    this.apiService.get(`/products/favorite-products/`).subscribe(favoriteProducts => {
      this.favoriteProducts = favoriteProducts.results.map(s => {
        if (s.active_picture_url) {
          const img = new Image();
          img.src = s.picture_url;
          return <IShowcase> { ...s, showRealPicture: img.complete };
        }
        return <IShowcase> { ...s };
      });
    });
    this.favoritesModalStatus = true;
  }

  toggleFavorite(skn) {
    this.apiService.post(`/products/toggle-favorite/${skn}/`).subscribe(isFavorite => {
      this.isFavorite = isFavorite;
    });
  }

  toggleCurrentNote(item: IShowcase) {
    this.currentlyEditedNoteText = item.note;
  }

  saveCurrentNote(item: IShowcase) {
    this.currentlyEditedNoteTextLoading = true;
    this.currentlyEditedNote = item;
    const oldText = item.note;
    item.note = this.currentlyEditedNoteText;
    this.apiService.put(`/products/favorite-products/${item.id}/`, item).subscribe(_ => {
      this.currentlyEditedNoteTextLoading = false;
      this.currentlyEditedNote = {} as IShowcase;
    }, () => {
      this.currentlyEditedNoteTextLoading = false;
      this.currentlyEditedNote = {} as IShowcase;
      item.note = oldText;
    });
  }

  changeFavoritesModalStatus(status: boolean) {
    this.favoritesModalStatus = status;
  }

  goSearch($event, skn: string) {
    $event.preventDefault();
    $event.stopPropagation();
    this.favoritesModalStatus = false;
    this.router.navigate([`/search/${skn}/`]);
  }

  removeFromFavorites(item: IShowcase) {
    this.apiService.delete(`/products/favorite-products/${item.id}/`).subscribe(_ => {
      this.apiService.get(`/products/favorite-products/`).subscribe(favoriteProducts => {
        this.favoriteProducts = favoriteProducts.results;
      });
    });
  }

  imageLoaded(image: IShowcase) {
    image.showRealPicture = true;
  }
}
