import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@core/api.service';
import { ISearchHistory } from '@shared/models/search-history.model';
import { map } from 'rxjs/internal/operators';
import { DecoratorsService } from '@app/core/develop/decorators';

@Injectable()
export class StatisticsService {
  constructor(private apiService: ApiService) {}

  @DecoratorsService.DevCache()
  getSearchHistory(body: any): Observable<ISearchHistory> {
    return this.apiService
      .get(`/statistics/searches/`, body)
      .pipe(map(data => data));
  }
}
