import { Component, OnInit, Input } from '@angular/core';
import { fadeInDown } from '@shared/animations';
import { BreadcrumbsItem } from '@shared/ui/breadcrumbs/breadcrumbs.model';
import * as fromRoot from '@app/reducers';
import { Router } from '@angular/router';
import { PageComponent } from '@app/shared/models/page-component';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  animations: [fadeInDown]
})
export class ErrorPageComponent extends PageComponent implements OnInit {
  /**
   * Page breadcrumbs items.
   * @type {{label: string; url: string}[]}
   */
  urlPath = '';
  breadcrumbs: BreadcrumbsItem[] = [
    {
      label: 'ERROR',
      url: '/error'
    }
  ];

  constructor(
    private router: Router,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    const routeSerializer = new fromRoot.CustomSerializer();
    const routeState = routeSerializer.serialize(this.router.routerState.snapshot);
    this.urlPath = routeState.url;
  }
}
