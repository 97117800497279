import { Select } from '@app/shared/ui/select/select.model';
import { IUserShop } from '../models/user';

  // formName should start with ai_ prefix to correctly save data to db
  
  export type AdditionalCustomBranchFields =
    'pi_reservation_client';
  type SlovakiaFormNameActionTypes =
    'ai_client_name'
    | 'ai_client_id'
    | 'ai_city'
    | 'ai_street'
    | 'ai_street_num'
    | 'ai_postal_code'
    | 'ai_address_district'
    | 'ai_customer_type'
    | 'ai_number_of_trucks'
    | 'ai_vat_number'
    | AdditionalCustomBranchFields;

  export class AdditionalFields {
    formName: SlovakiaFormNameActionTypes;
    placeholder: string;
    label: string;
    offset?: number;
    span: number;
    pull?: number;
    br?: boolean;
    isText ? = true;
    isNumber ? = false;
    isSelect ? = false;
    selectValues ?: Select[];
    required?: boolean;
  }
  export const ADDITIONAL_FIELDS: AdditionalFields[] = [
    {
      formName: 'ai_client_id',
      placeholder: 'CLIENT_ID_EXAMPLE',
      label: 'CLIENT_ID',
      offset: 0,
      span: 8,
      pull: 0,
      isNumber: true,
      required: true,
    },
    {
      formName: 'ai_vat_number',
      placeholder: 'VAT_NUMBER_EXAMPLE',
      label: 'VAT_NUMBER',
      span: 8,
      isText: true,
      br: true,
      required: true,
    },
    {
      formName: 'ai_city',
      placeholder: 'CITY_EXAMPLE',
      label: 'CITY',
      isText: true,
      span: 8,
      required: true,
    },
    {
      formName: 'ai_street',
      placeholder: 'STREET_EXAMPLE',
      label: 'STREET',
      isText: true,
      span: 8,
      required: true,
    },
    {
      formName: 'ai_street_num',
      placeholder: 'STREET_NUM_EXAMPLE',
      label: 'STREET_NUM',
      isText: true,
      span: 8,
      required: true,
    },
    {
      formName: 'ai_postal_code',
      placeholder: 'POSTAL_CODE_EXAMPLE',
      label: 'POSTAL_CODE',
      isText: true,
      span: 8,
      required: true,
    },
    {
      formName: 'ai_address_district',
      placeholder: 'ADDRESS_DISTRICT_EXAMPLE',
      label: 'ADDRESS_DISTRICT',
      isText: true,
      span: 8,
      br: true,
      required: true,
    },
    {
      formName: 'ai_customer_type',
      placeholder: 'CUSTOMER_TYPE_EXAMPLE',
      label: 'CUSTOMER_TYPE',
      isSelect: true,
      required: true,
      selectValues: [
        {
          name: 'USER_OF_TRUCKS',
          val: 'USER_OF_TRUCKS',
        },
        {
          name: 'WORKSHOP',
          val: 'WORKSHOP',
        },
        {
          name: 'RESELLER',
          val: 'RESELLER',
        },
        {
          name: 'REG_TYPE_OTHER',
          val: 'REG_TYPE_OTHER',
        }
      ],
      span: 8,
    },
    {
      formName: 'ai_number_of_trucks',
      placeholder: 'NUMBER_OF_TRUCKS_EXAMPLE',
      label: 'NUMBER_OF_TRUCKS',
      span: 8,
      isNumber: true,
      required: true,
    },
  ];
  export const ADDITIONAL_FIELDS_USER: AdditionalFields[] = [
    {
      formName: 'ai_client_name',
      placeholder: 'AI_CLIENT_NAME_EXAMPLE',
      label: 'AI_CLIENT_NAME',
      isText: true,
      span: 24,
      required: true,
    },
  ];
  export const ADDITIONAL_FIELDS_BRANCH: AdditionalFields[] = [
    {
      formName: 'pi_reservation_client',
      placeholder: 'LT_CLIENT_FOR_WH_EXAMPLE',
      label: 'LT_CLIENT_FOR_WH',
      span: 8,
      isNumber: true,
      required: true,
    },
  ]
export interface CompaniesSelect {
  name: string;
  val: number;
  image: string;
  skuba_company_id: number;
  metadata: {
    country_code: string;
    language_code: string;
    shops: IUserShop[];
  };
}
