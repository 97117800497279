import { ClientInfo } from '@shared/models/client-info.model';
import { ICompany } from '@shared/models/company.model';
import { UserSettings, UserRightsShopSettings } from '@pages/users/models/users.model';

export interface IAuthenticate {
  username: string;
  password: string;
}

export interface IToken {
  token: string;
}

export interface IUser {
  id: any;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  is_active: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  country: IUserCountry;
  profile: IUserProfile;
  last_login: string;
  user_settings: UserSettings;
  shop_settings: UserRightsShopSettings[];
  selected_shop_settings: UserRightsShopSettings;
  hide_base_price: boolean;
  hide_purchase_price: boolean;
  hide_product_location: boolean;
  hide_product_code: boolean;
  hide_manufacturer_code: boolean;
  hide_uppercase_style: boolean;
  overdue_debt: number;
  overdue_debt_eur: number;
  user_is_pre_payment: boolean;
  user_is_cash_only: boolean;
  user_has_payment_choice: boolean;
  user_negative_balance: boolean;
  user_manager_info: {
    manager: string;
    email: string;
    tel: string;
  };
}

export interface IUserProfile {
  id: number;
  user: number;
  client: ClientInfo;
  main_client: ClientInfo;
  email_verified: boolean;
  email_verified_at: string;
  shop: IUserShop;
  shop_id: number;
  default_address: IUserAddress;
  legal_type: any;
  client_hint: string;
  language: IUserLanguage;
  language_code: string;
  phone_code: number;
  phone: number;
  skype: string | number;
  bkn: number;
  status: number;
  ip: number;
  valid_till: number;
  created_at: string;
  updated_at: string;
  company: ICompany;
  tawkto?: ITawkto;
  show_prices_with_vat: boolean;
  subscribed_to_emails: boolean;
  send_minimal_order_notification: boolean;
  send_order_notifications: boolean;
  user_field_values?: { key: string, value: string }[];
  is_customer_blocked: boolean;
  selected_client_references: IUserClientReference[];
  enable_payments: boolean;
  enable_invoice_payments: boolean;
  payment_order_expiration_hours: number;
  instant_orders: boolean;
  note_choice_on_add_cart: boolean;
  force_reset_password: boolean;

  // calculated fields
  is_user_interface_active: boolean;
}

export interface ITawkto {
  id: number;
  site: string;
  user_hash: string;
  widget: string;
}

export interface IUserLanguage {
  code: string;
  name: string;
  native_name: string;
}

export interface IUserShop {
  id: number;
  name: string;
  country: IUserCountry;
  country_code: string;
  fid: string;
}

export interface IUserShopEmail {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  position: string;
  shop: number;
}

export interface IUserAddressesModel {
  count: number;
  results: IUserAddress[];
}

export class IUserAddress {
  id: number;
  is_default: boolean;
  title: string;
  address1: string;
  imported: boolean;
}

export interface IUserCarsModel {
  count: number;
  results: IUserCar[];
}

export class IUserCar {
  id: number;
  truck_number: string;
  plate_number: string;
  vin: string;
  tecdoc_make_id: number;
  tecdoc_make: string;
  tecdoc_model_id: number;
  tecdoc_model: string;
  tecdoc_type_id: number;
  tecdoc_type: string;
  year_of_construction: number;
  state: number;
  imported: boolean;
}

export interface IUserCountry {
  code: string;
  language_code: string;
  language_name: string;
  name: string;
  native_name: string;
  currency: string;
  vat: number;
}

export class IUserCity {
  id: number;
  country_code: string;
  country_name: string;
  name: string;
  population: number;
}

export class IUserAddressBody {
  is_default?: boolean;
  title: string;
  address1: string;
}

export class IUserCarBody {
  plate_number: string;
  vin: string;
  tecdoc_make_id: number;
  tecdoc_make: string;
  tecdoc_model_id: number;
  tecdoc_model: string;
  year_of_construction: number;
  state: number;
  imported: boolean;
}

export interface IUSerChange {
  phone_code?: number;
  phone?: number;
  skype?: string;
  language_code?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  show_prices_with_vat?: boolean;
  subscribed_to_emails?: boolean;
  send_minimal_order_notification?: boolean;
  send_order_notifications?: boolean;
}

export interface ISilent {
  _ems_silenced: boolean;
}

export interface IUserRegister extends ISilent {
  username: string;
  password1: any;
  password2: any;
  email: string;
  first_name: string;
  last_name: string;
  company_id: number;
  language_code: string;
  client_hint: string;
  phone_code: number;
  phone: number;
  skype?: string;
}

export interface IUserClientReference {
  client: ClientInfo;
  main_client?: ClientInfo;
}

export interface IUserModel<T> {
  count: number;
  results: T[];
}

export interface IResultsModel<T> {
  count: number;
  results: T[];
}


export const REGISTRATION_COMPANY_LOCK_KEY = 'country-parameter-set';
export const REGISTRATION_LANGUAGE_LOCK_KEY = 'language-parameter-set';
export const SEARCH_OPTIONS_SAVED_KEY = 'search-options-saved-key';
export interface IRegistrationLanguageLock {
  expires: number;
  language: string;
}
export interface IRegistrationCompanyLock {
  expires: number;
  company: string;
}
