/**
 * https://github.com/BolshakovNO/ng2-datepicker
 */

import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy,
  ChangeDetectionStrategy
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { LanguagesState } from '@core/store/reducers/language.reducer';
import * as fromRoot from '@app/reducers';
import 'moment/min/locales';
import { DateConstants } from '@app/shared/constants/date-formats';

export interface IDateModel {
  day: string;
  month: string;
  year: string;
  formatted: string;
  momentObj: moment.Moment;
}

export class DateModel {
  day: string;
  month: string;
  year: string;
  formatted: string;
  momentObj: moment.Moment;

  constructor(obj?: IDateModel) {
    this.day = obj && obj.day ? obj.day : null;
    this.month = obj && obj.month ? obj.month : null;
    this.year = obj && obj.year ? obj.year : null;
    this.formatted = obj && obj.formatted ? obj.formatted : null;
    this.momentObj = obj && obj.momentObj ? obj.momentObj : null;
  }
}

export interface WeekDays {
  S: string;
  M: string;
  T: string;
  W: string;
  Th: string;
  F: string;
  Sa: string;
}

export class DatePickerOptions {
  autoApply?: boolean;
  style?: 'normal' | 'big' | 'bold';
  locale?: string;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  initialDate?: moment.Moment;
  firstWeekdaySunday?: boolean;
  format?: string;
  weekDays?: WeekDays;
  monthTitleFormat?: string;

  constructor(obj?: DatePickerOptions) {
    this.autoApply = !!(obj && obj.autoApply === true);
    this.style = obj && obj.style ? obj.style : 'normal';
    this.locale = obj && obj.locale ? obj.locale : 'en';
    this.minDate = obj && obj.minDate ? obj.minDate : null;
    this.maxDate = obj && obj.maxDate ? obj.maxDate : null;
    this.initialDate = obj && obj.initialDate ? obj.initialDate : null;
    this.firstWeekdaySunday =
      obj && obj.firstWeekdaySunday ? obj.firstWeekdaySunday : false;
    this.format = obj && obj.format ? obj.format : DateConstants.DEFAULT_DATE;
    this.weekDays =
      obj && obj.weekDays
        ? obj.weekDays
        : { S: 'S', M: 'M', T: 'T', W: 'W', Th: 'T', F: 'F', Sa: 'S' };
    this.monthTitleFormat =
      obj && obj.monthTitleFormat ? obj.monthTitleFormat : 'MMMM YYYY';
  }
}

export interface CalendarDate {
  day: number;
  month: number;
  year: number;
  enabled: boolean;
  today: boolean;
  selected: boolean;
  momentObj: moment.Moment;
}

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // Unavoidable ref in component
    // eslint-disable-next-line @angular-eslint/no-forward-ref
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true
  }]
})
export class DatePickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() disableToday: boolean;
  @Input() options: DatePickerOptions;
  @Input() model: number;
  @Input() hasOpenLabel: false;
  @Input() inputEvents: EventEmitter<{
    type: string;
    data: string | DateModel;
  }>;
  @Output() readonly outputEvents: EventEmitter<{
    type: string;
    data: string | DateModel;
    init?: boolean;
  }>;

  date: DateModel;

  opened: boolean;
  currentDate: moment.Moment;
  days: CalendarDate[];
  years: number[];
  yearPicker: boolean;

  minDate: moment.Moment | any;
  maxDate: moment.Moment | any;

  languageSet$: Observable<string>;
  languageSub: Subscription;

  constructor(
    @Inject(ElementRef) public el: ElementRef,
    private ref: ChangeDetectorRef,
    private store: Store<LanguagesState>
  ) {
    this.languageSet$ = this.store.pipe(select(fromRoot.getActiveLanguage));

    this.languageSub = this.languageSet$.subscribe(lang => {
      moment.locale(lang);
    });

    this.opened = false;
    this.currentDate = moment();
    this.options = this.options || {};
    this.days = [];
    this.years = [];
    this.date = new DateModel({
      day: null,
      month: null,
      year: null,
      formatted: null,
      momentObj: null
    });

    this.generateYears();

    this.outputEvents = new EventEmitter<{
      type: string;
      data: string | DateModel;
    }>();

    if (!this.inputEvents) {
      return;
    }

    this.inputEvents.subscribe(
      (event: { type: string; data: string | DateModel }) => {
        if (event.type === 'setDate') {
          this.value = event.data as DateModel;
        } else if (event.type === 'default') {
          if (event.data === 'open') {
            this.open();
          } else if (event.data === 'close') {
            this.close();
          }
        }
      }
    );
  }

  get value(): DateModel {
    return this.date;
  }
  set value(date: DateModel) {
    if (!date) {
      return;
    }
    this.date = date;
    this.onChangeCallback(date);
  }

  ngOnInit() {
    this.options = new DatePickerOptions(this.options);

    if (this.options.initialDate instanceof Date || this.model) {
      this.currentDate = moment(
        new Date(this.model) || this.options.initialDate
      );
      this.selectDate(null, this.currentDate, true);
    }

    if (this.options.minDate instanceof Date) {
      this.minDate = moment(this.options.minDate);
    } else {
      this.minDate = null;
    }

    if (this.options.maxDate instanceof Date) {
      this.maxDate = moment(this.options.maxDate);
    } else {
      this.maxDate = null;
    }

    this.generateCalendar();
    this.outputEvents.emit({ type: 'default', data: 'init' });

    if (typeof window !== 'undefined') {
      const body = document.querySelector('body');
      body.addEventListener(
        'click',
        e => {
          if (!this.opened || !e.target) {
            return;
          }
          if (
            this.el.nativeElement !== e.target &&
            !this.el.nativeElement.contains(<any> e.target)
          ) {
            this.close();
          }
        },
        false
      );
    }

    if (this.inputEvents) {
      this.inputEvents.subscribe((e: any) => {
        if (e.type === 'action') {
          if (e.data === 'toggle') {
            this.toggle();
          }
          if (e.data === 'close') {
            this.close();
          }
          if (e.data === 'open') {
            this.open();
          }
        }

        if (e.type === 'setDate') {
          if (!(e.data instanceof Date)) {
            throw new Error('Input data must be an instance of Date!');
          }
          const date: moment.Moment = moment(e.data);
          if (!date) {
            throw new Error(`Invalid date: ${e.data}`);
          }
          this.value = {
            day: date.format('DD'),
            month: date.format('MM'),
            year: date.format('YYYY'),
            formatted: date.format(this.options.format),
            momentObj: date
          };
        }
      });
    }
  }

  generateCalendar() {
    const date: moment.Moment = moment(this.currentDate);
    const month = date.month();
    const year = date.year();
    let n = 1;
    const firstWeekDay = this.options.firstWeekdaySunday
      ? date.date(2).day()
      : date.date(1).day();

    if (firstWeekDay !== 1) {
      n -= (firstWeekDay + 6) % 7;
    }

    this.days = [];
    const selectedDate: moment.Moment = this.date.momentObj;
    for (let i = n; i <= date.endOf('month').date(); i += 1) {
      const currentDate: moment.Moment = moment(
        `${i}.${month + 1}.${year}`,
        'DD.MM.YYYY'
      );
      const today: boolean =
        moment().isSame(currentDate, 'day') &&
        moment().isSame(currentDate, 'month')
          ? true
          : false;
      const selected: boolean =
        selectedDate && selectedDate.isSame(currentDate, 'day') ? true : false;
      let betweenMinMax = true;

      if (this.minDate !== null) {
        if (this.maxDate !== null) {
          betweenMinMax = currentDate.isBetween(
            this.minDate,
            this.maxDate,
            'day',
            '[]'
          )
            ? true
            : false;
        } else {
          betweenMinMax = currentDate.isBefore(this.minDate, 'day')
            ? false
            : true;
        }
      } else {
        if (this.maxDate !== null) {
          betweenMinMax = currentDate.isAfter(this.maxDate, 'day')
            ? false
            : true;
        }
      }

      const day: CalendarDate = {
        day: i > 0 ? i : null,
        month: i > 0 ? month : null,
        year: i > 0 ? year : null,
        enabled: i > 0 ? betweenMinMax : false,
        today: i > 0 && today ? true : false,
        selected: i > 0 && selected ? true : false,
        momentObj: currentDate
      };

      this.days.push(day);
    }
  }

  selectDate(e: MouseEvent, date: moment.Moment, init = false) {
    if (e) {
      e.preventDefault();
    }

    // setTimeout(() => {
    this.value = {
      day: date.format('DD'),
      month: date.format('MM'),
      year: date.format('YYYY'),
      formatted: date.format(this.options.format),
      momentObj: date
    };
    this.generateCalendar();

    this.outputEvents.emit({
      type: 'dateChanged',
      data: this.value,
      init: init
    });
    // });

    if (this.options.autoApply === true && this.opened === true) {
      this.opened = false;
    }
  }

  selectYear(e: MouseEvent, year: number) {
    e.stopPropagation();
    const date: moment.Moment = this.currentDate.year(year);
    this.value = {
      day: date.format('DD'),
      month: date.format('MM'),
      year: date.format('YYYY'),
      formatted: date.format(this.options.format),
      momentObj: date
    };
    this.yearPicker = false;
    this.generateCalendar();
    this.ref.detectChanges();
  }

  generateYears() {
    const date: moment.Moment =
      this.options.minDate || moment().year(moment().year() - 13);
    const toDate: moment.Moment =
      this.options.maxDate || moment().year(moment().year() + 7);
    const years = toDate.year() - date.year();

    for (let i = 0; i < years; i++) {
      this.years.push(date.year());
      date.add(1, 'year');
    }
  }

  writeValue(date: DateModel) {
    if (!date) {
      return;
    }
    this.date = date;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  prevMonth() {
    this.currentDate = this.currentDate.subtract(1, 'month');
    this.generateCalendar();
  }

  nextMonth() {
    this.currentDate = this.currentDate.add(1, 'month');
    this.generateCalendar();
  }

  nextWeek() {
    this.currentDate = moment();
    this.currentDate = this.currentDate.add(7, 'day');
    this.selectDate(null, this.currentDate);
  }
  today() {
    this.currentDate = moment();
    this.selectDate(null, this.currentDate);
  }

  toggle() {
    this.opened = !this.opened;
    if (this.opened) {
      this.onOpen();
    }

    this.outputEvents.emit({ type: 'default', data: 'opened' });
  }

  open() {
    this.opened = true;
    this.onOpen();
  }

  close() {
    this.opened = false;
    this.outputEvents.emit({ type: 'default', data: 'closed' });
  }

  onOpen() {
    this.yearPicker = false;
  }

  openYearPicker(event) {
    this.yearPicker = true;
    this.ref.detectChanges();
    event.stopPropagation();
  }

  ngOnDestroy() {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }


  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};
}
