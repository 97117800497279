import { AbstractControl } from '@angular/forms';

export class PhoneValidator {
  public static validate(c: AbstractControl) {
    const PHONE_REGEXP = /^[0-9]+$/;

    return PHONE_REGEXP.test(c.value)
      ? null
      : {
          phoneValidator: {
            valid: false
          }
        };
  }
}
