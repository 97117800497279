var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap, debounceTime, distinct, delay, concatAll, toArray } from 'rxjs/operators';
import * as fromServices from '../services/cart.service';
import * as cartActions from '../actions/cart.action';
import { of, EMPTY } from 'rxjs';
import { UserOperation } from '@app/shared/constants/user-operations';
export class CartEffects {
    constructor(actions$, cartService) {
        this.actions$ = actions$;
        this.cartService = cartService;
        this.loadCart$ = this.actions$.pipe(ofType(cartActions.CartActionTypes.CartLoad), map((action) => ({ with_expired: action.with_expired })), debounceTime(50), mergeMap(({ with_expired }) => this.cartService.getCart(with_expired).pipe(map(data => new cartActions.LoadCartSuccess(data)), catchError(error => of(new cartActions.LoadCartFail(error))))));
        this.addProduct$ = this.actions$.pipe(ofType(cartActions.CartActionTypes.AddProduct), map((action) => ({ product: action.payload, acknowledged: action.acknowledged, oldQuantity: action.oldQuantity })), mergeMap(({ product, acknowledged, oldQuantity }) => {
            let info = '';
            if (UserOperation.shouldPost) {
                info = JSON.stringify({
                    id: UserOperation.TYPE,
                    query: UserOperation.query,
                });
            }
            // FIXME: add type to product, as params merge with any produces any
            return this.cartService.addToCart(Object.assign({}, product, { additional_info: info }), acknowledged).pipe(map(productItem => new cartActions.AddProductSuccess(productItem, product, oldQuantity)), catchError(error => of(new cartActions.AddProductFail(error))));
        }));
        this.addProducts$ = this.actions$.pipe(ofType(cartActions.CartActionTypes.AddProducts), map((action) => action.payload), map(action => action.map((update) => {
            let info = '';
            if (UserOperation.shouldPost) {
                info = JSON.stringify({
                    id: UserOperation.TYPE,
                    query: UserOperation.query,
                });
            }
            return this.cartService.addToCart(Object.assign({}, update.update, { additional_info: info }), update.acknowledged).pipe(delay(500), map(res => ({
                cart: res,
                error: false,
                data: undefined,
                update: update,
            })), catchError(error => of({ cart: undefined, error: true, data: error, update: update })));
        })), mergeMap(obs => of(...obs).pipe(concatAll(), toArray())), map(results => new cartActions.AddProductsResult(results)));
        this.updateProduct$ = this.actions$.pipe(ofType(cartActions.CartActionTypes.UpdateProduct), map((action) => ({ update: action.update, skn: action.skn, product: action.product })), mergeMap(({ update, skn, product }) => {
            let info = '';
            if (UserOperation.shouldPost) {
                info = JSON.stringify({
                    id: UserOperation.TYPE,
                    query: UserOperation.query,
                });
            }
            return this.cartService.updateCart(update.id, Object.assign({}, update, { additional_info: info })).pipe(map(productItem => new cartActions.UpdateProductSuccess(productItem, skn, product, update)), catchError(error => of(new cartActions.UpdateProductFail(error))));
        }));
        this.updateProducts$ = this.actions$.pipe(ofType(cartActions.CartActionTypes.UpdateProducts), map((action) => action.update), map(action => action.map((update) => this.cartService.updateCart(update.update.id, update.update).pipe(delay(500), map(res => ({
            cart: res,
            error: false,
            data: undefined,
            update: update,
        })), catchError(error => of({ cart: undefined, error: true, data: error, update: update }))))), mergeMap(obs => of(...obs).pipe(concatAll(), toArray())), map(results => new cartActions.UpdateProductsResult(results)));
        this.deleteProduct$ = this.actions$.pipe(ofType(cartActions.CartActionTypes.RemoveProduct), map((action) => ({ product: action.payload })), distinct(), mergeMap(({ product }) => this.cartService.removeFromCart(product.id).pipe(map(cart => new cartActions.RemoveProductSuccess(cart, product.skn)), catchError(error => of(new cartActions.RemoveProductFail(error))))));
        this.deleteExpiredProduct$ = this.actions$.pipe(ofType(cartActions.CartActionTypes.RemoveExpiredProduct), map((action) => ({ product: action.payload, silenced: action.silenced })), distinct(), mergeMap(({ product, silenced }) => this.cartService.removeFromCartExpired(product.id).pipe(map(cart => {
            if (silenced) {
                return EMPTY;
            }
            else {
                return new cartActions.RemoveExpiredProductSuccess(cart, product.skn);
            }
        }), catchError(error => {
            if (silenced) {
                return EMPTY;
            }
            else {
                return of(new cartActions.RemoveExpiredProductFail(error));
            }
        }))));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CartEffects.prototype, "loadCart$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CartEffects.prototype, "addProduct$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CartEffects.prototype, "addProducts$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CartEffects.prototype, "updateProduct$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CartEffects.prototype, "updateProducts$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CartEffects.prototype, "deleteProduct$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], CartEffects.prototype, "deleteExpiredProduct$", void 0);
