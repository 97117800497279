import * as fromRoot from '../../../reducers';
import * as fromAuth from './auth.reducer';
import * as fromUser from './user.reducer';
import * as fromLoginPage from './login-page.reducer';
import {
  createSelector,
  createFeatureSelector,
  ActionReducerMap
} from '@ngrx/store';

export interface AuthState {
  status: fromAuth.State;
  loginPage: fromLoginPage.State;
  user: fromUser.UserState;
}

// export interface State extends fromRoot.AppState {
//   auth: AuthState;
// }

export const reducers: ActionReducerMap<AuthState> = {
  status: fromAuth.reducer,
  loginPage: fromLoginPage.reducer,
  user: fromUser.reducer
};

export const selectAuthState = createFeatureSelector<AuthState>('auth');
