import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import * as fromContacts from '@core/store/actions/contacts.action';
import * as fromUser from '@app/auth/store';
import { IContact } from '@core/models/contact';
import { IUser } from '@app/auth/models/user';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {
  openedFooter = false;
  currentLanguage: string;
  contacts$: Observable<IContact[]>;
  contactsSub: Subscription;
  user$: Observable<IUser>;
  userSub: Subscription;
  contacts: IContact[];
  user: IUser;
  showAlternativeFooter = false;
  languageCode = '';
  showPaymentOptions: boolean = false;
  constructor(private store: Store<fromRoot.AppState>) {
    this.contacts$ = this.store.pipe(select(fromRoot.getContactsEntities));
    this.user$ = this.store.pipe(select(fromUser.getUser));
  }

  ngOnInit() {
    this.userSub = this.user$.subscribe(user => {
      if (
        user &&
        user.profile &&
        user.profile.company &&
        user.profile.company &&
        user.profile.company.country
      ) {
        this.currentLanguage = user.profile.company.country.code;
        if (user.profile.company.country.code === 'sk') {
          this.showAlternativeFooter = true;
        } else {
          this.showAlternativeFooter = false;
        }
        if (user.profile.company.enable_payment_tatrac) {
          this.showPaymentOptions = true;
        } else {
          this.showPaymentOptions = false;
        }
        if (this.contactsSub) { this.contactsSub.unsubscribe(); }
        this.contactsSub = this.contacts$.subscribe(contacts => {
          if (user.profile.shop) {
          this.contacts = contacts.slice().sort((a, b) => (a.skuba_company_id === +user.profile.shop.fid) ? -1 : 1);
          } else {
            this.contacts = contacts;
          }
        });
      }

      if (user && user.profile) {
        this.user = user;
        this.languageCode = user.profile.company.country.language_code;
      }
    });

    this.store.dispatch(new fromContacts.ContactsLoad());
  }

  ngOnDestroy() {
    if (this.contactsSub) { this.contactsSub.unsubscribe(); }
    if (this.userSub) { this.userSub.unsubscribe(); }
  }
}
