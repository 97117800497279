var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductService } from '@app/shared/services/product.service';
import { SelectPipe } from '@app/shared/ui/select/pipes/select.pipe';
import { FilterEmptyPipe } from '@shared/pipes/filter-empty/filter-empty.pipe';
import { BooleanPipe } from '@shared/pipes/boolean/boolean.pipe';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import * as fromAuth from '@app/auth/store';
import * as toast from '@app/core/store/actions/toast.action';
import * as LayoutActions from '@core/store/actions/layout.action';
import { TranslationService } from '@core/service/translation.service';
import { debounceTime } from 'rxjs/operators';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { SEARCH_OPTIONS_SAVED_KEY } from '@app/auth/models/user';
import { SkubaCompanyCodeEnum } from '@app/shared/models/company.model';
/**
 * @whatItDoes returns search fixed menu component.
 */
export class SearchNavComponent {
    constructor(router, productService, fb, filterEmpty, booleanPipe, selectPipe, store, cdRef, translationService, localStorageService, element) {
        this.router = router;
        this.productService = productService;
        this.fb = fb;
        this.filterEmpty = filterEmpty;
        this.booleanPipe = booleanPipe;
        this.selectPipe = selectPipe;
        this.store = store;
        this.cdRef = cdRef;
        this.translationService = translationService;
        this.localStorageService = localStorageService;
        this.element = element;
        /**
         * Product subgroup list.
         * @type {{}}
         */
        this.subGroupsOptions = {};
        /**
         * Open/close search status.
         * @type {boolean}
         */
        this.searchNavStatus = true;
        this.searchMainStatus = true;
        /**
         * Detail bar show/hide state.
         * @type {boolean}
         */
        this.detailStatus = false;
        /**
         * Validation min symbols.
         * @type {{value: number}}
         */
        this.min_symbols = { value: 3 };
        /** Matrix params for search */
        this.urlParams = {};
        this.disableSearch = false;
        this.formSubmitAttempt = false;
        this.setSearchStateEvent = new EventEmitter();
        /** Update parent status */
        this.updateStatus = new EventEmitter();
        this.quickSelectChoice = '';
        this.useOESearchOnly = true;
        this.useTecDocSeach = false;
        this.sortByAvailibilityDefault = null;
        this.savedParamsVersion = null;
        this.savedParamsVersionKey = "_skuba_version";
        this.otherStatesSubs = {};
        this.user$ = this.store.pipe(select(fromAuth.getUser));
        this.router$ = this.store.pipe(select(fromRoot.getRouterState));
        this.subGroupOptions$ = this.store.pipe(select(fromRoot.getSubGroupsEntities));
    }
    /** Init form group, search status and subgroups from API, set radio items */
    ngOnInit() {
        this.loadLastConfiguredSearch();
        this.initForm();
        this.userSub = this.user$.subscribe((user) => {
            this.user = user;
            if (user && user.profile) {
                if (user.profile.company.skuba_company_id === SkubaCompanyCodeEnum.Vilnius || user.profile.company.skuba_company_id === SkubaCompanyCodeEnum.Ukraine) {
                    this.useOESearchOnly = true;
                }
                else {
                    this.useOESearchOnly = false;
                }
                if (user.profile.company.skuba_company_id === SkubaCompanyCodeEnum.Ukraine) {
                    this.useTecDocSeach = false;
                    this.sortByAvailibilityDefault = "sort_availability";
                    if (this.savedParamsVersion !== 1) {
                        if (this.localStorageService.hasKey(SEARCH_OPTIONS_SAVED_KEY)) {
                            this.urlParams.sub_group = null;
                            this.urlParams.tec_doc_state = false;
                            this.urlParams.card_number_state = false;
                            this.urlParams.skn_state = false;
                            this.urlParams.codes_state = true;
                            this.urlParams.context_state = false;
                            this.urlParams.sort = 'sort_availability';
                        }
                    }
                }
                else {
                    this.useTecDocSeach = false;
                }
            }
            this.initForm();
            this.checkboxEvent();
        });
        this.subGroupOptionsSub = this.subGroupOptions$.subscribe(data => {
            this.subGroupsOptions = data;
            this.options = this.selectPipe.transform(data, 'name', 'id');
        });
        this.searchStateEventSub = this.setSearchStateEvent
            .pipe(debounceTime(100))
            .subscribe(() => {
            this.setSearchStateImplementation();
        });
    }
    ngAfterViewInit() {
        this.routerSub = this.router$.subscribe((route) => __awaiter(this, void 0, void 0, function* () {
            this.viewInit = true;
            this.autofocus = route.state.url.substring(0, 10) === '/dashboard';
            this.searchMainStatus = false;
            if (route.state.url.substring(0, 7) === '/tecdoc' ||
                route.state.url.substring(0, 10) === '/catalogue' ||
                route.state.url === '/search') {
                this.urlParams = {};
                this.searchNavStatus = false;
            }
            else {
                this.searchMainStatus = true;
                this.searchNavStatus = true;
            }
            setTimeout(() => {
                this.updateStatus.emit({ navStatus: this.searchNavStatus, isSearchWithSettings: this.searchMainStatus });
            });
            if (Object.keys(route.state.params).length > 0 &&
                route.state.url.substring(0, 8) === '/search;') {
                this.urlParams = Object.assign({}, route.state.params);
                this.products.patchValue({
                    search: this.urlParams.search ? this.urlParams.search : '',
                    context_state: this.booleanPipe.transform(this.urlParams.context_state),
                    codes_state: this.booleanPipe.transform(this.urlParams.codes_state),
                    skn_state: this.booleanPipe.transform(this.urlParams.skn_state),
                    card_number_state: this.booleanPipe.transform(this.urlParams.card_number_state),
                    tec_doc_state: this.booleanPipe.transform(this.urlParams.tec_doc_state),
                    sub_group: this.urlParams.sub_group ? this.urlParams.sub_group : null,
                    sort: this.urlParams.sort ? this.urlParams.sort : this.sortByAvailibilityDefault
                });
            }
            if (route.state.url.substring(0, 7) === '/tecdoc' || route.state.url.substring(0, 10) === '/catalogue') {
                setTimeout(() => {
                    this.store.dispatch(new LayoutActions.ShowSearchIcon());
                });
            }
            else {
                setTimeout(() => {
                    this.store.dispatch(new LayoutActions.HideSearchIcon());
                });
            }
            this.cdRef.detectChanges();
        }));
    }
    /** Create search navigation form group */
    initForm() {
        this.products = this.fb.group({
            search: [
                this.urlParams.hasOwnProperty('search') ? this.urlParams.search : '',
                [Validators.minLength(3), Validators.required]
            ],
            context_state: [
                this.urlParams.hasOwnProperty('context_state') ? this.booleanPipe.transform(this.urlParams.context_state) : (!this.useOESearchOnly)
            ],
            codes_state: [
                this.urlParams.hasOwnProperty('codes_state') ? this.booleanPipe.transform(this.urlParams.codes_state) : true
            ],
            skn_state: [this.urlParams.hasOwnProperty('skn_state') ? this.booleanPipe.transform(this.urlParams.skn_state) : false],
            card_number_state: [
                this.urlParams.hasOwnProperty('card_number_state')
                    ? this.booleanPipe.transform(this.urlParams.card_number_state)
                    : false
            ],
            tec_doc_state: [
                this.urlParams.hasOwnProperty('tec_doc_state') ? this.booleanPipe.transform(this.urlParams.tec_doc_state) : (this.useTecDocSeach)
            ],
            sub_group: [this.urlParams.hasOwnProperty('sub_group') ? this.urlParams.sub_group : null],
            sort: [this.urlParams.hasOwnProperty('sort') ? this.urlParams.sort : this.sortByAvailibilityDefault]
        });
        if (this.productSearchChangesSub) {
            this.productSearchChangesSub.unsubscribe();
        }
        this.productSearchChangesSub = this.products.controls['search'].valueChanges.subscribe(() => {
            this.formSubmitAttempt = false;
        });
        setTimeout(() => {
            this.setQuickChoiceValue();
        });
        if (this.productChangesSub) {
            this.productChangesSub.unsubscribe();
        }
        this.productChangesSub = this.products.valueChanges.subscribe((changes) => {
            this.setQuickChoiceValue();
        });
    }
    loadLastConfiguredSearch() {
        if (!this.urlParams.sub_group &&
            !this.urlParams.tec_doc_state &&
            !this.urlParams.card_number_state &&
            !this.urlParams.skn_state &&
            !this.urlParams.codes_state &&
            !this.urlParams.context_state &&
            !this.urlParams.search &&
            !this.urlParams.sort) {
            if (this.localStorageService.hasKey(SEARCH_OPTIONS_SAVED_KEY)) {
                const savedSearchParams = this.localStorageService.getItem(SEARCH_OPTIONS_SAVED_KEY);
                if (savedSearchParams.hasOwnProperty(this.savedParamsVersionKey)) {
                    this.savedParamsVersion = savedSearchParams[this.savedParamsVersionKey];
                    delete savedSearchParams[this.savedParamsVersionKey];
                }
                else {
                    this.savedParamsVersion = 0;
                }
                this.urlParams = Object.assign({}, savedSearchParams, { search: undefined });
            }
        }
    }
    setQuickChoiceValue() {
        if (this.products.controls['skn_state'].value) {
            this.quickSelectChoice = 'SKN';
        }
        else if (this.products.controls['card_number_state'].value) {
            this.quickSelectChoice = 'CARD';
        }
        else if (((!this.useOESearchOnly && this.products.controls['context_state'].value) ||
            (this.useOESearchOnly && !this.products.controls['context_state'].value)) &&
            this.products.controls['codes_state'].value &&
            ((this.useTecDocSeach && this.products.controls['tec_doc_state'].value) ||
                (!this.useTecDocSeach && !this.products.controls['tec_doc_state'].value))) {
            this.quickSelectChoice = 'DEFAULT';
        }
        else {
            this.quickSelectChoice = '';
        }
    }
    setQuickChoices(value) {
        switch (value) {
            case 'SKN':
                this.products.patchValue({
                    context_state: false,
                    codes_state: false,
                    skn_state: true,
                    card_number_state: false,
                    tec_doc_state: false,
                });
                break;
            case 'CARD':
                this.products.patchValue({
                    context_state: false,
                    codes_state: false,
                    skn_state: false,
                    card_number_state: true,
                    tec_doc_state: false
                });
                break;
            case 'DEFAULT':
                this.products.patchValue({
                    context_state: !this.useOESearchOnly,
                    codes_state: true,
                    skn_state: false,
                    card_number_state: false,
                    tec_doc_state: this.useTecDocSeach,
                });
                break;
            default:
                break;
        }
    }
    /** Close search navigation and navigate to search route with matrix params */
    search(products) {
        if (this.autocompleteHandler) {
            clearTimeout(this.autocompleteHandler);
        }
        this.formSubmitAttempt = true;
        if (!products.controls['search'].valid) {
            const minSymbols = this.translationService.translate('MIN_SYMBOLS', {
                value: 3
            });
            this.store.dispatch(new toast.ShowErrorToast(minSymbols));
            return;
        }
        if (products.controls['search'].value.trim().length < 3) {
            const minSymbols = this.translationService.translate('MIN_SYMBOLS', {
                value: 3
            });
            this.store.dispatch(new toast.ShowErrorToast(minSymbols));
            return;
        }
        if (!products.valid) {
            return;
        }
        this.detailStatus = false;
        // const searchOptions
        this.localStorageService.setItem(SEARCH_OPTIONS_SAVED_KEY, Object.assign({}, products.value, { [this.savedParamsVersionKey]: 1, search: undefined }));
        this.router.navigate([
            '/search/',
            this.filterEmpty.transform(products.value)
        ]);
    }
    /** Get data from name suggestion */
    setNameAutocomplete() {
        // Show autocomplete only on context search
        if (!this.products.controls['context_state'].value) {
            return;
        }
        if (this.autocompleteHandler) {
            clearTimeout(this.autocompleteHandler);
        }
        this.autocompleteHandler = setTimeout(() => {
            if (this.products.value &&
                this.products.value.search &&
                this.products.value.search.length >= this.min_symbols.value) {
                if (this.autocompleteNameSub) {
                    this.autocompleteNameSub.unsubscribe();
                }
                this.autocompleteNameSub = this.productService
                    .getNameSuggestion(this.products.value.search)
                    .subscribe(data => {
                    this.nameSuggestionItems = data;
                }, error => {
                    this.store.dispatch(new toast.ShowErrorToast(error));
                });
            }
        }, 800);
    }
    /** Reset search component data */
    resetData() {
        this.products.patchValue({
            context_state: !this.useOESearchOnly,
            codes_state: true,
            skn_state: false,
            card_number_state: false,
            tec_doc_state: this.useTecDocSeach,
            sub_group: null
        });
    }
    checkboxEvent() {
        // onClick SKN - sets all other states to false
        if (this.sknStateSub) {
            this.sknStateSub.unsubscribe();
        }
        this.sknStateSub = this.products.controls['skn_state'].valueChanges.subscribe(state => {
            if (state) {
                this.products.patchValue({
                    context_state: false,
                    codes_state: false,
                    card_number_state: false,
                    tec_doc_state: false
                });
            }
            // this.setSearchState();
            this.setSearchStateEvent.emit({});
        });
        // onClick card number - sets all other states to false
        if (this.cardNrSub) {
            this.cardNrSub.unsubscribe();
        }
        this.cardNrSub = this.products.controls['card_number_state'].valueChanges.subscribe(state => {
            if (state) {
                this.products.patchValue({
                    context_state: false,
                    skn_state: false,
                    codes_state: false,
                    tec_doc_state: false
                });
            }
            // this.setSearchState();
            this.setSearchStateEvent.emit({});
        });
        // onClick other states - set card number and skn to false
        const otherStates = ['context_state', 'codes_state', 'tec_doc_state'];
        otherStates.map(name => {
            if (this.otherStatesSubs[name]) {
                this.otherStatesSubs[name].unsubscribe();
            }
            this.otherStatesSubs[name] = this.products.controls[name].valueChanges.subscribe(state => {
                if (state) {
                    this.products.patchValue({
                        card_number_state: false,
                        skn_state: false
                    });
                }
                // this.setSearchState();
                this.setSearchStateEvent.emit({});
            });
        });
    }
    setSearchStateImplementation() {
        this.disableSearch = false;
        const data = this.products.value;
        if (!data.context_state &&
            !data.codes_state &&
            !data.skn_state &&
            !data.card_number_state &&
            !data.tec_doc_state) {
            this.disableSearch = true;
        }
    }
    ngOnDestroy() {
        if (this.autocompleteNameSub) {
            this.autocompleteNameSub.unsubscribe();
        }
        if (this.cardNrSub) {
            this.cardNrSub.unsubscribe();
        }
        if (this.productChangesSub) {
            this.productChangesSub.unsubscribe();
        }
        if (this.productSearchChangesSub) {
            this.productSearchChangesSub.unsubscribe();
        }
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
        if (this.searchStateEventSub) {
            this.searchStateEventSub.unsubscribe();
        }
        if (this.sknStateSub) {
            this.sknStateSub.unsubscribe();
        }
        if (this.subGroupOptionsSub) {
            this.subGroupOptionsSub.unsubscribe();
        }
        if (this.userSub) {
            this.userSub.unsubscribe();
        }
        for (const name in this.otherStatesSubs) {
            if (this.otherStatesSubs.hasOwnProperty(name)) {
                const sub = this.otherStatesSubs[name];
                if (sub) {
                    sub.unsubscribe();
                }
            }
        }
    }
    subGroupFilter(input, option) {
        if (!input) {
            return true;
        }
        const searched = input.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        const term = option.nzLabel.toLocaleLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        if (term.indexOf(searched) > -1) {
            return true;
        }
        return false;
    }
}
