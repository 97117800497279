import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/auth/services/auth.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import * as toast from '@app/core/store/actions/toast.action';
import { Router } from '@angular/router';
import { HelperService } from '@shared/services/helper.service';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss']
})
export class ForgotPasswordFormComponent implements OnInit {
  forgotPasswordForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private store: Store<fromRoot.AppState>,
    private element: ElementRef,
    private helperService: HelperService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm(): void {
    this.forgotPasswordForm = this.fb.group({
      username: [null, Validators.required]
    });
  }

  submit() {
    this.helperService.validationScroll(this.element);

    if (this.forgotPasswordForm.valid) {
      this.authService
        .forgotPassword({
          username: this.forgotPasswordForm.value.username
        })
        .subscribe(() => {
          this.store.dispatch(new toast.ShowSuccessToast('SUCCESS'));

          this.router.navigate(['/login']);
        });
    }
  }
}
