var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../reducers';
import * as toast from '@app/core/store/actions/toast.action';
import { TranslationService } from '@core/service/translation.service';
import { SearchByCarService } from '@shared/services/search-by-car.service';
/**
 * @whatItDoes returns search fixed menu component.
 */
export class SearchByCarNavComponent {
    constructor(router, productService, fb, store, cdRef, translationService, searchByCarService) {
        this.router = router;
        this.productService = productService;
        this.fb = fb;
        this.store = store;
        this.cdRef = cdRef;
        this.translationService = translationService;
        this.searchByCarService = searchByCarService;
        /**
         * Open/close search status.
         * @type {boolean}
         */
        this.searchNavStatus = true;
        /** Name autocomplete input data */
        this.plateNumberSuggestionItems = [];
        /**
         * Validation min symbols.
         * @type {{value: number}}
         */
        this.min_symbols = { value: 1 };
        /** Matrix params for search */
        this.urlParams = {};
        this.subscriptionsList = [];
        this.formSubmitAttempt = false;
        this.numberInputHandler = null;
        this.allowReload = false;
        this.searchByCarCatalogue = true;
        this.urlNumber = null;
        this.plateNumber = null;
        /** Update parent status */
        this.updateStatus = new EventEmitter();
        this.router$ = this.store.pipe(select(fromRoot.getRouterState));
        let initLoad = true;
        this.subscriptionsList.push(this.searchByCarService.plateNumberStatus$.subscribe(value => {
            if (!initLoad) {
                if (value.found) {
                    if (value.state === 1 || value.state === 2 || value.state === 4) {
                        this.plateNumberValid = true;
                        this.disableSubmitButton = true;
                    }
                    else if (value.state === 3) {
                        this.plateNumberValid = false;
                        if (this.products.controls['query'].value && this.products.controls['query'].value.length > 0) {
                            this.disableSubmitButton = false;
                        }
                        setTimeout(() => {
                            this.queryInput['vc'].nativeElement.focus();
                        });
                    }
                }
                else {
                    this.plateNumberValid = true;
                    this.disableSubmitButton = true;
                }
            }
            initLoad = false;
        }));
        this.subscriptionsList.push(this.router.events.subscribe(value => {
            this.plateNumberValid = true;
            this.disableSubmitButton = true;
            if (this.products.controls['number'].value && this.products.controls['number'].value.length > 0) {
                this.plateNumberValid = false;
            }
            if (this.products.controls['query'].value && this.products.controls['query'].value.length > 0) {
                this.disableSubmitButton = false;
            }
        }));
    }
    /** Init form group, search status and subgroups from API, set radio items */
    ngOnInit() {
        this.initForm();
    }
    ngAfterViewInit() {
        this.subscriptionsList.push(this.router$.subscribe((route) => __awaiter(this, void 0, void 0, function* () {
            this.viewInit = true;
            this.autofocus = true;
            this.plateNumberValid = true;
            this.disableSubmitButton = true;
            this.urlParams = {};
            this.searchNavStatus = true;
            this.products.patchValue({
                number: '',
                query: ''
            });
            setTimeout(() => {
                this.updateStatus.emit({ navStatus: this.searchNavStatus, isSearchWithSettings: false });
            });
            if (Object.keys(route.state.params).length > 0 &&
                route.state.url.substring(0, 24) === '/search/by_car_catalogue') {
                this.urlParams = route.state.params;
                this.searchByCarCatalogue = true;
                this.products.patchValue({
                    number: this.urlParams.number ? this.urlParams.number : '',
                    query: this.urlParams.query ? this.urlParams.query : ''
                });
            }
            else if (Object.keys(route.state.params).length > 0 &&
                route.state.url.substring(0, 14) === '/search/by_car') {
                this.urlParams = route.state.params;
                this.searchByCarCatalogue = false;
                this.products.patchValue({
                    number: this.urlParams.number ? this.urlParams.number : '',
                    query: this.urlParams.query ? this.urlParams.query : ''
                });
            }
            if (this.products.controls['query'].value && this.products.controls['query'].value.length > 0) {
                this.plateNumberValid = false;
                this.disableSubmitButton = false;
            }
            if (this.urlParams.number) {
                this.urlNumber = this.urlParams.number;
            }
            this.cdRef.detectChanges();
        })));
    }
    /** Create search navigation form group */
    initForm() {
        this.products = this.fb.group({
            number: [
                '',
                [
                    Validators.minLength(3),
                    Validators.required
                ]
            ],
            query: [
                '',
                [
                    Validators.minLength(3),
                    Validators.required
                ]
            ]
        });
        let plateNumber = null;
        this.subscriptionsList.push(this.products.controls['number'].valueChanges.subscribe(value => {
            if (plateNumber && plateNumber.toUpperCase() == value) {
                return;
            }
            this.formSubmitAttempt = false;
            if (value) {
                if (this.numberInputHandler) {
                    clearTimeout(this.numberInputHandler);
                    this.numberInputHandler = null;
                }
                this.numberInputHandler = setTimeout(() => {
                    this.subscriptionsList.push(this.productService.checkCarExists(value).subscribe(value1 => {
                        if (this.searchByCarCatalogue && this.urlNumber && value !== this.urlNumber) {
                            this.router.navigate(['/search/by_car/' + value]);
                        }
                        if (!plateNumber) {
                            return;
                        }
                        if (value1 && value != this.plateNumber) {
                            const data = [];
                            data.push({
                                state: value1,
                                value: value.toUpperCase(),
                                materialIcon: this.getIconByState(value1),
                            });
                            if (this.plateNumberSuggestionItems.length > 0) {
                                const plateNumberExists = this.plateNumberSuggestionItems.filter((item, index) => item.value.replace(/[^a-zA-Z0-9]/g, '') === value.toUpperCase().replace(/[^a-zA-Z0-9]/g, ''));
                                if (plateNumberExists.length <= 0) {
                                    this.plateNumberSuggestionItems.unshift(data[0]);
                                }
                            }
                            else {
                                this.plateNumberSuggestionItems = this.appendCustomButton(data);
                            }
                            this.plateNumber = value;
                        }
                        if (value1 === 1 || value1 === 2 || value1 === 4) {
                            this.searchByCarService.emitChange({ found: true, reload: false, state: value1, value: value });
                        }
                        else if (value1 === 3) {
                            this.searchByCarService.emitChange({ found: true, reload: false, state: value1, value: value });
                            this.allowReload = true;
                        }
                    }));
                }, 800);
            }
            else {
                this.searchByCarService.emitChange({ found: true, reload: false, state: 0, value: '' });
                this.plateNumberValid = true;
                this.disableSubmitButton = true;
            }
            plateNumber = value;
        }));
        this.subscriptionsList.push(this.products.controls['query'].valueChanges.subscribe((value) => {
            this.formSubmitAttempt = false;
            if (value && value.length >= 3) {
                this.disableSubmitButton = false;
            }
            else {
                this.disableSubmitButton = true;
            }
        }));
        if (this.urlParams.number) {
            this.products.controls['number'].patchValue(this.urlParams.number);
        }
        if (this.urlParams.query) {
            this.products.controls['query'].patchValue(this.urlParams.query);
        }
    }
    selectedItem(item) {
        this.plateNumber = item;
        this.autofocus = false;
        this.plateNumberSuggestionItems = [];
    }
    /** Close search navigation and navigate to search route with matrix params */
    search(products) {
        this.formSubmitAttempt = true;
        if (!products.valid) {
            return;
        }
        this.autofocus = false;
        this.plateNumberSuggestionItems = [];
        if (this.allowReload
            && this.urlParams.number === products.controls['number'].value.toUpperCase()
            && this.urlParams.query === products.controls['query'].value) {
            this.searchByCarService.emitChange({ found: true, reload: true, state: 0, value: '' });
            this.allowReload = false;
        }
        else {
            this.router.navigate([
                '/search/by_car/',
                products.value.number.toUpperCase(),
                products.value.query
            ]);
        }
    }
    /** Get data from name suggestion */
    setPlateNumberAutocomplete() {
        if (this.autocompleteHandler) {
            clearTimeout(this.autocompleteHandler);
        }
        this.autocompleteHandler = setTimeout(() => {
            if (this.products.value &&
                this.products.value.number &&
                this.products.value.number.length >= this.min_symbols.value) {
                this.subscriptionsList.push(this.searchByCarService
                    .getPlateNumberSuggestion(this.products.value.number)
                    .subscribe(data => {
                    data.forEach((item) => {
                        item.materialIcon = this.getIconByState(item.state);
                        if (item.truck_number) {
                            item.preValue = item.truck_number;
                        }
                    });
                    // if (this.plateNumberSuggestionItems.length === 2) {
                    //   this.plateNumberSuggestionItems.push(this.appendCustomButton(data));
                    // } else {
                    //   this.plateNumberSuggestionItems = this.appendCustomButton(data);
                    // }
                    this.plateNumberSuggestionItems = this.appendCustomButton(data);
                }, error => {
                    this.store.dispatch(new toast.ShowErrorToast(error));
                }));
            }
        }, 800);
    }
    getLastPlateNumberSearches() {
        this.subscriptionsList.push(this.searchByCarService
            .getPlateNumberSearches()
            .subscribe(data => {
            data.forEach((item) => {
                item.materialIcon = this.getIconByState(item.state);
                if (item.truck_number) {
                    item.preValue = item.truck_number;
                }
            });
            this.plateNumberSuggestionItems = this.appendCustomButton(data);
        }, error => {
            this.store.dispatch(new toast.ShowErrorToast(error));
        }));
    }
    getIconByState(state) {
        switch (state) {
            case 1:
            case 2:
                return 'error_outline';
            case 3:
                return 'check_circle_outline';
            case 4:
                return 'highlight_off';
            case 5:
                return 'history';
            default:
                break;
        }
        return '';
    }
    appendCustomButton(data) {
        data.push({
            value: this.translationService.translate('ADD_NEW_CAR'),
            materialIcon: 'add_circle_outline',
            path: '/profile/cars/create',
        });
        return data;
    }
    /** Reset search component data */
    // resetData(): void {
    //   this.products.patchValue({
    //     query: '',
    //     number: ''
    //   });
    // }
    onButtonClick() {
        if (!this.plateNumberValid) {
            this.router.navigate(['/search/by_car_catalogue/' + this.plateNumber]);
        }
    }
    ngOnDestroy() {
        this.subscriptionsList.forEach(subscription => {
            if (subscription) {
                subscription.unsubscribe();
            }
        });
    }
}
