<div id="toast-container" class="toast-right">
  <div *ngFor="let toast of toasts"
       [ngClass]="cssClass(toast)"
       class="animated bounceInRight">
    <div (mouseenter)="mouseEnter(toast)" (mouseleave)="mouseLeave(toast)">
      <i class="close-toast fas fa-times" (click)="removeToast(toast)"></i>
      <div class="toast-message">{{toast.message | translate}}</div>
    </div>
  </div>
</div>
