var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ApiService } from '@core/api.service';
import { of } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { CachedRequestService } from '../../core/service/cached-req.service';
import { DecoratorsService } from '@app/core/develop/decorators';
export class ProductService {
    constructor(apiService, localStorageService, cachedRequestService) {
        this.apiService = apiService;
        this.localStorageService = localStorageService;
        this.cachedRequestService = cachedRequestService;
    }
    getProductWithInfo(skn, info) {
        return this.apiService.post(`/products/${skn}/`, {
            info: info
        }).pipe(map(data => data));
    }
    getCatalogue(filters = null) {
        return this.apiService.get('/products/catalogue/', filters).pipe(map(data => data));
    }
    getCatalogueByCar(filters = null) {
        if (filters.hasOwnProperty('number') && filters.hasOwnProperty('id')) {
            const plate_number = filters.number;
            const id = filters.id;
            delete filters.number;
            delete filters.id;
            return this.apiService.get(`/products/by_car_catalogue/${plate_number}/${id}/`, filters).pipe(map(data => data));
        }
    }
    getList(filters = null) {
        if (this.cachedRequestService.hasKey('cached-list') &&
            this.cachedRequestService.getItem('cached-list').filters ===
                JSON.stringify(filters)) {
            return of(this.cachedRequestService.getItem('cached-list').list);
        }
        else {
            return this.apiService.get('/products/', filters).pipe(map(data => {
                if (this.cachedListTimeoutHandler) {
                    clearTimeout(this.cachedListTimeoutHandler);
                }
                this.cachedRequestService.setItem('cached-list', {
                    filters: JSON.stringify(filters),
                    list: data
                });
                this.cachedListTimeoutHandler = setTimeout(() => this.cachedRequestService.clearItem('cached-list'), 600000);
                return data;
            }));
        }
    }
    getRelated(skn, reltype) {
        return this.apiService
            .get(`/products/related/${skn}/${reltype}/`)
            .pipe(map(data => data));
    }
    checkCarExists(number = null) {
        return this.apiService
            .get(`/products/check_car_exists/${number}/`)
            .pipe(map(data => data));
    }
    getByCar(number, query) {
        const filters = { number: number, query: query };
        if (this.localStorageService.hasKey('cached-list') &&
            this.localStorageService.getItem('cached-list').filters ===
                JSON.stringify(filters)) {
            return of(this.localStorageService.getItem('cached-list').list);
        }
        else {
            return this.apiService.get(`/products/by_car/${number}/${query}/`).pipe(map(data => {
                if (this.cachedListTimeoutHandler) {
                    clearTimeout(this.cachedListTimeoutHandler);
                }
                this.localStorageService.setItem('cached-list', {
                    filters: JSON.stringify(filters),
                    list: data
                });
                this.cachedListTimeoutHandler = setTimeout(() => this.localStorageService.clearItem('cached-list'), 600000);
                return data;
            }));
        }
    }
    getNameSuggestion(name = null) {
        return this.apiService
            .get(`/products/name/${name}/`)
            .pipe(map(data => data));
    }
    getProduct(skn) {
        return this.apiService.get(`/products/${skn}/`).pipe(map(data => data));
    }
    getProductBrandsWithInfo(skn, info) {
        return this.apiService.post(`/products/stocks/${skn}/`, {
            info: info
        }).pipe(map(data => data));
    }
    getSubGroupsByCar(plateNumber) {
        return this.apiService.get(`/products/sub_groups_by_car/${plateNumber}/`);
    }
    getProductPrice(card, warehouse, is_central, brand, skn, deposit, on_the_way, silenced = false) {
        return this.apiService
            .post(`/products/cards/${card}/${warehouse}/${is_central ? '1' : '0'}/`, {
            _ems_silenced: silenced.toString(),
            skn,
            brand,
            deposit,
            on_the_way,
        })
            .pipe(map(data => data));
    }
    boughtWith(body) {
        return this.apiService
            .get(`/products/bought_with/${body.skn}/`, body)
            .pipe(map(data => data));
    }
    getDistinctValues(allFilterValues) {
        return allFilterValues.reduce((a, v) => {
            if (v.value === null) {
                a[0].unique['-'] = 'null';
            }
            else if (v.title === '') {
                a[0].unique['-'] = v.value;
            }
            else {
                a[0].unique[v.title] = v.value;
            }
            return a;
        }, [{ unique: {} }])
            .map(filterGroupItems => {
            const result = [];
            for (const property in filterGroupItems.unique) {
                if (filterGroupItems.unique.hasOwnProperty(property)) {
                    result.push({
                        title: property,
                        value: filterGroupItems.unique[property],
                    });
                }
            }
            return result;
        }).reduce((a, v) => v, []);
    }
    sendPriceRequest(sknId, form) {
        return this.apiService
            .post(`/products/price_request/${sknId}/`, Object.assign({ product: sknId }, form))
            .pipe(map(data => data));
    }
}
__decorate([
    DecoratorsService.DevCache(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [String, String]),
    __metadata("design:returntype", void 0)
], ProductService.prototype, "getProductWithInfo", null);
