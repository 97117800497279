import { IToast, ToastType } from '@app/core/models/toast';
import {
  ToastActions,
  ToastActionTypes
} from '@app/core/store/actions/toast.action';

export interface ToastState {
  toast: IToast;
}

const initialState: ToastState = {
  toast: null
};

export function reducer(
  state = initialState,
  action: ToastActions
): ToastState {
  switch (action.type) {
    case ToastActionTypes.ShowToast: {
      return {
        ...state,
        toast: action.payload
      };
    }
    case ToastActionTypes.ShowErrorToast: {
      const t: IToast = { type: ToastType.Error, message: action.payload, skipReport: action.skipReport };
      return {
        ...state,
        toast: t
      };
    }
    case ToastActionTypes.ShowSuccessToast: {
      const t: IToast = { type: ToastType.Success, message: action.payload };
      return {
        ...state,
        toast: t
      };
    }
    case ToastActionTypes.ShowWarningToast: {
      const t: IToast = { type: ToastType.Warning, message: action.payload, persistent: action.persistent };
      return {
        ...state,
        toast: t
      };
    }
    case ToastActionTypes.ResetToastState: {
      return {
        ...initialState
      };
    }
    default: {
      return state;
    }
  }
}

export const getToaster = (state: ToastState) => state.toast;
