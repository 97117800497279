import { Component, Input, OnInit } from '@angular/core';

import { fadeInDown } from '@app/shared/animations';

@Component({
  selector: 'app-cart-info-bar',
  templateUrl: './cart-info-bar.component.html',
  styleUrls: ['./cart-info-bar.component.scss'],
  animations: [fadeInDown]
})
export class CartInfoBarComponent implements OnInit {
  /**
   * Total count of products in cart.
   * @type {number}
   */
  @Input() total_count = 0;

  /**
   * Cart sum without pvm.
   * @type {number}
   */
  @Input() sum_without_pvm = 0.0;

  constructor() {}

  ngOnInit() {}
}
