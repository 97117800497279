import { IUserCountry, IUserShop } from '@app/auth/models/user';

export class ICompanyData {
  count: number;
  next: string;
  previous: string;
  results: ICompany[];
}

export class ICompany {
  id: number;
  address: string;
  city: string;
  company_code: string;
  country: IUserCountry;
  name: string;
  skuba_company_id: number;
  email: string;
  vilnius_skuba_client_id: number;
  shops: IUserShop[];
  show_catalogue_dash_with_central: boolean;
  show_truck_catalog: boolean;
  force_delivery_type_selection: boolean;
  note_choice_on_add_cart: boolean;
  enable_ziticity: boolean;
  enable_payment_paysera: boolean;
  enable_payment_tatrac: boolean;
  enable_payment_choice: boolean;
  enable_instant_orders: boolean;
  enable_context_requests: boolean;
  hide_cards_in_stocks: boolean;
  show_same_country_near_stocks_qty: boolean;
  show_client_manager_info_in_page: boolean;
  show_address_and_insert_default: boolean;
  show_client_manager_info_on_non_reservable_stocks: boolean;
  enable_cart_split_by_items: boolean;
  future_shop_settings_type: number;
}

export enum SkubaCompanyCodeEnum {
  Utena = 2,
  Kaunas = 3,
  Marijampole = 8,
  Panevezys = 9,
  Fortrakas = 11,
  Poland = 12,
  Finland = 14,
  Minsk = 15,
  Estonia = 20,
  Klaipeda = 23,
  Vilnius = 25,
  Siauliai = 31,
  Romania = 33,
  Hungary = 34,
  Autodara = 35,
  Ukraine = 39,
  Kaliningrad = 40,
  Slovakia = 49,
  Nergita = 51,
  Latvia = 52,
  Czech = 53,
  Bulgaria = 62,
  Slovenia = 100,
  Croatia = 101,
  Greece = 111,
  Serbia = 141,
  Italy = 153,
  VilniusSkuba = 200,
  AutodaraPlius = 221,
  Trade = 257,
}
