/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./toast.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/pipes/translate/translate.pipe";
import * as i4 from "../service/translation.service";
import * as i5 from "@ngrx/store";
import * as i6 from "./toast.component";
var styles_ToastComponent = [i0.styles];
var RenderType_ToastComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ToastComponent, data: {} });
export { RenderType_ToastComponent as RenderType_ToastComponent };
function View_ToastComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "animated bounceInRight"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.mouseEnter(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.mouseLeave(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "close-toast fas fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeToast(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "toast-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i4.TranslationService, i5.Store, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "animated bounceInRight"; var currVal_1 = _co.cssClass(_v.context.$implicit); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_v.context.$implicit.message)); _ck(_v, 6, 0, currVal_2); }); }
export function View_ToastComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "toast-right"], ["id", "toast-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ToastComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toasts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ToastComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-toast", [], null, null, null, View_ToastComponent_0, RenderType_ToastComponent)), i1.ɵdid(1, 245760, null, 0, i6.ToastComponent, [i5.Store, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ToastComponentNgFactory = i1.ɵccf("app-toast", i6.ToastComponent, View_ToastComponent_Host_0, {}, {}, []);
export { ToastComponentNgFactory as ToastComponentNgFactory };
