import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BooleanPipe } from '@shared/pipes/boolean/boolean.pipe';
import { enter } from '@app/shared/models/const.model';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // Unavoidable ref in component
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor {
  /** attr.aria-label (optional) */
  @Input() label: string;

  @Input() icon: {};

  /** Form control name */
  @Input() name: string;

  /** Value (optional) */
  @Input() val = false;

  /** Event emitted when the checkbox's `checked` value changes */
  @Output() readonly changed = new EventEmitter<boolean>();

  /**
   * Disable checkbox attribute.
   */
  @Input() disabled: string;

  constructor(private booleanPipe: BooleanPipe) {}

  handleKeyboardEvent(event: KeyboardEvent): void {
    // eslint-disable-next-line deprecation/deprecation
    if (event.keyCode === enter) {
      this.onValueChange(!this.val);
      event.preventDefault();
    }
  }

  /** This is the initial value set to the component */
  writeValue(value: any): void {
    this.val = this.booleanPipe.transform(value);
  }

  /** Not used, used for touch input */
  registerOnTouched(): void {}

  /**
   * Registers 'fn' that will be fired when changes are made.
   * This is how we emit the changes back to the form.
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * The method set in registerOnChange to emit changes back to the form
   * @param _
   */
  propagateChange = (_: any) => {};

  /**
   * Detect when checkbox value changes.
   * @param {string} value
   */
  onValueChange(value: boolean) {
    this.val = value;
    this.propagateChange(this.booleanPipe.transform(this.val));
    this.changed.emit(this.booleanPipe.transform(this.val));
  }
}
