import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../reducers/index';
import * as fromStore from '../../store/index';
import * as fromAuth from '@app/auth/store';
import {
  IAuthenticate,
  IUser,
  IRegistrationLanguageLock,
  IRegistrationCompanyLock,
  REGISTRATION_LANGUAGE_LOCK_KEY,
  REGISTRATION_COMPANY_LOCK_KEY,
} from '@app/auth/models/user';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import * as TranslationActions from '@core/store/actions/translation.action';
import * as LanguageActions from '@core/store/actions/language.action';
import * as moment from 'moment';
import { CachedRequestService } from '@app/core/service/cached-req.service';
import { PageComponent } from '@app/shared/models/page-component';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-skuba-login-page',
  templateUrl: './login-page.component.html'
})
export class LoginPageComponent extends PageComponent implements OnDestroy {
  pending$ = this.store.pipe(select(fromStore.getLoginPagePending));
  error$ = this.store.pipe(select(fromStore.getLoginPageError));
  redirectRoute: string;

  user$: Observable<IUser>;
  userSub: Subscription;
  router$: Observable<any>;
  routerSub: Subscription;
  loginErrorSub: Subscription;
  isLogoutRequest = false;
  isLoginPending = false;
  infoMessage = '';

  constructor(
    private store: Store<fromStore.AuthState>,
    private router: Router,
    private localStorageService: LocalStorageService,
    private cachedRequestService: CachedRequestService,
  ) {
    super();
    this.loginErrorSub = this.error$.subscribe(error => {
      this.isLoginPending = false;
    });
    this.router$ = this.store.pipe(select(fromRoot.getRouterState));
    this.routerSub = this.router$.subscribe(route => {
      this.isLogoutRequest = false;
      if (route.state.data.translationKey !== 'LOGIN' && route.state.data.translationKey !== 'LOGOUT') {
        return;
      }
      if (route.state.data.translationKey === 'LOGOUT') {
        this.isLogoutRequest = true;
        this.store.dispatch(new fromStore.Logout());
        this.router.navigate(['/login']);
        return;
      }
      if (route.state.queryParams && route.state.queryParams.redirect) {
        this.redirectRoute = route.state.queryParams.redirect;
      }
      if (route.state.queryParams && route.state.queryParams.language) {
        if (route.state.queryParams.language === 'all' || route.state.queryParams.language === 'en') {
          this.localStorageService.clearItem(REGISTRATION_LANGUAGE_LOCK_KEY);
          this.store.dispatch(
            new LanguageActions.SetActiveLanguage('en')
          );
          this.store.dispatch(
            new TranslationActions.SetTranslationLanguage('en')
          );
          const newRegistrationLock =
              <IRegistrationLanguageLock> {
                expires: moment().add(1, 'hours').unix(),
                language: 'en'
              };
          this.localStorageService.setItem(REGISTRATION_LANGUAGE_LOCK_KEY, newRegistrationLock);
        } else {
          const newRegistrationLock =
            <IRegistrationLanguageLock> {
              expires: moment().add(1, 'hours').unix(),
              language: route.state.queryParams.language
            };
          this.localStorageService.setItem(REGISTRATION_LANGUAGE_LOCK_KEY, newRegistrationLock);
          this.store.dispatch(
            new LanguageActions.SetActiveLanguage(route.state.queryParams.language)
          );
          this.store.dispatch(
            new TranslationActions.SetTranslationLanguage(route.state.queryParams.language)
          );
        }
      }
      // Check if company is fixed
      if (route.state.queryParams && route.state.queryParams.company) {
        let companyId = route.state.queryParams.company;
        if (companyId === '0') {
          this.localStorageService.clearItem(REGISTRATION_COMPANY_LOCK_KEY);
          this.store.dispatch(
            new LanguageActions.SetActiveLanguage('en')
          );
          this.store.dispatch(
            new TranslationActions.SetTranslationLanguage('en')
          );
        }
        if (companyId === '11') {
          companyId = '30';
        }
        const newRegistrationLock =
          <IRegistrationCompanyLock> {
            expires: moment().add(1, 'hours').unix(),
            company: companyId
          };
        this.localStorageService.setItem(REGISTRATION_COMPANY_LOCK_KEY, newRegistrationLock);
      }
      // Check for registration success
      // register_success=1
      if (route.state.queryParams && route.state.queryParams.register_success) {
        const success = route.state.queryParams.register_success;
        if (success === '1') {
          this.infoMessage = 'M_REGISTRATION_SUCCESS';
        }
      }
      this.checkUserToken();
    });
    this.user$ = this.store.pipe(select(fromAuth.getUser));
    this.userSub = this.user$.subscribe(user => {
      if (user) {
        this.userLoggedIn();

        if (this.userSub) {
          this.userSub.unsubscribe();
          this.userSub = undefined;
        }
      } else {
        this.isLoginPending = false;
      }
    });
  }
  ngOnDestroy() {
    if (this.loginErrorSub) { this.loginErrorSub.unsubscribe(); }
    if (this.userSub) { this.userSub.unsubscribe(); }
    if (this.routerSub) { this.routerSub.unsubscribe(); }
  }
  checkUserToken() {
    this.store.dispatch(new fromStore.SilentAuthNoAction('/'));
    this.isLoginPending = true;
  }
  userLoggedIn() {
    if (this.isLogoutRequest) {
      return;
    }
    this.isLoginPending = false;
    this.cachedRequestService.clearItems();
    // clear cached tawk.to cookies
    this.deleteAllTawkToCookies();
    if (this.redirectRoute) {
      this.router.navigateByUrl(this.redirectRoute);
    } else {
      this.router.navigate(['/dashboard']);
    }

  }
  deleteAllTawkToCookies() {
    try {
      let cookies = document.cookie.split(";");
      cookies = cookies.filter(c => c.trim().startsWith('twk_'))
  
      for (let i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.skuba.eu";
      }
  
      const localStorageKeys = Object.keys(localStorage).filter(c => c.startsWith('twk_'));
      
      for (let i = 0; i < localStorageKeys.length; i++) {
        this.localStorageService.clearItem(localStorageKeys[i]);
      }
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
}
  onSubmit($event: IAuthenticate) {
    this.store.dispatch(new fromStore.SilentLogin($event));
    this.isLoginPending = true;
  }
}
