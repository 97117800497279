<header>
  <div class="app-header" [@fadeIn]>
    <div class="base-container">
      <nav class="row">
        <div class="start-menu">
          <div class="nav-toggler">
            <div id="burger">
              <div
                class="burger burger3"
                [ngClass]="{ selected: openMobileMenu }"
                (click)="openMenu.emit(openMobileMenu)"
              >
                <span></span>
              </div>
            </div>
          </div>
          <div class="brand-logo">
            <a class="title-icon" routerLink="/dashboard">
              <img *ngIf="logoSettings.normal" src="assets/images/logo-skuba.png" />
              <img  *ngIf="logoSettings.christmas" class="christmas-logo" src="assets/images/logo-skuba-christmas.png" />
            </a>
          </div>
          <ul
            #menuContainer
            nz-menu
            [nzMode]="'horizontal'"
            [nzTheme]="'light'"
            *ngIf="user && user.profile && user.profile.is_user_interface_active"
          >
            <ng-container *ngFor="let item of navLinks">
              <li
                *ngIf="item.display"
                nz-menu-item
                routerLinkActive="ant-menu-item-selected"
              >
                  <a
                    class="nav-link"
                    href
                    [routerLink]="item.path"
                    (click)="openMenu.emit(openMobileMenu)"
                    routerLinkActive="ant-menu-item-selected"
                  >
                  <img class="image-icon" src="{{ item.imageSrc }}" *ngIf="item.image" [ngClass]="[item.imageClass ? item.imageClass : '']"/>
                    <i class="fas big-fa" *ngIf="item.iconFA" [ngClass]="[item.icon]"></i>
                    <i class="{{ item.icon }}" *ngIf="!item.iconFA"></i>
                    <nz-badge [nzCount]="item.badgeCount">
                      {{ item.label | translate }}
                    </nz-badge>
                  </a>
              </li>
            </ng-container>
            <li
              *ngIf="adminPanelLink.display"
              class="nav-admin-panel"
              nz-submenu
              [(nzOpen)]="adminMenuOpen"
              (nzOpenChange)="workaroundAdminMenuBadges()"
              routerLinkActive="ant-menu-item-selected"
              #adminPanelSubmenu
            >
              <span title="">
                <i class="fas big-fa fa-gears"></i>
                {{ adminPanelLink.label | translate }}
                <nz-badge class="in-menu" [nzCount]="translationLink.badgeCount + emailTranslationsLink.badgeCount + usersLink.badgeCount">
                  <i
                    class="fas fa-caret-down"
                  ></i>
                </nz-badge>
              </span>
              <ul class="admin-panel-menu">
                <li
                  class="level1"
                  *ngIf="adminPanelLink.display"
                  nz-menu-item
                  routerLinkActive="ant-menu-item-selected"
                >
                  <a class="nav-link" href [routerLink]="adminPanelLink.path" routerLinkActive="ant-menu-item-selected">
                    <i class="fas big-fa" [ngClass]="[adminPanelLink.icon]"></i>
                    {{ adminPanelLink.label | translate }}
                  </a>
                </li>
                <li *ngIf="adminMenuItemsRendered && usersLink.display" class="level1" nz-menu-item routerLinkActive="ant-menu-item-selected">
                  <a class="nav-link" href [routerLink]="usersLink.path" routerLinkActive="ant-menu-item-selected">
                    <nz-badge class="translations-email" [nzCount]="usersLink.badgeCount">
                      <i class="fas big-fa" [ngClass]="[usersLink.icon]"></i>
                      {{ usersLink.label | translate }}
                    </nz-badge>
                  </a>
                </li>
                <li *ngIf="adminMenuItemsRendered" nz-menu-group>
                  <span title="" class="ant-menu-item-group-title"
                    ><i class="fas big-fa fa-globe"></i> {{ 'TRANSLATIONS' | translate }}</span
                  >
                  <ul>
                    <li
                      class="level2"
                      nz-menu-item
                      routerLinkActive="ant-menu-item-selected"
                    >
                      <a [routerLink]="translationLink.path" routerLinkActive="ant-menu-item-selected">
                        <nz-badge class="translations" [nzCount]="translationLink.badgeCount">
                          {{ translationLink.label | translate }}
                        </nz-badge>
                      </a>
                    </li>
                    <li class="level2" nz-menu-item>
                      <a [routerLink]="largeTextsLink.path" routerLinkActive="ant-menu-item-selected">
                          <nz-badge class="translations" [nzCount]="largeTextsLink.badgeCount">
                            {{ largeTextsLink.label | translate }}
                          </nz-badge>
                      </a>
                    </li>
                    <li class="level2"
                    nz-menu-item
                    >
                      <a [routerLink]="emailTranslationsLink.path" routerLinkActive="ant-menu-item-selected">
                        <nz-badge class="translations-email" [nzCount]="emailTranslationsLink.badgeCount">
                          {{ emailTranslationsLink.label | translate }}
                        </nz-badge>
                      </a>
                    </li>
                  </ul>
                </li>
                <li *ngIf="adminMenuItemsRendered && customEmailLink.display" class="level1" nz-menu-item routerLinkActive="ant-menu-item-selected">
                  <a class="nav-link" href [routerLink]="customEmailLink.path" routerLinkActive="ant-menu-item-selected">
                    <i class="fas big-fa" [ngClass]="[customEmailLink.icon]"></i>
                    {{ customEmailLink.label | translate }}
                  </a>
                </li>
                <li *ngIf="adminMenuItemsRendered && bannersEditLink.display" class="level1" nz-menu-item routerLinkActive="ant-menu-item-selected">
                  <a class="nav-link" href [routerLink]="bannersEditLink.path" routerLinkActive="ant-menu-item-selected">
                    <i class="fas big-fa" [ngClass]="[bannersEditLink.icon]"></i>
                    {{ bannersEditLink.label | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="end-menu">
          <div class="nav-control col">
            <ng-container *ngIf="user && user.profile && user.profile.is_user_interface_active">
              <a title="{{ user.first_name }} {{ user.last_name }}, {{ user.username }}" nz-dropdown [nzDropdownMenu]="menu">
                <div class="nav-profile-container">
                  <div class="profile-info">
                    <strong>{{ user.first_name }} {{ user.last_name }} </strong>
                    <div>{{ user.username | ellipsis: 20 }}</div>
                  </div>
                  <div class="profile-icon">
                    <i class="fas fa-hammer" *ngIf="user.is_staff"></i>
                    <i class="fas fa-hammer" *ngIf="user.is_superuser"></i>
                    <i class="fas fa-caret-down user-menu"></i>
                  </div>
                </div>
              </a>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu="">
                  <li nz-menu-item>
                    <a [routerLink]="settingsLink.path" routerLinkActive="ant-menu-item-selected">
                      <i class="fas {{ settingsLink.icon }}"></i>
                      {{ settingsLink.label | translate }}
                    </a>
                  </li>
                  <!-- <li nz-menu-item>
                    <a [routerLink]="addressesLink.path" routerLinkActive="ant-menu-item-selected">
                      <i class="fas {{ addressesLink.icon }}"></i>
                      {{ addressesLink.label | translate }}
                    </a>
                  </li> -->
                  <li nz-menu-item>
                    <a [routerLink]="carLink.path" routerLinkActive="ant-menu-item-selected">
                      <i class="{{ carLink.icon }}"></i>
                      {{ carLink.label | translate }}
                    </a>
                  </li>
                  <li nz-menu-divider></li>
                  <li nz-menu-item>
                    <a [routerLink]="logoutLink.path" routerLinkActive="ant-menu-item-selected">
                      <i class="fas {{ logoutLink.icon }}"></i>
                      {{ logoutLink.label | translate }}
                    </a>
                  </li>
                </ul>
              </nz-dropdown-menu>
            </ng-container>

            <ng-container *ngIf="user && user.profile && (!user.profile.is_user_interface_active)">
              <a title="{{ user.first_name }} {{ user.last_name }}, {{ user.username }}" nz-dropdown [nzDropdownMenu]="menuAny">
                <strong>{{ user.first_name }} {{ user.last_name }} </strong>
                <i class="fas fa-hammer" *ngIf="user.is_staff"></i>
                <i class="fas fa-hammer" *ngIf="user.is_superuser"></i>
                <i class="fas fa-caret-down user-menu"></i>
                <br />
                {{ user.username | ellipsis: 20 }}
              </a>
              <nz-dropdown-menu #menuAny="nzDropdownMenu">
                <ul nz-menu="">
                  <li nz-menu-item>
                    <a [routerLink]="settingsLink.path" routerLinkActive="ant-menu-item-selected">
                      <i class="fas {{ settingsLink.icon }}"></i>
                      {{ settingsLink.label | translate }}
                    </a>
                  </li>
                  <li nz-menu-divider></li>
                  <li nz-menu-item>
                    <a [routerLink]="logoutLink.path" routerLinkActive="ant-menu-item-selected">
                      <i class="fas {{ logoutLink.icon }}"></i>
                      {{ logoutLink.label | translate }}
                    </a>
                  </li>
                </ul>
              </nz-dropdown-menu>
              <app-toast></app-toast>
            </ng-container>
            <ng-container *ngIf="user && user.profile && user.profile.is_user_interface_active && showCartMiniAndLanguage">
              <ng-container *ngIf="user && user.user_settings && user.user_settings.enable_basket_logic">
                <div class="nav-cart-button">
                  <nz-spin [nzSpinning]="isLoadingCart" 
                           [ngStyle]="{'width': '63px'}"
                           (mouseenter)="mouseEnter()" 
                           (mouseleave)="mouseLeave()">
                    <a [routerLink]="['/cart']">
                      <nz-badge [nzCount]="totalQuantity">
                          <app-button
                            class="collapse-icon"
                            className="btn-r-small"
                            icon="fas fa-shopping-cart"
                          ></app-button>
                      </nz-badge>
                    </a>
                  </nz-spin>
                  <div [ngClass]="{'nav-cart-and-toast': true, 'cart-mini-hidden': !cartMiniShowUp}">
                    <div class="nav-cart-and-toast-container">
                      <app-cart-mini *ngIf="user && user.user_settings && user.user_settings.enable_basket_mini && !cartIsOpen"
                                     [ngClass]="{
                                       'cart-mini-show-up': cartMiniShowUp,
                                       'cart-mini-max-height': cartMiniMaxHeight,
                                       'cart-mini-display': cartMiniDisplay
                                     }"
                                     (mouseenter)="mouseEnter()"
                                     (mouseleave)="mouseLeave()"
                                     (selectOpen)="selecting($event)">
                      </app-cart-mini>
                      <app-toast></app-toast>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!user || !user.user_settings || !user.user_settings.enable_basket_logic">
                <app-toast></app-toast>
              </ng-container>
            </ng-container>
            <app-language-switch *ngIf="showCartMiniAndLanguage"></app-language-switch>
          </div>
        </div>
      </nav>
    </div>
  </div>
</header>
<app-search-nav (updateStatus)="setSearchClass($event)" *ngIf="!displaySearchByCar"></app-search-nav>
<app-search-by-car-nav (updateStatus)="setSearchClass($event)" *ngIf="displaySearchByCar"></app-search-by-car-nav>
