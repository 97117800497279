import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input-validation',
  templateUrl: './input-validation.component.html',
  styleUrls: ['./input-validation.component.scss']
})
export class InputValidationComponent {
  /**
   * Class which defines validation type.
   * 1) error
   */
  @Input() className: string;

  /**
   * ngIf validation condition.
   */
  @Input() condition: any;

  /**
   * Validation text.
   */
  @Input() message: any;
}
