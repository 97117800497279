var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api.service';
import { map } from 'rxjs/internal/operators';
import { IShowcaseCataloguePogrhead } from '@app/pages/dashboard/models/showcase.model';
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';
import { DecoratorsService } from '../develop/decorators';
export class CatalogueService {
    constructor(apiService, http) {
        this.apiService = apiService;
        this.http = http;
    }
    getBaseGroups() {
        return this.apiService.getJSON('basegroups.json').pipe(map(data => data));
    }
    getGroupsByStock() {
        return this.http.get(environment.api_url + '/products/stock_sub_groups/');
        // return this.apiService.getJSON('groups.json').pipe(map(data => data));
    }
    getPogrHead() {
        return this.apiService.getJSON('pogrhead.json').pipe(map(data => data));
    }
}
__decorate([
    DecoratorsService.DevCache(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Observable)
], CatalogueService.prototype, "getBaseGroups", null);
__decorate([
    DecoratorsService.DevCache(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Observable)
], CatalogueService.prototype, "getGroupsByStock", null);
__decorate([
    DecoratorsService.DevCache(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Observable)
], CatalogueService.prototype, "getPogrHead", null);
