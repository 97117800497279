<div class="app-status-chip" *ngIf="!type">
  <div *ngIf="success" class="chip chip-success">{{success | translate}}</div>
  <div *ngIf="warning" class="chip chip-warning">{{warning | translate}}</div>
  <div *ngIf="error" class="chip chip-error">{{error | translate}}</div>
  <div *ngIf="info" class="chip chip-info">{{info | translate}}</div>
</div>
<div class="app-status-chip" *ngIf="type">
  <div class="chip" [ngClass]="{
    'chip-success': type === 'SUCCESS',
    'chip-warning': type === 'WARNING',
    'chip-error': type === 'ERROR',
    'chip-info': type === 'INFO',
    'chip-gray': type === 'GRAY'
    }"
  >{{text | translate}}</div>
</div>