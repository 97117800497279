import { Component, Input, OnInit } from '@angular/core';

import { BreadcrumbsItem } from './breadcrumbs.model';
import { fadeIn } from '../../animations';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  animations: [fadeIn]
})
export class BreadcrumbsComponent implements OnInit {
  @Input() items: BreadcrumbsItem[];

  constructor(private route: ActivatedRoute) {}
  ngOnInit() {}
  getBreadcrumbs(breadcrumb) {
    if (breadcrumb.params) {
      return [breadcrumb.url, breadcrumb.params];
    }
    return [breadcrumb.url];
  }
}
