import { Pipe, PipeTransform } from '@angular/core';
import { ProductStock } from '@app/shared/models/product.model';
import { IUser } from '@app/auth/models/user';
import { SkubaCompanyCodeEnum } from '@app/shared/models/company.model';

@Pipe({
  name: 'productState'
})
export class ProductStatePipe implements PipeTransform {
  transform(extraProduct: ProductStock, key: string, user: IUser = null): boolean {
    switch (key) {
      case 'deposit':
        return this.isDeposit(extraProduct);
      case 'onTheWay':
        return this.isOnTheWay(extraProduct);
      case 'onTheWayWDate':
        return this.isOnTheWayWithDate(extraProduct);
      case 'peopleCarry':
        return this.isPeopleCarry(extraProduct);
      case 'historyStock':
        return this.isStockState(extraProduct, 'historyStock', user);
      case 'centralStock':
        return this.isStockState(extraProduct, 'centralStock', user);
      case 'nearStock':
        return this.isStockState(extraProduct, 'nearStock', user);
      case 'localStock':
        return this.isStockState(extraProduct, 'localStock', user);
      default:
        return false;
    }
  }
  isStockState(extraProduct: ProductStock, key: string, user: IUser) {
    if (
      key === 'historyStock' && extraProduct.quantity <= 0) {
      return true;
    }
    if (
      key === 'centralStock' && extraProduct.is_central) {
      return true;
    }
    if (key === 'centralStock' &&
        user &&
        extraProduct &&
        extraProduct.shop &&
        +extraProduct.shop.fid === 201 &&
        user.profile.company.skuba_company_id === SkubaCompanyCodeEnum.VilniusSkuba &&
        +user.profile.shop.fid === 201) {
      return true;
    }
    if (
      key === 'nearStock' &&
      (extraProduct.quantity > 0 || (typeof extraProduct.quantity === 'string' && extraProduct.quantity.substring(1, 3) === '10')) &&
      !extraProduct.is_central &&
      !(extraProduct.shop && user && extraProduct.shop.id === user.profile.shop.id)) {
      return true;
    }
    if (
      key === 'localStock' &&
      (extraProduct.quantity > 0 || (typeof extraProduct.quantity === 'string' && extraProduct.quantity.substring(1, 3) === '10')) &&
      !extraProduct.is_central &&
      (extraProduct.shop && user && extraProduct.shop.id === user.profile.shop.id)) {
      return true;
    }

    return false;
  }
  isDeposit(extraProduct: ProductStock) {
    return extraProduct.deposit > 0;
  }
  isOnTheWay(extraProduct: ProductStock) {
    return extraProduct.on_the_way;
  }
  isOnTheWayWithDate(extraProduct: ProductStock) {
    return !!(extraProduct.on_the_way && !extraProduct.is_carried && extraProduct.on_the_way_date);
  }
  isPeopleCarry(extraProduct: ProductStock) {
    return extraProduct.on_the_way && extraProduct.is_carried;
  }

}
