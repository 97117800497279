import {
  LayoutActions,
  LayoutActionTypes
} from '@app/core/store/actions/layout.action';

export interface LayoutState {
  hamburgerMenuOpen: boolean;
  searchIconVisible: boolean;
}

const initialState: LayoutState = {
  hamburgerMenuOpen: false,
  searchIconVisible: true
};

export function reducer(
  state: LayoutState = initialState,
  action: LayoutActions
): LayoutState {
  switch (action.type) {
    case LayoutActionTypes.ShowSearchIcon:
      return {
        ...state,
        searchIconVisible: true
      };
    case LayoutActionTypes.HideSearchIcon:
      return {
        ...state,
        searchIconVisible: false
      };
    case LayoutActionTypes.CloseHamburger:
      return {
        ...state,
        hamburgerMenuOpen: false
      };

    case LayoutActionTypes.OpenHamburger:
      return {
        ...state,
        hamburgerMenuOpen: true
      };

    case LayoutActionTypes.ResetLayoutState:
      return {
        ...initialState
      };

    default:
      return state;
  }
}
export const getShowSearchIcon = (state: LayoutState) =>
  state.searchIconVisible;
export const getShowHamburger = (state: LayoutState) => state.hamburgerMenuOpen;
