import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import * as fromCart from '@pages/cart/store';
import { Observable, of } from 'rxjs';
import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';

@Injectable()
export class CartGuard implements CanActivate {
  constructor(private store: Store<fromCart.CartState>) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.pipe(select(fromCart.getCartLoaded)).pipe(
      tap(loaded => {
        if (!loaded) {
          this.store.dispatch(new fromCart.LoadCart());
        }
      }),
      filter(loaded => loaded),
      take(1)
    );
  }
}
