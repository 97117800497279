<section [@fadeInDown]>
  <div class="base-container">
    <div class="row">
      <app-back-button></app-back-button>
    </div>
    <div class="align-center">
      <app-forgot-password-reset-form
        [user]="user"
        [token]="token"
        *ngIf="user && token">
      </app-forgot-password-reset-form>
    </div>
  </div>
</section>
