import { Routes } from '@angular/router';
import { CartComponent } from '@pages/cart/containers/cart.component';
const ɵ0 = {
    translationKey: 'CART'
};
const routes = [
    {
        path: '',
        component: CartComponent,
        data: ɵ0
    }
];
export class CartRoutingModule {
}
export const routedComponents = [CartComponent];
export { ɵ0 };
