import { ProductActions, ProductActionTypes } from '../actions/product.action';
import { ProductItem, ProductStock } from '@shared/models/product.model';

export interface ProductState {
  product: ProductItem;
  loaded: boolean;
  loading: boolean;
}

const initialState: ProductState = {
  product: null,
  loaded: false,
  loading: false
};

export function reducer(
  state = initialState,
  action: ProductActions
): ProductState {
  switch (action.type) {
    case ProductActionTypes.ProductLoad: {
      return {
        ...state,
        loading: true
      };
    }
    case ProductActionTypes.ProductLoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        product: action.payload
      };
    }
    case ProductActionTypes.ProductLoadFail: {
      return {
        ...state,
        loading: false,
        loaded: false
      };
    }
    case ProductActionTypes.ProductExtend: {
      return {
        ...state
      };
    }
    case ProductActionTypes.ProductExtendSuccess: {
      const stock = action.payload;
      const newState = JSON.parse(JSON.stringify(state));
      if (action.withoutCard) {
        newState.product.stock.map((item: ProductStock) => {
          if (
            (item.brand.toLocaleLowerCase().trim() === stock.brand.toLocaleLowerCase().trim()) &&
            item.warehouse === stock.warehouse &&
            item.is_central === stock.is_central &&
            item.on_the_way === stock.on_the_way &&
            item.deposit === stock.deposit
          ) {
            item.quantity = stock.quantity;

            item.list_price = stock.list_price;
            item.list_price_eur = stock.list_price_eur;
            item.price = stock.price;
            item.price_eur = stock.price_eur;

            item.list_price_wo_vat = stock.list_price_without_vat;
            item.list_price_wo_vat_eur = stock.list_price_without_vat_eur;
            item.price_wo_vat = stock.price_without_vat;
            item.price_wo_vat_eur = stock.price_without_vat_eur;

            item.deposit = stock.deposit;
            item.deposit_eur = stock.deposit_eur;
            item.deposit_wo_vat = stock.deposit_without_vat;
            item.deposit_wo_vat_eur = stock.deposit_without_vat_eur;
          }
          return item;
        });
      } else {
        newState.product.stock.map((item: ProductStock) => {
          if (
            (item.card_number === stock.card) &&
            item.warehouse === stock.warehouse &&
            item.is_central === stock.is_central
          ) {
            item.quantity = stock.quantity;

            item.list_price = stock.list_price;
            item.list_price_eur = stock.list_price_eur;
            item.price = stock.price;
            item.price_eur = stock.price_eur;

            item.list_price_wo_vat = stock.list_price_without_vat;
            item.list_price_wo_vat_eur = stock.list_price_without_vat_eur;
            item.price_wo_vat = stock.price_without_vat;
            item.price_wo_vat_eur = stock.price_without_vat_eur;

            item.deposit = stock.deposit;
            item.deposit_eur = stock.deposit_eur;
            item.deposit_wo_vat = stock.deposit_without_vat;
            item.deposit_wo_vat_eur = stock.deposit_without_vat_eur;
          }
          return item;
        });
      }

      return newState;
    }
    case ProductActionTypes.ResetProductState: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export const getProduct = (state: ProductState) => state.product;
export const getProductLoading = (state: ProductState) => state.loading;
export const getProductLoaded = (state: ProductState) => state.loaded;
