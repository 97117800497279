import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { SubGroupActionTypes } from '../actions/subGroup.action';
import { SubGroupService } from '@app/core/service/subGroup.service';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SubGroupActions from '../actions/subGroup.action';

@Injectable()
export class SubGroupEffects {
  @Effect() loadSubgroups$ = this.actions$.pipe(
    ofType(SubGroupActionTypes.SubGroupsLoad),
    mergeMap(() => {
      return this.service.getSubGroups().pipe(
        map(jobs => new SubGroupActions.SubGroupsLoadSuccess(jobs.results)),

        catchError(error => of(new SubGroupActions.SubGroupsLoadFail(error)))
      );
    })
  );

  constructor(private actions$: Actions, private service: SubGroupService) {}
}
