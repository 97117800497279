import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ElementRef,
  OnChanges,
  OnDestroy
} from '@angular/core';
import { fadeInDown, fadeIn } from '@shared/animations';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { PhoneValidator } from '@app/shared/validators/phone.validator';
import { Select } from '@app/shared/ui/select/select.model';
import { Router } from '@angular/router';
import { ShopService } from '@shared/services/shop.service';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import { Observable, Subscription } from 'rxjs';
import { HelperService } from '@shared/services/helper.service';
import { ICompany, SkubaCompanyCodeEnum } from '@shared/models/company.model';
import { REGISTRATION_LANGUAGE_LOCK_KEY, IRegistrationLanguageLock, REGISTRATION_COMPANY_LOCK_KEY, IRegistrationCompanyLock } from '@app/auth/models/user';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import * as LanguageActions from '@core/store/actions/language.action';
import * as TranslationActions from '@core/store/actions/translation.action';
import * as moment from 'moment';
import { IRegisterComponentError } from '@app/auth/containers/register-page/register-page.component';
import { ADDITIONAL_FIELDS, CompaniesSelect, ADDITIONAL_FIELDS_USER } from '@app/auth/data/register-form';
import { TranslationService } from '@app/core/service/translation.service';
import { UsernameValidator } from '@app/shared/validators/username.validator';
import { PasswordValidator } from '@app/shared/validators/password.validator';
import { ApiService } from '@app/core/api.service';

@Component({
  selector: 'app-skuba-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
  animations: [fadeInDown, fadeIn]
})
export class RegisterFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() countryPhoneCodesInitial;

  countryPhoneCodes: any;

  userForm: FormGroup;

  /** Countries select list */
  @Input() countriesIn: Select;
  @Input() companiesInitial: CompaniesSelect[];
  @Input() presetCompany: ICompany;
  @Input() backendError$: Observable<IRegisterComponentError>;
  customErrors: { [key: string]: IRegisterComponentError } = {};
  customErrorsMsg = '';
  companies: CompaniesSelect[];
  shops: any;
  shopInitial: any;

  @Output() readonly registrationSubmitted = new EventEmitter<FormGroup>();

  activeLanguage$: Observable<string>;
  activeLanguageSub: Subscription;

  formSubmitAttempt = false;
  disableRegistration: boolean;
  phoneCodeInitial: any;
  companyInitial: any;
  showGeneralTermsCheckbox = true;
  showAdditionalRestrictionCheckbox = false;
  generalTermsError = false;
  additionalRestrictionCheckboxError = false;

  additionalFields = ADDITIONAL_FIELDS;
  additionalFieldsUser = ADDITIONAL_FIELDS_USER;
  showAdditionalFields = false;
  router$: Observable<any>;
  routerSub: Subscription;

  hasTooCommonPasswordInputed = false;
  passCheckLoading = false;

  hasExistingUsernameInputed = false;
  usernameCheckLoading = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private shopService: ShopService,
    private store: Store<fromRoot.AppState>,
    private element: ElementRef,
    private helperService: HelperService,
    private localStorageService: LocalStorageService,
    private translationService: TranslationService,
    private apiService: ApiService,
  ) {
    this.router$ = this.store.pipe(select(fromRoot.getRouterState));
    this.activeLanguage$ = this.store.pipe(select(fromRoot.getActiveLanguage));
    PasswordValidator.Init(apiService);
    UsernameValidator.Init(apiService);
  }

  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('password1').value;
    const confirmPassword = AC.get('password2').value;

    if (confirmPassword !== null) {
      if (password !== confirmPassword) {
        AC.get('password2').setErrors({ MatchPassword: true });
      } else {
        AC.get('password2').setErrors(null);
      }
      AC.get('password2').markAsPristine();
      AC.get('password2').markAsTouched();
    }
  }

  static ShortPassword(AC: AbstractControl) {
    const password = AC.value;
    if (password && password.length < 6) {
      AC.markAsTouched();
      return {
        PASS_MIN_LEN_6: true,
      };
    } else {
      return null;
    }
  }

  CommonPassword(AC: AbstractControl) {
    if (this.hasTooCommonPasswordInputed) {
      return {
        PASS_TOO_COMMON: this.hasTooCommonPasswordInputed
      };
    }
    return null;
  }

  CommonUsername(AC: AbstractControl) {
    if (this.hasExistingUsernameInputed) {
      return {
        USERNAME_TAKEN: this.hasExistingUsernameInputed
      };
    }
    return null;
  }

  ngOnInit(): void {
    this.disableRegistration = false;

    if (this.disableRegistration) {
      this.router.navigate(['/login']);
    }
    this.fixLanguage();
    this.initForm();
    this.initActiveLanguage();
    this.updateCompaniesSort();
    this.updatePhonesSort();
    this.backendError$.subscribe((error) => {
      if (error.customError || !this.userForm.get(error.name)) {
        this.customErrors[error.name] = error;
        this.customErrorsMsg = error.name + ': [' +
          Object.keys(this.customErrors)
          .map(key => this.translationService.translate(this.customErrors[key].value)).join('; ')
          + ']';
      } else {
        this.userForm.get(error.name).setErrors({[error.errorType]: true});
        this.userForm.get(error.name).markAsPristine();
        this.userForm.get(error.name).markAsTouched();
      }
    });
    PasswordValidator.checkPassword.subscribe(result => {
      if (!result) {
        this.hasTooCommonPasswordInputed = false;
      } else if (result.error) {
        this.hasTooCommonPasswordInputed = true;
      } else {
        this.hasTooCommonPasswordInputed = false;
      }
      setTimeout(() => {
        this.passCheckLoading = false;
        const password1Control = this.userForm.get('password1');
        password1Control.updateValueAndValidity()
        password1Control.markAsPristine();
        password1Control.markAsTouched();
      });
    });
    UsernameValidator.checkUsername.subscribe(result => {
      if (!result) {
        this.hasExistingUsernameInputed = false;
      } else if (result.error) {
        this.hasExistingUsernameInputed = true;
      } else {
        this.hasExistingUsernameInputed = false;
      }
      setTimeout(() => {
        this.usernameCheckLoading = false;
        const usernameControl = this.userForm.get('username');
        usernameControl.updateValueAndValidity()
        usernameControl.markAsPristine();
        usernameControl.markAsTouched();
      });
    });
  }

  fixLanguage(): void {
    //FIXME: move to helper method, as in login form is smame logic.....
    // Check if language is fixed
    this.routerSub = this.router$.subscribe(route => {
      if (route.state.queryParams && route.state.queryParams.language) {
        if (route.state.queryParams.language === 'all') {
          this.localStorageService.clearItem(REGISTRATION_LANGUAGE_LOCK_KEY);
          this.store.dispatch(
            new LanguageActions.SetActiveLanguage('en')
          );
          this.store.dispatch(
            new TranslationActions.SetTranslationLanguage('en')
          );
          return;
        }
        const newRegistrationLock =
          <IRegistrationLanguageLock> {
            expires: moment().add(1, 'hours').unix(),
            language: route.state.queryParams.language
          };
        this.localStorageService.setItem(REGISTRATION_LANGUAGE_LOCK_KEY, newRegistrationLock);
        this.store.dispatch(
          new LanguageActions.SetActiveLanguage(route.state.queryParams.language)
        );
        this.store.dispatch(
          new TranslationActions.SetTranslationLanguage(route.state.queryParams.language)
        );
      }
      // Check if company is fixed
      if (route.state.queryParams && route.state.queryParams.company) {
        let companyId = route.state.queryParams.company;
        if (companyId === '0') {
          this.localStorageService.clearItem(REGISTRATION_COMPANY_LOCK_KEY);
          this.store.dispatch(
            new LanguageActions.SetActiveLanguage('en')
          );
          this.store.dispatch(
            new TranslationActions.SetTranslationLanguage('en')
          );
          return;
        }
        if (companyId === '11') {
          companyId = '30';
        }
        const newRegistrationLock =
          <IRegistrationCompanyLock> {
            expires: moment().add(1, 'hours').unix(),
            company: companyId
          };
        this.localStorageService.setItem(REGISTRATION_COMPANY_LOCK_KEY, newRegistrationLock);
      }
    });
  }

  clearFormErrors() {
    this.customErrorsMsg = '';
    this.userForm.setErrors(null);
  }

  initForm(): void {
    this.userForm = this.fb.group(
      {
        username: [null, [Validators.required, UsernameValidator.validate, (AC: AbstractControl) => this.CommonUsername(AC)]],
        first_name: [null, [Validators.required, Validators.maxLength(30)]],
        last_name: [null, [Validators.required, Validators.maxLength(150)]],
        email: [null, [Validators.required, Validators.email]],
        client_hint: [null, [Validators.required, Validators.maxLength(100)]],
        company_id: [null, Validators.required],
        shop_id: [null, Validators.required],
        language_code: [null],
        phone_code: [null, Validators.required],
        phone: [null, [Validators.required, PhoneValidator.validate]],
        password1: [null, [Validators.required, RegisterFormComponent.ShortPassword, (AC: AbstractControl) => this.CommonPassword(AC)]],
        password2: [null, Validators.required],
        skype: [null, Validators.maxLength(30)],
        agreement: [false],
        additional_restriction: [false],
        subscribe_to_emails: [false],
      },
      {
        validator: [
          RegisterFormComponent.MatchPassword,
        ]
      }
    );
    this.userForm.controls['company_id'].valueChanges.subscribe(v => {
      this.updateShops(v);
    });
    this.userForm.controls['password1'].valueChanges.subscribe((password: string) => {
      if (!password) {
        return;
      }
      if (password && password.length < 6) {
        return;
      }
      PasswordValidator.checkPasswordSubject.next({
        password: password,
        ac: this.userForm.controls['password1'],
        callback: (isLoading) => { this.passCheckLoading = isLoading; } 
      });
    });
    this.userForm.controls['username'].valueChanges.subscribe((username: string) => {
      if (!username) {
        return;
      }
      if (username && username.length < 1) {
        return;
      }
      UsernameValidator.checkUsernameSubject.next({
        username: username,
        ac: this.userForm.controls['username'],
        callback: (isLoading) => { this.usernameCheckLoading = isLoading; } 
      });
    });
  }

  initActiveLanguage() {
    this.activeLanguageSub = this.activeLanguage$.subscribe(language => {
      this.userForm.controls['language_code'].setValue(language);
      this.updateCompaniesSort();
      this.updatePhonesSort();
    });
  }

  submitForm(form: FormGroup) {
    if (!this.userForm.value['agreement'] && this.showGeneralTermsCheckbox) {
      this.generalTermsError = false;
      setTimeout(() => {
        this.generalTermsError = true;
      });
      return;
    }
    if (!this.userForm.value['additional_restriction'] && this.showAdditionalRestrictionCheckbox) {
      this.additionalRestrictionCheckboxError = false;
      setTimeout(() => {
        this.additionalRestrictionCheckboxError = true;
      });
      return;
    }
    this.generalTermsError = false;
    this.additionalRestrictionCheckboxError = false;
    this.helperService.validationScroll(this.element);
    if (form.valid) {
      this.customErrors = {};
      this.customErrorsMsg = '';
      this.registrationSubmitted.emit(form.value);
    }

    this.formSubmitAttempt = true;
  }

  initCompanyPreset(company: ICompany) {
    // Set phone code
    const phoneCodeByCountry = this.countryPhoneCodes.filter(item => {
      return item.name
        .toLowerCase()
        .includes(company.country.code.toLowerCase());
    });
    if (phoneCodeByCountry[0]) {
      this.phoneCodeInitial = phoneCodeByCountry[0];
    }
    // Set company
    const companyInitial = this.companies.filter(item => {
      return item.val === company.id;
    });
    if (companyInitial[0]) {
      this.companyInitial = companyInitial[0];
    }
  }

  ngOnChanges() {
    if (this.companiesInitial) {
      this.hideCompanies();
      this.updateCompaniesSort();
    }
    if (this.countryPhoneCodesInitial) {
      this.countryPhoneCodes = this.countryPhoneCodesInitial;
      this.updatePhonesSort();
    }
    if (this.presetCompany) {
      this.initCompanyPreset(this.presetCompany);
    }
  }

  hideCompanies(): any {
    this.companies = this.companiesInitial;
    let isCompanyFiltered = false;
    if (this.localStorageService.hasKey(REGISTRATION_COMPANY_LOCK_KEY)) {
      const registrationLock = <IRegistrationCompanyLock> this.localStorageService.getItem(REGISTRATION_COMPANY_LOCK_KEY);
      if (registrationLock.expires && (moment().unix() - registrationLock.expires < 0) ) {
        const companyId = Number.parseInt(registrationLock.company, 10);
        this.companies = this.companies.filter(
          item => companyId === 0 || item.val === companyId
        );
        isCompanyFiltered = true;
      }
    }
    if (!isCompanyFiltered) {
      if (this.localStorageService.hasKey(REGISTRATION_LANGUAGE_LOCK_KEY)) {
        const registrationLock = <IRegistrationLanguageLock> this.localStorageService.getItem(REGISTRATION_LANGUAGE_LOCK_KEY);
        if (registrationLock.expires && (moment().unix() - registrationLock.expires < 0) && registrationLock.language !== 'en') {
          this.companies = this.companies.filter(
            item => item.metadata.language_code === registrationLock.language
            );
          }
        }
      }
  }

  updateCompaniesSort() {
    if (this.companiesInitial && this.userForm.value['language_code']) {
      const language = this.userForm.value['language_code'];
      this.companies = this.companiesInitial.sort((first, second) => {
        if (first.metadata.language_code === second.metadata.language_code) {
          return 0;
        }
        if (
          first.metadata.language_code !== language &&
          second.metadata.language_code === language
        ) {
          return 1;
        }
        if (
          second.metadata.language_code !== language &&
          first.metadata.language_code === language
        ) {
          return -1;
        }
        return 0;
      });
      this.hideCompanies();
      if (!this.userForm.value['company_id']) {
        const languageCompanies = this.companies.filter(
          item => item.metadata.language_code === language
        );
        if (languageCompanies.length === 1) {
          this.userForm.controls['company_id'].setValue(
            languageCompanies[0].val
          );
          this.companyInitial = languageCompanies[0];
        }
      }
      this.updateShops(this.userForm.value['company_id']);
    }
  }

  updateShops(company_id) {
    if (company_id) {
      const company = this.companies.filter(cmp => cmp.val === company_id)[0]
      this.shops = company.metadata.shops
        .map(shop => ({
          name: shop.name,
          val: shop.id,
        }));
        if (this.shops.length === 1) {
          this.shopInitial = this.shops[0];
        }
        if (
          company.skuba_company_id === SkubaCompanyCodeEnum.Slovakia 
          || company.skuba_company_id === SkubaCompanyCodeEnum.Poland
          || company.skuba_company_id === SkubaCompanyCodeEnum.Ukraine
          ) {
          this.userForm.get('client_hint').setValue('-');
          this.userForm.get('client_hint').markAsPristine();
          this.userForm.get('client_hint').markAsTouched();
          this.additionalFields.forEach(v => {
            // client id is not required for poland
            if (company.skuba_company_id === SkubaCompanyCodeEnum.Poland && v.formName === 'ai_client_id') {
              v.required = false;
            }
            if (v.required) {
                this.userForm.addControl(v.formName, this.fb.control(null, Validators.required));
            } else {
              this.userForm.addControl(v.formName, this.fb.control(null));
            }
          });
          this.additionalFieldsUser.forEach(v => {
            if (v.required) {
              this.userForm.addControl(v.formName, this.fb.control(null, Validators.required));
            } else {
              this.userForm.addControl(v.formName, this.fb.control(null));
            }
          });
          if (company.skuba_company_id === SkubaCompanyCodeEnum.Slovakia) {
            this.userForm.controls.additional_restriction.setValidators([Validators.required]);
            this.userForm.controls.additional_restriction.updateValueAndValidity();
            this.showAdditionalRestrictionCheckbox = true;
          }
          this.showAdditionalFields = true;
        } else {
          this.userForm.get('client_hint').setValue('');
          this.userForm.get('client_hint').markAsPristine();
          this.userForm.get('client_hint').markAsTouched();
          this.additionalFields.forEach(v => {
            this.userForm.removeControl(v.formName);
          });
          this.showAdditionalRestrictionCheckbox = false;
          this.userForm.controls.additional_restriction.setValidators(null);
          this.userForm.controls.additional_restriction.updateValueAndValidity();
          this.showAdditionalFields = false;
        }
    }
  }

    updatePhonesSort() {
    if (
      this.countryPhoneCodesInitial &&
      this.userForm.value['language_code'] &&
      this.companies
    ) {
      const language = this.userForm.value['language_code'];
      const languageCompanies = this.companies.filter(
        item => item.metadata.language_code === language
      );
      if (languageCompanies.length > 0) {
        const country = languageCompanies[0].metadata.country_code.toUpperCase();
        this.countryPhoneCodes = this.countryPhoneCodesInitial.sort(
          (first, second) => {
            if (
              first.metadata.countryCallingCode ===
              second.metadata.countryCallingCode
            ) {
              return 0;
            }
            if (
              first.metadata.countryCallingCode !== country &&
              second.metadata.countryCallingCode === country
            ) {
              return 1;
            }
            if (
              second.metadata.countryCallingCode !== country &&
              first.metadata.countryCallingCode === country
            ) {
              return -1;
            }
            return 0;
          }
        );
      }
      if (!this.userForm.value['phone_code'] && languageCompanies.length > 0) {
        const country = languageCompanies[0].metadata.country_code.toUpperCase();
        const phoneCompanies = this.countryPhoneCodes.filter(
          item => item.metadata.countryCallingCode === country
        );
        if (phoneCompanies.length === 1) {
          this.phoneCodeInitial = phoneCompanies[0];
          this.userForm.controls['phone_code'].setValue(phoneCompanies[0].val);
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.activeLanguageSub) {
      this.activeLanguageSub.unsubscribe();
    }
  }
}
