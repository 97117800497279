<ng-container *ngIf="languages" >
  <div nz-dropdown [nzDropdownMenu]="menu">
    <div class="active-language" *ngIf="languageEntities && activeLanguage && languageEntities[activeLanguage]">
      <img [src]="languageEntities[activeLanguage].image" />
      <span>{{ languageEntities[activeLanguage].title }}</span>
      <i style="padding-left: 5px;" class="fas fa-caret-down"></i>
    </div>
  </div>
  <nz-dropdown-menu #menu="nzDropdownMenu">
    <ul nz-menu>
      <li
        nz-menu-item
        *ngFor="let language of languages"
        class="active-language"
        [class.lang-is-active]="language.value === activeLanguage"
        (click)="selectLanguage(language.value)"
      >
        <img [src]="language.image" />
        <span>{{ language.title }}</span>
      </li>
    </ul>
  </nz-dropdown-menu>
</ng-container>
