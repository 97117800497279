import { AbstractControl } from '@angular/forms';
import { ApiService } from '@app/core/api.service';
import { map, debounceTime, filter, distinctUntilChanged, mergeMap, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

class CheckPasswordContainer {
  public password: string;
  public ac: AbstractControl;
  public callback: (isLoading: boolean) => void;
}
export class PasswordValidator {
  static apiService: ApiService = null;
  static checkPasswordSubject = new Subject<CheckPasswordContainer>();
  static checkPassword = PasswordValidator.checkPasswordSubject
    .pipe(
      filter(x => x.password !== ''),
      distinctUntilChanged((x, y) => x.password === y.password),
      tap((x) => {
        x.callback(true);
      }),
      debounceTime(500),
      tap((x) => {
        x.callback(false);
      }),
    )
    .pipe(
      map((pass) => {
        pass.callback(true);
        return PasswordValidator.apiService.post(`/auth/validate_pass/`, { password: pass.password })
          .pipe(
            map((res: string[]) => {
              if (res.length > 0) {
                return {
                  data: pass,
                  error: res[0]
                };
              }
              return null;
            })
        );
      }),
    )
    .pipe(
      mergeMap(pp => {
        return pp;
      }),
    );
  static Init(apiService: ApiService) {
    PasswordValidator.apiService = apiService;
  }
  static MatchPassword(AC: AbstractControl) {
    const password = AC.get('new_password').value;
    const confirmPassword = AC.get('new_password_repeat').value;

    if (confirmPassword !== null) {
      if (password !== confirmPassword) {
        AC.get('new_password_repeat').setErrors({ MatchPassword: true });
      } else {
        AC.get('new_password_repeat').setErrors(null);
      }
    }
  }
}
