import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectionStrategy,
  OnChanges
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

import { Select } from './select.model';
import { DOCUMENT } from '@angular/common';
import { SelectSearchPipe } from '@shared/ui/select/pipes/select-search.pipe';
import { enter, arrowDown, arrowUp } from '@app/shared/models/const.model';

/**
 * @whatItDoes Returns select or multiselect ui component.
 */
@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // Unavoidable ref in component
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent
  implements ControlValueAccessor, OnInit, OnChanges {
  /** Options given in select box */
  @Input() options: any[];

  /** Select placeholder (optional) */
  @Input() placeholder = 'SELECT_OPTION';

  /** Select label (optional) */
  @Input() label: string;

  /** Select label (optional) */
  @Input() disabled: false;

  /** Select label (optional) */
  @Input() disablePlaceholder: false;

  /** Select default option */
  @Input() initialOption: any;

  /**
   * Text ellipsis symbol count.
   * @type {number}
   */
  @Input() ellipsis = 50;

  @Input() imgWithBorder = false;

  @Input() imgSmall = false;
  @Input() isRequired = false;
  @Input() compact = false;

  /**
   * Explanation (optional)
   * @type {string}
   */
  @Input() explanation = '';

  @Input() addNewSelectionItemLink = null;

  /** Emits all changes via `update` */
  @Output() readonly update = new EventEmitter();

  /** Emits reset change` */
  // TODO: This is invoked when native event is ocuring, do we really want this?
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() readonly reset = new EventEmitter();

  /** Scrollable container */
  @ViewChild('container', { static: false }) private container: ElementRef;

  /** Select component data */
  data: any;

  /** Selected component index */
  index: number;

  /**
   * Select option from list.
   * @type {boolean}
   */
  selectStatus = false;

  /**
   * Output for default select.
   * @type {any}
   */
  selectedOption: Select = null;

  /** Search input form group */
  search: FormGroup;

  /**
   * Input focus state.
   * @type {boolean}
   */
  inputFocus = false;

  /** Preselected with keyboard index */
  preIndex: number;

  /** Preselected with keyboard option */
  preOption: Select;

  /** Search input text */
  searchText: any = '';

  constructor(
    @Inject(DOCUMENT) private document: any,
    private element: ElementRef,
    private fb: FormBuilder,
    private selectSearchPipe: SelectSearchPipe
  ) {
    this.search = this.fb.group({
      searchText: ['']
    });
  }

  /**
   * Close select when click outside.
   * @param $event
   */
  @HostListener('document:click', ['$event']) clickedOutside($event) {
    if (!this.element.nativeElement.contains($event.target)) {
      this.selectStatus = false;
    }
  }

  @HostListener('keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent): void {
    // eslint-disable-next-line deprecation/deprecation
    if (event.keyCode === enter && !this.selectStatus) {
      this.selectToggle();
      event.preventDefault();
    }
  }

  /**
   * Scroll and select on keyboard actions.
   * @param e
   * @returns {boolean}
   */
  @HostListener('keydown', ['$event']) onKeyDown(e) {
    const itemDOM: HTMLElement[] = this.element.nativeElement.getElementsByClassName('item');
    const options = this.selectSearchPipe.transform(
      this.options,
      this.search.controls['searchText'].value
    );

    if (!options || !this.selectStatus) {
      return true;
    }

    if (
      this.search.controls['searchText'].value &&
      this.searchText !== this.search.controls['searchText'].value
    ) {
      setTimeout(() => {
        this.searchText = this.search.controls['searchText'].value;
      }, 500);
    }

    // eslint-disable-next-line deprecation/deprecation
    if (e.which === arrowDown || e.keyCode === arrowDown) {
      this.focusOption(this.data, 'DOWN');

      if (itemDOM[this.preIndex]) {
        this.scroll(itemDOM[this.preIndex]);
      }
    // eslint-disable-next-line deprecation/deprecation
    } else if (e.which === arrowUp || e.keyCode === arrowUp) {
      this.focusOption(this.data, 'UP');

      if (itemDOM[this.preIndex]) {
        this.scroll(itemDOM[this.preIndex]);
      }
    // eslint-disable-next-line deprecation/deprecation
    } else if (e.which === enter || e.keyCode === enter) {
      if (this.preOption) {
        this.selectOption(this.preOption);
      }
    // eslint-disable-next-line deprecation/deprecation
    } else if (e.keyCode === escape) {
      this.selectStatus = false;
    }

    return true;
  }

  ngOnInit() {
    this.selectOption({ name: 'SELECT_OPTION', val: null });
    if (this.search) {
      this.search.controls['searchText'].valueChanges.subscribe(value => {
        const options = this.selectSearchPipe.transform(
          this.options,
          this.search.controls['searchText'].value
        );
        if (this.searchText !== this.search.controls['searchText'].value) {
          this.preOption = options[0];
          this.preIndex = 0;
          this.searchText = this.search.controls['searchText'].value;
        }
      });
    }
  }

  ngOnChanges() {
    // Set initial option if provided
    if (this.initialOption) {
      setTimeout(() => {
        this.selectOption(this.initialOption);
      });
    } else {
      this.writeValue(null);
    }
  }

  /**
   * Focus on keyboard up/down.
   * @param val
   * @param {string} action
   */
  focusOption(val: any, action: string): void {
    const options = this.selectSearchPipe.transform(
      this.options,
      this.search.controls['searchText'].value
    );
    let selected = false;

    options.map((option, index) => {
      if (option.val === val) {
        if (this.preIndex === undefined) {
          this.preIndex = index;
        }

        if (action === 'UP' && this.preIndex > 0) {
          this.preIndex = this.preIndex - 1;
        } else if (action === 'DOWN' && this.preIndex < options.length - 1) {
          this.preIndex = this.preIndex + 1;
        }

        this.preOption = options[this.preIndex];
        selected = true;
      }
    });

    if (!selected) {
      if (!this.preOption) {
        this.preIndex = 0;
        this.preOption = options[0];
      } else {
        if (action === 'UP' && this.preIndex > 0) {
          this.preIndex = this.preIndex - 1;
        } else if (action === 'DOWN' && this.preIndex < options.length - 1) {
          this.preIndex = this.preIndex + 1;
        }

        this.preOption = options[this.preIndex];
      }
    }
  }

  /**
   * Merge placeholder and options objects.
   */
  setOptions() {
    if (
      this.options &&
      this.options.length > 0 &&
      this.options[0].val === null
    ) {
      return;
    }

    const defOption = [{ name: this.placeholder, val: null }];

    if (this.options) {
      this.options.map((option, index) => {
        defOption[index + 1] = option;
      });
    }

    this.options = defOption;
  }

  /**
   * This is the initial value set to the component.
   */
  writeValue(value: any) {
    if (!this.disablePlaceholder) {
      this.setOptions();
    }

    this.data = value;
    this.setSelectedOption(this.data);
  }

  /**
   * Not used, used for touch input.
   */
  registerOnTouched() {}

  /**
   * Registers 'fn' that will be fired when changes are made.
   * This is how we emit the changes back to the form.
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * The method set in registerOnChange to emit changes back to the form
   * @param _
   */
  propagateChange = (_: any) => {};

  /** Open/close drop-down depending on current state */
  selectToggle() {
    if (!this.disabled) {
      this.selectStatus = !this.selectStatus;
      this.inputFocus = this.selectStatus;
      this.getOptionIndex(this.data);

      if (this.selectStatus) {
        setTimeout(() => {
          const itemDOM = this.element.nativeElement.getElementsByClassName(
            'item'
          );
          //FIXME: this if is workaround, we need to check why it is called when it should not be called
          if (itemDOM[this.preIndex]) {
            this.scroll(itemDOM[this.preIndex]);
          }
        }, 300);
      }
    }
  }

  /**
   * Select option from list.
   * @param option
   */
  selectOption(option: Select): void {
    this.data = option.val;
    this.selectStatus = false;
    this.setSelectedOption(this.data);
    this.propagateChange(this.data);

    if (!this.disablePlaceholder) {
      this.setOptions();
    }
  }

  /**
   * Select option form list.
   * @param val
   */
  setSelectedOption(val: any): void {
    const options = this.selectSearchPipe.transform(
      this.options,
      this.search.controls['searchText'].value
    );

    options.map((option, index) => {
      if (option.val === val) {
        this.selectedOption = Object.assign({}, option);
        this.preOption = null;
        this.index = index;
        this.preIndex = index;
        this.update.emit(option);
      }
    });
  }

  /**
   * Get selected value index.
   * @param val
   */
  getOptionIndex(val: any): void {
    const options = this.selectSearchPipe.transform(
      this.options,
      this.search.controls['searchText'].value
    );

    options.map((option, index) => {
      if (option.val === val) {
        this.index = index;
      }
    });
  }

  /** Reset search form group */
  searchReset(e) {
    e.preventDefault();
    e.stopPropagation();
    this.search.reset();
  }

  /**
   * Hover on item action.
   * @param {Select} item
   * @param {number} index
   */
  itemMouseEnter(item: Select, index: number) {
    this.preIndex = index;
    this.preOption = item;
  }

  /**
   * Scroll to list item.
   * @param item
   */
  scroll(item: HTMLElement): void {
    try {
      item.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } catch (error) {
      item.scrollIntoView({ behavior: 'smooth' });
    }
    // const pageScrollInstance: PageScrollInstance = PageScrollInstance.newInstance(
    //   {
    //     document: this.container ? this.container.nativeElement : null,
    //     scrollTarget: item,
    //     scrollingViews: this.container ? [this.container.nativeElement] : null
    //   }
    // );
    // this.pageScrollService.start(pageScrollInstance);
  }

  resetToDefault(e) {
    this.selectOption({ name: 'SELECT_OPTION', val: null });
    this.selectedOption.name = 'SELECT_OPTION';
    this.index = 0;
    this.preIndex = 0;
    this.reset.emit();
  }

  resetThroughParent() {
    this.selectOption({ name: 'SELECT_OPTION', val: null });
    this.selectedOption.name = 'SELECT_OPTION';
    this.index = 0;
    this.preIndex = 0;
  }
}
