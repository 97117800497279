/**
 * @module AppModule
 * @preferred
 */
import * as Sentry from '@sentry/browser';
import { ErrorHandler, ApplicationRef, NgZone, Injector } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import en_gb from '@angular/common/locales/en-GB';
import './core/rxjs-extensions';
import { Store } from '@ngrx/store';
import { AppState } from '@app/reducers';
import { environment } from '@environments/environment';
import { createNewHosts, createInputTransfer, removeNgStyles } from '@angularclass/hmr';
import { take } from 'rxjs/operators';
import { HelperService } from './shared/services/helper.service';
import { en_GB } from 'ng-zorro-antd/i18n';
registerLocaleData(en_gb);
export class RavenErrorHandler {
    constructor(ngZone, injector) {
        this.ngZone = ngZone;
        this.injector = injector;
    }
    get helpers() {
        return this.injector.get(HelperService);
    }
    handleError(err) {
        if (err && err.toString && err.toString().includes('Error: Loading chunk')) {
            this.ngZone.run(() => {
                this.helpers.showVersionErrorModal();
            });
        }
        let canPostToRaven = true;
        if (err && err.toString && err.toString().includes('Error: Loading chunk')) {
            canPostToRaven = false;
        }
        else if (err && err.toString && err.toString().includes('Signature has expired')) {
            canPostToRaven = false;
        }
        else if (err && err.toString && err.toString().includes('You do not have permission to')) {
            canPostToRaven = false;
        }
        if (environment.production && canPostToRaven) {
            Sentry.captureException(err);
        }
        else if (!environment.production) {
            console.error(err);
        }
    }
}
const ɵ0 = { firstDayOfWeek: 1 }, ɵ1 = en_GB;
/**
 * @whatItDoes The root module class that is bootstraped by the `main.ts` file.
 * @see [Angular 2 docs - the application root module]
 * (https://angular.io/docs/ts/latest/guide/ngmodule.html#root-module)
 */
export class AppModule {
    constructor(appRef, store) {
        this.appRef = appRef;
        this.store = store;
    }
    hmrOnInit(store) {
        if (!store || !store.state) {
            return;
        }
        console.log('HMR store', store);
        console.log('store.state:', store.state);
        this.store.dispatch({
            type: 'SET_ROOT_STATE',
            payload: store.state
        });
        if ('restoreInputValues' in store) {
            const restore = store.restoreInputValues;
            setTimeout(() => restore(), 500);
        }
        // change detection
        this.appRef.tick();
        delete store.state;
        delete store.restoreInputValues;
    }
    hmrOnDestroy(store) {
        const cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);
        // recreate elements
        store.disposeOldHosts = createNewHosts(cmpLocation);
        // inject your AppStore and grab state then set it on store
        this.store.pipe(take(1)).subscribe(s => (store.state = s));
        store.state = Object.assign({}, store.state);
        // save input values
        store.restoreInputValues = createInputTransfer();
        // remove styles
        removeNgStyles();
    }
    hmrAfterDestroy(store) {
        // display new elements
        store.disposeOldHosts();
        delete store.disposeOldHosts;
        // anything you need done the component is removed
    }
}
export { ɵ0, ɵ1 };
