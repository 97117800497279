import { Injectable } from '@angular/core';
import { CartActionTypes, UpdateProductsResult, AddProductsResult } from '@app/pages/cart/store/index';
import { CartItem, CartItemFormItem } from '@pages/cart/models/cart.model';
import { Store, ActionsSubject } from '@ngrx/store';
import * as toast from '@app/core/store/actions/toast.action';
import { AppState } from '@app/reducers';
import { TranslationService } from '@core/service/translation.service';
import { CartItemUpdateResult, AddProductUpdateResult, CartItemUpdate } from '@app/shared/models/product.model';
import { map } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { CartListComponent } from '@app/pages/cart/components/cart-list/cart-list.component';
import { CartListMiniComponent } from '@app/pages/cart/components/cart-list-mini/cart-list-mini.component';
import * as moment from 'moment';
import { DateConstants } from '@app/shared/constants/date-formats';
import { sum } from 'lodash';
import { CartListExpiredComponent } from '../components/cart-list-expired/cart-list-expired.component';

@Injectable()
export class CartHelperService {
  component: CartListComponent | CartListMiniComponent | CartListExpiredComponent;
  // ID of component to track separate messages
  matchId: string;
  constructor(
    private translationService: TranslationService,
    private store: Store<AppState>,
    private actionsSubject: ActionsSubject,
  ) {
  }
  registerComponent(component: CartListComponent | CartListMiniComponent | CartListExpiredComponent, matchId: string) {
    this.component = component;
    this.matchId = matchId;
  }
  registerProductUpdateProcessing() {
    return this.actionsSubject.pipe(
      ofType(CartActionTypes.UpdateProductsResult),
      map((action: UpdateProductsResult) => (action.payload)),
    ).subscribe((productsUpdatedResult) => {
      this.processProductUpdate(productsUpdatedResult);
    });
  }
  registerProductUpdateFifoProcessing() {
    return this.actionsSubject.pipe(
      ofType(CartActionTypes.UpdateProductsResult),
      map((action: UpdateProductsResult) => (action.payload)),
    ).subscribe((productsUpdatedResult) => {
      this.processProductFifoUpdate(productsUpdatedResult);
    });
  }
  processProductUpdate(productsUpdatedResult: CartItemUpdateResult[]) {
    productsUpdatedResult = productsUpdatedResult.filter(v => this.matchId === v.update.matchId);
    if (productsUpdatedResult.length === 0) {
      return;
    }
    let everyProductUpdatedSuccesfully = true;
    productsUpdatedResult.forEach((productUpdatedResult: CartItemUpdateResult) => {
      let updatedCartItem: CartItem;
      let cartItemUpdate;
      if (productUpdatedResult.error) {
        updatedCartItem = this.component.products.filter(item => item.id === productUpdatedResult.update.update.id)[0];
      } else if (productUpdatedResult.update.update.expired) {
        const qtyBeforeUpdate = productUpdatedResult.update.update.oldQuantity;

        let qtyAfterUpdate = 0;
        if (productUpdatedResult.cart && productUpdatedResult.cart.cart && productUpdatedResult.cart.cart.items) {
          qtyAfterUpdate = sum(productUpdatedResult.cart.cart.items.map(ci => ci.quantity));
        }

        if (qtyBeforeUpdate === qtyAfterUpdate) {
          everyProductUpdatedSuccesfully = false;
          this.store.dispatch(new toast.ShowWarningToast(this.translationService.translate('M_RESERVED_STOCK_ERROR')));
        }
        // we update product throug expired cart item, if overal was success then just display success result
      } else {
        updatedCartItem = productUpdatedResult.cart.cart.items.filter(item => item.id === productUpdatedResult.update.update.id)[0];
        cartItemUpdate = productUpdatedResult.update.update;
        if (!updatedCartItem) {
          updatedCartItem = this.getCartItemByParams(productUpdatedResult.cart.cart.items, productUpdatedResult);
        }
      }
      if (!updatedCartItem) {
        // const expiredCartItem = this.getCartItemByParams(productUpdatedResult.cart.cart.expiredItems, productUpdatedResult);
        // this.store.dispatch(
        //   new toast.ShowWarningToast(
        //     this.translationService.translate('M_RESERVED_STOCK_ERROR') +
        //     '\n' +
        //     '\n' +
        //     `${expiredCartItem.name}\n` +
        //     this.translationService.translate('BRAND') +
        //     `: ${expiredCartItem.brand}\n` +
        //     this.translationService.translate('SKN') +
        //     `: ${expiredCartItem.skn}\n` +
        //     this.translationService.translate('WAREHOUSE') +
        //     `: ${expiredCartItem.warehouse_title}\n` +
        //     this.translationService.translate('CARD') +
        //     `: ${expiredCartItem.card}`,
        //     true
        //   )
        // );
        // everyProductUpdatedSuccesfully = false;

      } else if (cartItemUpdate.quantity !== updatedCartItem.quantity) {
        this.store.dispatch(
          new toast.ShowWarningToast(
            this.translationService.translate('M_RESERVED_QUANTITY_ERROR', {
              quantity: cartItemUpdate.quantity.toFixed(0),
              reserved: updatedCartItem.quantity.toFixed(0),
            }) +
            '\n' +
            '\n' +
            `${updatedCartItem.name}\n` +
            this.translationService.translate('BRAND') +
            `: ${updatedCartItem.brand}\n` +
            this.translationService.translate('SKN') +
            `: ${updatedCartItem.skn}\n` +
            this.translationService.translate('WAREHOUSE') +
            `: ${updatedCartItem.warehouse_title}\n` +
            this.translationService.translate('CARD') +
            `: ${updatedCartItem.card}`,
            true
          )
        );
        everyProductUpdatedSuccesfully = false;
      } else if (
        cartItemUpdate.note !== updatedCartItem.note ||
        cartItemUpdate.ndo !== updatedCartItem.ndo ||
        cartItemUpdate.client_ref_id !== updatedCartItem.client_ref_id
        ) {
        // M_RESERVED_QUANTITY_ERROR
        this.store.dispatch(
          new toast.ShowWarningToast(
            this.translationService.translate('ERROR') +
            '\n' +
            '\n' +
            `${updatedCartItem.name}\n` +
            this.translationService.translate('BRAND') +
            `: ${updatedCartItem.brand}\n` +
            this.translationService.translate('SKN') +
            `: ${updatedCartItem.skn}\n` +
            this.translationService.translate('WAREHOUSE') +
            `: ${updatedCartItem.warehouse_title}\n` +
            this.translationService.translate('CARD') +
            `: ${updatedCartItem.card}`,
            true
          )
        );
        everyProductUpdatedSuccesfully = false;
      } else if (productsUpdatedResult.length === 1) {
        this.store.dispatch(
          new toast.ShowSuccessToast(
            this.translationService.translate('SUCCESS') +
            '\n' +
            '\n' +
            `${updatedCartItem.name}\n` +
            this.translationService.translate('BRAND') +
            `: ${updatedCartItem.brand}\n` +
            this.translationService.translate('SKN') +
            `: ${updatedCartItem.skn}\n` +
            this.translationService.translate('WAREHOUSE') +
            `: ${updatedCartItem.warehouse_title}\n` +
            this.translationService.translate('CARD') +
            `: ${updatedCartItem.card}`
          )
        );
        everyProductUpdatedSuccesfully = false;
      }
    });
    if (everyProductUpdatedSuccesfully) {
      this.store.dispatch(new toast.ShowSuccessToast(this.translationService.translate('SUCCESS')));
    }
    this.component.productsUpdated.emit({ success: false });
  }

  processProductFifoUpdate(productsUpdatedResult: CartItemUpdateResult[]) {
    productsUpdatedResult = productsUpdatedResult.filter(v => this.matchId === v.update.matchId);
    if (productsUpdatedResult.length === 0) {
      return;
    }
    let everyProductUpdatedSuccesfully = true;
    productsUpdatedResult.forEach((productUpdatedResult: CartItemUpdateResult) => {
      let updatedCartItem: CartItem;
      let cartItemUpdate: CartItemUpdate;
      if (productUpdatedResult.error) {
        updatedCartItem = this.component.products.filter(item => item.id === productUpdatedResult.update.update.id)[0];
      } else {
        const newCartItems = this.getProductsByBrandForCart(productUpdatedResult.cart.cart.items);

        updatedCartItem = newCartItems.filter(item => item.id === productUpdatedResult.update.update.id)[0];
        cartItemUpdate = productUpdatedResult.update.update;
        if (!updatedCartItem) {
          updatedCartItem = this.getCartItemByParams(newCartItems, productUpdatedResult);
        }
      }
      const triedToReserveQuantity =
        cartItemUpdate.quantity +
        productUpdatedResult.update.update.oldQuantity;

      if (!updatedCartItem) {
      } else if ((triedToReserveQuantity) !== updatedCartItem.quantity) {
        this.store.dispatch(
          new toast.ShowWarningToast(
            this.translationService.translate('M_RESERVED_QUANTITY_ERROR', {
              quantity: triedToReserveQuantity.toFixed(0),
              reserved: updatedCartItem.quantity.toFixed(0),
            }) +
            '\n' +
            '\n' +
            `${updatedCartItem.name}\n` +
            this.translationService.translate('BRAND') +
            `: ${updatedCartItem.brand}\n` +
            this.translationService.translate('SKN') +
            `: ${updatedCartItem.skn}\n` +
            this.translationService.translate('WAREHOUSE') +
            `: ${updatedCartItem.warehouse_title}`,
            true
          )
        );
        everyProductUpdatedSuccesfully = false;
      } else if (
        cartItemUpdate.note !== updatedCartItem.note ||
        cartItemUpdate.ndo !== updatedCartItem.ndo ||
        cartItemUpdate.client_ref_id !== updatedCartItem.client_ref_id
        ) {
        // M_RESERVED_QUANTITY_ERROR
        this.store.dispatch(
          new toast.ShowWarningToast(
            this.translationService.translate('ERROR') +
            '\n' +
            '\n' +
            `${updatedCartItem.name}\n` +
            this.translationService.translate('BRAND') +
            `: ${updatedCartItem.brand}\n` +
            this.translationService.translate('SKN') +
            `: ${updatedCartItem.skn}\n` +
            this.translationService.translate('WAREHOUSE') +
            `: ${updatedCartItem.warehouse_title}`,
            true
          )
        );
        everyProductUpdatedSuccesfully = false;
      } else if (productsUpdatedResult.length === 1) {
        this.store.dispatch(
          new toast.ShowSuccessToast(
            this.translationService.translate('SUCCESS') +
            '\n' +
            '\n' +
            `${updatedCartItem.name}\n` +
            this.translationService.translate('BRAND') +
            `: ${updatedCartItem.brand}\n` +
            this.translationService.translate('SKN') +
            `: ${updatedCartItem.skn}\n` +
            this.translationService.translate('WAREHOUSE') +
            `: ${updatedCartItem.warehouse_title}` 
          )
        );
        everyProductUpdatedSuccesfully = false;
      }
    });
    if (everyProductUpdatedSuccesfully) {
      this.store.dispatch(new toast.ShowSuccessToast(this.translationService.translate('SUCCESS')));
    }
    this.component.productsUpdated.emit({ success: false });
  }
  getProductsByCardForCart(products: CartItem[]): CartItem[] {
    return products.map(product => {
      return {
        ...product,
        on_the_way_date: moment(product.on_the_way_date).format(DateConstants.DEFAULT_DATE),
        are_clean_fields: true,
        are_clean_field_note: true,
      };
    });
  }
  getProductsByBrandForCart(products: CartItem[]): CartItem[] {
    const cartItemsSummed = products.reduce((a, cartItem) => {
      const brandSeparatorKey = this.getCartItemBrandKey(cartItem);
      if (!a[brandSeparatorKey]) {
        a[brandSeparatorKey] = [];
      }
      if (cartItem.quantity > 0) {
        a[brandSeparatorKey].push(cartItem);
      }
      return a;
    }, [] as any as Record<string, CartItem[]>);
    const localProducts: CartItem[] = [];
    Object.keys(cartItemsSummed).forEach(cartItemKey => {
      const totalQuantity = cartItemsSummed[cartItemKey].reduce((acc, cartItem) => acc + cartItem.quantity, 0);
      const total_price = cartItemsSummed[cartItemKey].reduce((acc, cartItem) => acc + cartItem.total_price, 0);
      const total_price_eur = cartItemsSummed[cartItemKey].reduce((acc, cartItem) => acc + cartItem.total_price_eur, 0);
      const total_price_wo_vat = cartItemsSummed[cartItemKey].reduce((acc, cartItem) => acc + cartItem.total_price_wo_vat, 0);
      const total_price_wo_vat_eur = cartItemsSummed[cartItemKey].reduce((acc, cartItem) => acc + cartItem.total_price_wo_vat_eur, 0);
      localProducts.push({
        ...cartItemsSummed[cartItemKey][0],
        reference_ids: cartItemsSummed[cartItemKey].map(ci => ci.id),
        on_the_way_date: moment(cartItemsSummed[cartItemKey][0].on_the_way_date).format(DateConstants.DEFAULT_DATE),
        are_clean_fields: true,
        are_clean_field_note: true,
        quantity: totalQuantity,
        card: 0,
        total_price: total_price,
        total_price_eur: total_price_eur,
        total_price_wo_vat: total_price_wo_vat,
        total_price_wo_vat_eur: total_price_wo_vat_eur,
      });
    });
    return localProducts;
  }
  getCartItemBrandKey(cartItem: CartItem): string {
    return `${cartItem.skn.toLocaleLowerCase().trim()} - ${cartItem.brand.toLocaleLowerCase().trim()} - ${cartItem.warehouse} - ${cartItem.is_central} - ${cartItem.on_the_way} - ${cartItem.deposit > 0} - ${cartItem.note} - ${cartItem.ndo} - ${cartItem.client_ref_id}`;
  }
  getCartItemByParams(itemsList: CartItem[], productUpdatedResult: CartItemUpdateResult) {
    return itemsList.filter(item =>
      item.product_id === productUpdatedResult.update.update.product_id &&
      item.skn === productUpdatedResult.update.skn &&
      item.card === productUpdatedResult.update.update.card_number &&
      item.ndo === productUpdatedResult.update.update.ndo &&
      item.note === productUpdatedResult.update.update.note &&
      item.client_ref_id === productUpdatedResult.update.update.client_ref_id
    )[0];
  }
}
