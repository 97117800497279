<nz-spin [nzSpinning]="isSaving">
  <app-skuba-register-form
    [countryPhoneCodesInitial]="countryPhoneCodes"
    [countriesIn]="countriesSelect"
    [companiesInitial]="companiesSelect"
    [presetCompany]="presetCompanyValue"
    (registrationSubmitted)="onSubmitRegistration($event)"
    [backendError$]="error$"
    >
  </app-skuba-register-form>
</nz-spin>
