import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromAuth from '../../auth/store';
import { IToken } from '@app/auth/models/user';
import { map, take } from 'rxjs/operators';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  token: IToken;
  loggedIn$: Observable<boolean>;

  private stateSubscription: Subscription;
  constructor(
    private store: Store<fromRoot.AppState>,
    private localStorageService: LocalStorageService
  ) {
    this.loggedIn$ = this.store.pipe(select(fromAuth.getLoggedIn));
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const idToken = this.localStorageService.getItem('id_token');

    if (idToken) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'JWT ' + idToken)
      });

      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}
