import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DecoratorsService } from '@app/core/develop/decorators';
import { AppState } from '@app/reducers';
import { ApiService } from '@core/api.service';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import * as fromHelper from '@core/store/actions/helper.action';
import { Store } from '@ngrx/store';
import { PageScrollConfig, PageScrollInstance, PageScrollService } from 'ngx-page-scroll';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';

export class SkubaHoliday {
  skuba_branch_id: number;
  date: string;
  description: string;
  description_local: string;
}
export interface IGroupPrototypeMap {
  group: PrototypeGroup;
  subgroups: IGroupPrototypeMap[];
}

export class PrototypeGroup {
  id: string;
  hasSubgroups = false;
  hasParts = false;
  name = '';
  img: '';
  description = '';
  parentId: string = null;
}

export class HolidayNotificationProperties {
  holidayDate: string;
  showNotification: boolean;
  constructor() {
    this.holidayDate = '';
    this.showNotification = false;
  }
};

@Injectable()
export class HelperService {
  cookiesPolicy$: Observable<boolean>;

  private cookiesPolicySub = new BehaviorSubject<boolean>(true);


  constructor(
    private router: Router,
    private apiService: ApiService,
    private localStorage: LocalStorageService,
    private pageScrollService: PageScrollService,
    private store: Store<AppState>,
    @Inject(DOCUMENT) private document: any
  ) {
    PageScrollConfig.defaultScrollOffset = 170;
    PageScrollConfig.defaultDuration = 300;
    PageScrollConfig.defaultInterruptible = false;
    this.cookiesPolicy$ = this.cookiesPolicySub.asObservable();
  }

  /**
   * Scroll to main section or defined id element top.
   * @param {string} id
   */
  scrollTop(id: string = 'scroll-top'): void {
    // Scroll to page top.
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  }

  /**
   * Get country list from API.
   * @returns {Observable<any>}
   */
  @DecoratorsService.DevCache()
  countries(): Observable<any> {
    return this.apiService
      .get('/helper/countries/')
      .pipe(map(country => country));
  }

  /**
   * Get cities list from API.
   * @returns {Observable<any>}
   */
  getCities(body = null): Observable<any> {
    return this.apiService
      .get('/helper/cities/', body)
      .pipe(map(cities => cities));
  }

  /**
   * Return pages array.
   * @param {number} number
   * @returns {number[]}
   */
  createRange(number: number) {
    const items: number[] = [];
    for (let i = 1; i <= number; i++) {
      items.push(i);
    }

    return items;
  }

  formAddressName(fields) {
    return fields
      .filter(x => x !== null && x !== '')
      .join(', ');
  }

  updateCookiesPolicy() {
    const status = this.localStorage.getItem('cookiesPolicy');

    if (status !== null) {
      this.cookiesPolicySub.next(status);
    }
  }

  validationScroll(element: ElementRef) {
    const items = element.nativeElement.querySelectorAll(
      'app-input.ng-invalid, app-select.ng-invalid, app-checkbox.ng-invalid'
    );
    const item = items.length ? items[0] : null;

    if (item) {
      const pageScrollInstance: PageScrollInstance = PageScrollInstance.newInstance(
        {
          document: this.document,
          scrollTarget: item
        }
      );

      this.pageScrollService.start(pageScrollInstance);
    }
  }

  /**
   * we can use this to get information when we clicked navigation element
                  [routerLink]="item.path"
                  [state]="{
                    navWithClick: true
                  }"
   */
  getNavWithClickParameter() {
    const navigation = this.router.getCurrentNavigation();
    return navigation.extras && navigation.extras.state ? !!navigation.extras.state.navWithClick : false;
  }

  showVersionErrorModal() {
    this.store.dispatch(new fromHelper.VersionErrorModal());
  }
  hideVersionErrorModal() {
    this.store.dispatch(new fromHelper.HideVersionErrorModal());
  }
  isChristmasWeeks() {
    // Set christmass logo
    const currentDate = new Date();
    const currentDateTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()).getTime();
    if (currentDate.getMonth() === 11) {
      const christmassDayTime = new Date(new Date().getFullYear(), 11, 24).getTime();
      const thisWeekFirstDay = christmassDayTime - (1000 * 60 * 60 * 24 * currentDate.getDay()) + 1000 * 60 * 60 * 24;
      const christmassWeekStartTime = thisWeekFirstDay - (1000 * 60 * 60 * 24 * 7);
      const christmassWeekEndTime = thisWeekFirstDay + (1000 * 60 * 60 * 24 * 7);
      if (christmassWeekStartTime < currentDateTime && currentDateTime < christmassWeekEndTime) {
        return true;
      }
    }
    return false;
  }
  setCustomConfirmation(data): Observable<any> {
    return this.apiService
      .post('/helper/set-custom-confirmation/', data);
  }

  downloadFutursoftPacklistXml(doc_num: string, with_old_document_number: boolean): void {
    return this.apiService.getFile(
      `SKUBA_LAHETE_${doc_num}`,
      `/orders/active-reservations/download-futursoft/?${
        this.getQueryParamStringFromObj(
          {
            doc_num,
            with_old_document_number: with_old_document_number.toString(),
          }, '&')}`,
      'fsl', 'xml');
  }
  downloadPdf(doc_num: string, with_old_document_number: boolean): void {
    return this.apiService.getFile(
      `SKUBA_PICKINGLIST_${doc_num}_${new Date().toISOString().substr(0, 10)}`,
      `/orders/active-reservations/download-pdf/?${
        this.getQueryParamStringFromObj(
          {
            doc_num,
            with_old_document_number: with_old_document_number.toString(),
          }, '&')}`,
      'pdf');
  }
  getQueryParamStringFromObj(params: { [key: string]: string }, delimiter: string): string {
    return Object.keys(params)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
      .join(delimiter);
  }
  downloadFutursoftXml(id: number, basket_id: number): void {
    return this.apiService.getFile(`futursoft-${basket_id}`, `/orders/orders/${id}/download-futursoft/`, 'fsl', 'xml');
  }
  getAllHolidays(): Observable<SkubaHoliday[]> {
    return this.apiService.getJSON('holidays.json').pipe(map(data => data));
  }
  getLithuanianHolidays(): Observable<SkubaHoliday[]> {
    return this.getAllHolidays().pipe(map(data => data.filter(holidays => holidays.skuba_branch_id === 25)));
  }
  getCountryHolidays(country_code: string, skipConditionCheck: boolean = false): Observable<SkubaHoliday[]> {
    const localHolidaysCountryList: string[] = ['ua', 'rs']; // later can expand country codes list.
    if (localHolidaysCountryList.includes(country_code) || skipConditionCheck) {
      return this.apiService.getJSON(`holidays-${country_code}.json`).pipe(map(data => data));
    } else {
      return new Observable<SkubaHoliday[]>();
    }
  }
  getHolidaysNotificationProperties(country_code: string, skipConditionCheck: boolean = false): Observable<HolidayNotificationProperties> {
    const holidaysPropertiesObservable = this.getCountryHolidays(country_code, skipConditionCheck)
      .pipe(map( (holidays: Array<SkubaHoliday>) => {
        const _notificationProps: HolidayNotificationProperties = new HolidayNotificationProperties();
        const preHolidays = holidays.filter(hl => {
          const preDate0 = new Date();
          const preDate1 = new Date();
          preDate1.setDate(preDate1.getDate() + 1);
          const preDate2 = new Date();
          preDate2.setDate(preDate2.getDate() + 2);
          const preDate3 = new Date();
          preDate3.setDate(preDate3.getDate() + 3);
          return hl.date === preDate0.toISOString().substring(0, 10) ||
          hl.date === preDate1.toISOString().substring(0, 10) ||
          hl.date === preDate2.toISOString().substring(0, 10) ||
          hl.date === preDate3.toISOString().substring(0, 10);
        });
        if (preHolidays.length > 0) {
          _notificationProps.holidayDate = preHolidays[0].date;
          _notificationProps.showNotification = true;
        } else {
          _notificationProps.holidayDate = '';
          _notificationProps.showNotification = false;
        }
        return _notificationProps;
      } ));
    return holidaysPropertiesObservable;
  }
  getAllPrototypeMainGroups(): Observable<PrototypeGroup[]> {
    return this.apiService.getJSON('prototype-parts-groups.json').pipe(map(data => data));
  }
  getAllPrototypeSubGroups(): Observable<PrototypeGroup[]> {
    return this.apiService.getJSON('prototype-parts-subgroups.json').pipe(map(data => data));
  }
}
