<div class="search-nav" *ngIf="searchNavStatus" [@fadeInDown]>
  <form (ngSubmit)="search(products)"
        [formGroup]="products" novalidate
        [ngClass]="{'not-submitted': !formSubmitAttempt}"
        #searchFormDir="ngForm">
    <div class="search-row">
      <div class="base-container">
        <div class="search-bar row">
          <div class="search-input car-number">
            <app-input-with-autocomplete #number
                        formControlName="number"
                        placeholder="CAR_PLATE_NUMBER"
                        class="car-plate-number"
                        (autocomplete2Change)="getLastPlateNumberSearches()"
                        (autocompleteChange)="setPlateNumberAutocomplete()"
                        (selectedAutocompleteItemEvent)="selectedItem($event)"
                        [autocompleteData]="this.plateNumberSuggestionItems"
                        [focus]="focusNumber"
                        [defaultAutocomplete]="false"
                        [reset]="true"
                        *ngIf="viewInit">
            </app-input-with-autocomplete>
          </div>
          <div class="catalogue-button">
            <app-button
              nz-tooltip
              [nzTooltipTitle]="'CATALOGUE' | translate"
              [nzMouseEnterDelay]="0.01"
              [nzMouseLeaveDelay]="0.01"
              (click)="onButtonClick()"
              [disabled]="plateNumberValid"
              [icon]="'fas big-fa fa-layer-group'"
              [type]="'button'"
              title="{{!plateNumberValid ? plateNumber : ''}} {{'CATALOGUE' | translate}}"
              className="{{'btn-red'}}">
            </app-button>
          </div>
          <div class="search-input">
            <app-input #query
                       formControlName="query"
                       placeholder="PRODUCT_SEARCH"
                       [disabledState]="plateNumberValid"
                       [focus]="focusQuery"
                       [defaultAutocomplete]="false"
                       [reset]="true"
                       *ngIf="viewInit">
              <app-input-validation
                *ngIf="products.get('query')"
                className="error"
                message="{{'MIN_SYMBOLS' | translate:min_symbols}}"
                [condition]="(searchFormDir.submitted) && (products.get('query').hasError('minlength') || products.get('query').hasError('required'))">
              </app-input-validation>
            </app-input>
          </div>
          <div class="search-button col">
            <app-button
              [disabled]="disableSubmitButton"
              icon="icon-search"
              [type]="'submit'"
              className="{{'btn-red'}}"
              text="SEARCH_IT">
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
