import { Component, OnDestroy, OnInit } from '@angular/core';
import { IUser } from '@app/auth/models/user';
import { Observable, Subscription } from 'rxjs';
import { Store, select } from '@ngrx/store';
import * as fromAuth from '@app/auth/store';

declare var Tawk_API: any;
declare var Tawk_LoadStart: Date;
@Component({
  selector: 'app-tawkto',
  templateUrl: './tawkto.component.html',
  styleUrls: ['./tawkto.component.scss']
})
export class TawktoComponent implements OnInit, OnDestroy {
  user$: Observable<IUser>;
  userSub: Subscription;

  constructor(
    private store: Store<fromAuth.AuthState>
  ) {
    this.user$ = this.store.pipe(select(fromAuth.getUser));
  }
  TAWKTO = 'TawkTo_Script';
  ngOnInit() {
    this.userSub = this.user$.subscribe(user => {
      if (user) {
        if (user.profile && user.profile.company && user.profile.company && user.profile.company.country) {
          let siteId = '';
          let userHash = '';
          let widgetId = '';
          if (user.profile.tawkto) {
            siteId = user.profile.tawkto.site;
            userHash = user.profile.tawkto.user_hash;
            widgetId = user.profile.tawkto.widget;
          }
          if (siteId) {
            if (!document.getElementById(this.TAWKTO)) {
              // Tawk_LoadStart = new Date();
              const s1 = document.createElement('script');
              s1.id = this.TAWKTO;
              const that = this;
              s1.onload = this.initTawkTo(user, userHash);
              s1.async = true;
              s1.src = `https://embed.tawk.to/${siteId}/${widgetId}`;
              s1.charset = 'UTF-8';
              s1.setAttribute('crossorigin', '*');
              const s0 = document.getElementsByTagName('script')[0];
              s0.parentNode.insertBefore(s1, s0);
            } else {
              Tawk_API = Tawk_API || {};
              if (Tawk_API && Tawk_API.showWidget) {
                Tawk_API.showWidget();
              } else {
                setTimeout(() => {
                  if (Tawk_API && Tawk_API.showWidget) {
                    Tawk_API.showWidget();
                  }
                });
              }
            }
          }
        }
      }
    });
  }

  initTawkTo(user: IUser, userHash) {
    const that = this;
    return () => {
      Tawk_API = Tawk_API || {};
      let tawkToParametersSet = false;
      if (Tawk_API && Tawk_API.setAttributes) {
        tawkToParametersSet = true;
        let shopName = '';
        if (user.profile && user.profile.shop) {
          shopName = user.profile.shop.name;
        }
        Tawk_API.setAttributes(
          {
            name: that.getUserName(user.first_name, user.last_name),
            email: user.email,
            branch: shopName,
            hash: userHash
          },
          function(error) {
            tawkToParametersSet = false;
            console.error(error);
          }
        );
      }
      Tawk_API.onLoad = () => {
        if (!tawkToParametersSet) {
          tawkToParametersSet = true;
          let shopName = '';
          let clientName = '';
          let clientCode = '';
          if (user.profile && user.profile.shop) {
            shopName = user.profile.shop.name;
            clientName = user.profile.client.name;
            clientCode = user.profile.client.skuba_client_id;
          }
          Tawk_API.setAttributes(
            {
              name: that.getUserName(user.first_name, user.last_name),
              email: user.email,
              'client-name': clientName,
              'client-code': clientCode,
              branch: shopName,
              hash: userHash
            },
            function(error) {
              tawkToParametersSet = false;
              console.error(error);
            }
          );
        }
      };
    };
  }

  getUserName(name: string, surname: string) {
    const firstName = name.trim() ? name.trim() : null;
    const lastName = surname.trim() ? surname.trim() : null;

    return `${firstName} ${lastName}`.trim();
  }

  ngOnDestroy() {
    if (this.userSub) { this.userSub.unsubscribe(); }
    if (Tawk_API && Tawk_API.hideWidget) {
      Tawk_API.hideWidget();
    }
    // document.getElementById(this.TAWKTO).remove();
  }
}
