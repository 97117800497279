import { Injectable } from '@angular/core';

import { ApiService } from '@core/api.service';
import { ProductList } from '../models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/internal/operators';
import {
  ISimilarProduct,
  ProductStockSimple,
  PriceRequestForm
} from '@shared/models/product.model';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { FilterGroupItem } from '../components/overal-filter/overal-filter.component';
import { CachedRequestService } from '../../core/service/cached-req.service';
import { DecoratorsService } from '@app/core/develop/decorators';

@Injectable()
export class ProductService {
  cachedListTimeoutHandler: any;

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private cachedRequestService: CachedRequestService,
  ) {}

  @DecoratorsService.DevCache()
  getProductWithInfo(skn: string, info: string) {
    return this.apiService.post(`/products/${skn}/`, {
      info: info
    }).pipe(map(data => data));
  }

  getCatalogue(filters = null): Observable<ProductList> {
    return this.apiService.get('/products/catalogue/', filters).pipe(
      map(data => data)
    );
  }

  getCatalogueByCar(filters = null): Observable<ProductList> {
    if (filters.hasOwnProperty('number') && filters.hasOwnProperty('id')) {
      const plate_number = filters.number;
      const id = filters.id;
      delete filters.number;
      delete filters.id;
      return this.apiService.get(`/products/by_car_catalogue/${plate_number}/${id}/`, filters).pipe(
        map(data => data)
      );
    }
  }

  getList(filters = null): Observable<ProductList> {
    if (
      this.cachedRequestService.hasKey('cached-list') &&
      this.cachedRequestService.getItem('cached-list').filters ===
        JSON.stringify(filters)
    ) {
      return of(this.cachedRequestService.getItem('cached-list').list);
    } else {
      return this.apiService.get('/products/', filters).pipe(
        map(data => {
          if (this.cachedListTimeoutHandler) {
            clearTimeout(this.cachedListTimeoutHandler);
          }
          this.cachedRequestService.setItem('cached-list', {
            filters: JSON.stringify(filters),
            list: data
          });
          this.cachedListTimeoutHandler = setTimeout(
            () => this.cachedRequestService.clearItem('cached-list'),
            600000
          );
          return data;
        })
      );
    }
  }

  getRelated(skn: string, reltype: string): Observable<ISimilarProduct[]> {
    return this.apiService
      .get(`/products/related/${skn}/${reltype}/`)
      .pipe(map(data => data));
  }

  checkCarExists(number = null) {
    return this.apiService
      .get(`/products/check_car_exists/${number}/`)
      .pipe(map(data => data));
  }

  getByCar(number: string, query: string): Observable<ProductList> {
    const filters = { number: number, query: query };
    if (
      this.localStorageService.hasKey('cached-list') &&
      this.localStorageService.getItem('cached-list').filters ===
        JSON.stringify(filters)
    ) {
      return of(this.localStorageService.getItem('cached-list').list);
    } else {
      return this.apiService.get(`/products/by_car/${number}/${query}/`).pipe(
        map(data => {
          if (this.cachedListTimeoutHandler) {
            clearTimeout(this.cachedListTimeoutHandler);
          }
          this.localStorageService.setItem('cached-list', {
            filters: JSON.stringify(filters),
            list: data
          });
          this.cachedListTimeoutHandler = setTimeout(
            () => this.localStorageService.clearItem('cached-list'),
            600000
          );
          return data;
        })
      );
    }
  }

  getNameSuggestion(name = null) {
    return this.apiService
      .get(`/products/name/${name}/`)
      .pipe(map(data => data));
  }

  getProduct(skn: string) {
    return this.apiService.get(`/products/${skn}/`).pipe(map(data => data));
  }

  getProductBrandsWithInfo(skn: string, info: string) {
    return this.apiService.post(`/products/stocks/${skn}/`, {
      info: info
    }).pipe(map(data => data));
  }

  getSubGroupsByCar(plateNumber: string) {
    return this.apiService.get(`/products/sub_groups_by_car/${plateNumber}/`);
  }

  getProductPrice(
    card: string | number,
    warehouse: string,
    is_central: boolean,
    brand: string,
    skn: string,
    deposit: number,
    on_the_way: boolean,
    silenced: boolean = false
  ): Observable<ProductStockSimple> {
    return this.apiService
      .post(`/products/cards/${card}/${warehouse}/${is_central ? '1' : '0'}/`, {
        _ems_silenced: silenced.toString(),
        skn,
        brand,
        deposit,
        on_the_way,
      })
      .pipe(map(data => <ProductStockSimple> data));
  }

  boughtWith(body: any) {
    return this.apiService
      .get(`/products/bought_with/${body.skn}/`, body)
      .pipe(map(data => data));
  }

  getDistinctValues(allFilterValues: FilterGroupItem[] ) {
    return allFilterValues.reduce((a, v) => {
      if (v.value === null) {
        a[0].unique['-'] = 'null';
      } else if (v.title === '') {
        a[0].unique['-'] = v.value;
      } else {
        a[0].unique[v.title] = v.value;
      }
      return a;
    }, [{unique: {}}])
      .map(filterGroupItems => {
        const result = <FilterGroupItem[]> [];
        for (const property in filterGroupItems.unique) {
          if (filterGroupItems.unique.hasOwnProperty(property)) {
            result.push({
              title: property,
              value: filterGroupItems.unique[property],
            });
          }
        }
        return result;
      }).reduce((a, v) => v, []);
  }
  sendPriceRequest(sknId: number, form: PriceRequestForm) {
    return this.apiService
      .post(`/products/price_request/${sknId}/`, { product: sknId, ...form })
      .pipe(map(data => data));
  }
}
