/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./verification-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/pipes/translate/translate.pipe";
import * as i3 from "../../../core/service/translation.service";
import * as i4 from "@ngrx/store";
import * as i5 from "../../../shared/ui/button/button.component.ngfactory";
import * as i6 from "../../../shared/ui/button/button.component";
import * as i7 from "@angular/router";
import * as i8 from "@angular/common";
import * as i9 from "./verification-info.component";
var styles_VerificationInfoComponent = [i0.styles];
var RenderType_VerificationInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VerificationInfoComponent, data: {} });
export { RenderType_VerificationInfoComponent as RenderType_VerificationInfoComponent };
function View_VerificationInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("EMAIL_VERIFICATION_DESCRIPTION")); _ck(_v, 1, 0, currVal_0); }); }
function View_VerificationInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("VERIFICATION_ERROR")); _ck(_v, 2, 0, currVal_0); }); }
function View_VerificationInfoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "align-right verification-action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-button", [["className", "btn-red"], ["text", "CONFIRM"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.continue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(2, 704512, null, 0, i6.ButtonComponent, [i1.ElementRef], { className: [0, "className"], text: [1, "text"] }, null)], function (_ck, _v) { var currVal_0 = "btn-red"; var currVal_1 = "CONFIRM"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_VerificationInfoComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "align-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [["href", "/login"], ["routerLink", "/login"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "img", [["class", "home-logo form-logo"], ["src", "assets/images/logo-skuba.png"]], [[8, "alt", 0]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerificationInfoComponent_1)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerificationInfoComponent_2)), i1.ɵdid(13, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VerificationInfoComponent_3)), i1.ɵdid(15, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/login"; _ck(_v, 4, 0, currVal_2); var currVal_5 = _co.status; _ck(_v, 11, 0, currVal_5); var currVal_6 = !_co.status; _ck(_v, 13, 0, currVal_6); var currVal_7 = _co.status; _ck(_v, 15, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).target; var currVal_1 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("SKUBA_LOGO")), ""); _ck(_v, 5, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("EMAIL_VERIFICATION")); _ck(_v, 8, 0, currVal_4); }); }
export function View_VerificationInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-verification-info", [], null, null, null, View_VerificationInfoComponent_0, RenderType_VerificationInfoComponent)), i1.ɵdid(1, 114688, null, 0, i9.VerificationInfoComponent, [i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VerificationInfoComponentNgFactory = i1.ɵccf("app-verification-info", i9.VerificationInfoComponent, View_VerificationInfoComponent_Host_0, { status: "status" }, {}, []);
export { VerificationInfoComponentNgFactory as VerificationInfoComponentNgFactory };
