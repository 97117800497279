import { Action } from '@ngrx/store';
import { CartModel, CartItem } from '../../models/cart.model';
import { ProductStock, CartItemUpdate, CartItemUpdateRequest, CartItemUpdateResult, AddProductUpdateRequest, AddProductUpdateResult } from '@app/shared/models/product.model';

export enum CartActionTypes {
  CartLoad = '[Cart] Load Cart',
  CartLoadFail = '[Cart] Load Cart Fail',
  CartLoadSuccess = '[Cart] Load Cart Success',
  ResetCartState = '[Cart] Reset Cart State',
  AddProduct = '[Cart] Add Product',
  AddProducts = '[Cart] Add Products',
  AddProductsResult = '[Cart] Add Products Result',
  AddProductSuccess = '[Cart] Add Product Success',
  AddProductFail = '[Cart] Add Product Fail',
  UpdateProduct = '[Cart] Update Product',
  UpdateProducts = '[Cart] Update Products',
  UpdateProductsResult = '[Cart] Update Products Result',
  UpdateProductSuccess = '[Cart] Update Product Success',
  UpdateProductFail = '[Cart] Update Product Fail',
  RemoveExpiredProduct = '[Cart] Remove Expired Product',
  RemoveExpiredProductSuccess = '[Cart] Remove Expired Product Success',
  RemoveExpiredProductFail = '[Cart] Remove Expired Product Fail',
  RemoveProduct = '[Cart] Remove Product',
  RemoveProductSuccess = '[Cart] Remove Product Success',
  RemoveProductFail = '[Cart] Remove Product Fail'
}

/**
 * Load Cart Actions
 */
export class LoadCart implements Action {
  readonly type = CartActionTypes.CartLoad;

  constructor(public with_expired?: boolean) {}
}

export class LoadCartSuccess implements Action {
  readonly type = CartActionTypes.CartLoadSuccess;

  constructor(public payload: any) {}
}

export class LoadCartFail implements Action {
  readonly type = CartActionTypes.CartLoadFail;

  constructor(public payload: any) {}
}

export class ResetCartState implements Action {
  readonly type = CartActionTypes.ResetCartState;
}

/**
 * Add Product from Cart Actions
 */
export class AddProduct implements Action {
  readonly type = CartActionTypes.AddProduct;

  constructor(public payload: any, public acknowledged: boolean = false, public oldQuantity: number = 0) {}
}
/**
 * Add Product from Cart Actions
 */
export class AddProducts implements Action {
  readonly type = CartActionTypes.AddProducts;

  constructor(public payload: AddProductUpdateRequest[]) {}
}
export class AddProductsResult implements Action {
  readonly type = CartActionTypes.AddProductsResult;

  constructor(public payload: AddProductUpdateResult[]) {}
}

export class AddProductSuccess implements Action {
  readonly type = CartActionTypes.AddProductSuccess;

  constructor(public cart: CartModel, public product: ProductStock, public oldQuantity: number = 0) {}
}

export class AddProductFail implements Action {
  readonly type = CartActionTypes.AddProductFail;

  constructor(public payload: any) {}
}

/**
 * Update Product from Cart Actions
 */
export class UpdateProduct implements Action {
  readonly type = CartActionTypes.UpdateProduct;

  constructor(public update: CartItemUpdate, public skn: string = null, public product: any = null) {}
}

/**
 * Update Products from Cart Actions
 */
export class UpdateProducts implements Action {
  readonly type = CartActionTypes.UpdateProducts;

  constructor(public update: CartItemUpdateRequest[]) {}
}

export class UpdateProductSuccess implements Action {
  readonly type = CartActionTypes.UpdateProductSuccess;

  constructor(public cart: CartModel, public skn: string = null, public product: any = null, public update: CartItemUpdate = null) {}
}

export class UpdateProductFail implements Action {
  readonly type = CartActionTypes.UpdateProductFail;

  constructor(public payload: any) {}
}

/**
 * Remove Product from Expired Cart Actions
 */
export class RemoveExpiredProduct implements Action {
  readonly type = CartActionTypes.RemoveExpiredProduct;

  constructor(public payload: CartItem, public silenced: boolean = false) {}
}

export class RemoveExpiredProductSuccess implements Action {
  readonly type = CartActionTypes.RemoveExpiredProductSuccess;

  constructor(public cart: CartModel, public skn: string = null) {}
}

export class RemoveExpiredProductFail implements Action {
  readonly type = CartActionTypes.RemoveExpiredProductFail;

  constructor(public payload: any) {}
}

/**
 * Remove Product from Cart Actions
 */
export class RemoveProduct implements Action {
  readonly type = CartActionTypes.RemoveProduct;

  constructor(public payload: CartItem) {}
}

export class RemoveProductSuccess implements Action {
  readonly type = CartActionTypes.RemoveProductSuccess;

  constructor(public cart: CartModel, public skn: string = null) {}
}

export class RemoveProductFail implements Action {
  readonly type = CartActionTypes.RemoveProductFail;

  constructor(public payload: any) {}
}
export class UpdateProductsResult implements Action {
  readonly type = CartActionTypes.UpdateProductsResult;

  constructor(public payload: CartItemUpdateResult[]) {}
}

export type CartAction =
  | LoadCart
  | LoadCartSuccess
  | LoadCartFail
  | ResetCartState
  | AddProduct
  | AddProducts
  | AddProductsResult
  | AddProductSuccess
  | AddProductFail
  | UpdateProduct
  | UpdateProducts
  | UpdateProductSuccess
  | UpdateProductFail
  | RemoveProduct
  | RemoveProductSuccess
  | RemoveProductFail
  | RemoveExpiredProduct
  | RemoveExpiredProductSuccess
  | RemoveExpiredProductFail
  | UpdateProductsResult;
