var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from '@app/core/api.service';
import { map } from 'rxjs/internal/operators';
import { DecoratorsService } from '@app/core/develop/decorators';
export class UsersService {
    constructor(apiService) {
        this.apiService = apiService;
        /**
         * User status subject.
         * @type {Subject<string>}
         */
        this.userStatusSubject = new BehaviorSubject('');
        /** Status name */
        this.status = this.userStatusSubject.asObservable();
    }
    getUsers(filters = null) {
        return this.apiService
            .get('/users/users/', filters)
            .pipe(map(data => data));
    }
    getUsersForRights() {
        return this.apiService
            .get('/users/users/users_for_rights/')
            .pipe(map(data => data));
    }
    /**
     * Users list by filters params from API.
     * @param {any} filters
     * @returns {Observable<any>}
     */
    getUsersClients(main_client_id = null) {
        return this.apiService
            .get('/shops/client_refs/', { main_client_id: main_client_id.toString(), })
            .pipe(map(data => data));
    }
    getUserActions(id) {
        return this.apiService
            .get(`/statistics/get_last_searches/${id}/`)
            .pipe(map(data => data));
    }
    getUsersActions(shop_id = null) {
        const shopFilterParam = shop_id ? { shop_id: shop_id, } : {};
        return this.apiService
            .get('/statistics/get_last_searches/', shopFilterParam)
            .pipe(map(data => data));
    }
    /**
     * Post method to create user contacted actvity history.
     * @param body
     * @returns {Observable<any>}
     */
    createUserContactedActions(
    // id: number,
    body) {
        return this.apiService
            .post('/statistics/contacted_action_history/', body)
            .pipe(map(data => data));
    }
    /**
     * Specific user data by user id from API.
     * @param id
     * @returns {Observable<any>}
     */
    getUser(id) {
        return this.apiService.get(`/users/users/${id}/`).pipe(map(data => data));
    }
    /**
     * Update user by id from API.
     * @param id
     * @param body
     * @returns {Observable<any>}
     */
    updateUser(id, body) {
        return this.apiService
            .patch(`/users/users/${id}/`, body)
            .pipe(map(data => data));
    }
    /**
     * Create user.
     * @param body
     * @returns {Observable<any>}
     */
    createUser(body) {
        return this.apiService.post('/users/users/', body).pipe(map(data => data));
    }
    /**
     * Delete user.
     * @param id
     * @returns {Observable<any>}
     */
    deleteUser(id) {
        return this.apiService.delete(`/users/users/${id}`).pipe(map(data => data));
    }
    /**
     * Get status name by status id.
     * @param {number} status
     */
    getStatusName(status) {
        let name = '';
        switch (status) {
            case 1:
                name = 'ACTIVE';
                break;
            case 2:
                name = 'INACTIVE';
                break;
            case 3:
                name = 'BLOCKED';
                break;
            case 4:
                name = 'SUSPENDED';
                break;
            case 5:
                name = 'ARCHIVED';
                break;
            case 6:
                name = 'PENDING';
                break;
            default:
                name = 'ERROR';
        }
        this.userStatusSubject.next(name);
    }
    /**
     * Get phone codes from JSON.
     * @param json
     * @returns {Observable<any>}
     */
    getPhoneCodes(json) {
        return this.apiService.getJSON(`${json}`).pipe(map(data => data));
    }
    /**
     * Get user other settings form API.
     * @param id
     * @returns {Observable<any>}
     */
    getOtherSettings(id) {
        return this.apiService
            .get(`/users/user_other_settings/${id}/`)
            .pipe(map(data => data));
    }
    /**
     * Put method to update user other settings.
     * @param id
     * @param body
     * @returns {Observable<any>}
     */
    updateOtherSettings(id, body) {
        return this.apiService
            .put(`/users/user_other_settings/${id}/`, body)
            .pipe(map(data => data));
    }
    /**
     * POST method for update users shops settings.
     * @param {UserShopSettings} body
     * @returns {Observable<any>}
     */
    updateShopsSettings(body) {
        return this.apiService
            .post('/users/shops_settings/', body)
            .pipe(map(data => data));
    }
    /**
     * POST method for update_future users shops settings.
     * @param {UserFutureShopSettings} body
     * @returns {Observable<any>}
     */
    updateFutureShopsSettings(body) {
        return this.apiService
            .post('/users/future_shops_settings/', body)
            .pipe(map(data => data));
    }
    /**
     * Update operation limits.
     * @param {{user: number}} body
     * @returns {Observable<UserOperations>}
     */
    updateOperationLimits(id, body) {
        return this.apiService
            .patch(`/users/operation_limits/${id}/`, body)
            .pipe(map(data => data));
    }
    /**
     * Create operation limits.
     * @param {{user: number; action: number}} body
     * @returns {Observable<UserOperations>}
     */
    createOperationLimits(body) {
        return this.apiService
            .post('/users/operation_limits/', body)
            .pipe(map(data => data));
    }
    /**
     * Get default operations list from API.
     * @returns {Observable<any>}
     */
    operations() {
        return this.apiService.get('/users/operation/').pipe(map(data => data));
    }
    /**
     * Get user shops settings.
     * @param user_id
     * @returns {Observable<UserShopSettings[]>}
     */
    getShopsSettings(user_id) {
        return this.apiService
            .get(`/users/shops_settings/${user_id}/`)
            .pipe(map(data => data));
    }
    /**
     * Get user shops settings.
     * @param user_id
     * @returns {Observable<any>}
     */
    getFutureShopsSettings(user_id) {
        return this.apiService
            .get(`/users/future_shops_settings/${user_id}/`)
            .pipe(map(data => data));
    }
    /**
     * Get list of available limit operations.
     * @returns {Observable<UserOperations>}
     */
    getOperationActions() {
        return this.apiService
            .get('/users/operation_limits/list-actions/')
            .pipe(map(data => data));
    }
    /**
     * Get list of available reset parameters.
     * @returns {Observable<UsersLimitsResets>}
     */
    getOperationsLimitsResets() {
        return this.apiService
            .get('/users/operation_limits/list-resets/')
            .pipe(map(data => data));
    }
    /**
     * Get user operations limits.
     * @param body
     * @returns {Observable<UserOperationsLimits>}
     */
    getOperationLimits(body) {
        return this.apiService
            .get('/users/operation_limits/', body)
            .pipe(map(data => data));
    }
    getIpList(body) {
        return this.apiService
            .get('/users/remote_addresses/', body)
            .pipe(map(data => data));
    }
    addIp(body) {
        return this.apiService
            .post('/users/remote_addresses/', body)
            .pipe(map(data => data));
    }
    updateIpStatus(id, body) {
        return this.apiService
            .patch(`/users/remote_addresses/${id}/`, body)
            .pipe(map(data => data));
    }
    removeIp(ipId) {
        return this.apiService
            .delete(`/users/remote_addresses/${ipId}/`)
            .pipe(map(data => data));
    }
    /**
     * Register new user.
     * @param {IUserRegister} body
     * @returns {Observable<any>}
     */
    register(body) {
        return this.apiService
            .post('/users/register/', body, true)
            .pipe(map(data => data));
    }
    /**
     * Send message for specified user.
     * @param {number} id
     * @param {{subject: string; message: string; force?: boolean}} body
     * @returns {Observable<any>}
     */
    message(id, body) {
        return this.apiService
            .post(`/users/users/${id}/message/`, body)
            .pipe(map(data => data));
    }
}
__decorate([
    DecoratorsService.DevCache(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", Observable)
], UsersService.prototype, "getUsers", null);
__decorate([
    DecoratorsService.DevCache(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", Observable)
], UsersService.prototype, "getUsersForRights", null);
