import { Action } from '@ngrx/store';

import { IContact } from '@core/models/contact';

export enum ContactsActionTypes {
  ContactsLoad = '[Contacts] Load Contacts',
  ContactsLoadFail = '[Contacts] Load Contacts Fail',
  ContactsLoadSuccess = '[Contacts] Load Contacts Success'
}

export class ContactsLoad implements Action {
  readonly type = ContactsActionTypes.ContactsLoad;
}

export class ContactsLoadFail implements Action {
  readonly type = ContactsActionTypes.ContactsLoadFail;

  constructor(public payload: any) {}
}

export class ContactsLoadSuccess implements Action {
  readonly type = ContactsActionTypes.ContactsLoadSuccess;

  constructor(public payload: IContact[]) {}
}

export type ContactsActions =
  | ContactsLoad
  | ContactsLoadFail
  | ContactsLoadSuccess;
