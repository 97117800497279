<div class="app-input" [ngClass]="{'disabled-input' : disabledState, 'no-bottom': !marginBottom}" *ngIf="!hiddenState">
  <label *ngIf="label">{{label | translate}}
    <ng-container *ngIf="isRequired" >*</ng-container>
    <i *ngIf="explanation" class="material-icons material-pointer" [satPopoverAnchor]="pop" (click)="pop.toggle()">info</i>
  </label>
  <div class="input-wrapper">
    <input
      #input
      (focus)="focusIn()"
      (focusout)="focusOut()"
      (click)="clickedInside($event)"
      (input)="onValueChange($event.target.value)"
      (change)="onChange($event)"
      (keyup)="onChange($event)"
      (blur)="onBlurEvent($event)"
      [placeholder]="placeholder | translate"
      [type]="type"
      [value]="data"
      [attr.disabled]="disabledState ? 'disabled' : undefined"
      [attr.aria-label]="label ? label : null"
      [autocomplete]="defaultAutocomplete? 'on' : 'off'">
    <ng-content *ngIf="!focusState"></ng-content>
    <div class="reset" *ngIf="reset && data" (click)="resetInput()">
      <i class="material-icons">clear</i>
    </div>
  </div>
  <app-input-autocomplete *ngIf="autocompleteData || focusState"
                          (preselectedItem)="setAutocompleteItem($event)"
                          [data]="autocompleteData"
                          [hasLabel]="!!label"
                          [highlight]="highlight"
                          [(hasFocused)]="focusState">
  </app-input-autocomplete>
  <sat-popover #pop horizontalAlign="end" verticalAlign="below" hasBackdrop>
    <div class="info-wrapper">{{explanation | translate}}</div>
  </sat-popover>
</div>
