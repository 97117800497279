import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { CartComponent } from '@pages/cart/containers/cart.component';

const routes: Routes = [
  {
    path: '',
    component: CartComponent,
    data: {
      translationKey: 'CART'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CartRoutingModule {}

export const routedComponents = [CartComponent];
