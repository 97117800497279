import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, filter } from 'rxjs/operators';
import { ISubGroupResult, ISubGroup } from '@app/core/models/subgroup';

import { environment } from '@environments/environment';
import { DecoratorsService } from '../develop/decorators';

@Injectable()
export class SubGroupService {
  constructor(private http: HttpClient) {}

  @DecoratorsService.DevCache()
  getSubGroups(filters = null): Observable<ISubGroupResult> {
    return this.http
      .get<ISubGroupResult>(
      environment.api_url + '/products/sub_groups/?limit=2000'
    )
      .pipe(catchError((error: any) => throwError(error.json())));
  }
}
