var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap } from 'rxjs/operators';
import * as orderActions from '../actions/order.action';
import { of } from 'rxjs';
import { OrderService } from '@pages/checkout/services/order.service';
import { Router } from '@angular/router';
import * as fromCart from '@pages/cart/store';
import { Store } from '@ngrx/store';
export class OrderEffects {
    constructor(actions$, orderService, router, store) {
        this.actions$ = actions$;
        this.orderService = orderService;
        this.router = router;
        this.store = store;
        this.createOrder$ = this.actions$.pipe(ofType(orderActions.OrderActionTypes.CreateOrder), map((action) => ({ data: action.payload, acknowledged: action.acknowledged, cartItemsIds: action.cartItemsIds })), mergeMap(({ data, acknowledged, cartItemsIds }) => this.orderService.createOrder(data, acknowledged, cartItemsIds).pipe(map(order => {
            const fixPrice = item => {
                Object.keys(item).forEach(key => {
                    if (key.includes('price') &&
                        (typeof item[key] === 'number' || typeof item[key] === 'string')) {
                        item[key] = Number(item[key]).toFixed(2);
                    }
                });
            };
            // First fix order
            fixPrice(order);
            // Fix items
            if (order.items) {
                order.items.forEach(item => {
                    fixPrice(item);
                });
            }
            return new orderActions.CreateOrderSuccess(order);
        }), catchError(error => of(new orderActions.CreateOrderFail(error))))));
        this.updateOrder$ = this.actions$.pipe(ofType(orderActions.OrderActionTypes.UpdateOrder), map((action) => action.payload), mergeMap(order => this.orderService.updateOrder(order.id, order).pipe(map(orderItem => {
            const fixPrice = item => {
                Object.keys(item).forEach(key => {
                    if (key.includes('price') &&
                        (typeof item[key] === 'number' || typeof item[key] === 'string')) {
                        item[key] = Number(item[key]).toFixed(2);
                    }
                });
            };
            // First fix order
            fixPrice(orderItem);
            // Fix items
            orderItem.items.forEach(item => {
                fixPrice(item);
            });
            return new orderActions.UpdateOrderSuccess(orderItem);
        }), catchError(error => of(new orderActions.UpdateOrderFail(error))))));
        this.updateOrderSuccess$ = this.actions$.pipe(ofType(orderActions.OrderActionTypes.UpdateOrderSuccess), map((action) => action.payload), map(() => new orderActions.OrderEmpty()));
        // @Effect() updateOrderStatus$ = this.actions$.pipe(
        //   ofType(orderActions.OrderActionTypes.UpdateOrderStatus),
        //   map((action: orderActions.UpdateOrderStatus) => action.payload),
        //   mergeMap(order => {
        //     return this.orderService.updateOrderStatus(order.id, order).pipe(
        //       map(orderItem => new orderActions.UpdateOrderStatusSuccess(orderItem)),
        //       catchError(error => of(new orderActions.UpdateOrderStatusFail(error)))
        //     );
        //   })
        // );
        this.updateOrderMany$ = this.actions$.pipe(ofType(orderActions.OrderActionTypes.UpdateOrderMany), map((action) => ({ ordersUpdate: action.payload, singleOrder: action.singleOrder })), mergeMap(update => this.orderService.updateOrderMany(Object.assign({}, update.ordersUpdate, { _ems_silenced: true })).pipe(map(orders => {
            const fixPrice = item => {
                Object.keys(item).forEach(key => {
                    if (key.includes('price') &&
                        (typeof item[key] === 'number' || typeof item[key] === 'string')) {
                        item[key] = Number(item[key]).toFixed(2);
                    }
                });
            };
            orders.forEach(order => {
                // First fix order
                fixPrice(order);
                // Fix items
                order.items.forEach(item => {
                    fixPrice(item);
                });
            });
            if (update.singleOrder) {
                return new orderActions.UpdateOrderManySingleSuccess(orders);
            }
            else {
                return new orderActions.UpdateOrderManySuccess(orders);
            }
        }), catchError(error => of(new orderActions.UpdateOrderManyFail(error))))));
        this.updateOrderManySuccess$ = this.actions$.pipe(ofType(orderActions.OrderActionTypes.UpdateOrderManySuccess), map((action) => action.payload), map(() => new orderActions.OrderEmpty()));
        this.updateOrderManySingleSuccess$ = this.actions$.pipe(ofType(orderActions.OrderActionTypes.UpdateOrderManySingleSuccess), map((action) => action.orders), map(() => new orderActions.OrderEmpty()));
        this.updateOrderManyFail$ = this.actions$.pipe(ofType(orderActions.OrderActionTypes.UpdateOrderManyFail), map((action) => action.payload), map((error) => new orderActions.OrderEmpty(error)));
        this.updateOrderStatusMany$ = this.actions$.pipe(ofType(orderActions.OrderActionTypes.UpdateOrderStatusMany), map((action) => action.payload), mergeMap(orders => this.orderService.updateOrderStatusMany(orders).pipe(map(orderItem => new orderActions.UpdateOrderStatusManySuccess(orderItem)), catchError(error => of(new orderActions.UpdateOrderStatusManyFail(error))))));
        this.handlePatchUserAddressSuccess$ = this.actions$.pipe(ofType(orderActions.OrderActionTypes.UpdateOrderStatusSuccess), map(() => new fromCart.LoadCart()));
    }
}
__decorate([
    Effect(),
    __metadata("design:type", Object)
], OrderEffects.prototype, "createOrder$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], OrderEffects.prototype, "updateOrder$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], OrderEffects.prototype, "updateOrderSuccess$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], OrderEffects.prototype, "updateOrderMany$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], OrderEffects.prototype, "updateOrderManySuccess$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], OrderEffects.prototype, "updateOrderManySingleSuccess$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], OrderEffects.prototype, "updateOrderManyFail$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], OrderEffects.prototype, "updateOrderStatusMany$", void 0);
__decorate([
    Effect(),
    __metadata("design:type", Object)
], OrderEffects.prototype, "handlePatchUserAddressSuccess$", void 0);
