import { NgModule, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { StoreModule } from '@ngrx/store';

import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { StatusGuard } from './services/status-guard.service';
import { AdminStatusGuard } from './services/admin-guard.service';
import * as fromStore from './store';
import { LoginPageComponent } from './containers/login-page/login-page.component';
import { LoginFormComponent } from './components/login/login-form.component';
import { SharedModule } from '@app/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from '@app/auth/store/effects/auth.effects';
import { UserEffects } from './store';
import { RegisterComponent } from '@app/auth/containers/register-page/register-page.component';
import { RegisterFormComponent } from '@app/auth/components/register/register-form.component';
import { RegistrationService } from '@app/auth/services/registration.service';
import { ShopService } from '@shared/services/shop.service';
import { UsersService } from '@pages/users/services/users.service';
import { VerificationPageComponent } from '@app/auth/containers/verification-page/verification-page.component';
import { VerificationInfoComponent } from '@app/auth/components/verification-info/verification-info.component';
import { ForgotPasswordPageComponent } from '@app/auth/containers/forgot-password-page/forgot-password-page.component';
import { ForgotPasswordFormComponent } from '@app/auth/components/forgot-password-form/forgot-password-form.component';
import { ForgotPasswordResetPageComponent } from '@app/auth/containers/forgot-password-reset-page/forgot-password-reset-page.component';
import { ForgotPasswordResetFormComponent } from '@app/auth/components/forgot-password-reset-form/forgot-password-reset-form.component';
import { ForgotUserPageComponent } from '@app/auth/containers/forgot-user-page/forgot-user-page.component';
import { ForgotUserFormComponent } from '@app/auth/components/forgot-user-form/forgot-user-form.component';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { ErrorPageComponent } from '@shared/components/error-page/error-page.component';
import { LanguageGuard } from './services/language-guard.service';

export const COMPONENTS = [
  LoginPageComponent,
  LoginFormComponent,
  RegisterComponent,
  RegisterFormComponent,
  VerificationPageComponent,
  VerificationInfoComponent,
  ForgotPasswordPageComponent,
  ForgotPasswordFormComponent,
  ForgotPasswordResetPageComponent,
  ForgotPasswordResetFormComponent,
  ForgotUserPageComponent,
  ForgotUserFormComponent
];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, SharedModule, RouterModule, NgZorroAntdModule],
  providers: [RegistrationService],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class AuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      ngModule:  forwardRef(() => (RootAuthModule)),
      providers: [
        AuthService,
        AuthGuard,
        AdminStatusGuard,
        UsersService,
        ShopService,
        StatusGuard,
        LanguageGuard,
      ]
    };
  }
}

const routes: Routes = [
  {
    path: 'login',
    component: LoginPageComponent,
    data: {
      translationKey: 'LOGIN',
      paramsKey: ''
    },
    canActivate: [LanguageGuard]
  },
  {
    path: 'logout',
    component: LoginPageComponent,
    data: {
      translationKey: 'LOGOUT'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      translationKey: 'REGISTER'
    }
  },
  {
    path: 'email/verification/:user/:token',
    component: VerificationPageComponent,
    data: {
      translationKey: 'EMAIL_VERIFICATION'
    }
  },
  {
    path: 'auth/forgot-password',
    component: ForgotPasswordPageComponent,
    data: {
      translationKey: 'FORGOT_PASSWORD_Q'
    }
  },
  {
    path: 'auth/forgot-user',
    component: ForgotUserPageComponent,
    data: {
      translationKey: 'FORGOT_USERNAME_Q'
    }
  },
  {
    path: 'auth/password/forgot_password_reset/:user/:token',
    component: ForgotPasswordResetPageComponent,
    data: {
      translationKey: 'FORGOT_RESET'
    }
  },
  { path: '**', component: ErrorPageComponent }
];

@NgModule({
  imports: [
    AuthModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('auth', fromStore.reducers),
    EffectsModule.forFeature([AuthEffects, UserEffects])
  ],
  exports: [RouterModule]
})
export class RootAuthModule {}

export const routedComponents = [
  LoginPageComponent,
  RegisterComponent,
  VerificationPageComponent,
  ForgotPasswordPageComponent,
  ForgotPasswordResetPageComponent,
  ForgotUserPageComponent
];
