import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  EventEmitter
} from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { fadeIn, fadeInDown } from '@shared/animations';
import { CartItem, CartModel } from '@pages/cart/models/cart.model';
import * as fromCart from '@app/pages/cart/store';
import { select, Store, ActionsSubject } from '@ngrx/store';
import * as fromStore from '@pages/cart/store';
import { Router } from '@angular/router';
import { AppState } from '@app/reducers';
import { environment } from '@environments/environment';
import * as fromAuth from '@app/auth/store';
import { IUser, IUserClientReference } from '@app/auth/models/user';
import { DateConstants, DF } from '@app/shared/constants/date-formats';
import { CartItemUpdate } from '@app/shared/models/product.model';
import { ColumnSetting } from '@app/shared/ui/table/models/table.model';
import { CartService } from '../../store/services/cart.service';
import { sum } from 'lodash';
import { CartActionTypes, RemoveExpiredProductSuccess } from '@app/pages/cart/store';
import { ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { TranslationService } from '@app/core/service/translation.service';
import * as toast from '@app/core/store/actions/toast.action';
import { CartHelperService } from '../../services/carthelper.service';
import { PageOperation, UserOperation } from '@app/shared/constants/user-operations';

@Component({
  selector: 'app-cart-list-expired',
  templateUrl: './cart-list-expired.component.html',
  styleUrls: ['./cart-list-expired.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn, fadeInDown]
})
export class CartListExpiredComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() cart: CartModel;
  @Input() itemsExpiredInitial: CartItem[];

  readonly productsUpdated = new EventEmitter();
  products: CartItem[] = [];
  DF = DF;
  base_url = environment.api_url;

  /** Cart products */

  itemsExpired: CartItem[] = [];

  productLoadTimeout: any;
  user: IUser;

  extraSettings: ColumnSetting[];

  showUserClientRef = false;
  userClientRefValues: IUserClientReference[] = [];
  userClientRefObject: {[id: number]: IUserClientReference} = {};

  userSub: Subscription;
  updateProductSuccessSub: Subscription;
  isLoading = true;
  expiredItemsSub: Subscription;
  firstCartId: string | number = 99999999999;
  isAddedToCartOperation = false;

  showLoadMoreBtn = true;
  updateProductsSuccessSub: Subscription;
  cartHelperService: CartHelperService;
  showFifoStocks = false;
  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private actionsSubject: ActionsSubject,
    private store: Store<AppState>,
    private ref: ChangeDetectorRef,
    private cartService: CartService,
    private translationService: TranslationService,
  ) {
    this.cartHelperService = new CartHelperService(translationService, store, actionsSubject);
    this.cartHelperService.registerComponent(this, 'cart-list-expired');
  }

  ngOnInit(): void {
    this.userSub = this.store.pipe(select(fromAuth.getUser)).subscribe(user => {
      if (user && user.user_settings) {
        this.user = user;
      }
      if (user && (user.profile.company.hide_cards_in_stocks || user.user_settings.hide_cards_in_stocks)) {
        this.showFifoStocks = true;
      } else {
        this.showFifoStocks = false;
      }
      this.initClientReferences(user);
      if (this.updateProductSuccessSub) {
        this.updateProductSuccessSub.unsubscribe();
      }
      this.updateProductSuccessSub = this.actionsSubject.pipe(
        ofType(CartActionTypes.RemoveExpiredProductSuccess),
        map((action: RemoveExpiredProductSuccess) => ({cart: action.cart, skn: action.skn})),
      ).subscribe(() => {
        this.store.dispatch(new toast.ShowSuccessToast(this.translationService.translate('SUCCESS')));
      });
      // Called when products were updated
      if (this.updateProductsSuccessSub) {
        this.updateProductsSuccessSub.unsubscribe();
      }
      this.updateProductsSuccessSub = this.cartHelperService.registerProductUpdateProcessing();
      this.loadItemsExpired(false);
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cart && !changes.cart.firstChange) {
      // The cart property has changed, and it's not the initial change
      if (this.isAddedToCartOperation) {
        // Product updated successfully (without reloading expired carts)
        this.isAddedToCartOperation = false;
      } else {
        // Cart changed. Reloading expired carts...
        this.loadItemsExpired(true);
        this.showLoadMoreBtn = true;
      }
    }
  }

  ngAfterViewInit() {
    this.isLoading = false;
  }

  trackByFn(index, item: CartItem) {
    return `${item.card}-${item.is_central}-${item.warehouse}-${item.note}-${item.ndo}`;
    // return index; // or item.id
  }

  initClientReferences(user) {
    if (user && user.profile.selected_client_references.length > 0) {
      this.userClientRefValues = [{ client: user.profile.client }, ...user.profile.selected_client_references];
      this.userClientRefObject = this.userClientRefValues.reduce((a, value) => {
        a[value.client.id] = value;
        return a;
      }, {});
      this.showUserClientRef = true;
      this.ref.markForCheck();
    }
  }

  setProducts(itemsExpiredInitial: CartItem[]) {
    if (itemsExpiredInitial) {
      this.showLoadMoreBtn = true;
      this.itemsExpired = itemsExpiredInitial.map(product => ({
        ...product,
        on_the_way_date: moment(product.on_the_way_date).format(DateConstants.DEFAULT_DATE),
        refreshed: true,
        refreshed_note: true,
      }));
      if (itemsExpiredInitial.length === 0) {
        this.showLoadMoreBtn = false;
      }
      if (this.showFifoStocks) {
        this.itemsExpired = this.cartHelperService.getProductsByBrandForCart(this.itemsExpired);
      }
    }
    if (this.itemsExpired && this.itemsExpired.length > 0) {
      this.firstCartId = this.itemsExpired[0].first_cart_id;
    }
    this.initTable();
  }

  loadItemsExpired (isReload: boolean = false) {
    if (this.expiredItemsSub) {
      this.expiredItemsSub.unsubscribe();
    }
    let tempFirstCartId = this.firstCartId;
    if (isReload) {
      tempFirstCartId = 99999999999; // default value
    }
    this.expiredItemsSub = this.cartService.getCartExpired(String(tempFirstCartId)).subscribe((data) => {

      if (data && data.items_expired) {
        if (data.items_expired.length > 0) {
          this.firstCartId = data.items_expired[0].first_cart_id;
          let newItems = data.items_expired;
          if (this.showFifoStocks) {
            newItems = this.cartHelperService.getProductsByBrandForCart(newItems);
          }
          if (!isReload) {
            this.itemsExpired = this.itemsExpired.concat(newItems);
          } else {
            // during reload set initial values
            this.itemsExpired = newItems;
          }
          this.initTable();
          this.cdRef.detectChanges();
        } else {
          this.showLoadMoreBtn = false;
          this.cdRef.detectChanges();
        }
      }
    });
  }

  getUserClientSelectorValue(id) {
    const clientRef = this.userClientRefObject[id];
    if (clientRef) {
      return `${clientRef.client.skuba_client_id} - ${clientRef.client.name}`;
    }
    return id;
  }

  setOperation() {
    UserOperation.TYPE = PageOperation.CartItemExpired;
    UserOperation.query = '';
  }
  addProduct(product: CartItem): void {
    this.isAddedToCartOperation = true;
    this.setOperation();
    const data: CartItemUpdate = {
      id: +product.id,
      product_id: +product.product_id,
      card_number: +product.card,
      warehouse_code: product.warehouse,
      quantity: +product.quantity,
      quantity_added: +product.quantity,
      note: product.note,
      ndo: product.ndo,
      client_ref_id: product.client_ref_id,
      is_central: product.is_central,
      is_carried: product.is_carried,
      on_the_way: product.on_the_way,
      expired: true,
      skn: product.skn,
    };

    data.skn = product.skn;
    data.deposit = product.deposit;
    data.brand = product.brand;
    if (this.cart && this.cart.cart && this.cart.cart.items) {
      data.oldQuantity = sum(this.cart.cart.items.map(ci => ci.quantity));
    } else {
      data.oldQuantity = 0;
    }

    this.store.dispatch(new fromStore.UpdateProducts([{
      update: data,
      skn: product.skn,
      product: null,
      matchId: 'cart-list-expired',
    }]));

  }

  removeProduct(product: CartItem): void {
    this.itemsExpired = this.itemsExpired.filter(item => item.id !== product.id);
    if (this.showFifoStocks) {
      for (let index = 0; index < product.reference_ids.length; index++) {
        const id = product.reference_ids[index];
        this.store.dispatch(new fromCart.RemoveExpiredProduct({...product, id }, index + 1 !== product.reference_ids.length));
      }
    } else {
      this.store.dispatch(new fromCart.RemoveExpiredProduct(product));
    }
  }

  viewProduct(skn): void {
    this.router.navigate([`/search/${skn}`]);
  }

  initTable() {
    let delivery = [];
    let client = [];

    if (this.itemsExpired) {
      this.itemsExpired.map(item => {
        if (item.ndo) {
          delivery = [{
            primaryKey: 'ndo',
            header: 'NDO_DELIVERY',
          }];
          return;
        }
      });
    }
    if (this.showUserClientRef) {
      client = [{
        primaryKey: 'client',
        header: 'CLIENT',
      }];
    }


    this.extraSettings = [
      {
        primaryKey: '',
        header: ''
      },
      {
        primaryKey: 'photo',
        header: 'PHOTO'
      },
      {
        primaryKey: 'name',
        header: 'INFO'
      },
      {
        primaryKey: 'quantity',
        header: 'QUANTITY',
      },
      {
        primaryKey: 'note',
        header: 'NOTE',
      },
      ...delivery,
      ...client,
      {
        primaryKey: '',
        header: '',
      }
    ];
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.expiredItemsSub) {
      this.expiredItemsSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.updateProductSuccessSub) {
      this.updateProductSuccessSub.unsubscribe();
    }
    if (this.updateProductsSuccessSub) {
      this.updateProductsSuccessSub.unsubscribe();
    }

  }
}
