import * as fromShowcase from './showcase.reducer';

import {
  ActionReducerMap, createFeatureSelector
} from '@ngrx/store';

export interface ShowcaseState {
  showcase: fromShowcase.ShowcaseState;
}

export const reducers: ActionReducerMap<ShowcaseState> = {
  showcase: fromShowcase.reducer,
};

export const getDashboardState = createFeatureSelector<ShowcaseState>(
  'dashboard'
);

