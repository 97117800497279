import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit
} from '@angular/core';

@Component({
  selector: 'app-skuba-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  @HostBinding('class.app-layout') hostClass = false;

  constructor() {}

  ngOnInit() {
    this.hostClass = true;
  }
}
