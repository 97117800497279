<div class="app-select" tabindex="0" (keydown)="handleKeyboardEvent($event)">
  <label *ngIf="label">{{label | translate}}
    <ng-container *ngIf="isRequired">*</ng-container>
    <i *ngIf="explanation" class="material-icons material-pointer" [satPopoverAnchor]="pop" (click)="pop.toggle()">info</i>
  </label>
  <div class="select" [ngClass]="{'compact': compact}" >
    <div data-toggle="select" class="select-button" (click)="selectToggle()">
      <!--Single item select Start-->
      <div class="selected-options" [ngClass]="{'has-image': selectedOption && selectedOption.image, 'disabled': disabled, 'compact': compact}">
        <img *ngIf="selectedOption && selectedOption.image" [src]="selectedOption.image" [ngClass]="{'bordered': imgWithBorder}">
        <div *ngIf="selectedOption" class="label" [ngClass]="{'item-img-sm': imgSmall}" [innerHTML]="selectedOption.name | translate"></div>
      </div>
      <span *ngIf="data && !disabled" class="material-icons reset-select" (click)="resetToDefault($event)">close</span>
      <span class="caret" [ngClass]="{'compact': compact}"></span>
      <!--Single item select End-->
    </div>
    <!--Search options list Start-->
    <div *ngIf="options" class="select-menu" [ngClass]="{'selected': selectStatus}">
      <!--Search filter input-->
      <div [formGroup]="search" *ngIf="search" class="select-search">
        <app-input formControlName="searchText" [focus]="inputFocus" *ngIf="selectStatus"></app-input>
        <i class="material-icons search-reset" (click)="searchReset($event)" *ngIf="search.controls['searchText'].value">close</i>
      </div>
      <div class="select-menu-wrapper" #container>
        <!--Options-->
        <ul>
          <li *ngFor="let item of options | selectSearch : search.controls['searchText'].value; let i = index"
              [ngClass]="{'disabled': item.val === data, 'has-image': item.image}">
            <img [src]="item.image" *ngIf="item.image" [ngClass]="{'bordered': imgWithBorder}">
            <span (click)="selectOption(item)" (mouseenter)="itemMouseEnter(item, i)" class="item" [ngClass]="{'item-active': item === preOption, 'item-img-sm': imgSmall}">
              <i *ngIf="item.icon" [ngClass]="item.icon"></i>
              <i *ngIf="item.materialIcon" class="material-icons">{{item.materialIcon}}</i>
              {{item.name | translate | ellipsis: ellipsis}}
            </span>
          </li>

          <li *ngIf="addNewSelectionItemLink && !disabled">
            <hr />
            <span class="item"
                  [routerLink]="[addNewSelectionItemLink]"
                  class="add-new">
              <i class="material-icons">add_circle_outline</i>
              <span [innerHTML]="'ADD_NEW' | translate"></span>
            </span>
          </li>

        </ul>
      </div>
    </div>
    <!--Search options list End-->
  </div>
  <sat-popover #pop horizontalAlign="end" verticalAlign="below" hasBackdrop>
    <div class="info-wrapper">{{explanation | translate}}</div>
  </sat-popover>
</div>
