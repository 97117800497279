<ng-container *ngIf="!skn">
  <i 
    nz-tooltip
    [nzTooltipTitle]="'FAVORITE' | translate"
    [nzMouseEnterDelay]="0.01"
    [nzMouseLeaveDelay]="0.01"
    class="fas fa-thumbs-up favorite-btn material-pointer"
    (click)="handleFavoriteClick()"
  ></i>
  <app-modal *ngIf="favoritesModalStatus"
             [status]="favoritesModalStatus"
             [confirmButton]="false"
             [closeButton]="true"
             [showOverlay]="true"
             [maxWidth]="'900px'"
             [title]="'FAVORITE_PRODUCTS' | translate"
             (outputStatus)="changeFavoritesModalStatus(false)">
    <div class="favorite-products">

<!--      <div *ngIf="favoriteProducts.length">-->
<!--        <div class="favorite-product">-->
<!--          <span class="favorite-product-skn">{{"SKN"}}</span>-->
<!--          <span class="favorite-product-name">{{"TITLE" | translate}}</span>-->
<!--          <span class="favorite-product-remove">{{"REMOVE" | translate}}</span>-->
<!--        </div>-->
<!--        <div class="favorite-product"-->
<!--             *ngFor="let item of favoriteProducts"-->
<!--             (click)="goSearch(item.skn)">-->
<!--          <span class="favorite-product-skn">{{item.skn}}</span>-->
<!--          <span class="favorite-product-name"><strong>{{item.name}}</strong></span>-->
<!--          <span class="favorite-product-remove" (click)="removeFromFavorites($event, item)">-->
<!--            <i title="{{'REMOVE' | translate}}" class="material-icons material-pointer">highlight_off</i>-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->

      <div class="showcase-row row" *ngIf="favoriteProducts.length">
        <div class="showcase-box col" *ngFor="let item of favoriteProducts">
          <a (click)="goSearch($event, item.skn)">
            <div class="showcase-image">
              <div class="image-placeholder" *ngIf="!item.showRealPicture">
                <h4>
                  <i class="fas fa-camera"></i>
                </h4>
              </div>
              <img src="{{base_url}}{{item.active_picture_url}}?width=200&quality=60" [alt]="item.name" [hidden]="!item.showRealPicture" (load)="imageLoaded(item)">
            </div>
            <div class="showcase-text" [title]="item.name">
              <h2>{{item.name | ellipsis:28}}</h2>
              <p>SKN: {{item.skn}}</p>
              <nz-spin [nzSpinning]="currentlyEditedNote === item && currentlyEditedNoteTextLoading">
                <p class="favorite-note">{{'NOTE' | translate}}: {{item.note | ellipsis:28}}</p>
              </nz-spin>
            </div>
          </a>
          <div class="hover-anchor remove-icon" nz-popconfirm
               nzTitle="{{'DELETE_CONFIRMATION_TEXT' | translate}}"
               nzCancelText="{{'DELETE_CANCEL_TEXT' | translate}}"
               nzOkText="{{'DELETE_OK_TEXT' | translate}}"
               (nzOnConfirm)="removeFromFavorites(item)">
            <i title="{{'REMOVE' | translate}}" class="material-icons material-pointer">highlight_off</i>
          </div>
          
          <div class="hover-anchor note-icon" nz-popconfirm
               [nzTitle]="noteEdit"
               nzCancelText="{{'DELETE_CANCEL_TEXT' | translate}}"
               nzOkText="{{'SAVE' | translate}}"
               (nzOnConfirm)="saveCurrentNote(item)"
               (click)="toggleCurrentNote(item)">
            <i title="{{'REMOVE' | translate}}" class="material-icons material-pointer">edit</i>
          </div>

          <div class="hover-anchor info-icon"
               [satPopoverAnchor]="p"
               (mouseenter)="p.open()"
               (mouseleave)="p.close()">
            <i class="material-icons material-pointer">info</i>
          </div>
          <i class="material-icons corner-icon" nz-tooltip [nzTitle]="titleTemplate" [ngClass]="{
            'star-transparent': item['stock_state'] === -1,
            'star-black': item['stock_state'] === 0,
            'star-yellow': item['stock_state'] === 1,
            'star-blue': item['stock_state'] === 2,
            'star-green': item['stock_state'] === 3
          }">star</i>
          <ng-template #titleTemplate>
              <p *ngIf="item['stock_state'] === 0">
                {{'OUT_OF_STOCK' | translate}}
              </p>
              <p *ngIf="item['stock_state'] === 1">
                {{'ITEM_LOCATED_IN_VILNIUS' | translate}}
              </p>
              <p *ngIf="item['stock_state'] === 2">
                {{'ITEM_LOCATED_IN_PERIPHERAL_STORE' | translate}}
              </p>
              <p *ngIf="item['stock_state'] === 3">
                {{'ITEM_IN_STOCK' | translate}}
              </p>
          </ng-template>

          <sat-popover #p horizontalAlign="after">
            <div class="tooltip-wrapper">
              {{item.manufacturer_name}} <br>
              {{item.sub_group_name}} <br>
              {{item.dimensions}}
            </div>
          </sat-popover>
          <ng-template #noteEdit>
            <input nz-input [placeholder]="'NOTE' | translate" [(ngModel)]="currentlyEditedNoteText" />
          </ng-template>
        </div>
      </div>

      <div *ngIf="!favoriteProducts.length" class="align-center">
        {{'RESULTS_NOT_FOUND' | translate}}
      </div>

    </div>
  </app-modal>
</ng-container>
<ng-container *ngIf="skn">
  <i title="{{'FAVORITE' | translate}}"
    class="fas fa-thumbs-up favorite-toggle-btn material-pointer"
    [ngClass]="{'is-favorite': isFavorite}"
    (click)="toggleFavorite(skn)"
  ></i>
</ng-container>
