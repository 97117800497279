import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, catchError, mergeMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserCarsService } from '@app/core/service/user-cars.service';
import * as fromActions from '../actions/user-cars.action';
import * as fromUser from '@app/auth/store/actions';

@Injectable()
export class UserCarsEffects {
  constructor(
    private actions$: Actions,
    private userCarsService: UserCarsService
  ) {}

  @Effect() loadUserCar$ = this.actions$.pipe(
    ofType(fromActions.LOAD_USER_CARS),
    switchMap((data) => {
      return this.userCarsService.getCars().pipe(
        map(user => new fromActions.LoadUserCarsSuccess(user)),
        catchError(error => of(new fromActions.LoadUserCarsFail(error)))
      );
    })
  );

  @Effect() loadUserCarByUser$ = this.actions$.pipe(
    ofType(fromActions.LOAD_USER_CARS_BY_USER),
    switchMap((data: fromActions.LoadUserCarsByUser) => {
      let  userId = 0;
      if ( data !== undefined && data !== null && data.payload && data.payload.userId > 0) {
        userId = data.payload.userId;
      }
      return this.userCarsService.getCarsByUser(userId).pipe(
        map(cars => new fromActions.LoadUserCarsSuccess(cars)),
        catchError(error => of(new fromActions.LoadUserCarsFail(error)))
      );
    })
  );

  @Effect() addUserCar$ = this.actions$.pipe(
    ofType(fromActions.ADD_USER_CAR),
    map((action: fromActions.AddUserCar) => action.payload),
    mergeMap(car => {
      return this.userCarsService.addCars(car).pipe(
        map(carItem => new fromActions.AddUserCarSuccess(carItem)),
        catchError(error => of(new fromActions.AddUserCarFail(error)))
      );
    })
  );

  @Effect() patchUserCar$ = this.actions$.pipe(
    ofType(fromActions.PATCH_USER_CAR),
    map((action: fromActions.PatchUserCar) => action.payload),
    mergeMap(data => {
      return this.userCarsService
        .patchCar(data.id, data.body)
        .pipe(
          map(
            carItem => new fromActions.PatchUserCarSuccess(carItem)
          ),
          catchError(error => of(new fromActions.PatchUserCarFail(error)))
        );
    })
  );

  @Effect() updateUserCar$ = this.actions$.pipe(
    ofType(fromActions.UPDATE_USER_CAR),
    map((action: fromActions.UpdateUserCar) => action.payload),
    mergeMap(data => {
      return this.userCarsService
        .updateCar(data.id, data.body)
        .pipe(
          map(
            carItem => new fromActions.UpdateUserCarSuccess(carItem)
          ),
          catchError(error => of(new fromActions.UpdateUserCarFail(error)))
        );
    })
  );

  @Effect() removeUserCar$ = this.actions$.pipe(
    ofType(fromActions.REMOVE_USER_CAR),
    map((action: fromActions.RemoveUserCar) => action.payload),
    mergeMap(id => {
      return this.userCarsService.removeCar(id).pipe(
        map(() => new fromActions.RemoveUserCarSuccess()),
        catchError(error => of(new fromActions.RemoveUserCarFail(error)))
      );
    })
  );

  @Effect() setUserCar$ = this.actions$.pipe(
    ofType(fromActions.SET_USER_CAR),
    map((action: fromActions.SetUserCar) => action.payload),
    map(car => {
      return new fromActions.SetUserCarSuccess(car);
    })
  );

  @Effect() handlePatchUserCarSuccess$ = this.actions$.pipe(
    ofType(fromActions.PATCH_USER_CAR_SUCCESS),
    map(() => {
      return new fromActions.LoadUserCars();
    })
  );

  @Effect() handleUpdateUserCarSuccess$ = this.actions$.pipe(
    ofType(fromActions.UPDATE_USER_CAR_SUCCESS),
    map(() => {
      return new fromActions.LoadUserCars();
    })
  );

  @Effect() handleRemoveUserCarSuccess$ = this.actions$.pipe(
    ofType(fromActions.REMOVE_USER_CAR_SUCCESS),
    map(() => {
      return new fromActions.LoadUserCars();
    })
  );
}
