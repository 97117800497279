import { IUserClientReference, IUserShop } from '@app/auth/models/user';
import { ClientInfo } from '@app/shared/models/client-info.model';

export class UsersList {
  count = 0;
  results: [
    {
      username: string;
      email: string;
      first_name: string;
      last_name: string;
      is_staff: boolean;
      last_login: string;
      profile: {
        id: number;
        user: number;
        client: {
          address: string;
          company: number;
          company_code: string;
          id: number;
          name: string;
          skuba_client_id: string;
        };
        email_verified: boolean;
        email_verified_at: string;
        legal_type: any;
        country: string;
        city: string;
        address: string;
        delivery_address: string;
        phone: string;
        skype: string;
        bkn: number;
        status: number;
        tax_code: string | number;
        valid_till: string;
        ip: number;
        created_at: string;
        updated_at: string;
        shop: any;
      };
      found_by_user_client: boolean;
      found_user_client: string;
      password: string;
      user_field_values?: { key: string; value: string }[];
    }
  ];
}

export class UserData {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  is_staff: boolean;
  last_login: string;
  profile: {
    id?: number;
    client: ClientInfo;
    main_client: ClientInfo;
    client_hint: string;
    client_id: number;
    shop: UserShop;
    user: number;
    legal_type: any;
    country_name: string;
    country: string;
    city: string;
    address1: string;
    address2: string;
    zip: number;
    delivery_address: string;
    phone_code: number;
    phone: number;
    skype: string;
    bkn: number;
    status: number;
    tax_code: string | number;
    valid_till: string;
    ip: number;
    created_at: string;
    updated_at: string;
    shop_id: number;
    billing_address: string;
    selected_client_references: IUserClientReference[];
    email_verified: boolean;
    instant_orders: boolean;
    enable_payments: boolean;
    ignore_overdue8_debt: boolean;
    note_choice_on_add_cart: boolean;
    force_reset_password: boolean;
  };
  password: string;
  user_field_values?: { key: string; value: string }[];
}

export class UserDetails {
  label: string | number;
  val: string | number;
}

export class UserOtherSettings {
  user_settings: UserSettings;
  sub_groups_settings: any;
}

export class UserSettings {
  id: number;
  user: number;
  show_accurate_vilnius_stock = false;
  show_local_currency = false;
  show_oe_codes = false;
  show_manufacturer_codes = false;
  show_same_country_near_stocks_qty = false;
  same_country_near_stocks_collapse_by_brand = false;
  allow_reservation = false;
  show_vilnius_list_price = false;
  show_vilnius_purchase_price = false;
  show_vilnius_stock = false;
  cart_expiration: number;
  cart_expiration_final: number;
  enable_order_delivery: boolean;
  enable_ip_tracking: boolean;
  enable_email_choice: boolean;
  enable_invoices: boolean;
  enable_search_by_client_car: boolean;
  enable_search_by_any_car: boolean;
  can_modify_prod_loc_show: boolean;
  enable_basket_logic: boolean;
  enable_basket_mini: boolean;
  hide_cards_in_stocks: boolean;
  disable_default_client_ref: boolean;
  show_invoice_note: boolean;
  show_declarations: boolean;
  show_consignment_warehouse: boolean;
  show_price_request: boolean;
}

export class UserOperation {
  id: number;
  name: string;
}

export class UserOperations {
  count: number;
  results: UserOperation[];
}

export class UserOperationsLimits {
  count: number;
  results: UserOperationsLimit[];
}

export interface UserOperationsLimit {
  id?: number;
  user: number;
  action: number;
  reset: number;
  limit: number;
  notify_at: number;
  current: number;
  action_name?: string;
  reset_name?: string;
  updated_at?: string;
  canceled_at?: string;
}

export class UserShopSettings {
  id?: number;
  show_quantity: boolean;
  allow_reservation: boolean;
  show_base_price: boolean;
  show_purchase_price: boolean;
  show_accurate_stock: boolean;
  show_product_location: boolean;
  user?: number;
  shop: number;
  name?: string;
}

export class UserRightsShopSettings {
  id?: number;
  show_quantity: boolean;
  allow_reservation: boolean;
  show_base_price: boolean;
  show_purchase_price: boolean;
  show_accurate_stock: boolean;
  show_product_location: boolean;
  user?: number;
  shop: IUserShop;
  name?: string;
}

export class UserFutureShopSettings {
  id?: number;
  user?: number;
  future_shop_settings_type: number;
  show_quantity: boolean;
  allow_reservation: boolean;
  show_base_price: boolean;
  show_purchase_price: boolean;
  show_accurate_stock: boolean;
  show_product_location: boolean;
}

export class UserShop {
  id: number;
  name: string;
  country?: string;
}

export interface UsersLimitsResets {
  count: number;
  results: UsersLimitsReset[];
}

export interface UsersLimitsReset {
  id: number;
  name: string;
}

export interface UsersIpList {
  count: number;
  results: UsersIp[];
}

export interface UsersIp {
  id: number;
  created_at: string;
  updated_at: string;
  last_seen_at: string;
  address: string;
  blocked: boolean;
  user: number;
}

  type NoteDeliveryFormNameTypes =
    'ndo_customer_take_center'
    | 'ndo_customer_take'
    | 'ndo_customer_deliver';

export class NoteDeliveryFields {
  formName: NoteDeliveryFormNameTypes;
  placeholder: string;
  label: string;
  template: string;
  isRequired: boolean;
  isDefault ?= false;
  showOnlyOnCentral ?= false;
}
export class NoteDeliverySelector {
  label: string;
  value: any;
  isDefault: boolean;
}
export const NOTE_DELIVERY_CONDITIONS: NoteDeliveryFields[] = [
  {
    formName: 'ndo_customer_take',
    placeholder: 'NDO_CUSTOMER_TAKE',
    label: 'NDO_CUSTOMER_TAKE',
    template: '',
    isRequired: false,
    isDefault: true,
  },
  {
    formName: 'ndo_customer_take_center',
    placeholder: 'NDO_CUSTOMER_TAKE_CENTER',
    label: 'NDO_CUSTOMER_TAKE_CENTER',
    template: 'P/P',
    isRequired: false,
    showOnlyOnCentral: true,
  },
  {
    formName: 'ndo_customer_deliver',
    placeholder: 'NDO_CUSTOMER_DELIVER',
    label: 'NDO_CUSTOMER_DELIVER',
    template: 'N/',
    isRequired: false,
  },
];
