import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import * as toast from '@core/store/actions/toast.action';
import { TranslationService } from '@core/service/translation.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable()
export class FileService {

  constructor(
    private store: Store<fromRoot.AppState>,
    private translationService: TranslationService,
    private http: HttpClient,
  ) {}

  filePickerCallback(cb, value, meta, object_id: string, content_type_id: string, insert_url: string) {
    let input = document.createElement('input');
    input.setAttribute('type', 'file');
    if (meta.filetype === 'image') {
      input.setAttribute('accept', 'image/*');
    }

    /*
      Note: In modern browsers input[type="file"] is functional without
      even adding it to the DOM, but that might not be the case in some older
      or quirky browsers like IE, so you might want to add it to the DOM
      just in case, and visually hide it. And do not forget do remove it
      once you do not need it anymore.
    */

    input.onchange = (e: Event) => {
      let file: File;
      let formData: FormData;
      let typesAccepted: string = '';

      file = (<HTMLInputElement>e.target).files[0];

      if(e.composedPath().length > 0){
        if(e.composedPath()[0]['accept']) {
          typesAccepted = e.composedPath()[0]['accept'];
        }
      }

      if (this.fileValidation(file, typesAccepted)) {
        formData = new FormData();
        formData.append('file', file);
        formData.append('insert_url', insert_url);
        formData.append('content_type_id', content_type_id);
        formData.append('object_id', object_id);

        this.http.post(`${environment.api_url}/users/upload_file/`, formData).subscribe(
          (response) => {
            cb(`${environment.url}${response['location']}`, { title: file.name });
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.store.dispatch(new toast.ShowErrorToast('FAILED_TO_LOAD_FILE'));
              throw `Error occurred while getting file in TinyMCE editor, HTTP Error: ${error.status} HTTP Response Text: ${error.statusText}`;
            } else {
              this.store.dispatch(new toast.ShowErrorToast('FAILED_TO_LOAD_FILE'));
              throw `Error occurred while getting file in TinyMCE editor, not HTTP Error: ${error}`;
            }
          }
        );
      }
    };
    input.click();
  }

  fileValidation(file: File, typesAccepted: string): boolean {
    const maxFileSizeMB = 30;
    const maxFileSizeB = maxFileSizeMB * 1024 * 1024;
    const availableExtensions = [
      'bmp',
      'doc',
      'docx',
      'eps',
      'gif',
      'jpeg',
      'jpg',
      'odg',
      'odm',
      'ods',
      'odt',
      'otg',
      'oth',
      'ots',
      'ott',
      'pdf',
      'png',
      'raw',
      'svg',
      'xls',
      'xlsx'
    ];
    const availableImageExtensions = [
      'bmp',
      'gif',
      'jpeg',
      'jpg',
      'png',
      'raw',
      'svg',
    ];
    let fileExtension = file.name.split('.').pop()
    if (file.size > maxFileSizeB) {
      this.store.dispatch(new toast.ShowErrorToast(
        this.translationService.translate('FILE_SIZE_LIMIT_IS') + ' ' + maxFileSizeMB + ' MB',
      ));
      return false;
    } else if (typesAccepted.includes('image') && !availableImageExtensions.includes(fileExtension)) {
      this.store.dispatch(new toast.ShowErrorToast('WRONG_IMAGE_FORMAT'));
      return false;
    } else if (!availableExtensions.includes(fileExtension)) {
      this.store.dispatch(new toast.ShowErrorToast('WRONG_FILE_FORMAT'));
      return false;
    } 
    return true;
  }
}