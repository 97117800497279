import { ChangeDetectorRef, Component, OnInit, EventEmitter } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../store/index';
import { Select } from '@app/shared/ui/select/select.model';
import { Observable } from 'rxjs';
import { ApiService } from '@app/core/api.service';
import { Country, Countries } from '@app/shared/models/country.model';
import { HelperService } from '@app/shared/services/helper.service';
import { SelectPipe } from '@app/shared/ui/select/pipes/select.pipe';
import * as fromRoot from '@app/reducers/index';
import { ShopService } from '@app/shared/services/shop.service';
import { ICompanyData } from '@app/shared/models/company.model';
import { IUserRegister } from '@app/auth/models/user';
import { UsersService } from '@app/pages/users/services/users.service';
import * as toast from '@app/core/store/actions/toast.action';
import { Router } from '@angular/router';
import * as fromLastRoute from '@core/store/actions/last-route.action';
import { Subscription } from 'rxjs/index';
import { ICompany } from '@shared/models/company.model';
import * as LanguageActions from '@core/store/actions/language.action';
import * as TranslationActions from '@core/store/actions/translation.action';
import { CompaniesSelect } from '@app/auth/data/register-form';

export interface IRegisterComponentError {
  name: string;
  errorType: string;
  value: string;
  customError?: boolean;
}

@Component({
  selector: 'app-skuba-register',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterComponent implements OnInit {
  pending$ = this.store.pipe(select(fromStore.getLoginPagePending));
  error$ = new EventEmitter<IRegisterComponentError>();

  /** Country phone codes select data */
  countryPhoneCodes: Select[];
  /** Countries list from API */
  countries: Country[];
  /** Countries select list */
  countriesSelect: Select;
  /** Companies select list */
  companiesSelect: CompaniesSelect[];

  presetCompanyValue: ICompany;

  router$: Observable<any>;
  routerSub: Subscription;

  isSaving = false;

  constructor(
    private router: Router,
    private store: Store<fromRoot.AppState>,
    private apiService: ApiService,
    private helperService: HelperService,
    private selectPipe: SelectPipe,
    private usersService: UsersService,
    private shopService: ShopService
  ) {
    this.router$ = this.store.pipe(select(fromRoot.getRouterState));
  }

  ngOnInit(): void {
    this.initPhoneCodes();
    this.initCountries();
    this.initCompanies();
  }

  /** Get and form phone code list from JSON */
  initPhoneCodes(): void {
    this.getPhoneCodes('country-calling-codes.json').subscribe(
      (phoneCodeList: any) => {
        const selectPhoneCodeList = [];
        phoneCodeList.map(item => {
          selectPhoneCodeList.push({
            name: `${item.code} (+${item.callingCode})`,
            val: item.callingCode,
            metadata: {
              countryCallingCode: item.code
            }
          });
        });

        this.countryPhoneCodes = selectPhoneCodeList;
      }
    );
  }

  getPhoneCodes(json): Observable<any> {
    return this.apiService.getJSON(`${json}`);
  }

  /** Init counties list and select */
  initCountries(): void {
    this.helperService.countries().subscribe((countries: Countries) => {
      this.countries = countries.results;
      this.countriesSelect = this.selectPipe.transform(
        this.countries,
        'name',
        'code'
      );
    });
  }

  presetCompany(company: ICompany) {
    // Set company
    this.presetCompanyValue = company;
    // Set language
    this.store.dispatch(
      new LanguageActions.SetActiveLanguage(company.country.language_code)
    );
    this.store.dispatch(
      new TranslationActions.SetTranslationLanguage(
        company.country.language_code
      )
    );
    //
  }

  initCompanies() {
    this.shopService.getCompanies().subscribe((companies: ICompanyData) => {
      this.routerSub = this.router$.subscribe(route => {
        if (route.state.queryParams && route.state.queryParams.company) {
          let companyId = route.state.queryParams.company;
          if (companyId === '11') {
            companyId = '30';
          }
          const company = companies.results.filter(
            item =>
              item.skuba_company_id === Number(companyId)
          )[0];
          if (company) {
            this.presetCompany(company);
          }
        }
      });
      if (companies && companies.results) {
        this.companiesSelect = companies.results.map(
          item =>
            <CompaniesSelect> {
              name: item.name,
              val: item.id,
              image: `/assets/flags/svg/${item.country.code}.svg`,
              skuba_company_id: item.skuba_company_id,
              metadata: {
                country_code: item.country.code,
                language_code: item.country.language_code,
                shops: item.shops,
              }
            }
        );
      }
    });
  }

  onSubmitRegistration(form: IUserRegister) {
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.usersService.register({...form, _ems_silenced: true }).subscribe(response => {
      this.isSaving = false;
      this.store.dispatch(new toast.ShowSuccessToast('SUCCESS'));
      this.router.navigate(['/login'], { queryParams: {
        register_success: '1'
      }});
    }, (error) => {
      this.isSaving = false;
      if (error && error.error) {
        if (error.error.email) {
          error.error.email.forEach(value => {
            this.error$.emit({
              name: 'email',
              errorType: 'email',
              value
            });
          });
        }
        if (error.error.password1) {
          error.error.password1.forEach(value => {
            this.error$.emit({
              name: 'password1',
              errorType: value,
              value
            });
          });
        }
        if (error.error.username) {
          error.error.username.forEach(value => {
            if (typeof value === 'string' && value.includes('Enter a valid username.')) {
              this.error$.emit({
                name: 'username',
                errorType: 'VALID_USERNAME',
                value
              });
            } else {
              this.error$.emit({
                name: 'username',
                errorType: value,
                value,
                customError: true,
              });
            }
          });
        }
        Object.keys(error.error)
          .filter(key => key !== 'password1')
          .filter(key => key !== 'email')
          .filter(key => key !== 'username')
          .forEach(key => {
            if (error.error[key] && error.error[key].forEach) {
              error.error[key].forEach(value => {
                this.error$.emit({
                  name: key,
                  errorType: key,
                  value,
                  customError: true,
                });
              });
            } else {
              this.error$.emit({
                name: key,
                errorType: key,
                value: error.error[key],
                customError: true,
              });
            }
          });

      }
    });
  }
}
