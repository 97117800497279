import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Input,
  OnDestroy,
  AfterViewInit
} from '@angular/core';
import * as fromRoot from '@app/reducers';

import { fadeIn } from '@shared/animations';
import { Router } from '@angular/router';
import { Observable, Subscription, Subject } from 'rxjs';
import { Store, select, ActionsSubject } from '@ngrx/store';
import { CartModel } from '@pages/cart/models/cart.model';
import { IUser } from '@app/auth/models/user';
import * as fromAuth from '@app/auth/store';
import { takeUntil } from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import { ofType } from '@ngrx/effects';
import { CartActionTypes } from '@pages/cart/store';

@Component({
  selector: 'app-cart-mini-container',
  templateUrl: './cart-mini-container.component.html',
  styleUrls: ['./cart-mini-container.component.scss'],
  animations: [fadeIn]
})
export class CartMiniContainerComponent implements OnInit, OnDestroy, AfterViewInit {
  loggedIn$: Observable<boolean>;
  router$: Observable<any>;
  routerSub: Subscription;
  user$: Observable<IUser>;
  userSub: Subscription;
  user: IUser;
  cart$: Observable<CartModel>;
  cartSub: Subscription;
  cartMiniShowUp = false;
  cartMiniMaxHeight = false;
  cartMiniDisplay = false;
  cartMiniTimeout: NodeJS.Timeout;
  cartMiniDisplayTimeout: NodeJS.Timeout;
  selectOpen = false;
  cartIsOpen = false;
  totalQuantity = 0;

  cartState$: Observable<any>;
  cartStateSub: Subscription;
  isLoadingCart = false;
  cartLoadTimeoutHandler: any;

  private destroyed$ = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private store: Store<fromRoot.AppState>,
    private actionsSubject: ActionsSubject,
  ) {
    this.router$ = this.store.pipe(select(fromRoot.getRouterState));
    this.user$ = this.store.pipe(select(fromAuth.getUser));
    this.cartState$ = this.store.pipe(select(fromRoot.getCartState));
  }

  ngOnInit() {
    this.cart$ = this.store.pipe(select(fromRoot.getCartProducts));
    this.cartSub = this.cart$.subscribe(products => {
      this.totalQuantity = 0;
      if (products.cart && products.cart.items) {
        products.cart.items.map(product => {
          this.totalQuantity = this.totalQuantity + product.quantity;
        });
      }
      if (this.totalQuantity === 0) {
        this.cartMiniShowUp = false;
        this.cartMiniDisplay = false;
      }
    });

    this.routerSub = this.router$.subscribe(async route => {
      if (route && route.state && route.state.url && route.state.url.startsWith('/cart')) {
        this.cartIsOpen = true;
      } else {
        this.cartIsOpen = false;
      }
    });

    this.getUserInfo();

    this.cartStateSub = this.cartState$.subscribe(state => {
      if (state.loading && !state.updated && !this.isLoadingCart) {
        this.isLoadingCart = true;
        if (this.cartLoadTimeoutHandler) {
          clearTimeout(this.cartLoadTimeoutHandler);
        }
        this.cartLoadTimeoutHandler = setTimeout(() => {
          this.isLoadingCart = false;
          Sentry.captureMessage(`Error occurred while getting cart state, timeout initiated.`);
        }, 30000);
      } else if (!state.loading && state.updated && this.isLoadingCart) {
        this.isLoadingCart = false;
        if (this.cartLoadTimeoutHandler) {
          clearTimeout(this.cartLoadTimeoutHandler);
        }
      }
    }, error1 => {
      this.isLoadingCart = false;
      if (this.cartLoadTimeoutHandler) {
        clearTimeout(this.cartLoadTimeoutHandler);
      }
      Sentry.captureMessage(`Error occurred while getting cart state: ${error1}`);
    });

    this.actionsSubject.pipe(
      ofType(CartActionTypes.AddProductFail),
      takeUntil(this.destroyed$)
    ).subscribe(data => this.hideSpinner(data));

    this.actionsSubject.pipe(
      ofType(CartActionTypes.UpdateProductFail),
      takeUntil(this.destroyed$)
    ).subscribe(data => this.hideSpinner(data));
  }

  hideSpinner(data) {
    this.isLoadingCart = false;
    if (this.cartLoadTimeoutHandler) {
      clearTimeout(this.cartLoadTimeoutHandler);
    }
  }

  /**
   * Get user info and form header for profile drop-down.
   */
  getUserInfo() {
    if (this.userSub) { this.userSub.unsubscribe(); }
    this.userSub = this.user$.subscribe(user => {
      if (user) {
        this.user = user;
      } else {
        this.user = null;
      }
    });
  }

  /** Go to search route */
  navigateCart(): void {
    this.router.navigate(['/cart']);
  }

  mouseEnter() {
    clearTimeout(this.cartMiniTimeout);
    clearTimeout(this.cartMiniDisplayTimeout);
    this.cartMiniShowUp = true;
    this.cartMiniDisplay = true;
    setTimeout(() => {
      this.cartMiniMaxHeight = true;
    });
  }

  mouseLeave() {
    this.cartMiniCheck();
  }

  selecting(selectOpen: boolean) {
    this.selectOpen = selectOpen;
    this.cartMiniCheck();
  }

  cartMiniCheck() {
    if (!this.selectOpen) {
      const timeOut = 500;
      this.cartMiniTimeout = setTimeout(() => {
        this.cartMiniShowUp = false;
        setTimeout(() => {
          this.cartMiniMaxHeight = false;
        });
        this.cartMiniDisplayTimeout = setTimeout(() => {
          this.cartMiniDisplay = false;
        }, timeOut);
      }, timeOut);
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.userSub) { this.userSub.unsubscribe(); }
    if (this.cartSub) { this.cartSub.unsubscribe(); }
    if (this.cartStateSub) { this.cartStateSub.unsubscribe(); }
  }
  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }
}
