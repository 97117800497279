import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import { catchError } from 'rxjs/operators';

import {
  HttpHeaders,
  HttpClient,
  HttpParams,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';

import { Router } from '@angular/router';

@Injectable()
export class ApiService {
  saveData = (function() {
    const a = document.createElement('a') as any;
    document.body.appendChild(a);
    a.style.display = 'none';
    return function(data, fileName, type) {
      // Workaround on ios/osx safari. Wrapped data should somehow fix it...
      const wrappedData = [data];
      const blob = new Blob(wrappedData, { type: type }),
        url = window.URL.createObjectURL(blob);
      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
        return;
      }
      // Workaround on ios/osx safari. Wrapped data should somehow fix it...
      const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
      if (isSafari) {
        window.location.href = url;
      } else {
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    };
  })();

  constructor(private http: HttpClient, private router: Router) {}

  getDirect(path: string, params: HttpParams | { [param: string]: string | string[]; } = new HttpParams()): Observable<any> {
    return this.http
      .get(path, { params })
      .pipe(catchError(err => this.handleError(err)));
  }

  get(path: string, params: HttpParams | { [param: string]: string | string[]; } = new HttpParams()): Observable<any> {
    return this.http
      .get(`${environment.api_url}${path}`, { params })
      .pipe(catchError(err => this.handleError(err)));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http
      .put(`${environment.api_url}${path}`, JSON.stringify(body))
      .pipe(catchError(err => this.handleError(err)));
  }

  patch(path: string, body: Object = {}): Observable<any> {
    return this.http
      .patch(`${environment.api_url}${path}`, JSON.stringify(body))
      .pipe(catchError(err => this.handleError(err)));
  }

  post(path: string, body: Object = {}, throwWholeResponse = false): Observable<any> {
    return this.http
      .post(`${environment.api_url}${path}`, JSON.stringify(body))
      .pipe(catchError(err => this.handleError(err, throwWholeResponse)));
  }

  request(
    method: string,
    path: string,
    body: Object = {},
    throwWholeResponse = false,
  ): Observable<HttpResponse<string>> {
    return this.http
      .request(method, `${environment.api_url}${path}`, {
        body: JSON.stringify(body),
        observe: 'response',
        responseType: 'text'
      })
      .pipe(catchError(err => this.handleError(err, throwWholeResponse)));
  }
  delete(path): Observable<any> {
    return this.http
      .delete(`${environment.api_url}${path}`)
      .pipe(catchError(err => this.handleError(err)));
  }

  getJSON(path: string, url?: string): Observable<any> {
    return this.http
      .get(url || `./assets/json/${path}`)
      .pipe(catchError(err => this.handleError(err)));
  }

  getFile(name: string, url: string, ext: string, extContent: string = null) {
    if (!extContent) {
      extContent = ext;
    }
    const o = this.http
      .get(`${environment.api_url}${url}`, { responseType: 'arraybuffer' })
      .pipe(catchError(err => this.handleError(err)));
    const sub = o.subscribe(data => {
      this.saveData(data, `${name}.${ext}`, `application/${extContent}`);
      sub.unsubscribe();
    });
  }

  getJSONasFile(name: string, url: string) {
    const o = this.getJSON('', `${environment.api_url}${url}`);
    const sub = o.subscribe(data => {
      this.saveData(JSON.stringify(data), `${name}.json`, 'application/json');
      sub.unsubscribe();
    });
  }

  getObjectAsAsJSONString(name: string, jsonObj: Object) {
    this.saveData(JSON.stringify(jsonObj), `${name}.json`, 'application/json');
  }

  getObjectAsCsv(name: string, jsonObj: Object[]) {
    this.saveData(this.objToCsv(jsonObj), `${name}.csv`, 'text/csv');
  }

  objToCsv(data: Object[]) {
    const headers = Object.keys(data[0]).join();
    const content = data.map(r => Object.values(r).join());
    return [headers].concat(content).join('\n');
  }

  private formatErrors(data: any) {
    if (data.error.detail) {
      return throwError(data.error.detail);
    }
  }

  private handleError(error: Response | any, throwWholeResponse = false) {
    let errMsg: string;

    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        this.router.navigate(['/login']);
      }

      if (error.error.detail) {
        errMsg = error.error.detail;
      } else {
        errMsg = `${error.statusText || 'Network error'}`;
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    if (throwWholeResponse) {
      return throwError(error);
    }
    return throwError(errMsg);
  }
}
