import { createSelector } from '@ngrx/store';
import * as fromShipping from '../reducers/shipping.reducer';
import * as fromFeature from '../reducers';
const ɵ0 = (state) => state.shipping;
export const getShippingState = createSelector(fromFeature.getCheckoutState, ɵ0);
export const getShipping = createSelector(getShippingState, fromShipping.getShipping);
export const getShippingLoaded = createSelector(getShippingState, fromShipping.getShippingLoaded);
export const getShippingLoading = createSelector(getShippingState, fromShipping.getShippingLoading);
export const getShippingUpdated = createSelector(getShippingState, fromShipping.getShippingUpdated);
export { ɵ0 };
