/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../components/login/login-form.component.ngfactory";
import * as i2 from "../../components/login/login-form.component";
import * as i3 from "@angular/forms";
import * as i4 from "../../../shared/services/helper.service";
import * as i5 from "../../../core/api.service";
import * as i6 from "../../../core/service/translation.service";
import * as i7 from "@angular/common";
import * as i8 from "./login-page.component";
import * as i9 from "@ngrx/store";
import * as i10 from "@angular/router";
import * as i11 from "../../../core/local-storage/local-storage.service";
import * as i12 from "../../../core/service/cached-req.service";
var styles_LoginPageComponent = [];
var RenderType_LoginPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginPageComponent, data: {} });
export { RenderType_LoginPageComponent as RenderType_LoginPageComponent };
export function View_LoginPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-skuba-login-form", [], null, [[null, "submitted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submitted" === en)) {
        var pd_0 = (_co.onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_LoginFormComponent_0, i1.RenderType_LoginFormComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoginFormComponent, [i3.FormBuilder, i0.ElementRef, i4.HelperService, i5.ApiService, i6.TranslationService], { pending: [0, "pending"], errorMessage: [1, "errorMessage"], infoMessage: [2, "infoMessage"] }, { submitted: "submitted" }), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoginPending; var currVal_1 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 2).transform(_co.error$)); var currVal_2 = _co.infoMessage; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_LoginPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-skuba-login-page", [], null, null, null, View_LoginPageComponent_0, RenderType_LoginPageComponent)), i0.ɵdid(1, 245760, null, 0, i8.LoginPageComponent, [i9.Store, i10.Router, i11.LocalStorageService, i12.CachedRequestService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginPageComponentNgFactory = i0.ɵccf("app-skuba-login-page", i8.LoginPageComponent, View_LoginPageComponent_Host_0, {}, {}, []);
export { LoginPageComponentNgFactory as LoginPageComponentNgFactory };
