/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-autocomplete.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../pipes/translate/translate.pipe";
import * as i3 from "../../../../core/service/translation.service";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/common";
import * as i6 from "@angular/router";
import * as i7 from "./input-autocomplete.component";
var styles_InputAutocompleteComponent = [i0.styles];
var RenderType_InputAutocompleteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputAutocompleteComponent, data: {} });
export { RenderType_InputAutocompleteComponent as RenderType_InputAutocompleteComponent };
function View_InputAutocompleteComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, null); }
function View_InputAutocompleteComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "i", [["class", "material-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.materialIcon; _ck(_v, 1, 0, currVal_0); }); }
function View_InputAutocompleteComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit.preValue ? (_v.parent.context.$implicit.preValue + " - ") : "") + (_v.parent.context.$implicit.value ? _v.parent.context.$implicit.value : _v.parent.context.$implicit)); _ck(_v, 0, 0, currVal_0); }); }
function View_InputAutocompleteComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit.preValue ? (_v.parent.context.$implicit.preValue + " - ") : "") + (_v.parent.context.$implicit.value ? _v.parent.context.$implicit.value : i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_v.parent.context.$implicit)))); _ck(_v, 0, 0, currVal_0); }); }
function View_InputAutocompleteComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputAutocompleteComponent_3)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 11, "span", [["class", "item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.selectItem(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "add-new": 0, "item-active": 1, "color-yellow": 2, "color-green": 3, "color-red": 4 }), i1.ɵdid(7, 16384, null, 0, i6.RouterLink, [i6.Router, i6.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputAutocompleteComponent_4)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputAutocompleteComponent_5)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputAutocompleteComponent_6)), i1.ɵdid(14, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.path && (_co.data.length > 1)); _ck(_v, 2, 0, currVal_0); var currVal_1 = "item"; var currVal_2 = _ck(_v, 6, 0, _v.context.$implicit.path, (_co.keySelected === _v.context.$implicit), ((_v.context.$implicit.state == 1) || (_v.context.$implicit.state == 2)), (_v.context.$implicit.state == 3), (_v.context.$implicit.state == 4)); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = (_v.context.$implicit.path ? _ck(_v, 8, 0, _v.context.$implicit.path) : i1.ɵEMPTY_ARRAY); _ck(_v, 7, 0, currVal_3); var currVal_4 = _v.context.$implicit.materialIcon; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.highlight; _ck(_v, 12, 0, currVal_5); var currVal_6 = !_co.highlight; _ck(_v, 14, 0, currVal_6); }, null); }
function View_InputAutocompleteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "app-autocomplete"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "has-label": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputAutocompleteComponent_2)), i1.ɵdid(6, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "app-autocomplete"; var currVal_1 = _ck(_v, 3, 0, _co.hasLabel); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.data; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_InputAutocompleteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InputAutocompleteComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data && (_co.data.length > 0)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InputAutocompleteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-input-autocomplete", [], null, [["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_InputAutocompleteComponent_0, RenderType_InputAutocompleteComponent)), i1.ɵdid(1, 49152, null, 0, i7.InputAutocompleteComponent, [i1.ElementRef], null, null)], null, null); }
var InputAutocompleteComponentNgFactory = i1.ɵccf("app-input-autocomplete", i7.InputAutocompleteComponent, View_InputAutocompleteComponent_Host_0, { data: "data", hasLabel: "hasLabel", highlight: "highlight" }, { preselectedItem: "preselectedItem", active: "active" }, []);
export { InputAutocompleteComponentNgFactory as InputAutocompleteComponentNgFactory };
