import { IUserAddressesModel } from '@app/auth/models/user';
import * as fromUserAddresses from '../actions/user-addresses.action';
import { IUserAddress } from '@app/auth/models/user';
import { Country } from '@shared/models/country.model';

export interface UserAddressesState {
  addresses: IUserAddressesModel;
  entities: { [id: string]: any };
  shippingAddress: IUserAddress;
  shippingAddressId: number;
  loaded: boolean;
  loading: boolean;
  addSuccess: boolean;
  error: string;
}

export const initialState: UserAddressesState = {
  addresses: null,
  entities: {},
  shippingAddress: null,
  shippingAddressId: null,
  loaded: false,
  loading: false,
  addSuccess: false,
  error: null
};

export function reducer(
  state = initialState,
  action: fromUserAddresses.UserAddressesAction
): UserAddressesState {
  switch (action.type) {
    case fromUserAddresses.LOAD_USER_ADDRESSES: {
      return {
        ...state,
        loading: true,
        addSuccess: false,
        error: null
      };
    }
    case fromUserAddresses.LOAD_USER_ADDRESSES_SUCCESS: {
      let shippingAddress = state.shippingAddress;
      let shippingAddressId = state.shippingAddressId;

      const entities = action.payload.results.reduce(
        (entit: { [id: string]: IUserAddress }, address: IUserAddress) => {
          return {
            ...entit,
            [address.id]: address
          };
        },
        {
          ...state.entities
        }
      );

      action.payload.results.map(address => {
        if (address.is_default && state.shippingAddressId === null) {
          shippingAddress = address;
          shippingAddressId = address.id;
        }
      });

      return {
        ...state,
        addresses: action.payload,
        entities: entities,
        shippingAddress: shippingAddress,
        shippingAddressId: shippingAddressId,
        loaded: true,
        loading: false,
        addSuccess: false,
        error: null
      };
    }
    case fromUserAddresses.LOAD_USER_ADDRESSES_FAIL: {
      return {
        loaded: false,
        loading: false,
        addresses: null,
        entities: {},
        shippingAddress: null,
        shippingAddressId: null,
        addSuccess: false,
        error: action.payload
      };
    }
    case fromUserAddresses.ADD_USER_ADDRESS: {
      return {
        ...state,
        loading: true,
        addSuccess: false
      };
    }
    case fromUserAddresses.ADD_USER_ADDRESS_SUCCESS: {
      return {
        ...state,
        shippingAddress: action.payload,
        shippingAddressId: action.payload.id,
        loaded: true,
        loading: false,
        addSuccess: true,
        error: null
      };
    }
    case fromUserAddresses.ADD_USER_ADDRESS_FAIL: {
      return {
        ...state,
        loaded: true,
        loading: false,
        addSuccess: false,
        error: action.payload
      };
    }

    case fromUserAddresses.SET_USER_ADDRESS: {
      return {
        ...state,
        loading: true,
        addSuccess: false,
        error: null
      };
    }
    case fromUserAddresses.SET_USER_ADDRESS_SUCCESS: {
      return {
        ...state,
        shippingAddress: action.payload,
        shippingAddressId: action.payload ? action.payload.id : null,
        loaded: true,
        loading: false,
        addSuccess: false,
        error: null
      };
    }
    case fromUserAddresses.SET_USER_ADDRESS_FAIL: {
      return {
        ...state,
        addresses: null,
        loaded: true,
        loading: false,
        addSuccess: false,
        error: action.payload && action.payload.detail ? action.payload.detail : action.payload
      };
    }

    case fromUserAddresses.PATCH_USER_ADDRESS:
    case fromUserAddresses.UPDATE_USER_ADDRESS:
    case fromUserAddresses.REMOVE_USER_ADDRESS: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case fromUserAddresses.PATCH_USER_ADDRESS_SUCCESS:
    case fromUserAddresses.UPDATE_USER_ADDRESS_SUCCESS:
    case fromUserAddresses.REMOVE_USER_ADDRESS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: null
      };
    }
    case fromUserAddresses.PATCH_USER_ADDRESS_FAIL:
    case fromUserAddresses.UPDATE_USER_ADDRESS_FAIL:
    case fromUserAddresses.REMOVE_USER_ADDRESS_FAIL: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: action.payload && action.payload.detail ? action.payload.detail : action.payload
      };
    }

    case fromUserAddresses.RESET_USER_ADDRESSES_STATE: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export const getUserAddresses = (state: UserAddressesState) => state.addresses;
export const getUserAddressEntities = (state: UserAddressesState) =>
  state.entities;
export const getUserAddress = (state: UserAddressesState) =>
  state.shippingAddress;
export const addUserAddress = (state: UserAddressesState) => state.addSuccess;
export const addUserAddressError = (state: UserAddressesState) => state.error;
export const getUserAddressesLoaded = (state: UserAddressesState) =>
  state.loaded;
export const getUserAddressesLoading = (state: UserAddressesState) =>
  state.loading;
