import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as CountryActions from '../actions/country.action';
import { CountryActionTypes } from '../actions/country.action';
import { HelperService } from '@shared/services/helper.service';

@Injectable()
export class CountryEffects {
  constructor(private actions$: Actions, private service: HelperService) {}

  @Effect() loadSubgroups$ = this.actions$.pipe(
    ofType(CountryActionTypes.CountriesLoad),
    switchMap(() => {
      return this.service.countries().pipe(
        map(
          country => new CountryActions.CountriesLoadSuccess(country.results)
        ),

        catchError(error => of(new CountryActions.CountriesLoadFail(error)))
      );
    })
  );
}
