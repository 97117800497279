import * as fromShipping from '../actions/shipping.action';
import { ShippingActionTypes } from '../actions/index';
import { ShippingInfo } from '@pages/checkout/models/shipping.model';

export interface ShippingState {
  data: ShippingInfo;
  loaded: boolean;
  loading: boolean;
  updated: boolean;
}

export const initialState: ShippingState = {
  data: {
    first_name: null,
    last_name: null,
    company: null,
    phone_code: null,
    phone: null,
    email: null,
    shipping_id: null,
    delivery_condition: null,
    shipping_date: null,
    shipping_time: null,
    comment: null
  },
  loaded: false,
  loading: false,
  updated: false
};

export function reducer(
  state = initialState,
  action: fromShipping.ShippingAction
): ShippingState {
  switch (action.type) {
    case ShippingActionTypes.ShippingLoad: {
      return {
        ...state,
        loading: true
      };
    }
    case ShippingActionTypes.ShippingLoadSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        data: action.payload
      };
    }
    case ShippingActionTypes.ShippingLoadFail: {
      return {
        data: state.data,
        loading: false,
        loaded: false,
        updated: false
      };
    }

    case ShippingActionTypes.UpdateShipping: {
      return {
        ...state,
        loading: true
      };
    }

    case ShippingActionTypes.UpdateShippingSuccess: {
      return {
        ...state,
        loaded: true,
        loading: false,
        updated: true,
        data: action.payload
      };
    }

    case ShippingActionTypes.UpdateShippingFail: {
      return {
        ...state
      };
    }

    case ShippingActionTypes.ResetShipping: {
      return {
        ...initialState
      };
    }

    default: {
      return state;
    }
  }
}

export const getShipping = (state: ShippingState) => state.data;
export const getShippingLoaded = (state: ShippingState) => state.loaded;
export const getShippingLoading = (state: ShippingState) => state.loading;
export const getShippingUpdated = (state: ShippingState) => state.updated;
