/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./breadcrumbs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../pipes/translate/translate.pipe";
import * as i5 from "../../../core/service/translation.service";
import * as i6 from "@ngrx/store";
import * as i7 from "./breadcrumbs.component";
var styles_BreadcrumbsComponent = [i0.styles];
var RenderType_BreadcrumbsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BreadcrumbsComponent, data: { "animation": [{ type: 7, name: "fadeIn", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "void => *", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "400ms ease" }], options: null }, { type: 1, expr: "* => void", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "400ms ease" }], options: null }], options: {} }] } });
export { RenderType_BreadcrumbsComponent as RenderType_BreadcrumbsComponent };
function View_BreadcrumbsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [">"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [], [[24, "@fadeIn", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i5.TranslationService, i6.Store, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.getBreadcrumbs(_v.context.$implicit); _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = undefined; var currVal_1 = i1.ɵnov(_v, 4).target; var currVal_2 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.context.$implicit.label)); _ck(_v, 5, 0, currVal_4); }); }
function View_BreadcrumbsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "breadcrumbs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "a", [["class", "breadcrumb"], ["routerLink", "/dashboard"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i5.TranslationService, i6.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbsComponent_2)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/dashboard"; _ck(_v, 3, 0, currVal_2); var currVal_4 = _co.items; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("HOME")); _ck(_v, 4, 0, currVal_3); }); }
export function View_BreadcrumbsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "app-breadcrumbs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "base-container inner-floating-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbsComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_BreadcrumbsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-breadcrumbs", [], null, null, null, View_BreadcrumbsComponent_0, RenderType_BreadcrumbsComponent)), i1.ɵdid(1, 114688, null, 0, i7.BreadcrumbsComponent, [i2.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BreadcrumbsComponentNgFactory = i1.ɵccf("app-breadcrumbs", i7.BreadcrumbsComponent, View_BreadcrumbsComponent_Host_0, { items: "items" }, {}, []);
export { BreadcrumbsComponentNgFactory as BreadcrumbsComponentNgFactory };
