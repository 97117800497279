<div *ngIf="data && data.length > 0" class="app-autocomplete" [ngClass]="{'has-label': hasLabel}">
  <ul>
    <li *ngFor="let item of data">
      <hr *ngIf="item.path && data.length > 1" />
      <span class="item"
            (click)="selectItem(item)"
            [routerLink]="item.path ? [item.path] : []"
            [ngClass]="{
              'add-new': item.path,
              'item-active': keySelected === item,
              'color-yellow': item.state == 1 || item.state == 2,
              'color-green': item.state == 3,
              'color-red': item.state == 4
            }">
        <i class="material-icons" *ngIf="item.materialIcon">{{item.materialIcon}}</i>
        <span *ngIf="highlight" [innerHTML]="(item.preValue ? item.preValue + ' - ' : '') + (item.value ? item.value : item)"></span>
        <span *ngIf="!highlight" [innerHTML]="(item.preValue ? item.preValue + ' - ' : '') +(item.value ? item.value : item | translate)"></span>
      </span>
    </li>
  </ul>
</div>
