import { Action } from '@ngrx/store';

import { ISearchHistory } from '@shared/models/search-history.model';

export enum SearchHistoryActionTypes {
  SearchHistoryLoad = '[SearchHistory] Load Search History',
  SearchHistoryLoadFail = '[SearchHistory] Load Search History Fail',
  SearchHistoryLoadSuccess = '[SearchHistory] Load Search History Success',
  ResetSearchHistoryState = '[SearchHistory] Reset State'
}

export class SearchHistoryLoad implements Action {
  readonly type = SearchHistoryActionTypes.SearchHistoryLoad;

  constructor() {}
}

export class SearchHistoryLoadFail implements Action {
  readonly type = SearchHistoryActionTypes.SearchHistoryLoadFail;

  constructor(public payload: any) {}
}

export class SearchHistoryLoadSuccess implements Action {
  readonly type = SearchHistoryActionTypes.SearchHistoryLoadSuccess;

  constructor(public payload: ISearchHistory) {}
}

export class ResetSearchHistoryState implements Action {
  readonly type = SearchHistoryActionTypes.ResetSearchHistoryState;
}

export type SearchHistoryActions =
  | SearchHistoryLoad
  | SearchHistoryLoadFail
  | SearchHistoryLoadSuccess
  | ResetSearchHistoryState;
