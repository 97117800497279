export class User {
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  token: string;
  'is_staff': boolean;
  'is_superuser': boolean;
  'is_active': boolean;
}
