<header>
  <div class="app-header">
    <div class="base-container">
      <div class="brand-logo">
        <a href="/dashboard" routerLink="/dashboard" class="title-icon">
          <img src="assets/images/logo-skuba.png">
        </a>
      </div>
    </div>
  </div>
</header>
