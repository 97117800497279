<div class="app-table">
  <h2 *ngIf="caption">{{caption | translate}}</h2>
  <!-- TODO: add [@fadeInDown] later -->
  <table class="table table-condensed" [ngClass]="{'show-state': stateClass}" *ngIf="isConfigTable">
    <thead tableThead
           class="ant-table-thead"
           [checkbox]="checkbox"
           [actions]="actions"
           [filter]="filter"
           [group]="group"
           [columnMaps]="columnMaps"
           [languageSet]="languageSet$ | async"
           (clickedSort)="clickSort($event)"
           (clickedFilter)="filterOutput()"
           (clickedReset)="resetFilter()">
    </thead>
    <tbody *ngIf="records && records.length > 0">
    <tr *ngFor="let record of records; let i = index" [ngClass]="{'selected-row': this.checkboxStatusArray[i]}" (click)="rowClicked.emit(record)">
      <td class="nz-disable-td" *ngIf="checkbox && checkboxCreated">
        <app-checkbox
          name="{{uuuid}}-{{i}}"
          (changed)="$event ? toggleCheckbox($event, record, i) : null">
        </app-checkbox>
      </td>
      <td class="nz-disable-td" *ngFor="let map of columnMaps" [attr.data-label]="map.header | translate" [ngClass]="map.type">
        <app-table-column-role
          *ngIf="map.type === 'role'"
          [status]="record[map.access(record)]">
        </app-table-column-role>
        <app-status-chip
          *ngIf="map.type === 'status' && record[map.access(record)].type === 'success';"
          [success]="record[map.access(record)].name">
        </app-status-chip>
        <app-status-chip
          *ngIf="map.type === 'status' && record[map.access(record)].type === 'warning';"
          [warning]="record[map.access(record)].name">
        </app-status-chip>
        <app-status-chip
          *ngIf="map.type === 'status' && record[map.access(record)].type === 'error';"
          [error]="record[map.access(record)].name">
        </app-status-chip>
        <img [src]="record[map.access(record)]"
             *ngIf="map.type === 'image' && record[map.access(record)]"
             [ngClass]="{'has-navigate': record.table_navigate && map.navigate}"
             (click)="navigateTo(record.table_navigate, map.navigate)">
        <div *ngIf="map.type === 'title-status'">
          {{record[map.access(record)]}}
          <i class="material-icons corner-icon" nz-tooltip [nzTitle]="titleTemplate" [ngClass]="{
            'star-transparent': record[map.alternativeKeys[0]] === -1,
            'star-black': record[map.alternativeKeys[0]] === 0,
            'star-yellow': record[map.alternativeKeys[0]] === 1,
            'star-blue': record[map.alternativeKeys[0]] === 2,
            'star-green': record[map.alternativeKeys[0]] === 3
          }">star</i>
          <ng-template #titleTemplate>
              <p *ngIf="record[map.alternativeKeys[0]] === 0">
                {{'OUT_OF_STOCK' | translate}}
              </p>
              <p *ngIf="record[map.alternativeKeys[0]] === 1">
                {{'ITEM_LOCATED_IN_VILNIUS' | translate}}
              </p>
              <p *ngIf="record[map.alternativeKeys[0]] === 2">
                {{'ITEM_LOCATED_IN_PERIPHERAL_STORE' | translate}}
              </p>
              <p *ngIf="record[map.alternativeKeys[0]] === 3">
                {{'ITEM_IN_STOCK' | translate}}
              </p>
          </ng-template>
        </div>
        <div
          class="image-placeholder"
          [ngClass]="{'has-navigate': record.table_navigate && map.navigate}"
          (click)="navigateTo(record.table_navigate, map.navigate)"
          *ngIf="map.type === 'image' && !record[map.access(record)]">
          <h4>
            <i class="fas fa-camera"></i>
          </h4>
        </div>

        <span
          *ngIf="(map.type === 'default' || map.type === 'email') && record[map.access(record)]"
          [innerHTML]="record[map.access(record)]"
          [ngClass]="{'has-navigate': record.table_navigate && map.navigate}"
          (click)="navigateTo(record.table_navigate, map.navigate)">
          </span>
          <i class="material-icons column-icon unverified" *ngIf="map.type === 'email' && !record[map.email_verified]">email</i>
      </td>
      <td class="nz-disable-td" *ngIf="actions && group" class="actions-column">
        <div *ngIf="actions">
          <i *ngFor="let action of actions" [ngClass]="action.icon"
             (click)="clickAction(record[action.id], action.type)"></i>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="app-table-content-spinner" *ngIf="(!records || records.length === 0) && loading">
    <i class="fas fa-sync fa-spin" ></i>
  </div>
  <ng-content *ngIf="!isConfigTable"></ng-content>
</div>
