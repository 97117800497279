export enum PageOperation {
  None = 0,
  // Original
  Search_Ogn = 1,
  Search_Skn = 2,
  Search_Kortele = 3,
  Search_Pavadinimas = 4,
  Search_Grupe = 5,
  Search_Pogrupis = 6,
  Search_KilmesSalis = 7,
  Search_Pastaba = 8,
  Search_Sandelys = 9,
  Search_Vieta = 10,
  Search_TiekejoKortele = 11,
  Search_ArchyvineKortele = 12,
  Search_Tiekejas = 13,
  Dashboard = 30,
  TruckCatalogue = 31,
  Catalogue = 32,
  TecDocProductSelection = 33,
  SearchByCar = 34,
  OrderHistory = 36,
  InvoiceDetalization = 37,
  ActiveReservationsList = 38,
  OrderDetalization = 39,
  Group = 40,
  Cart = 41,
  CustomPage = 42,
  AnotherSkn = 43,
  SearchCard = 44,
  Search = 50,
  // SearchOgn = 51, maps to Search_Ogn
  // SearchPavadinimas = 52, maps to Search_Pavadinimas
  // SearchOgnPavadinimas = 53,
  // SearchTecdoc = 54,
  // SearchOgnTecdoc = 55,
  // SearchPavadinimasTecdoc = 56,
  // SearchOgnPavadinimasTecdoc = 57,
  // This range is sum of other
  SearchOgnPavadinimasTecdocSubGroup = 65,
  CartItemExpired = 66,
}
export const UserOperation = {
  TYPE: 0 as PageOperation,
  query: '',
  navigation_id: 0,
  routeOperation: [] as any[],
  shouldPost: false,
};

export interface LogOperation {
  setOperation();
}
