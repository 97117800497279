/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cookies-policy-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/pipes/translate/translate.pipe";
import * as i3 from "../../service/translation.service";
import * as i4 from "@ngrx/store";
import * as i5 from "../../../shared/ui/button/button.component.ngfactory";
import * as i6 from "../../../shared/ui/button/button.component";
import * as i7 from "./cookies-policy-modal.component";
import * as i8 from "@angular/router";
var styles_CookiesPolicyModalComponent = [i0.styles];
var RenderType_CookiesPolicyModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CookiesPolicyModalComponent, data: {} });
export { RenderType_CookiesPolicyModalComponent as RenderType_CookiesPolicyModalComponent };
export function View_CookiesPolicyModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "cookies-policy-modal card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "modal-actions flex-align-center row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-button", [["className", "btn-red"], ["text", "AGREE"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickAgree() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(12, 704512, null, 0, i6.ButtonComponent, [i1.ElementRef], { className: [0, "className"], text: [1, "text"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "app-button", [["className", "btn-transparent"], ["text", "MORE_INFO"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.readMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_ButtonComponent_0, i5.RenderType_ButtonComponent)), i1.ɵdid(15, 704512, null, 0, i6.ButtonComponent, [i1.ElementRef], { className: [0, "className"], text: [1, "text"] }, null)], function (_ck, _v) { var currVal_2 = "btn-red"; var currVal_3 = "AGREE"; _ck(_v, 12, 0, currVal_2, currVal_3); var currVal_4 = "btn-transparent"; var currVal_5 = "MORE_INFO"; _ck(_v, 15, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("COOKIES_POLICY_INFO1")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("COOKIES_POLICY_INFO2")); _ck(_v, 7, 0, currVal_1); }); }
export function View_CookiesPolicyModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cookies-policy-modal", [], null, null, null, View_CookiesPolicyModalComponent_0, RenderType_CookiesPolicyModalComponent)), i1.ɵdid(1, 114688, null, 0, i7.CookiesPolicyModalComponent, [i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CookiesPolicyModalComponentNgFactory = i1.ɵccf("app-cookies-policy-modal", i7.CookiesPolicyModalComponent, View_CookiesPolicyModalComponent_Host_0, {}, { updateStatus: "updateStatus" }, []);
export { CookiesPolicyModalComponentNgFactory as CookiesPolicyModalComponentNgFactory };
