<div class="app-cart-list">
  <div class="cart-list-wrapper shadow-1">
    <div *ngIf="(!showJustOneSum || !showPricesWithVat)" class="list-item">
      <div [ngClass]="{'col': !showJustOneSum}">
        <span>{{'SUM_WO_VAT' | translate :{value: summary.watPrecent} }}</span>
      </div>
      <div [ngClass]="{'col': !showJustOneSum}">
        <strong>{{summary.sum_without_pvm | customCurrency: 2 | async}} <span class="currency">{{"" | currencyName | async}}</span></strong>
      </div>
    </div>
    <div *ngIf="!showJustOneSum" class="list-item">
      <div [ngClass]="{'col': !showJustOneSum}">
        <span>{{'WAT_VAL' | translate :{value: summary.watPrecent} }}</span>
      </div>
      <div [ngClass]="{'col': !showJustOneSum}">
        <strong>{{summary.wat | customCurrency: 2 | async}} <span class="currency">{{"" | currencyName | async}}</span></strong>
      </div>
    </div>
    <div *ngIf="(!showJustOneSum || showPricesWithVat)" class="list-item">
      <div [ngClass]="{'col': !showJustOneSum}">
        <span>{{'SUM' | translate :{value: summary.watPrecent} }}</span>
      </div>
      <div [ngClass]="{'col': !showJustOneSum}">
        <strong>{{summary.sum | customCurrency: 2 | async}} <span class="currency">{{"" | currencyName | async}}</span></strong>
      </div>
    </div>
  </div>
</div>