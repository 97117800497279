import { Injectable, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslationService } from './translation.service';
import { ProductItem } from '@app/shared/models/product.model';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import { Subscription, Observable } from 'rxjs';

@Injectable()
export class PageTitleService implements OnDestroy {
  translations$: Observable<any>;
  translationsSub: Subscription;
  translationSet = false;
  destroyed = false;
  lastCommand = 'NONE';
  lastParameters: any[] = [];
  constructor(
    private titleService: Title,
    private translationService: TranslationService,
    private store: Store<fromRoot.AppState>
  ) {
    this.translations$ = this.store.pipe(select(fromRoot.getTranslations));
    this.translationsSub = this.translations$.subscribe(translations => {
      if (this.destroyed) {
        console.error('TranslatePipe: Running on destroyed component...');
        return;
      }
      if (this.lastCommand !== 'NONE') {
        this[this.lastCommand](...this.lastParameters);
        this.lastCommand = 'NONE';
        this.lastParameters = [];
      }
      // setTimeout(() => {
      //   if (!this.destroyed) {
      //     // console.warn(_ref);
      //     // _ref.detectChanges();
      //     _ref.markForCheck();
      //     // _appRef.tick();
      //   }
      // });
      // setTimeout(() => {
      //   _ref.markForCheck();
      //   // _appRef.tick();
      // });
    });
  }

  subscribeToRoute() {}

  private backupParameters(command: string, parameters: any[]) {
    this.lastCommand = command;
    this.lastParameters = parameters;
  }

  setTitle(route: any) {
    this.backupParameters('setTitle', [route]);
    this.setTitleImplementation(route);
  }
  setProductDetailTitle(product: ProductItem) {
    this.backupParameters('setProductDetailTitle', [product]);
    this.setProductDetailTitleImplementation(product);
  }

  setGroupDetailTitle(title: string, id: string) {
    this.backupParameters('setGroupDetailTitle', [title, id]);
    this.setGroupDetailTitleImplementation(title, id);
  }
  setSearchTitle(title: string) {
    this.backupParameters('setSearchTitle', [title]);
    this.setSearchTitleImplementation(title);
  }
  setCatalogueByCarTitle(number: string, title: string){
    this.backupParameters('setCatalogueByCarTitle', [number, title]);
    this.setCatalogueByCarTitleImplementation(number, title);
  }
  setCatalogueTitle(title: string){
    this.backupParameters('setCatalogueTitle', [title]);
    this.setCatalogueTitleImplementation(title);
  }
  setReservationsTitle(title: string) {
    this.backupParameters('setReservationsTitle', [title]);
    this.setReservationsTitleImplementation(title);
  }

  setTitleImplementation(route: any) {
    if (route.state.data && route.state.data.translationKey) {
      let title = 'Skuba EMS';
      const info = [];
      if (route.state.data.translationKey) {
        info.push(
          this.translationService.translate(route.state.data.translationKey)
        );
      }
      if (route.state.data.paramsKey) {
        info.push(`'${route.state.params[route.state.data.paramsKey]}'`);
      }
      if (info) {
        title = `${title} - ${info.join(' ')}`;
      }
      this.titleService.setTitle(title);
    } else {
      this.titleService.setTitle(`Skuba EMS - ${route.state.url}`);
    }
  }

  setProductDetailTitleImplementation(product: ProductItem) {
    if (product.main.name) {
      this.titleService.setTitle(
        `Skuba EMS - ${this.translationService.translate(
          'SKN_STOCK'
        )} '${this.formatTitle(product.main.name, 10)}' (${product.main.skn})'`
      );
    }
  }

  setGroupDetailTitleImplementation(title: string, id: string) {
    if (title && id) {
      this.titleService.setTitle(
        `Skuba EMS - ${this.translationService.translate(
          'GROUP'
        )} '${this.formatTitle(title, 10)}' (${id})'`
      );
    }
  }
  setSearchTitleImplementation(title: string) {
    if (title) {
      this.titleService.setTitle(
        `Skuba EMS - ${this.translationService.translate(
          'SEARCH'
        )} '${this.formatTitle(title, 10)}'`
      );
    }
  }

  setCatalogueByCarTitleImplementation(number: string, title: string) {
    if (number && title) {
      this.titleService.setTitle(
        `Skuba EMS - ${this.translationService.translate(
          'CATALOGUE'
        )} '${number}' '${this.formatTitle(title, 10)}'`
      );
    }
  }

  setCatalogueTitleImplementation(title: string) {
    if (title) {
      this.titleService.setTitle(
        `Skuba EMS - ${this.translationService.translate(
          'CATALOGUE'
        )} '${this.formatTitle(title, 20)}'`
      );
    }
  }

  setReservationsTitleImplementation(title: string) {
    if (title) {
      this.titleService.setTitle(
        `Skuba EMS - ${this.translationService.translate(
          'RESERVATIONS'
        )}: ${this.formatTitle(title, 10)}`
      );
    }
  }

  formatTitle(title: string, maxLength: number) {
    const translatedTitle = this.translationService.translate(title);
    if (translatedTitle.length > maxLength) {
      return translatedTitle.substring(0, maxLength).trim() + '...';
    }
    return translatedTitle;
  }

  ngOnDestroy() {
    if (this.translationsSub) { this.translationsSub.unsubscribe(); }
    this.destroyed = true;
  }
}
