/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./table-column-role.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../pipes/translate/translate.pipe";
import * as i3 from "../../../../../core/service/translation.service";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/common";
import * as i6 from "./table-column-role.component";
var styles_TableColumnRoleComponent = [i0.styles];
var RenderType_TableColumnRoleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TableColumnRoleComponent, data: {} });
export { RenderType_TableColumnRoleComponent as RenderType_TableColumnRoleComponent };
function View_TableColumnRoleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "i", [["class", "material-icons column-icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["gavel"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("IS_STAFF")); _ck(_v, 4, 0, currVal_0); }); }
function View_TableColumnRoleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i3.TranslationService, i4.Store, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("IS_NOT_STAFF")); _ck(_v, 2, 0, currVal_0); }); }
export function View_TableColumnRoleComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableColumnRoleComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TableColumnRoleComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.status === "true"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.status === "false"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_TableColumnRoleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-table-column-role", [], null, null, null, View_TableColumnRoleComponent_0, RenderType_TableColumnRoleComponent)), i1.ɵdid(1, 114688, null, 0, i6.TableColumnRoleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableColumnRoleComponentNgFactory = i1.ɵccf("app-table-column-role", i6.TableColumnRoleComponent, View_TableColumnRoleComponent_Host_0, { status: "status" }, {}, []);
export { TableColumnRoleComponentNgFactory as TableColumnRoleComponentNgFactory };
