import * as moment from 'moment';

export const DateConstants = {
  DEFAULT_DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
  DEFAULT_DATE: 'YYYY-MM-DD'
};

export const DF = {
  utcToLocal(datetime) {
    return moment.utc(datetime).local().format(DateConstants.DEFAULT_DATE_TIME);
  },
  fromUtc(datetime) {
    return moment.utc(datetime).local();
  }
};
