import {
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  Injectable,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
} from '@angular/forms';

@Injectable()
export abstract class BaseInputComponent implements AfterViewInit, ControlValueAccessor {
  /**
   * Accepts any input type, defaults to `text`
   * @type {string}
   */
  @Input() type = 'text';

  /** Allows custom options (e.g. search input styling) */
  @Input() special: string;

  /** attr.aria-label (optional) */
  @Input() label: string;

  /** Form control name */
  @Input() name: string;

  /**
   * Placeholder (optional
   * @type {string}
   */
  @Input() placeholder = '';

  /** Min value length */
  @Input() minLength: number;

  /**
   * Disabled attribute (optional)
   * @type {boolean}
   */
  @Input() disabledState = false;

  /**
   * Hide input (optional)
   * @type {boolean}
   */
  @Input() hiddenState = false;

  /**
   * Margin bottom (optional)
   * @type {boolean}
   */
  @Input() marginBottom = true;

  /**
   * Default input autocomplete status (optional)
   * @type {boolean}
   */
  @Input() defaultAutocomplete = true;

  /** Autocomplete data from parent (optional) */
  @Input() autocompleteData: any;

  /** Input focus (optional) */
  @Input() focus = false;

  /**
   * Input value reset (optional)
   * @type {boolean}
   */
  @Input() reset = false;

  /**
   * Input highlight status.
   * @type {boolean}
   */
  @Input() highlight = false;

  /** Get native input element ref */
  @ViewChild('input', { static: false }) vc: ElementRef;

  /**
   * Value change event emitter (optional).
   * @type {EventEmitter}
   */
  @Output() readonly autocompleteChange = new EventEmitter<string>();

  /**
   * Value change event emitter (optional).
   * @type {EventEmitter}
   */
  @Output() readonly autocomplete2Change = new EventEmitter<string>();

  /**
   * Value change event emitter (optional).
   * @type {EventEmitter}
   */
  @Output() readonly selectedAutocompleteItemEvent = new EventEmitter();

  @Output() readonly onBlur = new EventEmitter();

  /** Input focus state */
  focusState: boolean;

  /** Input component data */
  data: any = '';

  /** Validate function method */
  validateFn: any = () => {};

  constructor(protected element: ElementRef, protected cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    if (this.focus) {
      setTimeout(() => {
        this.vc.nativeElement.focus();
      }, 100);
    }
  }

  /** Input was clicked */
  focusIn() {
    this.focusState = true;
    if (!this.data) {
      this.autocomplete2Change.emit();
    }
  }

  /**
   * Input focus was removed.
   */
  focusOut() {
    this.focusState = false;
  }

  onBlurEvent(e) {
    this.onBlur.emit(e);
  }

  /**
   * This is the initial value set to the component.
   */
  writeValue(value: any) {
    this.data = value;
  }

  /**
   * Detect when input value changes.
   * @param {string} value
   */
  onValueChange(value: string) {
    this.data = value;
    this.autocompleteChange.emit(value);
  }

  /**
   * Not used, used for touch input.
   */
  registerOnTouched() {}

  /**
   * Registers 'fn' that will be fired when changes are made.
   * This is how we emit the changes back to the form.
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * The method set in registerOnChange to emit changes back to the form
   * @param _
   */
  propagateChange = (_: any) => {};

  /**
   * Validates the form.
   * @param {FormControl} c
   */
  validate(c: FormControl) {
    return this.validateFn(c);
  }

  /**
   * Change events from the input
   * @param event
   */
  onChange(event) {
    this.data = event.target.value;

    this.propagateChange(this.data);
  }

  /**
   * Set input value from autocomplite select.
   * @param {string} item
   */
  setAutocompleteItem(item: string) {
    this.data = item;

    this.propagateChange(this.data);
    this.selectedAutocompleteItemEvent.emit(item);
  }

  /**
   * Call autocomplite when click on input field.
   */
  clickedInside() {
    this.onValueChange(this.data);
  }

  /** Reset input value */
  resetInput() {
    this.onValueChange(null);

    this.propagateChange(this.data);

    setTimeout(() => {
      this.vc.nativeElement.focus();
    }, 100);
  }
}
