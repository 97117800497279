import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError, debounceTime } from 'rxjs/operators';
import { of } from 'rxjs';

import * as ProductOrderHistoryActions from '../actions/product-order-history.action';
import { ProductOrderHistoryActionTypes } from '../actions/product-order-history.action';
import { OrderService } from '@pages/checkout/services/order.service';

@Injectable()
export class ProductOrderHistoryEffects {
  constructor(private actions$: Actions, private orderService: OrderService) {}

  @Effect() loadSubgroups$ = this.actions$.pipe(
    ofType(ProductOrderHistoryActionTypes.ProductOrderHistoryLoad),
    debounceTime(300),
    map(
      (action: ProductOrderHistoryActions.ProductOrderHistoryLoad) =>
        action.payload
    ),
    switchMap(body => {
      return this.orderService.getBySkn(body).pipe(
        map(
          history =>
            new ProductOrderHistoryActions.ProductOrderHistoryLoadSuccess(
              history
            )
        ),
        catchError(error =>
          of(new ProductOrderHistoryActions.ProductOrderHistoryLoadFail(error))
        )
      );
    })
  );
}
