import { Action } from '@ngrx/store';

export enum ShippingActionTypes {
  ShippingEmpty = '[Shipping] Empty Shipping',
  ShippingLoad = '[Shipping] Load Shipping',
  ShippingLoadFail = '[Shipping] Load Shipping Fail',
  ShippingLoadSuccess = '[Shipping] Load Shipping Success',
  UpdateShipping = '[Shipping] Update Shipping',
  UpdateShippingSuccess = '[Shipping] Update Shipping Success',
  UpdateShippingFail = '[Shipping] Update Shipping Fail',
  ResetShipping = '[Shipping] Reset Shipping'
}

/**
 * Load Shipping Actions
 */
export class ShippingEmpty implements Action {
  readonly type = ShippingActionTypes.ShippingEmpty;
}

/**
 * Load Shipping Actions
 */
export class LoadShipping implements Action {
  readonly type = ShippingActionTypes.ShippingLoad;
}

export class LoadShippingSuccess implements Action {
  readonly type = ShippingActionTypes.ShippingLoadSuccess;

  constructor(public payload: any) {}
}

export class LoadShippingFail implements Action {
  readonly type = ShippingActionTypes.ShippingLoadFail;

  constructor(public payload: any) {}
}

/**
 * Update Shipping from Shipping Actions
 */
export class UpdateShipping implements Action {
  readonly type = ShippingActionTypes.UpdateShipping;

  constructor(public payload: any) {}
}

export class UpdateShippingSuccess implements Action {
  readonly type = ShippingActionTypes.UpdateShippingSuccess;

  constructor(public payload: any) {}
}

export class UpdateShippingFail implements Action {
  readonly type = ShippingActionTypes.UpdateShippingFail;

  constructor(public payload: any) {}
}

export class ResetShipping implements Action {
  readonly type = ShippingActionTypes.ResetShipping;
}

export type ShippingAction =
  | LoadShipping
  | LoadShippingSuccess
  | LoadShippingFail
  | UpdateShipping
  | UpdateShippingSuccess
  | UpdateShippingFail
  | ResetShipping;
