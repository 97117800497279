import {
  ContactsActions,
  ContactsActionTypes
} from '../actions/contacts.action';
import { IContact } from '@core/models/contact';

export interface ContactsState {
  entities: IContact[];
  loaded: boolean;
  loading: boolean;
}

const initialState: ContactsState = {
  entities: [],
  loaded: false,
  loading: false
};

export function reducer(
  state = initialState,
  action: ContactsActions
): ContactsState {
  switch (action.type) {
    case ContactsActionTypes.ContactsLoad: {
      return {
        ...state,
        loading: true
      };
    }
    case ContactsActionTypes.ContactsLoadSuccess: {
      return {
        ...state,
        loading: false,
        loaded: true,
        entities: action.payload
      };
    }
    case ContactsActionTypes.ContactsLoadFail: {
      return {
        ...state,
        loading: false,
        loaded: false,
        entities: []
      };
    }

    default: {
      return state;
    }
  }
}

export const getContactsEntities = (state: ContactsState) => state.entities;
export const getContactsLoading = (state: ContactsState) => state.loading;
export const getContactsLoaded = (state: ContactsState) => state.loaded;
