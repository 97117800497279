import { Action } from '@ngrx/store';

export enum SidebarActionTypes {
  OpenSidebar = '[Sidebar] Open Sidebar',
  CloseSidebar = '[Sidebar] Close Sidebar',
  ToggleSidebar = '[Sidebar] Toggle Sidebar',
  ResetSidebarState = '[Sidebar] Reset State'
}

export class OpenSidebar implements Action {
  readonly type = SidebarActionTypes.OpenSidebar;
}

export class CloseSidebar implements Action {
  readonly type = SidebarActionTypes.CloseSidebar;
}

export class ToggleSidebar implements Action {
  readonly type = SidebarActionTypes.ToggleSidebar;

  constructor(public payload: boolean) {}
}

export class ResetSidebarState implements Action {
  readonly type = SidebarActionTypes.ResetSidebarState;
}

export type SidebarActions =
  | OpenSidebar
  | CloseSidebar
  | ToggleSidebar
  | ResetSidebarState;
