import { Action } from '@ngrx/store';

export enum TranslationActionTypes {
  SetNotTranslatedCount = '[Translation] Set Not Translated count',
  SetNotTranslatedCountLoaded = '[Translation] Set Not Translated Finish',
  SetNotTranslatedEmailsCount = '[Translation] Set Not Translated Emails count',
  SetNotTranslatedEmailsCountLoaded = '[Translation] Set Not Translated Emails Finish',
  SetNotApprovedUsersCount = '[Translation] Set Not Approved Users Count',
  SetNotApprovedUsersCountLoaded = '[Translation] Set Not Approved Users Count Finish',
  SetTranslationLanguage = '[Translation] Set Translation language',
  TranslationLoadApi = '[Translation] Load Translation from Api',
  TranslationLoadApiFail = '[Translation] Load Translation from Api Fail',
  TranslationLoadApiSuccess = '[Translation] Load Translation from Api Success',
  TranslationLoad = '[Translation] Load Translation',
  TranslationLoadFail = '[Translation] Load Translation Fail',
  TranslationLoadSuccess = '[Translation] Load Translation Success',
  ResetTranslationState = '[Translation] Reset State'
}

export class SetNotTranslatedCount implements Action {
  readonly type = TranslationActionTypes.SetNotTranslatedCount;

  constructor(public payload?: number) {}
}

export class SetNotTranslatedCountLoaded implements Action {
  readonly type = TranslationActionTypes.SetNotTranslatedCountLoaded;

  constructor(public payload?: number) {}
}

export class SetNotTranslatedEmailsCount implements Action {
  readonly type = TranslationActionTypes.SetNotTranslatedEmailsCount;

  constructor(public payload?: number) {}
}

export class SetNotTranslatedEmailsCountLoaded implements Action {
  readonly type = TranslationActionTypes.SetNotTranslatedEmailsCountLoaded;

  constructor(public payload?: number) {}
}
export class SetNotApprovedUsersCount implements Action {
  readonly type = TranslationActionTypes.SetNotApprovedUsersCount;

  constructor() {}
}

export class SetNotApprovedUsersCountLoaded implements Action {
  readonly type = TranslationActionTypes.SetNotApprovedUsersCountLoaded;

  constructor(public payload?: number) {}
}
export class SetTranslationLanguage implements Action {
  readonly type = TranslationActionTypes.SetTranslationLanguage;

  constructor(public payload?: string) {}
}

export class TranslationLoadApi implements Action {
  readonly type = TranslationActionTypes.TranslationLoadApi;

  constructor(public payload: string) {}
}

export class TranslationLoadApiSuccess implements Action {
  readonly type = TranslationActionTypes.TranslationLoadApiSuccess;

  constructor(public payload: any) {}
}

export class TranslationLoadApiFail implements Action {
  readonly type = TranslationActionTypes.TranslationLoadApiFail;

  constructor(public payload: any) {}
}

export class TranslationLoad implements Action {
  readonly type = TranslationActionTypes.TranslationLoad;

  constructor(public payload: string) {}
}

export class TranslationLoadSuccess implements Action {
  readonly type = TranslationActionTypes.TranslationLoadSuccess;

  constructor(public payload: any) {}
}

export class TranslationLoadFail implements Action {
  readonly type = TranslationActionTypes.TranslationLoadFail;

  constructor(public payload: any) {}
}

export class ResetTranslationState implements Action {
  readonly type = TranslationActionTypes.ResetTranslationState;
}

export type TranslationActions =
  | SetNotTranslatedCount
  | SetNotTranslatedCountLoaded
  | SetNotTranslatedEmailsCount
  | SetNotTranslatedEmailsCountLoaded
  | SetNotApprovedUsersCount
  | SetNotApprovedUsersCountLoaded
  | SetTranslationLanguage
  | TranslationLoadApi
  | TranslationLoadApiSuccess
  | TranslationLoadApiFail
  | TranslationLoad
  | TranslationLoadSuccess
  | TranslationLoadFail
  | ResetTranslationState;
