import { Action } from '@ngrx/store';

import { Country } from '@shared/models/country.model';

export enum CountryActionTypes {
  CountriesLoad = '[Country] Load Countries',
  CountriesLoadFail = '[Country] Load Countries Fail',
  CountriesLoadSuccess = '[Country] Load Countries Success',
  ResetCountriesState = '[Country] Reset State'
}

export class CountriesLoad implements Action {
  readonly type = CountryActionTypes.CountriesLoad;

  constructor() {}
}

export class CountriesLoadFail implements Action {
  readonly type = CountryActionTypes.CountriesLoadFail;

  constructor(public payload: any) {}
}

export class CountriesLoadSuccess implements Action {
  readonly type = CountryActionTypes.CountriesLoadSuccess;

  constructor(public payload: Country[]) {}
}

export class ResetCountriesState implements Action {
  readonly type = CountryActionTypes.ResetCountriesState;
}

export type CountryActions =
  | CountriesLoad
  | CountriesLoadFail
  | CountriesLoadSuccess
  | ResetCountriesState;
