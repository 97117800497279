import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@app/core/api.service';
import { map } from 'rxjs/internal/operators';

@Injectable()
export class ContactsService {
  constructor(private apiService: ApiService) {}

  getContacts(): Observable<any> {
    return this.apiService.getJSON('contacts.json').pipe(map(data => data));
  }
}
