<form *ngIf="products && cartForm && cartForm.controls['products'].value.length > 0"
      [formGroup]="cartForm"
      #formDir="ngForm"
      [ngClass]="{'not-submitted': !formSubmitAttempt}">
  <div class="app-cart-list shadow-1" formGroupName="products">
    <div class="product-row"
         *ngFor="let product of products; let i = index; trackBy: trackByFn"
         [formArrayName]="i"
         [ngClass]="{'active': cartForm.controls['products'].value[i] && cartForm.controls['products'].value[i].checked}">
      <div class="product-info flex-row col product-col">
        <div class="checkbox-col">
          <app-checkbox
          name="checked-{{i}}"
          [formControl]="cartFormProductsFA.controls[i].controls.checked"></app-checkbox>
        </div>
        <div class="product-photo col">
          <img *ngIf="product.picture_url" src="{{base_url}}{{product.picture_url}}?width=100" [alt]="product.name">
        </div>
        <div class="product-description col">
          <h4>{{product.name}} <i class="fas fa-fw" [ngClass]="{'fa-truck': !product.is_carried, 'fa-people-carry': product.is_carried }"
                                  *ngIf="product.on_the_way"
                                  [satPopoverAnchor]="p"
                                  (mouseenter)="p.open()"
                                  (mouseleave)="p.close()"></i>
                                <i class="fas fa-hand-holding-usd fa-fw"
                                  *ngIf="product.deposit > 0"
                                  [satPopoverAnchor]="pp"
                                  (mouseenter)="pp.open()"
                                  (mouseleave)="pp.close()"></i>
            <sat-popover #p horizontalAlign="after">
              <div class="tooltip-wrapper">
                <p *ngIf="!product.is_carried && !product.on_the_way_date">{{'ON_THE_WAY' | translate}}</p>
                <p *ngIf="!product.is_carried && product.on_the_way_date">{{'ON_THE_WAY_W_DATE' | translate:{ date: product.on_the_way_date } }}</p>
                <p *ngIf="product.is_carried">{{'STOCK_CARRY' | translate}}</p>
              </div>
            </sat-popover>
            <sat-popover #pp horizontalAlign="after">
              <div class="tooltip-wrapper">
                <p>
                  {{'ITEM_WITH_DEPOSIT' | translate}}
                  {{'DEPOSIT_VALUE' | translate}}
                  <ng-container *ngIf="(product | productState: 'deposit')">
                    {{((showLocalPrice && product.deposit_wo_vat) || product.deposit_wo_vat_eur) | customCurrency | async}}
                  </ng-container>
                </p>
              </div>
            </sat-popover>
          </h4>
          <div class="product-details">
            <div *ngIf="product.brand" class="detail">
              <p>{{'BRAND' | translate}}: <strong>{{product.brand}}</strong></p>
            </div>
            <div *ngIf="product.card" class="detail">
              <p>{{'CARD_NR' | translate}}: <strong>{{product.card}}</strong></p>
            </div>
            <div *ngIf="product.skn" class="detail">
              <p>{{'SKN' | translate}}: <strong>{{product.skn}}</strong></p>
            </div>
            <div *ngIf="product.sub_group_name" class="detail">
              <p>{{'SUBGROUP' | translate}}: <strong>{{product.sub_group_name}}</strong></p>
            </div>
            <div *ngIf="product.manufacturer_name" class="detail">
              <p>{{'MANUFACTURER' | translate}}: <strong>{{product.manufacturer_name}}</strong></p>
            </div>
            <div *ngIf="product.warehouse_title" class="detail">
              <p>{{'WAREHOUSE' | translate}}: <strong>{{product.warehouse_title}}</strong></p>
            </div>
            <div *ngIf="product.weight" class="detail">
              <p>{{'WEIGHT' | translate}}: <strong>{{product.weight | customNumber : 3 | async}}</strong></p>
            </div>
            <div *ngIf="product.dimensions" class="detail">
              <p>{{'DIMENSION' | translate}}: <strong>{{product.dimensions}}</strong></p>
            </div>
          </div>
          <div class="product-note">
            <app-input
              [formControl]="cartFormProductsFA.controls[i].controls.note"
              placeholder="NOTE"
              label="NOTE">
              <app-input-validation
                className="error"
                message="{{'MAX_SYMBOLS' | translate:{ value: 26 } }}"
                [condition]="cartFormProductsFA.controls[i].controls.note.errors && cartFormProductsFA.controls[i].controls.note.errors.maxlength">
              </app-input-validation>
            </app-input>
              <i class="material-icons save-note"
                 *ngIf="!product.are_clean_field_note"
                 (click)="updateProduct(cartForm.value.products[i], i)">save</i>
            <i class="material-icons saved-note"
               *ngIf="product.are_clean_field_note">check</i>
          </div>
          <div class="product-ndo" *ngIf="product.ndo">
            <span>
              {{'NDO_DELIVERY' | translate}}
            </span>
            <nz-select
              style="width: 170px;"
              nzSize="medium"
              [formControl]="cartFormProductsFA.controls[i].controls.ndo"
            >
              <ng-container *ngIf="product | productState: 'centralStock': user">
                <nz-option *ngFor="let option of noteDeliveryValuesCentral" [nzLabel]="option.label" [nzValue]="option.value" nzShowSearch></nz-option>
              </ng-container>
              <ng-container *ngIf="!(product | productState: 'centralStock': user)">
                <nz-option *ngFor="let option of noteDeliveryValues" [nzLabel]="option.label" [nzValue]="option.value" nzShowSearch></nz-option>
              </ng-container>
           </nz-select>
          </div>
          <div class="product-client-ref" *ngIf="showUserClientRef">
            <span>
              {{'CLIENT' | translate}}
            </span>

            <nz-select
              style="width: 100%;"
              nzSize="medium"
              [formControl]="cartFormProductsFA.controls[i].controls.client_ref_id"
              [nzCustomTemplate]="custom"
            >
              <nz-option nzCustomContent
                *ngFor="let option of userClientRefValues"
                [nzLabel]="option.client.name"
                [nzValue]="option.client.id"
                nzShowSearch>
                <span [title]="option.client.name">
                  {{option.client.skuba_client_id}} - {{option.client.name}}
                </span>
              </nz-option>
            </nz-select>
            <ng-template #custom let-selected>
              {{getUserClientSelectorValue(selected.nzValue)}}
              <!-- <span>{{this.userClientRefObject[selected.nzValue].client.skuba_client_id}} - {{this.userClientRefObject[selected.nzValue].client.name}}</span> -->
            </ng-template>
          </div>
        </div>
      </div>
      <div class="product-count col-sm product-col">
        <div class="flex-row">
          <div class="col-sm">
            <label class="counter-label">{{'QUANTITY' | translate}}</label>
            <div class="counter-input numeric-input">
              <i class="material-icons"
                 (click)="decrementQuantity(cartForm.value.products[i].quantity, i, product)">remove</i>
              <app-input [formControl]="cartFormProductsFA.controls[i].controls.quantity"
                         (keypress)="_keyPress($event)"
                         [marginBottom]="false"
                         (onBlur)="validateQuantity(i, product)"
                         ></app-input>
              <i class="material-icons"
                 (click)="incrementQuantity(cartForm.value.products[i].quantity, i, product)">add</i>
            </div>
          </div>
          <div class="product-price col-sm">
            <label>{{'UNIT_PRICE_WO_WAT' | translate}}</label>
            <span>{{(showLocalPrice && product.price_wo_vat || product.price_wo_vat_eur) || 0.00 | customCurrency | async}} {{"" | currencyName | async}}</span>
          </div>
        </div>
        <div class="flex-row product-price-sum">
          <div class="col">
            <p *ngIf="product.deposit > 0">
              {{'DEPOSIT_VALUE' | translate}} {{(((""| useLocalCurrency | async) && product.deposit_wo_vat * +product.quantity) || product.deposit_wo_vat_eur * +product.quantity) | customCurrency | async}}&nbsp;{{"" | currencyName | async}}
            </p>
            <p>{{'SUM_WO_VAT' | translate}}: {{ (showLocalPrice && (+product.total_price_wo_vat + (+product.deposit_wo_vat * +product.quantity)) || (product.total_price_wo_vat_eur + (+product.deposit_wo_vat_eur * +product.quantity))) || 0.00 | customCurrency | async}} {{"" | currencyName | async}}</p>
            <p class="price-darken">{{'SUM' | translate}}: {{(showLocalPrice && (+product.total_price + (+product.deposit * +product.quantity)) || (+product.total_price_eur + (+product.deposit_eur * +product.quantity))) || 0.00 | customCurrency | async}} {{"" | currencyName | async}}</p>
          </div>
        </div>
      </div>
      <div class="product-actions flex-column product-col">
        <div (click)="removeProduct(product)">
          <i class="material-icons">remove_shopping_cart</i>{{'REMOVE' | translate}}
        </div>
        <div (click)="viewProduct(product.skn)">
          <i class="material-icons">search</i>{{'VIEW' | translate}}
        </div>
        <div (click)="updateProduct(cartForm.value.products[i], i)"
             [ngClass]="{'disabled': product.are_clean_fields && product.are_clean_field_note}">
          <i class="fas fa-save fa-fw"></i>{{'SAVE' | translate}}
        </div>
      </div>
    </div>
  </div>
</form>
