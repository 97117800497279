<div class="app-cart-bar" [@fadeInDown]>
  <div class="cart-bar-wrapper shadow-1">
    <div class="cart-total-products">
      <div class="label">{{'TOTAL_OF_UNITS' | translate}}</div>
      <span>{{total_count}}</span>
    </div>
    <div class="cart-sum">
      <div class="label">{{'CART_SUM_WO_VAT' | translate}}</div>
      <span>{{sum_without_pvm | customCurrency | async}}&nbsp;{{"" | currencyName | async}}</span>
    </div>
  </div>
</div>
