import { Injectable } from '@angular/core';

import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import * as showcaseActions from '../actions/showcase.action';
import { ShowcaseService } from '@pages/dashboard/services/showcase.service';

@Injectable()
export class ShowcaseEffects {
  constructor(
    private actions$: Actions,
    private showcaseService: ShowcaseService
  ) {}

  @Effect() loadShowcase$ = this.actions$.pipe(
    ofType(showcaseActions.ShowcaseActionTypes.ShowcaseLoad),
    mergeMap(() => {
      return this.showcaseService.getShowcases().pipe(
        map(data => new showcaseActions.LoadShowcaseSuccess(data)),
        catchError(error => of(new showcaseActions.LoadShowcaseFail(error)))
      );
    })
  );

  @Effect() loadGroups$ = this.actions$.pipe(
    ofType(showcaseActions.ShowcaseActionTypes.LoadGroups),
    mergeMap(() => {
      return this.showcaseService.getGroups().pipe(
        map(data => new showcaseActions.LoadGroupsSuccess(data)),
        catchError(error => of(new showcaseActions.LoadGroupsFail(error)))
      );
    })
  );
}
