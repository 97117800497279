import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  ElementRef,
  HostListener,
  Input,
  Output
} from '@angular/core';
import { arrowDown, arrowUp, enter } from '@app/shared/models/const.model';

@Component({
  selector: 'app-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputAutocompleteComponent {
  /**
   * Autocomplete data list.
   */
  @Input() data: any;

  /**
   * Value change event emitter (optional).
   * @type {EventEmitter}
   */
  @Output() readonly preselectedItem = new EventEmitter<string>();

  /**
   * Active item on keyboard select.
   * @type {EventEmitter}
   */
  @Output() readonly active = new EventEmitter<string>();

  /**
   * Input label status.
   * @type {boolean}
   */
  @Input() hasLabel = false;

  /**
   * Input highlight status.
   * @type {boolean}
   */
  @Input() highlight = false;

  /**
   * Counter for keyboard select.
   */
  counter = -1;

  /**
   * Element select on key down.
   */
  keySelected = null;

  constructor(private element: ElementRef) {}

  /**
   * Scroll and select on keyboard actions.
   * @param e
   * @returns {boolean}
   */
  @HostListener('document:keydown', ['$event']) onKeyDown(e) {
    const itemDOM = this.element.nativeElement.getElementsByClassName('item');

    if (!this.data) {
      return true;
    }

    // eslint-disable-next-line deprecation/deprecation
    if (e.which === arrowDown || e.keyCode === arrowDown) {
      if (this.counter < this.data.length - 1) {
        this.counter = this.counter + 1;
        this.keySelected = this.data[this.counter];
        try {
          itemDOM[this.counter].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest'
          });
        } catch (error) {
          itemDOM[this.counter].scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    // eslint-disable-next-line deprecation/deprecation
    } else if (e.which === arrowUp || e.keyCode === arrowUp) {
      if (this.counter > 0) {
        this.counter = this.counter - 1;
        this.keySelected = this.data[this.counter];
        try {
          itemDOM[this.counter].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest'
          });
        } catch (error) {
          itemDOM[this.counter].scrollIntoView({
            behavior: 'smooth',
          });
        }
      }
    // eslint-disable-next-line deprecation/deprecation
    } else if (e.which === enter || e.keyCode === enter) {
      if (this.keySelected) {
        e.preventDefault();
        e.stopPropagation();

        this.selectItem(this.keySelected);

        this.counter = -1;
        this.keySelected = null;
      }
    } else {
      this.counter = -1;
      this.keySelected = null;
    }

    return true;
  }


  /**
   * Send selected item to parent and close list by removing data.
   * @param item
   */
  selectItem(item: any) {
    if (!item.path) {
      this.preselectedItem.emit(item.value ? item.value : item);
      this.data = null;
    }
  }
}
