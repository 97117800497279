import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ProductActionTypes } from '../actions/product.action';
import { ProductService } from '@shared/services/product.service';
import { map, switchMap, catchError, filter } from 'rxjs/operators';
import * as ProductActions from '../actions/product.action';
import { UserOperation } from '@app/shared/constants/user-operations';

@Injectable()
export class ProductEffects {
  constructor(private actions$: Actions, private service: ProductService) {}

  @Effect() loadProduct$ = this.actions$.pipe(
    ofType(ProductActionTypes.ProductLoad),
    map((action: ProductActions.ProductLoad) => ({ skn: action.payload, log: action.log })),
    switchMap(({skn, log}) => {
      let info = '';
      if (UserOperation.shouldPost && log) {
        info = JSON.stringify({
          id: UserOperation.TYPE,
          query: UserOperation.query,
        });
      }
      return this.service.getProductWithInfo(skn, info).pipe(
        map(product => {
          if (product && product.stock && product.stock.length) {
            product.stock.forEach(item => {
              Object.keys(item).forEach(key => {
                if (key.includes('price') && typeof item[key] === 'number') {
                  item[key] = item[key].toFixed(2);
                }
              });
            });
          }
          if (product && product.stock && product.stock.length) {
            product.stock
              .sort((a, b) => a['quantity'] - b['quantity'])
              .reverse();
            product.stock.sort((a, b) => a['is_central'] - b['is_central']);
          }

          return new ProductActions.ProductLoadSuccess(product);
        }),
        catchError(error => of(new ProductActions.ProductLoadFail(error, 'Load')))
      );
    })
  );

  @Effect() extendProduct$ = this.actions$.pipe(
    ofType(ProductActionTypes.ProductExtend),
    map((action: ProductActions.ProductExtend) => ({ stock: action.payload, updateQuantity: action.updateQuantity, skn: action.skn })),
    switchMap(extendData => {
      // if (extendData.stock.card_number === 0) {
      //   return of(new ProductActions.ProductLoadFail('CARD_NOT_FOUND', 'Extend', extendData.stock, extendData.updateQuantity));
      // }
      return this.service
        .getProductPrice(
          extendData.stock.card_number,
          extendData.stock.warehouse || extendData.stock.warehouse_code,
          extendData.updateQuantity ? extendData.stock.is_central: true,
          extendData.stock.brand,
          extendData.skn,
          extendData.stock.deposit,
          extendData.stock.on_the_way,
          extendData.updateQuantity
        )
        .pipe(
          map(fullStock => {
            return new ProductActions.ProductExtendSuccess(fullStock, extendData.stock.card_number === 0);
          }),
          catchError(error => of(new ProductActions.ProductLoadFail(error, 'Extend', extendData.stock, extendData.updateQuantity)))
        );
    })
  );
}
