import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError, EMPTY } from 'rxjs';
import * as toast from '@app/core/store/actions/toast.action';
import { AppState } from '@app/reducers';
import { Store } from '@ngrx/store';
import * as fromAuth from '@app/auth/store';
import { tap, catchError } from 'rxjs/internal/operators';
import { TranslationService } from '@core/service/translation.service';
import { LocalStorageService } from '@core/local-storage/local-storage.service';
import { SearchByCarService } from '@shared/services/search-by-car.service';
import * as fromHelper from '@core/store/actions/helper.action';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private translationService: TranslationService,
    private localStorageService: LocalStorageService,
    private _injector: Injector,
    private store: Store<AppState>,
    private searchByCarService: SearchByCarService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        event => {},
        error => {
          let errMsg = '';
          if (
            req.body && req.body._ems_silenced ||
            (typeof req.body === 'string' && req.body.includes('_ems_silenced":true')) ||
            (typeof req.body === 'string' && req.body.includes('_ems_silenced":"true')) ||
            (req.params.has('_ems_silenced') && req.params.get('_ems_silenced') === 'true')
          ) {
            console.log('The request error was silenced.');
            return;
          }
          if (error instanceof HttpErrorResponse) {
            let skipReport = false;
            if (error.status === 0) {
              skipReport = true;
              return this.store.dispatch(
                new toast.ShowErrorToast(this.translationService.translate('INTERNET_ERROR_DETAIL'), skipReport)
              );
            } else if (
              error.status === 500 ||
              error.status === 502 ||
              error.status === 504) {
              return this.store.dispatch(
                new toast.ShowErrorToast(
                  this.translationService.translate('INTERNAL_ERROR')
                )
              );
            } else if (error.status === 429) {
              return this.store.dispatch(
                new toast.ShowWarningToast(
                  this.translationService.translate('RATE_LIMIT_WARNING')
                )
              );
            } else if (error.status === 404) {
              skipReport = true;
              if (
                error.error.detail && (error.error.detail === 'PLATE_NUMBER_NOT_FOUND')
              ) {
                // this.searchByCarService.emitChange({
                //   found: false,
                //   reload: false,
                //   state: 0,
                //   value: error.error.number.toString().toUpperCase()
                // });
                return;
              } else {
                return this.store.dispatch(
                  new toast.ShowErrorToast(
                    this.translationService.translate(
                      (error.error.detail === undefined || !error.error.detail)
                        ? 'NOT_FOUND'
                        : error.error.detail
                    ), skipReport
                  )
                );
              }
            } else if (error.status === 1) {
              skipReport = true;
            } else if (error.status === 403) {
              skipReport = true;
              if (error.error.detail && error.error.detail === 'RESERVATION_FORBIDDEN') {
                return this.store.dispatch(
                  new toast.ShowWarningToast(
                    this.translationService.translate('RESERVATION_FORBIDDEN')
                  )
                );
              }
            } else if (error.status === 401) {
              skipReport = true;
              this.localStorageService.clearItem('tecdoc_options');
              this.localStorageService.clearItem('id_token');
              this.store.dispatch(new fromAuth.Logout());
            }
            if (error.error.detail) {
              this.store.dispatch(new toast.ShowErrorToast(error.error.detail, skipReport));
              // todo translations future
              if (error.error.detail === 'Blocked IP') {
                this.store.dispatch(new fromAuth.Logout());
              }
            } else if (Object.keys(error.error).length) {
              Object.keys(error.error).forEach(key => {
                if (
                  error.error[key] &&
                  error.error[key].length &&
                  typeof error.error[key] === 'object'
                ) {
                  error.error[key].forEach(message => {
                    this.store.dispatch(
                      new toast.ShowErrorToast(
                        this.translationService.translate(message),
                        skipReport,
                      )
                    );
                  });
                } else {
                  this.store.dispatch(
                    new toast.ShowErrorToast(
                      this.translationService.translate(error.error),
                      skipReport,
                    )
                  );
                }
              });
            } else if (error.message) {
              this.store.dispatch(
                new toast.ShowErrorToast(
                  this.translationService.translate(error.message),
                  skipReport,
                )
              );
            } else {
              console.warn('err 1');
              errMsg = `${error.statusText || 'Network error'}`;

              this.store.dispatch(new toast.ShowErrorToast(errMsg, skipReport));
            }
          } else {
            console.warn('err 0');
            errMsg = error.message ? error.message : error.toString();

            this.store.dispatch(new toast.ShowErrorToast(errMsg));
          }
        }
      ),
      catchError((error: any) => {
        let shouldConsumeError = false;
        if (error instanceof HttpErrorResponse) {
          if (error.status === 0 ||
            error.status === 1 ||
            error.status === 401 ||
            error.status === 502 ||
            error.status === 504) {
            shouldConsumeError = true;
          }
        }
        if (shouldConsumeError) {
          return EMPTY;
        }
        return throwError(error);
      }),
    );
  }
}
