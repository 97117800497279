var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import * as Sentry from '@sentry/browser';
import { throwError, } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { ApiService } from '@core/api.service';
import { map, tap } from 'rxjs/internal/operators';
import { DecoratorsService } from '@app/core/develop/decorators';
export class AuthService {
    constructor(http, localStorageService, store, apiService) {
        this.http = http;
        this.localStorageService = localStorageService;
        this.store = store;
        this.apiService = apiService;
    }
    login({ username, password }, silenced = false) {
        return this.http
            .post(environment.api_url + '/auth/token-auth/', {
            username,
            password,
            _ems_silenced: silenced,
        })
            .pipe(tap((res) => this.setSession(res.token)), catchError((error) => {
            return throwError(error);
        }));
    }
    LoadUser() {
        const token = this.localStorageService.getItem('id_token');
        if (token) {
            return this.http
                .get(environment.api_url + '/auth/me/')
                .pipe(tap((user) => {
                if (user.profile) {
                    user.profile.is_user_interface_active = user.profile.status === 1 && !user.profile.force_reset_password;
                }
                Sentry.setUser({
                    id: user.id,
                    email: user.email,
                    username: user.username,
                    fid_name: user.profile ? user.profile.company.name : 'Not loaded',
                    fid: user.profile ? user.profile.company.skuba_company_id : 'Not loaded',
                });
            }), catchError((error) => throwError(error.json())));
        }
        else {
            return throwError('not logged in');
        }
    }
    updateUser(body) {
        return this.apiService
            .post('/auth/me/change/', body)
            .pipe(tap((user) => {
            if (user.profile) {
                user.profile.is_user_interface_active = user.profile.status === 1 && !user.profile.force_reset_password;
            }
        }, catchError((error) => throwError(error.json()))));
    }
    forcePasswordReset(new_password1, new_password2) {
        return this.apiService
            .post(`/auth/password/force_password_reset/`, {
            pass_not_same_check: '',
            new_password1,
            new_password2
        }, true)
            .pipe(map(data => data), catchError((error) => {
            if (error instanceof HttpErrorResponse) {
                return throwError(this.getFirstError(error));
            }
            return throwError(error);
        }));
    }
    changePassword(old_password, new_password1, new_password2) {
        return this.apiService
            .post(`/auth/password/change_password/`, {
            old_password,
            new_password1,
            new_password2
        }, true)
            .pipe(map(data => data), catchError((error) => {
            if (error instanceof HttpErrorResponse) {
                return throwError(this.getFirstError(error));
            }
            return throwError(error);
        }));
    }
    getFirstError(error) {
        let errMsg = '';
        let firstError = Object.keys(error.error).map(k => error.error[k])[0];
        if (firstError && firstError.length > 0) {
            firstError = firstError[0];
        }
        if (firstError) {
            errMsg = firstError;
        }
        else {
            errMsg = JSON.stringify(error.error);
        }
        return errMsg;
    }
    forgotPassword(body) {
        return this.apiService
            .post(`/auth/password/forgot_password/`, body)
            .pipe(map(data => data));
    }
    forgotUsername(body) {
        return this.apiService
            .post(`/auth/password/forgot_username/`, body)
            .pipe(map(data => data));
    }
    forgotPasswordReset(body) {
        return this.apiService
            .post(`/auth/password/forgot_password_reset/`, body, true)
            .pipe(map(data => data));
    }
    checkToken(silenced = false) {
        const token = this.localStorageService.getItem('id_token');
        if (!token) {
            return throwError('not logged in');
        }
        return this.http.post(environment.api_url + '/auth/token-verify/', {
            token: token,
            _ems_silenced: silenced,
        });
    }
    verifyEmail(body) {
        return this.apiService
            .post(`/email/verification/verify_email/`, body)
            .pipe(map(data => data));
    }
    resendVerificationEmail(obj = {}) {
        return this.apiService
            .post(`/email/verification/resend_verification_email/`, obj)
            .pipe(map(data => data));
    }
    setSession(authResult) {
        // const expiresAt = authResult.expiresIn;
        this.localStorageService.setItem('id_token', authResult);
        const data = this.getDataFromToken(authResult);
        this.localStorageService.setItem('expires_at', JSON.stringify(data.exp.valueOf()));
    }
    urlBase64Decode(str) {
        let output = str.replace('-', '+').replace('_', '/');
        switch (output.length % 4) {
            case 0:
                break;
            case 2:
                output += '==';
                break;
            case 3:
                output += '=';
                break;
            default:
                throw new Error('Illegal base64url string!');
        }
        return window.atob(output);
    }
    getDataFromToken(token) {
        let data = {};
        if (typeof token !== 'undefined') {
            const encoded = token.split('.')[1];
            data = JSON.parse(this.urlBase64Decode(encoded));
        }
        return data;
    }
}
__decorate([
    DecoratorsService.DevCache(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], AuthService.prototype, "LoadUser", null);
__decorate([
    DecoratorsService.DevCache(),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [Object]),
    __metadata("design:returntype", void 0)
], AuthService.prototype, "checkToken", null);
