var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ChangeDetectorRef, OnInit, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import * as fromRoot from '@app/reducers';
import { SearchService } from '@shared/services/search.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Store, select, ActionsSubject } from '@ngrx/store';
import * as fromAuth from '@app/auth/store';
import { SubGroupsLoad } from '@core/store/actions/subGroup.action';
import { takeUntil } from 'rxjs/operators';
import * as TranslationActions from '@core/store/actions/translation.action';
import { NzMenuDirective } from 'ng-zorro-antd';
import * as Sentry from '@sentry/browser';
import { ofType } from '@ngrx/effects';
import { CartActionTypes } from '@pages/cart/store';
import { HelperService } from '@app/shared/services/helper.service';
import { SkubaCompanyCodeEnum } from '@app/shared/models/company.model';
import { UserOperation } from '@app/shared/constants/user-operations';
class SearchRouteState {
}
export class NavigationComponent {
    constructor(cdRef, router, searchService, store, actionsSubject, helperService) {
        this.cdRef = cdRef;
        this.router = router;
        this.searchService = searchService;
        this.store = store;
        this.actionsSubject = actionsSubject;
        this.helperService = helperService;
        this.headerStatus = false;
        this.dropdownHeader = '';
        this.cartMiniShowUp = false;
        this.cartMiniMaxHeight = false;
        this.cartMiniDisplay = false;
        this.selectOpen = false;
        this.cartIsOpen = false;
        this.totalQuantity = 0;
        this.displaySearchByCar = false;
        this.adminMenuOpen = false;
        this.adminMenuItemsRendered = true;
        this.openMenu = new EventEmitter();
        this.isLoadingCart = false;
        this.translationLink = {
            id: 0,
            label: 'TRANSLATIONS',
            path: '/translations/translations',
            display: true,
            iconFA: true,
            icon: 'fa-globe',
            showBadge: true,
            badgeCount: 0
        };
        this.largeTextsLink = {
            id: 0,
            label: 'LARGE_TEXTS',
            path: '/translations/large-text-editor',
            display: true,
            iconFA: true,
            icon: 'fa-globe',
            showBadge: true,
            badgeCount: 0
        };
        this.emailTranslationsLink = {
            id: 0,
            label: 'EMAIL_TRANSLATIONS',
            path: '/translations/email-translations',
            display: true,
            iconFA: true,
            icon: 'fa-globe',
            showBadge: true,
            badgeCount: 0
        };
        this.customEmailLink = {
            id: 0,
            label: 'CUSTOM_EMAILS',
            path: '/custom-emails',
            display: true,
            iconFA: true,
            icon: 'fa-envelope',
            showBadge: true,
            badgeCount: 0
        };
        this.bannersEditLink = {
            id: 0,
            label: 'MANAGE_BANNERS',
            path: '/banners/manage',
            display: false,
            iconFA: true,
            icon: 'fa-ad',
            showBadge: false,
            badgeCount: 0
        };
        this.adminPanelLink = {
            id: 2,
            label: 'ADMIN_PANEL',
            path: '/admin',
            display: false,
            icon: 'fa-tachometer-alt',
            iconFA: true
        };
        this.usersLink = {
            id: 3,
            label: 'USERS',
            path: '/users',
            display: false,
            iconFA: true,
            icon: 'fa-users'
        };
        this.settingsLink = {
            id: 0,
            label: 'SETTINGS',
            path: '/profile/user',
            icon: 'fa-cog',
            display: false
        };
        this.addressesLink = {
            id: 0,
            label: 'ADDRESS_BOOK',
            path: '/profile/addresses',
            icon: 'fa-map-marked-alt',
            display: false
        };
        this.carLink = {
            id: 0,
            label: 'CARS',
            path: '/profile/cars',
            icon: 'svg-icon flaticon-frontal-truck',
            display: false,
        };
        this.logoutLink = {
            id: 0,
            label: 'LOGOUT',
            path: '/logout',
            icon: 'fa-sign-out-alt',
            display: true
        };
        this.adminPanelLinks = [
            this.adminPanelLink,
            this.usersLink,
            this.translationLink,
            this.largeTextsLink,
            this.emailTranslationsLink,
            this.customEmailLink,
            this.bannersEditLink,
        ];
        this.logoSettings = {
            normal: true,
            christmas: false
        };
        /**
         * Menu links to app pages.
         * @type {{id: number; label: string; path: string; icon: string}[]}
         */
        this.navLinks = [
            {
                id: 3,
                label: 'CATALOGUE',
                path: '/catalogue',
                display: true,
                iconFA: true,
                icon: 'fa-layer-group'
            },
            {
                id: 4,
                label: '',
                path: '/tecdoc/car-selection',
                display: true,
                image: true,
                imageSrc: 'assets/images/TecDoc_ea14d_52x15.png'
            },
            {
                id: 8,
                label: 'SEARCH_BY_CAR',
                path: '/search/by_car',
                display: false,
                iconFA: false,
                icon: 'svg-icon flaticon-frontal-truck'
            },
            {
                id: 5,
                label: 'ORDER_HISTORY',
                path: '/profile/order-history',
                display: true,
                iconFA: true,
                icon: 'fa-history',
            },
            {
                id: 7,
                label: 'INVOICES',
                path: '/profile/invoices',
                display: false,
                iconFA: true,
                icon: 'fa-file-invoice',
            },
            {
                id: 14,
                label: 'CAT_S_MERCHANDISE_MENU',
                path: '/catalogue/PGR15',
                display: true,
                iconFA: false,
                icon: '',
                image: true,
                imageClass: 's-merchandise',
                imageSrc: 'assets/images/s-icon.png'
            },
            // {
            //   id: 14,
            //   label: '',
            //   path: '/catalogue/PGR15',
            //   display: true,
            //   image: true,
            //   imageClass: 's-atributika',
            //   imageSrc: 'assets/images/s-atributika_89x16.png'
            // },
            { id: 9, label: 'PAGES', path: '/pages', display: false, icon: 'language' }
            // { id: 10, label: 'TEST_1_1', path: '/test/1/1', display: true },
            // { id: 11, label: 'TEST_1_100', path: '/test/1/100', display: true },
            // { id: 12, label: 'TEST_1_5000', path: '/test/1/5000', display: true },
            // { id: 13, label: 'TEST_3_10', path: '/test/3/10', display: true },
        ];
        this.destroyed$ = new Subject();
        this.showCartMiniAndLanguage = true;
        /** Search navigation state */
        this.searchState = false;
        this.searchStateSettings = false;
        this.router$ = this.store.pipe(select(fromRoot.getRouterState));
        this.user$ = this.store.pipe(select(fromAuth.getUser));
        this.searchIconVisible$ = this.store.pipe(select(fromRoot.getShowSearchIcon));
        this.translationsCountNum$ = this.store.pipe(select(fromRoot.getNotTranslatedKeysCount));
        this.translationsEmailsCountNum$ = this.store.pipe(select(fromRoot.getNotTranslatedEmailsKeysCount));
        this.translationsNotApprovedUsersCountNum$ = this.store.pipe(select(fromRoot.getNotApprovedUsersCount));
        this.translationLink.badgeCount = 0;
        this.translationsCountNum$.subscribe(count => {
            this.translationLink.badgeCount = count;
        });
        this.translationsEmailsCountNum$.subscribe(count => {
            this.emailTranslationsLink.badgeCount = count;
        });
        this.translationsNotApprovedUsersCountNum$.subscribe(count => {
            this.usersLink.badgeCount = count;
        });
        this.cartState$ = this.store.pipe(select(fromRoot.getCartState));
        if (this.helperService.isChristmasWeeks()) {
            this.logoSettings.normal = false;
            this.logoSettings.christmas = true;
        }
    }
    ngOnInit() {
        this.cart$ = this.store.pipe(select(fromRoot.getCartProducts));
        this.cartSub = this.cart$.subscribe(products => {
            this.totalQuantity = 0;
            if (products.cart && products.cart.items) {
                products.cart.items.map(product => {
                    this.totalQuantity = this.totalQuantity + product.quantity;
                });
            }
            if (this.totalQuantity === 0) {
                this.cartMiniShowUp = false;
                this.cartMiniDisplay = false;
            }
        });
        this.routerSub = this.router$.subscribe((route) => __awaiter(this, void 0, void 0, function* () {
            // Workaround for not removing class
            if (this.adminPanelSubmenu && this.adminPanelSubmenu['elementRef'] && this.adminPanelSubmenu['elementRef'].nativeElement) {
                this.adminPanelSubmenu['elementRef'].nativeElement.classList.remove('ant-menu-submenu-selected');
            }
            if (route.state.url.startsWith('/cart')) {
                this.cartIsOpen = true;
            }
            else {
                this.cartIsOpen = false;
            }
            if (route.state.url.substring(0, 24) === '/search/by_car_catalogue') {
                this.displaySearchByCar = true;
            }
            else if (route.state.url.substring(0, 14) === '/search/by_car') {
                this.displaySearchByCar = true;
            }
            else {
                this.displaySearchByCar = false;
            }
        }));
        this.getUserInfo();
        this.store.dispatch(new TranslationActions.SetNotTranslatedCount(0));
        this.store.dispatch(new TranslationActions.SetNotTranslatedEmailsCount(0));
        this.store.dispatch(new TranslationActions.SetNotApprovedUsersCount());
        this.cartStateSub = this.cartState$.subscribe(state => {
            if (state.loading && !state.updated && !this.isLoadingCart) {
                this.isLoadingCart = true;
                if (this.cartLoadTimeoutHandler) {
                    clearTimeout(this.cartLoadTimeoutHandler);
                }
                this.cartLoadTimeoutHandler = setTimeout(() => {
                    this.isLoadingCart = false;
                    Sentry.captureMessage(`Error occurred while getting cart state, timeout initiated.`);
                }, 30000);
            }
            else if (!state.loading && state.updated && this.isLoadingCart) {
                this.isLoadingCart = false;
                if (this.cartLoadTimeoutHandler) {
                    clearTimeout(this.cartLoadTimeoutHandler);
                }
            }
        }, error1 => {
            this.isLoadingCart = false;
            if (this.cartLoadTimeoutHandler) {
                clearTimeout(this.cartLoadTimeoutHandler);
            }
            Sentry.captureMessage(`Error occurred while getting cart state: ${error1}`);
        });
        this.actionsSubject.pipe(ofType(CartActionTypes.AddProductFail), takeUntil(this.destroyed$)).subscribe(data => this.hideSpinner(data));
        this.actionsSubject.pipe(ofType(CartActionTypes.UpdateProductFail), takeUntil(this.destroyed$)).subscribe(data => this.hideSpinner(data));
        this.screenSizeMediaListener = window.matchMedia("(max-width: 1350px)");
        this.screenSizeMediaCallbackFix = this.screenSize1350MediaCallback(this);
        this.screenSizeMediaListener.addListener(this.screenSizeMediaCallbackFix);
        this.screenSizeMediaCallbackFix(this.screenSizeMediaListener);
    }
    hideSpinner(data) {
        this.isLoadingCart = false;
        if (this.cartLoadTimeoutHandler) {
            clearTimeout(this.cartLoadTimeoutHandler);
        }
    }
    /**
     * Get user info and form header for profile drop-down.
     */
    getUserInfo() {
        if (this.userSub) {
            this.userSub.unsubscribe();
        }
        this.userSub = this.user$.subscribe(user => {
            if (user) {
                this.user = user;
                const name = `${user.first_name} ${user.last_name}`;
                const username = `${user.username}`;
                const roleIcon = '<i class="material-icons">star</i>';
                let role = '';
                if (user.is_staff) {
                    role += roleIcon;
                }
                if (user.is_superuser) {
                    role += roleIcon;
                }
                this.dropdownHeader = `${name} <br> ${username} ${role}`;
                this.menuItemsRights(user);
                if (user.profile && user.profile.company && user.profile.company.enable_context_requests) {
                    UserOperation.shouldPost = true;
                }
                else {
                    UserOperation.shouldPost = false;
                }
                if (user.profile && user.profile.is_user_interface_active) {
                    this.store.dispatch(new SubGroupsLoad());
                }
            }
            else {
                this.user = null;
                UserOperation.shouldPost = false;
            }
        });
    }
    menuItemsRights(user) {
        if (!user.profile.is_user_interface_active) {
            this.settingsLink.display = true;
            // this.addressesLink.display = true;
        }
        if (!user.profile.is_user_interface_active || !user.user_settings.enable_search_by_client_car) {
            this.carLink.display = true;
        }
        if (user.is_staff) {
            this.usersLink.display = true;
            if (user.profile.company.skuba_company_id === SkubaCompanyCodeEnum.Slovakia && typeof (this.usersLink.path) === 'string') {
                this.usersLink.path = [this.usersLink.path, { shop_id: user.profile.shop.id }];
            }
            // We need to call this as this somehow disrupts change detection on NzSubmenu
            setTimeout(() => {
                this.adminPanelLink.display = true;
            });
        }
        this.navLinks.map((link, index) => {
            // Invoices available
            if (user.user_settings.enable_invoices && link.id === 7) {
                link.display = true;
            }
            // Search by car enabled
            if (user.user_settings.enable_search_by_client_car && link.id === 8) {
                link.display = true;
            }
        });
    }
    deselectNavigationMenu() {
        // console.warn('ok');
        // this.menuContainer.listOfNzMenuItemDirective.forEach(mi => mi.setSelectedState(false));
    }
    /** Change search menu state */
    onSearchClicked() {
        this.searchService.toggleSearch(this.searchNavStatus);
    }
    /** Get current search menu state */
    getSearchStatus() {
        this.searchService.currentSearchStatus.subscribe(status => {
            this.searchNavStatus = status;
        });
    }
    /**
     * Get search navigation state from child.
     * @param {boolean} state
     */
    setSearchClass(state) {
        this.searchState = state.navStatus;
        this.searchStateSettings = state.isSearchWithSettings;
    }
    /** Go to search route */
    navigateCart() {
        this.router.navigate(['/cart']);
    }
    mouseEnter() {
        clearTimeout(this.cartMiniTimeout);
        clearTimeout(this.cartMiniDisplayTimeout);
        this.cartMiniShowUp = true;
        this.cartMiniDisplay = true;
        setTimeout(() => {
            this.cartMiniMaxHeight = true;
        });
    }
    mouseLeave() {
        this.cartMiniCheck();
    }
    selecting(selectOpen) {
        this.selectOpen = selectOpen;
        this.cartMiniCheck();
    }
    cartMiniCheck() {
        if (!this.selectOpen) {
            const timeOut = 500;
            this.cartMiniTimeout = setTimeout(() => {
                this.cartMiniShowUp = false;
                setTimeout(() => {
                    this.cartMiniMaxHeight = false;
                });
                this.cartMiniDisplayTimeout = setTimeout(() => {
                    this.cartMiniDisplay = false;
                }, timeOut);
            }, timeOut);
        }
    }
    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
        if (this.routerSub) {
            this.routerSub.unsubscribe();
        }
        if (this.userSub) {
            this.userSub.unsubscribe();
        }
        if (this.cartSub) {
            this.cartSub.unsubscribe();
        }
        if (this.cartStateSub) {
            this.cartStateSub.unsubscribe();
        }
        this.screenSizeMediaListener.removeListener(this.screenSizeMediaCallbackFix);
    }
    ngAfterViewInit() {
        this.cdRef.detectChanges();
    }
    workaroundAdminMenuBadges() {
        if (!this.adminMenuOpen) {
            setTimeout(() => {
                this.adminMenuItemsRendered = false;
                setTimeout(() => {
                    this.adminMenuItemsRendered = true;
                });
            }, 500);
        }
    }
    screenSize1350MediaCallback(that) {
        return function (ev) {
            if (ev.matches) {
                // console.log('Screen is small.');
                that.showCartMiniAndLanguage = false;
            }
            else {
                // console.log('Screen is big.');
                that.showCartMiniAndLanguage = true;
            }
            that.cdRef.detectChanges();
        };
    }
}
