import { Action } from '@ngrx/store';

export enum HelperActionTypes {
  VersionErrorModal = '[Helper] Show Version Error Modal',
  HideVersionErrorModal = '[Helper] Hide Version Error Modal',
}

export class VersionErrorModal implements Action {
  readonly type = HelperActionTypes.VersionErrorModal;

  constructor() {}
}

export class HideVersionErrorModal implements Action {
  readonly type = HelperActionTypes.HideVersionErrorModal;

  constructor() {}
}

export type HelperActions = VersionErrorModal | HideVersionErrorModal;
