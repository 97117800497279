<button
  class="btn waves-effect waves-light"
  [ngStyle]="buttonStyle ? buttonStyle : null"
  [disabled]="disabled"
  [attr.type]="type ? type : null"
  [class.active]="active"
  [class.active-clickable-yellow]="activeClickableYellow"
  [ngClass]="{'btn-icon': ((icon || materialIcon) && !text), 'btn-icon-big': iconBig}"
  [class.btn-small]="className === 'btn-small'"
  [class.btn-small-yellow]="className === 'btn-small-yellow'"
  [class.btn-xsmall]="className === 'btn-xsmall'"
  [class.btn-r-small]="className === 'btn-r-small'"
  [class.btn-red]="className === 'btn-red'"
  [class.btn-heather]="className === 'btn-heather'"
  [class.btn-white]="className === 'btn-white'"
  [class.btn-small-white]="className === 'btn-small-white'"
  [class.btn-disabled]="className === 'btn-disabled'"
  [class.btn-transparent]="className === 'btn-transparent'"
  [class.btn-transparent-text]="className === 'btn-transparent-text'">
  <span class="button-text">
    <i *ngIf="icon" [ngClass]="icon"></i>
    <i 
      *ngIf="materialIcon"
      class="material-icons"
      [style.font-size]="className === 'btn-small-yellow' ? '14px' : ''"
    >{{materialIcon}}</i>
    {{text | translate}}
  </span>
</button>
