<div class="footer-contacts" [@fadeIn] *ngIf="contacts">
  <div class="col">
    <div class="contacts-container row">
      <ng-container *ngFor="let contact of contacts">
        <div class="contact-box col" *ngIf="(contact.country | uppercase) === (currentLanguage | uppercase)">
          <h4>
            <a [href]="contact.home_url" target="_blank">{{contact.name}}</a>
          </h4>
          <p class="contacts-name" *ngIf="contact.latitude && contact.longitude">
            <a href="https://www.google.com/maps/?q={{contact.latitude}},{{contact.longitude}}" target="_blank">
              <i class="fas fa-map-marked-alt"></i>
              {{contact.address}}, {{contact.city}}
            </a>
          </p>
          <p class="contacts-name" *ngIf="!contact.latitude && !contact.longitude">
            {{contact.address}}, {{contact.city}}
          </p>
          <p *ngIf="contact.email">
            <a href="mailto:{{contact.email}}">{{contact.email}}</a>
          </p>
          <div>
            <p *ngFor="let work_hours of fieldSplit(contact.work_hours)">
              {{work_hours}}
            </p>
          </div>
          <div>
            <p *ngFor="let phone of phoneSplit(contact.phone)">
              <a href="tel:{{phone}}">{{phone}}</a>
            </p>
          </div>
          <p *ngIf="contact.service">{{'TRUCK_SERVICE' | translate}}</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>
