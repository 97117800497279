import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input, AfterViewInit } from '@angular/core';
import { fadeInDown, fadeIn } from '@shared/animations';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '@app/reducers';
import * as fromHelper from '@core/store/actions/helper.action';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-version-error',
  template: `
    <nz-modal
      [nzStyle]="{ top: '20px' }"
      [(nzVisible)]="isVisibleVersionErrorModal"
      [nzTitle]="'INTERNET_VERSION_ERROR' | translate"
      (nzOnOk)="handleVROk()"
      (nzOnCancel)="handleVRCancel()"
      [nzMaskClosable]="false"
      [nzOkText]="'REFRESH' | translate"
      [nzClosable]="false"
      [nzCancelText]="'DELETE_CANCEL_TEXT' | translate"
    >
      <p>{{ 'INTERNET_VERSION_ERROR_DETAIL' | translate }}</p>
    </nz-modal>
  `,
  styles: [`
    ::ng-deep .vertical-center-modal {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ::ng-deep .vertical-center-modal .ant-modal {
      top: 0;
    }
  `],
  animations: [fadeIn, fadeInDown]
})
export class VersionErrorComponent implements OnInit, OnDestroy, AfterViewInit {
  versionErrorModalSub: Subscription;
  isVisibleVersionErrorModal = false;
  constructor(private store: Store<fromRoot.AppState>) {}

  ngAfterViewInit() {
    this.versionErrorModalSub = this.store.pipe(select(fromRoot.getHelper)).subscribe(showVersionErrorModal => {
      this.isVisibleVersionErrorModal = showVersionErrorModal.showVersionErrorModal;
    });
  }
  showVersionRestartModal(): void {
    this.store.dispatch(new fromHelper.VersionErrorModal());
  }

  handleVROk(): void {
    // eslint-disable-next-line deprecation/deprecation
    location.reload(true);
    this.store.dispatch(new fromHelper.HideVersionErrorModal());
  }

  handleVRCancel(): void {
    this.store.dispatch(new fromHelper.HideVersionErrorModal());
  }

  ngOnInit() {}
  ngOnDestroy() {
    if (this.versionErrorModalSub) {
      this.versionErrorModalSub.unsubscribe();
    }
  }
}
