import { AbstractControl } from '@angular/forms';
import { ApiService } from '@app/core/api.service';
import { Subject } from 'rxjs';
import { filter, distinctUntilChanged, tap, debounceTime, map, mergeMap } from 'rxjs/operators';
class CheckUsernameContainer {
  public username: string;
  public ac: AbstractControl;
  public callback: (isLoading: boolean) => void;
}
export class UsernameValidator {
  static apiService: ApiService = null;
  static checkUsernameSubject = new Subject<CheckUsernameContainer>();
  static checkUsername = UsernameValidator.checkUsernameSubject
    .pipe(
      filter(x => x.username !== ''),
      distinctUntilChanged((x, y) => x.username === y.username),
      tap((x) => {
        x.callback(true);
      }),
      debounceTime(500),
      tap((x) => {
        x.callback(false);
      }),
    )
    .pipe(
      map((user) => {
        user.callback(true);
        return UsernameValidator.apiService.get(`/auth/check/${user.username}/`)
          .pipe(
            map((res: boolean | {}) => {
              return {
                data: user,
                error: !!res
              };
            })
        );
      }),
    )
    .pipe(
      mergeMap(pp => {
        return pp;
      }),
    );
  static Init(apiService: ApiService) {
    UsernameValidator.apiService = apiService;
  }
  public static validate(c: AbstractControl) {
    // Vartotojo vardas turi neleistinu simbolių. Leistini simboliai: raidės, skaičiai ir spec. simboliai "@" "." "+" "-" "_"
    const USERNAME_REGEXP = /^[A-z0-9@\.\+-_]+$/;

    return USERNAME_REGEXP.test(c.value)
      ? null
      : {
          usernameValidator: {
            valid: false
          }
        };
  }
}
