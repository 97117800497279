/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./choice.component.filter";
var styles_ChoiceFilter = [""];
var RenderType_ChoiceFilter = i0.ɵcrt({ encapsulation: 0, styles: styles_ChoiceFilter, data: {} });
export { RenderType_ChoiceFilter as RenderType_ChoiceFilter };
function View_ChoiceFilter_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["style", "margin-top: 3px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "label", [["style", "padding-left: 4px"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "input", [["type", "radio"]], [[8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onButtonPressed(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selected === _v.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); }); }
export function View_ChoiceFilter_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["style", "padding: 5px; border-bottom: 1px solid grey"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Custom Proficiency Filter"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ChoiceFilter_1)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filterValues; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_ChoiceFilter_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-choice-filter", [], null, null, null, View_ChoiceFilter_0, RenderType_ChoiceFilter)), i0.ɵdid(1, 49152, null, 0, i2.ChoiceFilter, [], null, null)], null, null); }
var ChoiceFilterNgFactory = i0.ɵccf("app-choice-filter", i2.ChoiceFilter, View_ChoiceFilter_Host_0, {}, {}, []);
export { ChoiceFilterNgFactory as ChoiceFilterNgFactory };
